import { MapsAPILoader } from '@agm/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatAutocomplete, MatDatepicker, MatDatepickerInputEvent, MatDialogRef } from '@angular/material';
import * as moment from 'moment';
import { Appointment } from '../../../_interfaces';
import { FilterCouplesPipe } from '../../../_pipes';
import { AppointmentsService, HelpersService, MapsService, RenderingService } from '../../../_services';

@Component({
	selector: 'app-calendar-blocking-dialog',
	templateUrl: './calendar-blocking-dialog.component.html',
	styleUrls: ['./calendar-blocking-dialog.component.sass']
})
export class CalendarBlockingDialogComponent implements OnInit, AfterViewInit {

	@ViewChild('titleInput') public titleElement: ElementRef;

	@ViewChild('fromPicker') public fromPicker: MatDatepicker<null>;
	@ViewChild('autoFrom') public autoFrom: MatAutocomplete;

	@ViewChild('timeInput') public inputTime: ElementRef;
	@ViewChild('toPicker') public toPicker: MatDatepicker<null>;

	@ViewChild('mapInput') public mapInputElementRef: ElementRef;
	@ViewChild('coupleInput') public coupleInput: ElementRef;

	public hasTime: boolean;
	public enableTimePicker: boolean;
	public timePickerValues: any[];
	public timePickerDurations: any[];
	public timeStart: String;
	public timeEnd: String;

	public appointmentType: 'appointment' | 'block' = 'appointment';

	public form: FormGroup;
	public couple = new FormControl();

	public rangeFromStartAt;
	public rangeFromMinDate = moment();

	public rangeToStartAt;
	public rangeToMinDate;

	public actEvent: any;
	public editMode: boolean = true;
	public couples: any[];

	public cityId: number;

	public alarmMinutes: Number = 0;

	public saving: boolean;
	public showError: boolean;
	public error: String;
	public deleting: boolean;

	public timeFrom: String;

	constructor(
		private formBuilder: FormBuilder,
		private appointmentsService: AppointmentsService,
		public dialogRef: MatDialogRef<CalendarBlockingDialogComponent>,
		private changeDetectorRef: ChangeDetectorRef,
		private media: MediaMatcher,
		private mapsAPILoader: MapsAPILoader,
		private mapsService: MapsService,
		public renderingService: RenderingService,
		public helpersService: HelpersService,
		private filterCouplesPipe: FilterCouplesPipe,
		private ngZone: NgZone,
		@Inject(MAT_DIALOG_DATA) public data: { couples: any[], selection: any, event?: any }) {

		this.timePickerValues = AppointmentsService.createHalfHourIntervals('00:00', '23:30');
		this.couples = this.data.couples;

		if (this.data.event) {
			this.actEvent = AppointmentsService.clone(this.data.event);
			if (this.actEvent.allDay && this.actEvent.end) {
				const end = moment(this.actEvent.end).subtract(1, 'day');
				this.actEvent.end = end.toDate();
			}

			this.editMode = false;
		}

	}

	public ngOnInit() {
		if (this.editMode) {
			this.buildForm();

			if (this.data.selection) {
				if (!this.data.selection.allDay) {
					this.useTimesForm();
				} else {
					this.useDaysForm();
				}

			}
			this.buildForm();
			this.setRangesStartAt();
		}

	}

	public ngAfterViewInit() {
		if (this.editMode) {
			this.loadMap();
			setTimeout(() => {
				this.titleElement.nativeElement.focus();
			}, 300);
		}
	}

	public setAppointmentType(type) {
		this.appointmentType = type;
	}

	public setRangesStartAt() {
		this.rangeFromStartAt = moment(new Date());
		this.rangeToStartAt = moment(new Date());
	}

	public onDateFromInputClick() {
		if (this.fromPicker) {
			this.fromPicker.open();
		}
	}

	public onDateToInputClick() {
		if (this.toPicker) {
			this.toPicker.open();
		}
	}

	public enableTime() {
		this.enableTimePicker = true;
		this.hasTime = true;
	}

	public displayFn(user?: any): string | undefined {
		if (user) {
			if (user.value) {
				return user.value;
			} else {
				return user;
			}
		} else {
			return undefined;
		}
	}

	public displayFnCouple(couple?: any): string | undefined {
		if (couple) {
			if (couple.accountData) {
				return couple.accountData.firstName;
			} else {
				return couple;
			}
		} else {
			return undefined;
		}
	}

	public timeSelectClick() {
		const timeNow = `${moment(new Date()).format('HH:')}30`;
		let selectionIndex = 0;

		// pre-select and scrollTo current Timespan
		const option = this.autoFrom.options.find((opt, index) => {
			if (opt.value === timeNow) {
				selectionIndex = index;
				return true;
			}
			return false;
		});
		if (option) {
			option.setActiveStyles();
			this.autoFrom._setScrollTop(35 * selectionIndex);
		}
	}

	public pickTimeFrom(time) {
		// set new Time
		time = time.target ? time.target.value : time.option ? time.option.value : time ? time : '00:00';
		let from = this.form.controls['from'].value;
		let to = this.form.controls['to'].value;

		this.timeFrom = time;
		const tc = time.split(':');
		const hours = tc[0];
		const min = tc[1];

		from = moment(from).toDate();
		to = moment(to).toDate();
		from.setHours(hours);
		from.setMinutes(min);

		this.form.controls['from'].patchValue(moment(from));
		if (from.getTime() > to.getTime()) {
			this.form.controls['to'].patchValue(moment(from));
		}

		this.timePickerDurations = [];
		this.timePickerDurations.push({
			title: `${moment(from).add(30, 'minutes').format('HH:mm')} (30 Min.)`,
			value: moment(from).add(30, 'minutes').format('HH:mm'),
			minutes: 30
		});
		this.timePickerDurations.push({
			title: `${moment(from).add(1, 'hour').format('HH:mm')} (1 Std.)`,
			value: moment(from).add(1, 'hour').format('HH:mm'),
			minutes: 60
		});

		for (let index = 3; index < 48; index++) {
			this.timePickerDurations.push({
				title: `${moment(from).add(index * 30, 'minutes').format('HH:mm')} (${(index * 30 / 60).toFixed(1)} Std.)`,
				value: moment(from).add(index * 30, 'minutes').format('HH:mm'),
				minutes: index * 30
			});
		}

		this.enableTime();

		this.rangeToStartAt = from;
		this.rangeToMinDate = from;
	}

	public pickTimeToInp(time) {
		time = time.target ? time.target.value : time.source && time.source.value ? time.source.value : time.option ? time.option.value : '00:00';
		let to = this.form.controls['to'].value;
		const tc = time.split(':');
		const hours = tc[0];
		const min = tc[1];

		to = moment(to).toDate();
		to.setHours(hours);
		to.setMinutes(min);

		this.form.controls['to'].patchValue(moment(to));
	}

	public pickTimeToComp(time) {
		const from = this.form.controls['from'].value;
		let minutes;
		try {
			if (time.minutes) {
				minutes = time.minutes;
			} else if (time.source && time.source.value && time.source.value.minutes) {
				minutes = time.source.value.minutes;
			} else if (time.option && time.option.value && time.option.value.minutes) {
				minutes = time.option.value.minutes;
			}
		} catch (error) {
			minutes = 0;
		}

		const to = moment(from).add(minutes, 'minutes');
		this.form.controls['to'].patchValue(to);
	}

	public onFromChange($event: MatDatepickerInputEvent<any>) {
		this.form.controls['from'].patchValue($event.target.value);
		this.form.controls['to'].patchValue($event.target.value);
		this.rangeToStartAt = $event.target.value;
		this.rangeToMinDate = $event.target.value;
		if (this.toPicker) {
			this.toPicker.open();
		}
	}

	public buildForm() {
		const from = moment(this.data.selection.start);
		const to = moment(this.data.selection.end);

		this.form = this.formBuilder.group({
			'title': [{value: null, disabled: false}, [Validators.required]],
			'from': [{value: from, disabled: false}, [Validators.required]],
			'to': [{value: to, disabled: false}, [Validators.required]],
			'couple': [{value: null, disabled: false}],
			'location': [{value: null, disabled: false}],
			'geo': [{value: null, disabled: false}],
			'comments': [{value: null, disabled: false}]
		});

		this.form.controls['from'].valueChanges.subscribe(
			(value) => {
			}
		);
	}

	public loadMap() {
		this.mapsAPILoader.load().then(() => {

			const autoMainComplete = new google.maps.places.Autocomplete(this.mapInputElementRef.nativeElement);
			autoMainComplete.addListener('place_changed', () => {
				this.ngZone.run(() => {

					const result: google.maps.places.PlaceResult = autoMainComplete.getPlace();

					const componentForm = {
						street_number: 'short_name',
						route: 'long_name',
						locality: 'long_name',
						administrative_area_level_1: 'short_name',
						country: 'long_name',
						postal_code: 'short_name'
					};
					// Get each component of the address from the place details
					// and fill the corresponding field on the form.
					for (let i = 0; i < result.address_components.length; i++) {
						const addressType = result.address_components[i].types[0];
						if (componentForm[addressType]) {
							componentForm[addressType] = result.address_components[i][componentForm[addressType]];
						}
					}

					const location = {
						place: result,
						street: `${componentForm['route']} ${componentForm['street_number']}`,
						zipCode: componentForm['postal_code'],
						state: componentForm['administrative_area_level_1'],
						country: componentForm['country'],
						city: componentForm['locality']
					};

					this.form.controls['geo'].patchValue(location);

					this.parseResult(result);

				});
			});
		});
	}

	public parseResult(result) {
		let city;

		for (const component of result.address_components) {
			const key = component.types[0];

			if (key === 'locality') {
				city = component.long_name;
			}
		}

		if (result.geometry === undefined || result.geometry === null) {
			return;
		}

		this.cityId = result.place_id;
	}

	public onSubmit(form: FormGroup) {
		if (this.appointmentType === 'block') {
			this.form.controls['title'].patchValue('Blocked');
		}

		if (form.valid) {
			const from = moment(form.value.from);
			let to = moment(form.value.to);

			if (moment(from).add(5, 'minutes').isBefore(moment(new Date()))) {
				this.error = 'Der Termin darf nicht in der Vergangenheit starten';
				this.showError = true;
				return;
			}

			if (from.toDate().getTime() > to.toDate().getTime()) {
				this.error = 'Das Terminende darf nicht vor dem Start liegen';
				this.showError = true;
				return;
			}

			if (this.appointmentType === 'appointment') {
				if (form.value.couple && typeof form.value.couple === 'string') {
					this.error = 'Du musst ein Paar aus der Liste auswählen.';
					this.showError = true;
					return;
				}

				//workaround for when a couple whas selected in the input field and cleared again. 
				if(form.value.couple == "")
					form.value.couple = null;

				if (this.mapInputElementRef.nativeElement.value && !form.value.geo) {
					this.error = 'Sie müssen einen Standort aus der Liste auswählen.';
					this.showError = true;
					return;
				}
			}

			if (!this.hasTime || (!this.timeStart && !this.timeEnd)) {
				to = moment(to).add(1, 'day');
			}


			

			if (this.appointmentType === 'appointment') {
				const appointment: Appointment = {
					start: from,
					end: to,
					title: form.value.title,
					couple: form.value.couple,
					comments: form.value.comments,
					location: form.value.location,
					geo: form.value.geo,
					color: '#3a546f',
					allDay: !this.hasTime,
					alarm: this.alarmMinutes
				};

				this.dialogRef.close({type: 'appointment', date: appointment});
			} else if (this.appointmentType === 'block') {
				const busyDate: Appointment = {
					start: from,
					end: to
				};
				this.dialogRef.close({type: 'block', date: busyDate});
			}
		} else {
			if(!form.value.from)
				form.setErrors({'noDates': true});
		}
	}

	public onNewAlarmAppointment(minutes) {
		this.alarmMinutes = minutes;
	}

	public onAlarmAppointment(event, minutes) {
		event.alarm = minutes;
		this.appointmentsService.changeNotification({event: event}).toPromise().catch(e => console.error(e));
	}

	public parseMinutes(minutes) {
		return AppointmentsService.parseMinutes(minutes);
	}

	public deleteAppointment() {
		if (this.actEvent && this.actEvent.favId) {
			alert('Bitte löschen Sie diesen Termin innerhalb der Konversation');
		} else {
			this.deleting = true;
			this.dialogRef.close({type: 'delete', date: this.actEvent});
		}
	}

	// private _coupleFilter(value: any): any[] {
	// 	const filterValue = value.accountData ? value.accountData.firstName.toLowerCase() : value.toLowerCase();
	// 	return this.couples ? this.couples.filter(option => option.accountData.firstName.toLowerCase().includes(filterValue)) : [];
	// }
	public onToDateChange($event: MatDatepickerInputEvent<any & null>) {
		this.form.controls['to'].patchValue($event.target.value);
	}

	private useTimesForm() {
		this.enableTime();

		if (this.data.selection.start) {
			const startTime = moment(this.data.selection.start).format('HH:mm');
			this.timeStart = startTime;
			this.pickTimeFrom(startTime);
		}

		if (this.data.selection.end) {
			const endTime = moment(this.data.selection.end).format('HH:mm');
			this.timeEnd = endTime;
		}
	}

	private useDaysForm() {
		const start = moment(this.data.selection.start);
		const end = moment(this.data.selection.end).subtract(1, 'day');
		this.data.selection.end = end.toDate();
		const startStr = start.format('dd.MM.YYYY');
		const endStr = end.format('dd.MM.YYYY');
		if (startStr !== endStr) {
			this.enableTime();
		}
	}
}
