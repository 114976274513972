export const CATEGORIES = {
  BACHELOR: 'bachelor',
  BRIDALWEAR: 'bridalwear',
  CAKES: 'cakes',
  CARS: 'cars',
  ENTERTAINMENT: 'entertainment',
  CHURCH: 'church',
  DEKO: 'deko',
  CIVIL: 'civil',
  FLORISTS: 'florists',
  GIFT: 'gift',
  LOCATIONS: 'locations',
  MUSIC: 'music',
  OFFICIANTS: 'officiants',
  PAPETERIE: 'papeterie',
  HONEYMOON: 'honeymoon',
  JEWELRY: 'jewelry',
  PHOTOBOX: 'photobox',
  VIDEOGRAPHY: 'videography',
  PLANNING: 'planning',
  STYLING: 'styling',
  CATERING: 'catering',
  PHOTOGRAPHY: 'photography'
};
