export interface BlockedDate {
	allDay: true;
	backgroundColor: string;
	start: Date;
	end: Date;
	rendering: 'background';
	title: 'busy day';
}

export class BlockedDateInstance implements BlockedDate {
	public allDay: true;
	public backgroundColor: string;
	public end: Date;
	public rendering: 'background';
	public start: Date;
	public title: 'busy day';

	constructor(end: Date, start: Date, backgroundColor: string) {
		this.allDay = true;
		this.backgroundColor = backgroundColor;
		this.end = end;
		this.rendering = 'background';
		this.start = start;
		this.title = 'busy day';
	}
}
