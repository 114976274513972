import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AppointmentsService } from '../../../_services/appointments.service';
import { InquiriesService } from '../../../_services/inquiries.service';
import { ShopsService } from '../../../_services/shops.service';

@Component({
	selector: 'app-auto-appointment-dialog',
	templateUrl: './auto-appointment-dialog.component.html',
	styleUrls: ['./auto-appointment-dialog.component.sass']
})
export class AutoAppointmentDialogComponent implements OnInit {

	public autoAppointments: boolean;
	public opening_hours: { open_now: String, periods: { close: { day: Number, time: String }, open: { day: Number, time: String } }[], weekday_text: [String] };
	// this.opening_hours = {
	//   open_now: "closed",
	//   periods: [
	//     {open: {day: 1, time: "10:00"}, close: {day: 1, time: "18:00"}},
	//     {open: {day: 2, time: "10:00"}, close: {day: 2, time: "18:00"}},
	//     {open: {day: 3, time: "10:00"}, close: {day: 3, time: "18:00"}},
	//     {open: {day: 4, time: "10:00"}, close: {day: 4, time: "18:00"}},
	//     {open: {day: 5, time: "10:00"}, close: {day: 5, time: "18:00"}}
	//   ],
	//   weekday_text: [""]
	// };

	public table = [
		{day: 0, title: 'Sonntag', open: false, times: []},
		{day: 1, title: 'Montag', open: false, times: []},
		{day: 2, title: 'Dienstag', open: false, times: []},
		{day: 3, title: 'Mittwoch', open: false, times: []},
		{day: 4, title: 'Donnerstag', open: false, times: []},
		{day: 5, title: 'Freitag', open: false, times: []},
		{day: 6, title: 'Samstag', open: false, times: []}
	];

	constructor(
		public dialogRef: MatDialogRef<AutoAppointmentDialogComponent>,
		private shopsService: ShopsService,
		private appointmentService: AppointmentsService,
		private inquiriesService: InquiriesService,
		@Inject(MAT_DIALOG_DATA) public data: any) {

	}

	public ngOnInit() {
		this.autoAppointments = this.shopsService.currentShop.autoAppointments;
		this.parseTo(this.shopsService.currentShop.opening_hours);
	}

	public parseTo(periodTable) {
		for (const period of periodTable.periods) {
			this.table[period.open.day].open = true;
			this.table[period.open.day].times.push({timeFrom: period.open.time, timeTo: period.close.time});
		}
	}

	public parseTable() {
		const tmp_opening_hours: any = {open_now: '', periods: [], weekday_text: []};

		for (let index = 0; index < this.table.length; index++) {
			const day = this.table[index];
			if (day.open) {
				for (let timeIndex = 0; timeIndex < day.times.length; timeIndex++) {
					const time = day.times[timeIndex];
					tmp_opening_hours.periods.push({open: {day: day.day, time: time.timeFrom}, close: {day: day.day, time: time.timeTo}});
				}
			}
		}

		return tmp_opening_hours;
	}

	public changeAutoAppointments() {
		this.autoAppointments = !this.autoAppointments;
	}

	public changeOpeningStatus(day) {
		day.open = !day.open;
		if (day.open && day.times.length <= 0) {
			day.times.push({timeFrom: '10:00', timeTo: '18:00'});
		} else {

		}
	}

	public addTime(day) {
		day.times.push({timeFrom: '10:00', timeTo: '18:00'});
	}

	public removeTime(day, index) {
		day.times.splice(index, 1);
	}

	public submit() {
		this.shopsService.currentShop.autoAppointments = this.autoAppointments;
		this.shopsService.currentShop.opening_hours = this.parseTable();
		this.shopsService.updateShop(this.shopsService.currentShop).subscribe((res) => {
			this.dialogRef.close(false);
		});
	}
}
