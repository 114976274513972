<p>
    
</p>
<div class="centered">
    <mat-icon [svgIcon]="'smiley-in-love'" class="sad-icon"></mat-icon>

    <h1 mat-dialog-title style="text-align: center">Fast geschafft!</h1>

    <p>Damit dein Profil veröffentlicht werden kann, ergänze bitte noch folgende Angaben:</p>
    <!--<div *ngIf="error" class="infoBox">-->
    <!--<mat-icon>error_outline</mat-icon>-->
    <!--<span style="margin-left: 5px">{{error}}</span>-->
    <!--</div>-->

    <p  class="infoBox" *ngFor="let error of errors">{{error.message}}</p>

    <div mat-dialog-actions>
        <div class="supportBox">
            <span>Wenn du Fragen hast, wende dich bitte direkt an den Weddy-Support<br> unter (0)30 4036 6899.</span>
            <button (click)="onClose()" color="accent" mat-button mat-raised-button>Alles klar!</button>
        </div>
    </div>
</div>

