import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, Observer } from 'rxjs';

export interface ScriptModel {
	name: string;
	src: string;
	loaded?: boolean;
	defer?: boolean;
}

export const SCRIPTS = {
	PEP: 'PEP',
	COOKIE_CONSENT: 'cookieConsent',
	CONSENT: 'consentPopUp',
	CRISP: 'crisp',
	G_TAG: 'googleTag',
	FONTS: 'fonts',
	STRIPE_LOADER: 'StripeLoader',
	STRIPE: 'Stripe'
};

@Injectable({
	providedIn: 'root'
})
export class ScriptsService {

	public scripts: ScriptModel[] = [];

	private scriptsLibrary: ScriptModel[] = [
		{name: SCRIPTS.PEP, src: `https://code.jquery.com/pep/0.4.3/pep.min.js`, defer: true},
		{name: SCRIPTS.COOKIE_CONSENT, src: `//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.0.3/cookieconsent.min.js`, defer: true},
		{name: SCRIPTS.CONSENT, src: `./scripts/consent.js`, defer: true},
		{name: SCRIPTS.CRISP, src: `./scripts/crisp.js`, defer: true},
		{name: SCRIPTS.G_TAG, src: `./scripts/g-tag.js`, defer: true},
		{name: SCRIPTS.FONTS, src: `./scripts/fonts.js`, defer: true},
		{name: SCRIPTS.STRIPE, src: `https://js.stripe.com/v3/`},
		{name: SCRIPTS.STRIPE_LOADER, src: `./scripts/stripe-loader.js`, defer: true}
	];

	constructor(@Inject(PLATFORM_ID) private platformId: Object) {
	}

	public load(scriptName: string): Observable<ScriptModel> {
		return new Observable<ScriptModel>((observer: Observer<ScriptModel>) => {
			const script = this.scriptsLibrary.find(aScript => aScript.name === scriptName);
			if (!script) {
				return;
			}
			const existingScript = this.scripts.find(s => s.name === script.name);

			// Complete if already loaded
			if (existingScript && existingScript.loaded) {
				observer.next(existingScript);
				observer.complete();
			} else {
				// Add the script
				this.scripts = [...this.scripts, script];

				// Load the script
				const scriptElement = document.createElement('script');
				scriptElement.id = script.name;
				scriptElement.type = 'text/javascript';
				scriptElement.src = script.src;

				scriptElement.onload = () => {
					script.loaded = true;
					observer.next(script);
					observer.complete();
				};

				scriptElement.onerror = (error: any) => {
					console.log(`Error while loading ${script.name} script!`, error);
					observer.error('Couldn\'t load script ' + script.src);
				};

				document.getElementsByTagName('body')[0].appendChild(scriptElement);
			}
		});
	}

}
