import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../_material/material.module';
import { SharedComponentsModule } from '../../components/shared-components.module';
import { ErrorComponent } from './error.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		SharedComponentsModule,
		MaterialModule,
		TranslateModule.forChild()
	],
	declarations: [
		ErrorComponent,
	]
})
export class ErrorModule {
}
