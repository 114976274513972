import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-invoice-dialog',
	templateUrl: './invoice-dialog.component.html',
	styleUrls: ['./invoice-dialog.component.sass']
})
export class InvoiceDialogComponent implements OnInit {

	safeIFrameUrl: SafeResourceUrl;

	constructor(
    public sanitizer: DomSanitizer,
		public dialogRef: MatDialogRef<InvoiceDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
	}

	public ngOnInit() {
    this.safeIFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data);
	}

}
