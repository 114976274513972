<div *ngIf="(inquiriesToReport | async)?.length > 0" class="banner">

    <mat-expansion-panel [expanded]="true" >
            <!-- [class.serious-warning]="isSeriousWarning" [class.warning]="isWarning" -->
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon *ngIf="isWarning || isSeriousWarning">warning</mat-icon>
                Monatliche Rückmeldung
            </mat-panel-title>
            <mat-panel-description>
                <span *ngIf="!inquiry">
                    {{ ((inquiriesToReport | async)?.length) > 1 ? ((inquiriesToReport | async)?.length) + ' offene Rückmeldungen' : '1 offene Rückmeldung' }}
                </span>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="content-wrapper">
            <div class="item">
                <h4>
                    {{ 'STATUS_REPORT_BANNER.TIP_HEADER_1' | translate }}
                </h4>
                <p>
                    {{ 'STATUS_REPORT_BANNER.TIP_PARAGRAPH_1' | translate }}
                </p>
            </div>
            <div class="item">
                <h4>
                    {{ 'STATUS_REPORT_BANNER.TIP_HEADER_2' | translate }}
                </h4>
                <p>
                    {{ 'STATUS_REPORT_BANNER.TIP_PARAGRAPH_2' | translate }}
                </p>
            </div>
            <div class="item">
                <h4>
                    {{ 'STATUS_REPORT_BANNER.TIP_HEADER_3' | translate }}
                </h4>
                <p>
                    {{ 'STATUS_REPORT_BANNER.TIP_PARAGRAPH_3' | translate }}
                </p>
            </div>
        </div>

        <mat-tab-group #tabGroup [dynamicHeight]="false">
            <mat-tab label="First">
                <div class="item">
                    <h4>
                        {{ 'STATUS_REPORT_BANNER.TIP_HEADER_1' | translate }}
                    </h4>
                    <p>
                        {{ 'STATUS_REPORT_BANNER.TIP_PARAGRAPH_1' | translate }}
                    </p>
                </div>
                <div  class="tip-actions">
                        <!-- [class.serious-warning]="isSeriousWarning" [class.warning]="isWarning" -->
                    <button (click)="tabGroup.selectedIndex = 1" class="next-button" mat-button>
                        {{ 'BUTTON.NEXT_TIP' | translate }}
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </button>
                </div>
            </mat-tab>
            <mat-tab label="Second">
                <div class="item">
                    <h4>
                        {{ 'STATUS_REPORT_BANNER.TIP_HEADER_2' | translate }}
                    </h4>
                    <p>
                        {{ 'STATUS_REPORT_BANNER.TIP_PARAGRAPH_2' | translate }}
                    </p>
                </div>
                <div  class="tip-actions">
                        <!-- [class.serious-warning]="isSeriousWarning" [class.warning]="isWarning" -->
                    <button (click)="tabGroup.selectedIndex = 0" class="previous-button" mat-button>
                        <mat-icon>keyboard_arrow_left</mat-icon>
                        {{ 'BUTTON.PREVIOUS_TIP' | translate }}
                    </button>
                    <button (click)="tabGroup.selectedIndex = 2" class="next-button" mat-button>
                        {{ 'BUTTON.NEXT_TIP' | translate }}
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </button>
                </div>
            </mat-tab>
            <mat-tab label="Third">
                <div class="item">
                    <h4>
                        {{ 'STATUS_REPORT_BANNER.TIP_HEADER_3' | translate }}
                    </h4>
                    <p>
                        {{ 'STATUS_REPORT_BANNER.TIP_PARAGRAPH_3' | translate }}
                    </p>
                </div>
                <div  class="tip-actions">
                    <!-- [class.serious-warning]="isSeriousWarning" [class.warning]="isWarning" -->
                    <button (click)="tabGroup.selectedIndex = 1" class="previous-button" mat-button>
                        <mat-icon>keyboard_arrow_left</mat-icon>
                        {{ 'BUTTON.PREVIOUS_TIP' | translate }}
                    </button>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-expansion-panel>

    <div *ngIf="nextInquiry" class="next-inquiry-wrapper">
        <mat-card class="next-inquiry-card"  (click)="openDetails()">
            <div class="first-block">
                <app-avatar [diameter]="40" [imagePath]="nextInquiry?.suggestedFor.accountData.imgSrc"
                            [name]="nextInquiry?.suggestedFor.accountData.firstName">
                </app-avatar>

                <a #textArea  class="text-area">
                    <h3>
                        {{ nextInquiry?.suggestedFor.accountData.firstName }}
                    </h3>
                    <p [style.width.px]="textArea.scrollWidth - 20" class="last-message">
                        {{ nextInquiry?.lastMessagePreview?.data?.text }}
                    </p>
                </a>
            </div>

            <div class="data-items">
                <div class="data-item">
                    <div class="title">
                        Hochzeitsdatum
                    </div>
                    <div class="value">
                        {{ (nextInquiry?.suggestedFor.weddingData.weddingDate | date:'MMM d, y') || nextInquiry?.weddingSeason }}
                    </div>
                </div>
                <div class="data-item">
                    <div class="title">
                        Budget
                    </div>
                    <div class="value">
                        {{ nextInquiry?.requestData?.budget | customCurrency:'EUR':'symbol':'1.0-0' }}
                    </div>
                </div>
                <div class="data-item">
                    <div class="title">
                        Gäste
                    </div>
                    <div class="value">
                        {{ 'INQUIRIES.TABLE.ROW.GUESTS' | translate:{x: nextInquiry?.requestData?.numberOfGuests} }}
                    </div>
                </div>
                <div class="data-item">
                    <div class="title">
                        Budget pro Gast
                    </div>
                    <div class="value">
                        {{ nextInquiry?.requestData?.numberOfGuests ? ('INQUIRIES.TABLE.ROW.BUDGET_PER_GUEST' | translate:{x: (nextInquiry?.requestData?.budget | pricePerPersonPipe:nextInquiry?.requestData?.numberOfGuests:'EUR':'symbol':'1.2-2')}) : ('UNKNOWN_BUDGET_PER_PERSON' | translate) }}
                    </div>
                </div>
                <div class="data-item">
                    <div class="title">
                        Letzter Kontakt
                    </div>
                    <div class="value">
                        {{ (nextInquiry?.lastMessagePreview.createdAt || nextInquiry?.openedDate || nextInquiry?.createdAt) | amLocale:'de' | amTimeAgo }}
                    </div>
                </div>
            </div>
        </mat-card>
        <div class="next-inquiry-actions">
            <span style="margin-left: 5px;margin-right: 10px;margin-bottom: 15px">Klicke auf einen der 3 Buttons</span>
            <button (click)="onDecline(nextInquiry)" [matTooltip]="'TOOLTIP.REPORT_DECLINE' | translate" class="decline"
                    mat-button
                    mat-raised-button
                    matTooltipPosition="below"
                    matTooltipShowDelay="500">
                <mat-icon [svgIcon]="'status-report-declined'"></mat-icon>
                Abgelehnt
            </button>
            <button (click)="onContact(nextInquiry)" [matTooltip]="'TOOLTIP.REPORT_CONTACT' | translate" class="contact"
                    mat-button
                    mat-raised-button
                    matTooltipPosition="below"
                    matTooltipShowDelay="500">
                <mat-icon [svgIcon]="'status-report-open'"></mat-icon>
                In Verhandlung
            </button>
            <button (click)="onBooked(nextInquiry)" [matTooltip]="'TOOLTIP.REPORT_BOOKED' | translate" class="booked"
                    mat-button
                    mat-raised-button
                    matTooltipPosition="below"
                    matTooltipShowDelay="500">
                <mat-icon [svgIcon]="'status-report-booked'"></mat-icon>
                Gebucht
            </button>
        </div>
    </div>
</div>

<div *ngIf="(inquiriesToReport | async)?.length === 0" class="banner">
    <mat-card class="thank-you">
        <mat-icon [svgIcon]="'check-circle-1'"></mat-icon>
        <h3>
            Vielen Dank!
        </h3>
        <p>
            Alle Brautpaar Rückmeldungen sind für diesen Monat abgeschlossen.
        </p>
    </mat-card>
</div>
