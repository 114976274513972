import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Appointment, Couple } from '../_interfaces';
import { ApiService } from './api.service';
import { LocalStorageService } from './local-storage.service';
import { ShopsService } from './shops.service';

@Injectable({
	providedIn: 'root'
})
export class AppointmentsService {

	get appointments(): Appointment[] {
		return this._appointments;
	}

	set appointments(newAppointments: Appointment[]) {
		this._appointments = newAppointments;
		this.appointmentsChanged.next(this.appointments);
	}

	get freeDates(): any[] {
		return this._freeDates;
	}

	set freeDates(newDates: any[]) {
		this._freeDates = newDates;
		this.freeDatesChanged.next(this.freeDates);
	}

	get couples(): Couple[] {
		return this._couples;
	}

	set couples(newCouples: Couple[]) {
		this._couples = newCouples;
		this.couplesChanged.next(this.couples);
	}

	public appointmentsChanged = new BehaviorSubject<Appointment[]>(this.appointments);
	public freeDatesChanged = new BehaviorSubject<Appointment[]>(this.freeDates);
	public couplesChanged = new BehaviorSubject<any[]>(this.couples);

	private _appointments: Appointment[];

	private _freeDates: any[];

	private _couples: Couple[];

	constructor(public api: ApiService,
				private shopsService: ShopsService,
				private localStorageService: LocalStorageService,
				@Inject(PLATFORM_ID) private platformId: any) {
	}

	public static doubleZeros(time) {
		return ('0' + time).slice(-2);
	}

	public static clone(obj) {
		if (null == obj || 'object' !== typeof obj) {
			return obj;
		}
		const copy = obj.constructor();
		for (const attr in obj) {
			if (obj.hasOwnProperty(attr)) {
				copy[attr] = obj[attr];
			}
		}
		return copy;
	}

	public static parseMinutes(minutes) {
		if (!minutes || minutes <= 0) {
			return 'unmittlebar';
		} else if (minutes < 60) {
			return minutes + ' Minuten';
		} else {
			return (minutes / 60) + ' Std.';
		}
	}

	// deleteAppointment(appointment: Appointment) {
	// 	const req = this.api.delete('appointments', appointment._id, true);

	// 	req.subscribe(
	// 		() => {
	// 			this.removeAppointment(appointment._id);
	// 		},
	// 		(err) => {
	// 			console.error(err);
	// 		}
	// 	);

	// 	return req;
	// }

	public static createHalfHourIntervals(from, until) {
		// "01/01/2001" is just an arbitrary date
		until = Date.parse('01/01/2001 ' + until);
		from = Date.parse('01/01/2001 ' + from);
		// *2 because because we want every 30 minutes instead of every hour
		const max = (Math.abs(until - from) / (60 * 60 * 1000)) * 2;
		const time = new Date(from);
		const hours = [];
		for (let i = 0; i <= max; i++) {
			// doubleZeros just adds a zero in front of the value if it's smaller than 10.
			const hour = AppointmentsService.doubleZeros(time.getHours());
			const minute = AppointmentsService.doubleZeros(time.getMinutes());
			hours.push(hour + ':' + minute);
			time.setMinutes(time.getMinutes() + 30);
		}
		return hours;
	}

	public getAppointment(appointmentId) {
		return this.api.get(`appointments/${appointmentId}`, null, false, true);
	}

	public getFreeDates(shopId: string) {
		const req = this.api.get(`pro_freeDates/${shopId}`, null, true, true);

		req.subscribe(
			(freeDates: any[]) => {
				this.freeDates = freeDates;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public updateFreeDates(shopId: string, body: any) {
		return this.api.post(`pro_freeDatesUpdate/${shopId}`, body, true);
	}

	public getAppointments(shopId: string, date: string) {
		const req = this.api.get(`appointmentsBV/${shopId}/${date}`, null, true, true);

		req.subscribe(
			(appointments: Appointment[]) => {
				this.appointments = appointments;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public getCouples(shopId: string): Observable<Couple[]> {
		const req = this.api.get(`pro_couples/${shopId}`, null, false, true);

		req.subscribe(
			(couples: Couple[]) => {
				this.couples = couples;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	// ADD

	public addAppointment(appointment: Appointment) {
		const index = (this.appointments.findIndex((anyAppointment: Appointment) => {
			return anyAppointment._id === appointment._id;
		}));
		if (index === -1) {
			this.appointments.push(appointment);
		}
	}

	public postAppointment(appointment: any): Observable<any> {
		return this.api.post('pro_appointment', appointment, true);
	}

	public postCustomAppointment(shopId: string, event: any): Observable<any> {
		return this.api.post('pro_customAppointment', {shopId: this.shopsService.currentShop._id, event: event}, true);
	}

	public deleteAppointment(appointment: any): Observable<any> {
		return this.api.post('pro_appointmentDelete', appointment, true);
	}

	public declineSuggestAppointment(appointment: any): Observable<any> {
		return this.api.post('pro_appointmentDeclineSuggest', appointment, true);
	}

	public acceptAppointment(appointment: any): Observable<any> {
		return this.api.post('pro_appointmentAccept', appointment, true);
	}

	public declineAppointment(appointment: { messageId: string; favId: string }) {
		return this.api.post('pro_appointmentDecline', appointment, true);
	}

	public withdrawAppointment(appointment: any): Observable<any> {
		return this.api.post('pro_appointmentWithdraw', appointment, true);
	}

	public cancelAppointment(appointment: any): Observable<any> {
		return this.api.post('pro_appointmentCancel', appointment, true);
	}

	public changeNotification(data: any): Observable<any> {
		return this.api.post('pro_appointmentNotification', data, true);
	}

	public getAppointmentsNumber(range: { shopId: string, start: Date, end: Date }): Observable<any> {
		return this.api.post('pro_appointmentsForRange', range, true);
	}

	// EDIT

	public editAppointment(appointment: Appointment) {
		const index = (this.appointments.findIndex((anyAppointment: Appointment) => {
			return anyAppointment._id === appointment._id;
		}));
		if (index >= 0) {
			this.appointments[index] = appointment;
		}
	}

	public updateAppointment(appointment: Appointment) {
		const req = this.api.put('appointments', appointment, true);

		req.subscribe(
			(res) => {
				this.editAppointment(res.accommodation);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	// REMOVE

	public removeAppointment(id) {
		const index = (this.appointments.findIndex((anyAppointment: Appointment) => {
			return anyAppointment._id === id;
		}));
		if (index >= 0) {
			this.appointments.splice(index, 1);
		}
	}

}
