import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { AutoAnswerSettingsComponent } from '../../../../dashboard/settings/auto-answer-settings/auto-answer-settings.component';

@Component({
	selector: 'app-auto-answer-dialog',
	templateUrl: './auto-answer-dialog.component.html',
	styleUrls: ['./auto-answer-dialog.component.sass']
})
export class AutoAnswerDialogComponent implements OnInit {

	public doSaveSubject: Subject<void> = new Subject<void>();
	@ViewChild(AutoAnswerSettingsComponent) public answerSettings: AutoAnswerSettingsComponent;

	constructor(public dialogRef: MatDialogRef<AutoAnswerDialogComponent>,
	            @Inject(MAT_DIALOG_DATA) public fav: any) {
	}

	public ngOnInit() {
	}

	public onCancel() {
		this.close();
	}

	public close() {
		this.dialogRef.close();
	}

	public save() {
		this.doSaveSubject.next();
	}

	public error(message) {
		console.log(message);
	}

}
