import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService, LocalStorageService, ShopsService, TokenService } from '../_services';

@Injectable()
export class AuthGuard implements CanActivate {

	constructor(private router: Router,
				private route: ActivatedRoute,
				private localStorageService: LocalStorageService,
				private tokenService: TokenService,
				private authenticationService: AuthenticationService,
				private shopsService: ShopsService,
				@Inject(PLATFORM_ID) private platformId: Object) {
	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (isPlatformBrowser(this.platformId)) {
			const tokenInUrl = this.authenticationService.authenticateThroughURL(route);
			ShopsService.findShopIdAndRedirect(route);

			if (this.tokenService.token) {
				if (tokenInUrl) {
					this.router.navigate([state.url.replace(/\?.+/, '')], {queryParams: {tok: null}, queryParamsHandling: 'merge'});
					return true;
				} else {
					return true;
				}
			} else {
				this.router.navigate(['/welcome'], {queryParams: {returnUrl: state.url}, queryParamsHandling: 'merge'});
				return false;
			}
		} else {
			this.router.navigate(['/loading'], {queryParams: {returnUrl: state.url}, queryParamsHandling: 'merge'});
			return false;
		}
	}

}
