import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnInit, PLATFORM_ID } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class FullScreenService implements OnInit {

	get isFullScreen(): boolean {
		return this._isFullScreen;
	}

	set isFullScreen(value: boolean) {
		// if (!this.elem) {
		// 	this.elem = this.document.documentElement;
		// }
		//
		// if (isPlatformBrowser(this.platformId) && value === true && this.elem) {
		// 	this.openFullscreen();
		// }
		// if (isPlatformBrowser(this.platformId) && value === false && this.isFullScreen && this.elem) {
		// 	this.closeFullscreen();
		// }

		this._isFullScreen = value;
	}

	private elem;

	private _isFullScreen: boolean;

	constructor(@Inject(PLATFORM_ID) private platformId: Object,
				@Inject(DOCUMENT) private document: any) {
	}

	public ngOnInit(): void {
		this.elem = this.document.documentElement;
	}

	// openFullscreen() {
	// 	if (!this.elem) {
	// 		console.error('openFullscreen did not work');
	// 		return;
	// 	}
	//
	// 	if (this.elem.requestFullscreen) {
	// 		this.elem.requestFullscreen();
	// 	} else if (this.elem.mozRequestFullScreen) {
	// 		this.elem.mozRequestFullScreen();
	// 	} else if (this.elem.webkitRequestFullscreen) {
	// 		this.elem.webkitRequestFullscreen();
	// 	} else if (this.elem.msRequestFullscreen) {
	// 		this.elem.msRequestFullscreen();
	// 	}
	// }

	// closeFullscreen() {
	// 	if (!this.document) {
	// 		console.error('closeFullscreen did not work');
	// 		return;
	// 	}
	// 	if (this.document.exitFullscreen) {
	// 		this.document.exitFullscreen();
	// 	} else if (this.document.mozCancelFullScreen) {
	// 		this.document.mozCancelFullScreen();
	// 	} else if (this.document.webkitExitFullscreen) {
	// 		this.document.webkitExitFullscreen();
	// 	} else if (this.document.msExitFullscreen) {
	// 		this.document.msExitFullscreen();
	// 	}
	// }

}
