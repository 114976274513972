import { CurrencyPipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDatepicker, MatDialogRef } from '@angular/material';
import { environment } from '../../../../../environments/environment';
import { Inquiry } from '../../../_interfaces/inquiry.interface';
import { Message } from '../../../_interfaces/message.interface';
import { Payload } from '../../../_interfaces/payload.interface';
import { FormsHelpersService } from '../../../_services/forms-helpers.service';
import { OffersService } from '../../../_services/offers.service';
import { DateValidator } from '../../../_validators/date.validator';

@Component({
	selector: 'app-offer-dialog',
	templateUrl: './offer-dialog.component.html',
	styleUrls: ['./offer-dialog.component.sass']
})
export class OfferDialogComponent implements OnInit {

	@ViewChild('startPicker') public startPicker: MatDatepicker<null>;

	public cdnPath: string = `${environment.cdn}/resize`;

	public minPrice: number = 0;
	public maxPrice: number = 999999999;
	public textMinLength: number = 5;
	public textMaxLength: number = 1000;

	public processing: boolean = false;
	public success: boolean = false;
	public editMode: boolean = false;
	public hovering: boolean = false;

	public form: FormGroup;

	public offer: any;

	constructor(private formBuilder: FormBuilder,
	            private offersService: OffersService,
	            private currencyPipe: CurrencyPipe,
	            public dialogRef: MatDialogRef<OfferDialogComponent>,
	            @Inject(MAT_DIALOG_DATA) public inquiry: Inquiry) {
	}

	public ngOnInit() {
		this.buildForm();
	}

	public buildForm() {
		this.form = this.formBuilder.group({
			'text': [null, [Validators.required, Validators.minLength(this.textMinLength), Validators.maxLength(this.textMaxLength)]],
			'price': [null, [Validators.min(this.minPrice), Validators.max(this.maxPrice)]],
			'date': [null, [DateValidator()]],
			'currency': ['EUR'],
			'payloads': [[]]
		});
	}

	public onPriceBlur() {
		if (this.form.get('price').value) {
			this.form.get('price').setValue(this.currencyPipe.transform(parseFloat(this.form.get('price').value.replace('.', '').replace(',', '.').replace(' ', '')), 'EUR', 'symbol'));
		}
	}

	public onUploaded(fileName: string | Payload) {
		const array = this.form.controls['payloads'].value;
		array.push(fileName);
		this.form.controls['payloads'].patchValue(array);
	}

	public submit(message: Message) {
		this.processing = true;
		this.form.disable();
		this.offersService.postOffer(message).subscribe(
			(res) => {
				this.processing = false;
				this.editMode = false;
				this.success = true;

				this.dialogRef.close();

				setTimeout(() => {
					this.success = false;
				}, 1000);
			},
			(err) => {
				this.processing = false;
				this.form.enable();
				this.form.setErrors({'somethingWentWrong': true});
			});
	}

	public onNoClick(): void {
		this.dialogRef.close();
	}

	public onSubmit(form: FormGroup) {
		if (form.valid) {
			const message: Message = {
				mType: 'offer',
				subject: 'offer',
				inquiryId: this.inquiry._id,
				message: form.controls['text'].value,
				data: {
					text: form.controls['text'].value,
					payloads: form.controls['payloads'].value,
					offer: {
						price: form.controls['price'].value ? parseFloat(form.controls['price'].value.replace(' ', '').replace('.', '').replace(',', '.')) : null,
						currency: form.controls['currency'].value,
						date: form.controls['date'].value
					}
				}
			};

			this.submit(message);
		} else {
			this.form.markAsTouched();
			FormsHelpersService.markControlsAsTouched(this.form);
		}
	}

	public onDateInputClick(event) {
		event.preventDefault();
		event.stopPropagation();
		this.startPicker.open();
	}

	public onCancel() {
		this.dialogRef.close();
	}

}
