import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
	selector: 'app-refresh-dialog',
	templateUrl: './refresh-dialog.component.html',
	styleUrls: ['./update-dialog.component.sass']
})
export class RefreshDialogComponent {

	constructor(public dialogRef: MatDialogRef<RefreshDialogComponent>) {
	}

	public onRefresh() {
		window.location.reload();
	}

	public onDismiss() {
		this.dialogRef.close();
	}

}
