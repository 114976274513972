// To update this file, simply put the svg icon in the icons folder an run node extract.icons.js
// Updated at Wed Sep 07 2022 14:49:52 GMT+0300 (Eastern European Summer Time)

export const ICONS = {
  "ADD_SQUARE": {
    "KEY": "add-square",
    "PATH": "icons/stroke-version/01-Interface-Essential/43-Remove-Add/add-square.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/43-Remove-Add/add-square.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/43-Remove-Add/add-square.svg"
  },
  "ALARM_CLOCK_1": {
    "KEY": "alarm-clock-1",
    "PATH": "icons/stroke-version/01-Interface-Essential/20-Alert/alarm-clock-1.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/20-Alert/alarm-clock-1.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/20-Alert/alarm-clock-1.svg"
  },
  "APP_WINDOW_REFRESH": {
    "KEY": "app-window-refresh",
    "PATH": "icons/stroke-version/04-Programing-Apps-Websites/12-Apps/app-window-refresh.svg",
    "STROKE_PATH": "icons/stroke-version/04-Programing-Apps-Websites/12-Apps/app-window-refresh.svg",
    "OUTLINE_PATH": "icons/outline-version/04-Programing-Apps-Websites/12-Apps/app-window-refresh.svg"
  },
  "ARROW_LEFT": {
    "KEY": "arrow-left",
    "PATH": "icons/stroke-version/52-Arrows-Diagrams/01-Arrows/arrow-left.svg",
    "STROKE_PATH": "icons/stroke-version/52-Arrows-Diagrams/01-Arrows/arrow-left.svg",
    "OUTLINE_PATH": "icons/outline-version/52-Arrows-Diagrams/01-Arrows/arrow-left.svg"
  },
  "ARROW_LEFT_1": {
    "KEY": "arrow-left-1",
    "PATH": "icons/stroke-version/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg",
    "STROKE_PATH": "icons/stroke-version/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg",
    "OUTLINE_PATH": "icons/outline-version/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg"
  },
  "ARROW_RIGHT_1": {
    "KEY": "arrow-right-1",
    "PATH": "icons/stroke-version/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg",
    "STROKE_PATH": "icons/stroke-version/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg",
    "OUTLINE_PATH": "icons/outline-version/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg"
  },
  "ATTACHMENT": {
    "KEY": "attachment",
    "PATH": "icons/stroke-version/01-Interface-Essential/27-Link-Unlink/attachment.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/27-Link-Unlink/attachment.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/27-Link-Unlink/attachment.svg"
  },
  "BEACH_PALM_WATER": {
    "KEY": "beach-palm-water",
    "PATH": "icons/stroke-version/43-Outdoors-Recreation/01-Beach/beach-palm-water.svg",
    "STROKE_PATH": "icons/stroke-version/43-Outdoors-Recreation/01-Beach/beach-palm-water.svg",
    "OUTLINE_PATH": "icons/outline-version/43-Outdoors-Recreation/01-Beach/beach-palm-water.svg"
  },
  "BIG_GREEN_CHECKMARK": {
    "KEY": "big-green-checkmark",
    "PATH": "icons/special/big-green-checkmark.svg"
  },
  "BIN_1": {
    "KEY": "bin-1",
    "PATH": "icons/stroke-version/01-Interface-Essential/23-Delete/bin-1.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/23-Delete/bin-1.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/23-Delete/bin-1.svg"
  },
  "BOOK_OPEN_BOOKMARK": {
    "KEY": "book-open-bookmark",
    "PATH": "icons/stroke-version/11-Content/02-Books/book-open-bookmark.svg",
    "STROKE_PATH": "icons/stroke-version/11-Content/02-Books/book-open-bookmark.svg",
    "OUTLINE_PATH": "icons/outline-version/11-Content/02-Books/book-open-bookmark.svg"
  },
  "BRIGITTE_MAGAZINE": {
    "KEY": "brigitte-magazine",
    "PATH": "icons/special/brigitte-magazine.svg"
  },
  "BULB_56PX": {
    "KEY": "bulb-56px",
    "PATH": "icons/special/bulb-56px.svg"
  },
  "BUTTON_REFRESH_ARROWS": {
    "KEY": "button-refresh-arrows",
    "PATH": "icons/stroke-version/01-Interface-Essential/42-Multimedia-Controls/button-refresh-arrows.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/42-Multimedia-Controls/button-refresh-arrows.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/42-Multimedia-Controls/button-refresh-arrows.svg"
  },
  "CAL_AVAILABILITY": {
    "KEY": "cal-availability",
    "PATH": "icons/special/cal-availability.svg"
  },
  "CAL_CALENDAR": {
    "KEY": "cal-calendar",
    "PATH": "icons/special/cal-calendar.svg"
  },
  "CAL_DOCUMENT": {
    "KEY": "cal-document",
    "PATH": "icons/special/cal-document.svg"
  },
  "CAMERA_1": {
    "KEY": "camera-1",
    "PATH": "icons/stroke-version/13-Images-Photography/02-Cameras/camera-1.svg",
    "STROKE_PATH": "icons/stroke-version/13-Images-Photography/02-Cameras/camera-1.svg",
    "OUTLINE_PATH": "icons/outline-version/13-Images-Photography/02-Cameras/camera-1.svg"
  },
  "CAMERA_PROFESSIONAL": {
    "KEY": "camera-professional",
    "PATH": "icons/stroke-version/15-Video-Movies-TV/01-Cameras/camera-professional.svg",
    "STROKE_PATH": "icons/stroke-version/15-Video-Movies-TV/01-Cameras/camera-professional.svg",
    "OUTLINE_PATH": "icons/outline-version/15-Video-Movies-TV/01-Cameras/camera-professional.svg"
  },
  "CAR_SPORTS": {
    "KEY": "car-sports",
    "PATH": "icons/stroke-version/47-Transportation/05-Cars/car-sports.svg",
    "STROKE_PATH": "icons/stroke-version/47-Transportation/05-Cars/car-sports.svg",
    "OUTLINE_PATH": "icons/outline-version/47-Transportation/05-Cars/car-sports.svg"
  },
  "CHECK_BADGE": {
    "KEY": "check-badge",
    "PATH": "icons/stroke-version/01-Interface-Essential/33-Form-Validation/check-badge.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/33-Form-Validation/check-badge.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/33-Form-Validation/check-badge.svg"
  },
  "CHECK_CIRCLE_1": {
    "KEY": "check-circle-1",
    "PATH": "icons/stroke-version/01-Interface-Essential/33-Form-Validation/check-circle-1.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/33-Form-Validation/check-circle-1.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/33-Form-Validation/check-circle-1.svg"
  },
  "CHURCH": {
    "KEY": "church",
    "PATH": "icons/stroke-version/50-Landmarks/03-Various-Buildings/church.svg",
    "STROKE_PATH": "icons/stroke-version/50-Landmarks/03-Various-Buildings/church.svg",
    "OUTLINE_PATH": "icons/outline-version/50-Landmarks/03-Various-Buildings/church.svg"
  },
  "CLOSE": {
    "KEY": "close",
    "PATH": "icons/stroke-version/01-Interface-Essential/33-Form-Validation/close.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/33-Form-Validation/close.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/33-Form-Validation/close.svg"
  },
  "DECORATION_FLOWERS_TABLE": {
    "KEY": "decoration-flowers-table",
    "PATH": "icons/stroke-version/27-Furnitures-Decoration/12-Decoration/decoration-flowers-table.svg",
    "STROKE_PATH": "icons/stroke-version/27-Furnitures-Decoration/12-Decoration/decoration-flowers-table.svg",
    "OUTLINE_PATH": "icons/outline-version/27-Furnitures-Decoration/12-Decoration/decoration-flowers-table.svg"
  },
  "DIAMOND": {
    "KEY": "diamond",
    "PATH": "icons/stroke-version/08-Money-Payments-Finance/03-Wealth/diamond.svg",
    "STROKE_PATH": "icons/stroke-version/08-Money-Payments-Finance/03-Wealth/diamond.svg",
    "OUTLINE_PATH": "icons/outline-version/08-Money-Payments-Finance/03-Wealth/diamond.svg"
  },
  "EMAIL_ACTION_HEART": {
    "KEY": "email-action-heart",
    "PATH": "icons/stroke-version/19-Emails/07-Email-Actions/email-action-heart.svg",
    "STROKE_PATH": "icons/stroke-version/19-Emails/07-Email-Actions/email-action-heart.svg",
    "OUTLINE_PATH": "icons/outline-version/19-Emails/07-Email-Actions/email-action-heart.svg"
  },
  "EMOJI_SAD_88PX": {
    "KEY": "emoji-sad-88px",
    "PATH": "icons/special/emoji-sad-88px.svg"
  },
  "ENGAGEMENT_RING_1": {
    "KEY": "engagement-ring-1",
    "PATH": "icons/stroke-version/39-Romance/04-Engagement/engagement-ring-1.svg",
    "STROKE_PATH": "icons/stroke-version/39-Romance/04-Engagement/engagement-ring-1.svg",
    "OUTLINE_PATH": "icons/outline-version/39-Romance/04-Engagement/engagement-ring-1.svg"
  },
  "ENVELOPE": {
    "KEY": "envelope",
    "PATH": "icons/stroke-version/19-Emails/05-Envelopes/envelope.svg",
    "STROKE_PATH": "icons/stroke-version/19-Emails/05-Envelopes/envelope.svg",
    "OUTLINE_PATH": "icons/outline-version/19-Emails/05-Envelopes/envelope.svg"
  },
  "FEMTASTICS": {
    "KEY": "femtastics",
    "PATH": "icons/special/femtastics.svg"
  },
  "FILE_64PX": {
    "KEY": "file-64px",
    "PATH": "icons/special/file-64px.svg"
  },
  "FILE_RAR_1": {
    "KEY": "file-rar-1",
    "PATH": "icons/stroke-version/07-Work-Office-Companies/07-Office-Files/file-rar-1.svg",
    "STROKE_PATH": "icons/stroke-version/07-Work-Office-Companies/07-Office-Files/file-rar-1.svg",
    "OUTLINE_PATH": "icons/outline-version/07-Work-Office-Companies/07-Office-Files/file-rar-1.svg"
  },
  "FILE_ZIP_1": {
    "KEY": "file-zip-1",
    "PATH": "icons/stroke-version/07-Work-Office-Companies/07-Office-Files/file-zip-1.svg",
    "STROKE_PATH": "icons/stroke-version/07-Work-Office-Companies/07-Office-Files/file-zip-1.svg",
    "OUTLINE_PATH": "icons/outline-version/07-Work-Office-Companies/07-Office-Files/file-zip-1.svg"
  },
  "FLOPPY_DISK": {
    "KEY": "floppy-disk",
    "PATH": "icons/stroke-version/03-Computers-Devices-Electronics/11-Floppy-Disks/floppy-disk.svg",
    "STROKE_PATH": "icons/stroke-version/03-Computers-Devices-Electronics/11-Floppy-Disks/floppy-disk.svg",
    "OUTLINE_PATH": "icons/outline-version/03-Computers-Devices-Electronics/11-Floppy-Disks/floppy-disk.svg"
  },
  "FLOWER_ROSE": {
    "KEY": "flower-rose",
    "PATH": "icons/stroke-version/32-Nature-Farming/07-Flowers/flower-rose.svg",
    "STROKE_PATH": "icons/stroke-version/32-Nature-Farming/07-Flowers/flower-rose.svg",
    "OUTLINE_PATH": "icons/outline-version/32-Nature-Farming/07-Flowers/flower-rose.svg"
  },
  "FOLDER_EMPTY_1": {
    "KEY": "folder-empty-1",
    "PATH": "icons/stroke-version/16-Files-Folders/02-Folders/folder-empty-1.svg",
    "STROKE_PATH": "icons/stroke-version/16-Files-Folders/02-Folders/folder-empty-1.svg",
    "OUTLINE_PATH": "icons/outline-version/16-Files-Folders/02-Folders/folder-empty-1.svg"
  },
  "GIFT_BOX": {
    "KEY": "gift-box",
    "PATH": "icons/stroke-version/22-Social-Medias-Rewards-Rating/11-Gifts/gift-box.svg",
    "STROKE_PATH": "icons/stroke-version/22-Social-Medias-Rewards-Rating/11-Gifts/gift-box.svg",
    "OUTLINE_PATH": "icons/outline-version/22-Social-Medias-Rewards-Rating/11-Gifts/gift-box.svg"
  },
  "HELP_CONCRETE_REQUESTS": {
    "KEY": "help-concrete-requests",
    "PATH": "icons/special/help-concrete-requests.svg"
  },
  "HELP_HIGH_REACH": {
    "KEY": "help-high-reach",
    "PATH": "icons/special/help-high-reach.svg"
  },
  "HELP_NO_RUNNING_COSTS": {
    "KEY": "help-no-running-costs",
    "PATH": "icons/special/help-no-running-costs.svg"
  },
  "HELP_PERSONAL_SUPPORT": {
    "KEY": "help-personal-support",
    "PATH": "icons/special/help-personal-support.svg"
  },
  "HUMAN_RESOURCES_OFFER_EMPLOYEE_1": {
    "KEY": "human-resources-offer-employee-1",
    "PATH": "icons/stroke-version/07-Work-Office-Companies/09-Human-Resources/human-resources-offer-employee-1.svg",
    "STROKE_PATH": "icons/stroke-version/07-Work-Office-Companies/09-Human-Resources/human-resources-offer-employee-1.svg",
    "OUTLINE_PATH": "icons/outline-version/07-Work-Office-Companies/09-Human-Resources/human-resources-offer-employee-1.svg"
  },
  "ICON_CHAT": {
    "KEY": "icon-chat",
    "PATH": "icons/special/icon-chat.svg"
  },
  "ICON_NOTES": {
    "KEY": "icon-notes",
    "PATH": "icons/special/icon-notes.svg"
  },
  "ICON_TEMPLATES": {
    "KEY": "icon-templates",
    "PATH": "icons/special/icon-templates.svg"
  },
  "IMAGE_FILE_JPG": {
    "KEY": "image-file-jpg",
    "PATH": "icons/stroke-version/13-Images-Photography/18-Image-Files/image-file-jpg.svg",
    "STROKE_PATH": "icons/stroke-version/13-Images-Photography/18-Image-Files/image-file-jpg.svg",
    "OUTLINE_PATH": "icons/outline-version/13-Images-Photography/18-Image-Files/image-file-jpg.svg"
  },
  "IMAGE_FILE_PNG": {
    "KEY": "image-file-png",
    "PATH": "icons/stroke-version/13-Images-Photography/18-Image-Files/image-file-png.svg",
    "STROKE_PATH": "icons/stroke-version/13-Images-Photography/18-Image-Files/image-file-png.svg",
    "OUTLINE_PATH": "icons/outline-version/13-Images-Photography/18-Image-Files/image-file-png.svg"
  },
  "IMAGE_FILE_SVG": {
    "KEY": "image-file-svg",
    "PATH": "icons/stroke-version/13-Images-Photography/18-Image-Files/image-file-svg.svg",
    "STROKE_PATH": "icons/stroke-version/13-Images-Photography/18-Image-Files/image-file-svg.svg",
    "OUTLINE_PATH": "icons/outline-version/13-Images-Photography/18-Image-Files/image-file-svg.svg"
  },
  "IMAGE_FILE_UPLOAD": {
    "KEY": "image-file-upload",
    "PATH": "icons/stroke-version/13-Images-Photography/18-Image-Files/image-file-upload.svg",
    "STROKE_PATH": "icons/stroke-version/13-Images-Photography/18-Image-Files/image-file-upload.svg",
    "OUTLINE_PATH": "icons/outline-version/13-Images-Photography/18-Image-Files/image-file-upload.svg"
  },
  "LOCK_1": {
    "KEY": "lock-1",
    "PATH": "icons/stroke-version/01-Interface-Essential/11-Lock-Unlock/lock-1.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/11-Lock-Unlock/lock-1.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/11-Lock-Unlock/lock-1.svg"
  },
  "LOCKER_88PX": {
    "KEY": "locker-88px",
    "PATH": "icons/special/locker-88px.svg"
  },
  "LOGO": {
    "KEY": "logo",
    "PATH": "icons/logo.svg"
  },
  "LOGO_GREENISH": {
    "KEY": "logo-greenish",
    "PATH": "icons/logo-greenish.svg"
  },
  "MAKE_UP_LIPSTICK": {
    "KEY": "make-up-lipstick",
    "PATH": "icons/stroke-version/35-Health-Beauty/29-Makeup/make-up-lipstick.svg",
    "STROKE_PATH": "icons/stroke-version/35-Health-Beauty/29-Makeup/make-up-lipstick.svg",
    "OUTLINE_PATH": "icons/outline-version/35-Health-Beauty/29-Makeup/make-up-lipstick.svg"
  },
  "MULTIPLE_ACTIONS_HOME": {
    "KEY": "multiple-actions-home",
    "PATH": "icons/stroke-version/17-Users/25-Natural-Close-Up-Multiple-Users-Actions/multiple-actions-home.svg",
    "STROKE_PATH": "icons/stroke-version/17-Users/25-Natural-Close-Up-Multiple-Users-Actions/multiple-actions-home.svg",
    "OUTLINE_PATH": "icons/outline-version/17-Users/25-Natural-Close-Up-Multiple-Users-Actions/multiple-actions-home.svg"
  },
  "MUSIC_NOTE_2": {
    "KEY": "music-note-2",
    "PATH": "icons/stroke-version/14-Music-Audio/01-Music/music-note-2.svg",
    "STROKE_PATH": "icons/stroke-version/14-Music-Audio/01-Music/music-note-2.svg",
    "OUTLINE_PATH": "icons/outline-version/14-Music-Audio/01-Music/music-note-2.svg"
  },
  "NAVIGATION_DOWN_1": {
    "KEY": "navigation-down-1",
    "PATH": "icons/stroke-version/01-Interface-Essential/50-Navigate/navigation-down-1.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/50-Navigate/navigation-down-1.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/50-Navigate/navigation-down-1.svg"
  },
  "OFFICE_FILE_DOC": {
    "KEY": "office-file-doc",
    "PATH": "icons/stroke-version/07-Work-Office-Companies/07-Office-Files/office-file-doc.svg",
    "STROKE_PATH": "icons/stroke-version/07-Work-Office-Companies/07-Office-Files/office-file-doc.svg",
    "OUTLINE_PATH": "icons/outline-version/07-Work-Office-Companies/07-Office-Files/office-file-doc.svg"
  },
  "OFFICE_FILE_PDF": {
    "KEY": "office-file-pdf",
    "PATH": "icons/stroke-version/07-Work-Office-Companies/07-Office-Files/office-file-pdf.svg",
    "STROKE_PATH": "icons/stroke-version/07-Work-Office-Companies/07-Office-Files/office-file-pdf.svg",
    "OUTLINE_PATH": "icons/outline-version/07-Work-Office-Companies/07-Office-Files/office-file-pdf.svg"
  },
  "OFFICE_FILE_PPT": {
    "KEY": "office-file-ppt",
    "PATH": "icons/stroke-version/07-Work-Office-Companies/07-Office-Files/office-file-ppt.svg",
    "STROKE_PATH": "icons/stroke-version/07-Work-Office-Companies/07-Office-Files/office-file-ppt.svg",
    "OUTLINE_PATH": "icons/outline-version/07-Work-Office-Companies/07-Office-Files/office-file-ppt.svg"
  },
  "OFFICE_FILE_RTF": {
    "KEY": "office-file-rtf",
    "PATH": "icons/stroke-version/07-Work-Office-Companies/07-Office-Files/office-file-rtf.svg",
    "STROKE_PATH": "icons/stroke-version/07-Work-Office-Companies/07-Office-Files/office-file-rtf.svg",
    "OUTLINE_PATH": "icons/outline-version/07-Work-Office-Companies/07-Office-Files/office-file-rtf.svg"
  },
  "OFFICE_FILE_TXT": {
    "KEY": "office-file-txt",
    "PATH": "icons/stroke-version/07-Work-Office-Companies/07-Office-Files/office-file-txt.svg",
    "STROKE_PATH": "icons/stroke-version/07-Work-Office-Companies/07-Office-Files/office-file-txt.svg",
    "OUTLINE_PATH": "icons/outline-version/07-Work-Office-Companies/07-Office-Files/office-file-txt.svg"
  },
  "OFFICE_FILE_XLS": {
    "KEY": "office-file-xls",
    "PATH": "icons/stroke-version/07-Work-Office-Companies/07-Office-Files/office-file-xls.svg",
    "STROKE_PATH": "icons/stroke-version/07-Work-Office-Companies/07-Office-Files/office-file-xls.svg",
    "OUTLINE_PATH": "icons/outline-version/07-Work-Office-Companies/07-Office-Files/office-file-xls.svg"
  },
  "OK_MAGAZINE": {
    "KEY": "ok-magazine",
    "PATH": "icons/special/ok-magazine.svg"
  },
  "PARTY_MASK": {
    "KEY": "party-mask",
    "PATH": "icons/stroke-version/44-Entertainment-Event-Hobbies/04-Party/party-mask.svg",
    "STROKE_PATH": "icons/stroke-version/44-Entertainment-Event-Hobbies/04-Party/party-mask.svg",
    "OUTLINE_PATH": "icons/outline-version/44-Entertainment-Event-Hobbies/04-Party/party-mask.svg"
  },
  "PARTY_MUSIC_DANCE": {
    "KEY": "party-music-dance",
    "PATH": "icons/stroke-version/44-Entertainment-Event-Hobbies/04-Party/party-music-dance.svg",
    "STROKE_PATH": "icons/stroke-version/44-Entertainment-Event-Hobbies/04-Party/party-music-dance.svg",
    "OUTLINE_PATH": "icons/outline-version/44-Entertainment-Event-Hobbies/04-Party/party-music-dance.svg"
  },
  "PENCIL_1": {
    "KEY": "pencil-1",
    "PATH": "icons/stroke-version/01-Interface-Essential/22-Edit/pencil-1.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/22-Edit/pencil-1.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/22-Edit/pencil-1.svg"
  },
  "PHONE": {
    "KEY": "phone",
    "PATH": "icons/stroke-version/20-Phones-Mobile-Devices/01-Phone/phone.svg",
    "STROKE_PATH": "icons/stroke-version/20-Phones-Mobile-Devices/01-Phone/phone.svg",
    "OUTLINE_PATH": "icons/outline-version/20-Phones-Mobile-Devices/01-Phone/phone.svg"
  },
  "PIN": {
    "KEY": "pin",
    "PATH": "icons/stroke-version/48-Maps-Navigation/05-Pins/pin.svg",
    "STROKE_PATH": "icons/stroke-version/48-Maps-Navigation/05-Pins/pin.svg",
    "OUTLINE_PATH": "icons/outline-version/48-Maps-Navigation/05-Pins/pin.svg"
  },
  "PRO_TAG": {
    "KEY": "pro-tag",
    "PATH": "icons/special/pro-tag.svg"
  },
  "QUESTION_HELP_CIRCLE": {
    "KEY": "question-help-circle",
    "PATH": "icons/stroke-version/01-Interface-Essential/16-Help/question-help-circle.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/16-Help/question-help-circle.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/16-Help/question-help-circle.svg"
  },
  "REMOVE_BADGE": {
    "KEY": "remove-badge",
    "PATH": "icons/stroke-version/01-Interface-Essential/33-Form-Validation/remove-badge.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/33-Form-Validation/remove-badge.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/33-Form-Validation/remove-badge.svg"
  },
  "ROOM_SERVICE_GIVE_PLATE": {
    "KEY": "room-service-give-plate",
    "PATH": "icons/stroke-version/24-Hotels-Spas/04-Room-Service/room-service-give-plate.svg",
    "STROKE_PATH": "icons/stroke-version/24-Hotels-Spas/04-Room-Service/room-service-give-plate.svg",
    "OUTLINE_PATH": "icons/outline-version/24-Hotels-Spas/04-Room-Service/room-service-give-plate.svg"
  },
  "SEARCH": {
    "KEY": "search",
    "PATH": "icons/stroke-version/01-Interface-Essential/25-Search/search.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/25-Search/search.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/25-Search/search.svg"
  },
  "SHOW_THEATER_SHOW_MASKS": {
    "KEY": "show-theater-show-masks",
    "PATH": "icons/stroke-version/44-Entertainment-Event-Hobbies/08-Show-Theater/show-theater-show-masks.svg",
    "STROKE_PATH": "icons/stroke-version/44-Entertainment-Event-Hobbies/08-Show-Theater/show-theater-show-masks.svg",
    "OUTLINE_PATH": "icons/outline-version/44-Entertainment-Event-Hobbies/08-Show-Theater/show-theater-show-masks.svg"
  },
  "SMILEY_IN_LOVE": {
    "KEY": "smiley-in-love",
    "PATH": "icons/stroke-version/21-Messages-Chat-Smileys/05-Smileys/smiley-in-love.svg",
    "STROKE_PATH": "icons/stroke-version/21-Messages-Chat-Smileys/05-Smileys/smiley-in-love.svg",
    "OUTLINE_PATH": "icons/outline-version/21-Messages-Chat-Smileys/05-Smileys/smiley-in-love.svg"
  },
  "SMILEY_SAD_1": {
    "KEY": "smiley-sad-1",
    "PATH": "icons/stroke-version/21-Messages-Chat-Smileys/05-Smileys/smiley-sad-1.svg",
    "STROKE_PATH": "icons/stroke-version/21-Messages-Chat-Smileys/05-Smileys/smiley-sad-1.svg",
    "OUTLINE_PATH": "icons/outline-version/21-Messages-Chat-Smileys/05-Smileys/smiley-sad-1.svg"
  },
  "SOCIAL_INSTAGRAM": {
    "KEY": "social-instagram",
    "PATH": "icons/stroke-version/53-Logos/03-Photos-Images/social-instagram.svg",
    "STROKE_PATH": "icons/stroke-version/53-Logos/03-Photos-Images/social-instagram.svg",
    "OUTLINE_PATH": "icons/outline-version/53-Logos/03-Photos-Images/social-instagram.svg"
  },
  "SOCIAL_MEDIA_FACEBOOK_1": {
    "KEY": "social-media-facebook-1",
    "PATH": "icons/stroke-version/53-Logos/01-Social-Medias/social-media-facebook-1.svg",
    "STROKE_PATH": "icons/stroke-version/53-Logos/01-Social-Medias/social-media-facebook-1.svg",
    "OUTLINE_PATH": "icons/outline-version/53-Logos/01-Social-Medias/social-media-facebook-1.svg"
  },
  "SOCIAL_PINTEREST": {
    "KEY": "social-pinterest",
    "PATH": "icons/stroke-version/53-Logos/03-Photos-Images/social-pinterest.svg",
    "STROKE_PATH": "icons/stroke-version/53-Logos/03-Photos-Images/social-pinterest.svg",
    "OUTLINE_PATH": "icons/outline-version/53-Logos/03-Photos-Images/social-pinterest.svg"
  },
  "STATUS_REPORT_BOOKED": {
    "KEY": "status-report-booked",
    "PATH": "icons/special/status-report-booked.svg"
  },
  "STATUS_REPORT_DECLINED": {
    "KEY": "status-report-declined",
    "PATH": "icons/special/status-report-declined.svg"
  },
  "STATUS_REPORT_OPEN": {
    "KEY": "status-report-open",
    "PATH": "icons/special/status-report-open.svg"
  },
  "SUDDEUTSCHE_ZEITUNG": {
    "KEY": "suddeutsche-zeitung",
    "PATH": "icons/special/suddeutsche-zeitung.svg"
  },
  "TA_DA_88PX": {
    "KEY": "ta-da-88px",
    "PATH": "icons/special/ta-da-88px.svg"
  },
  "TIME_CLOCK_CIRCLE": {
    "KEY": "time-clock-circle",
    "PATH": "icons/stroke-version/01-Interface-Essential/18-Time/time-clock-circle.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/18-Time/time-clock-circle.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/18-Time/time-clock-circle.svg"
  },
  "VIDEO_PLAYER": {
    "KEY": "video-player",
    "PATH": "icons/stroke-version/15-Video-Movies-TV/05-Video-Players/video-player.svg",
    "STROKE_PATH": "icons/stroke-version/15-Video-Movies-TV/05-Video-Players/video-player.svg",
    "OUTLINE_PATH": "icons/outline-version/15-Video-Movies-TV/05-Video-Players/video-player.svg"
  },
  "VIEW": {
    "KEY": "view",
    "PATH": "icons/stroke-version/01-Interface-Essential/24-View/view.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/24-View/view.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/24-View/view.svg"
  },
  "VIEW_OFF": {
    "KEY": "view-off",
    "PATH": "icons/stroke-version/01-Interface-Essential/24-View/view-off.svg",
    "STROKE_PATH": "icons/stroke-version/01-Interface-Essential/24-View/view-off.svg",
    "OUTLINE_PATH": "icons/outline-version/01-Interface-Essential/24-View/view-off.svg"
  },
  "VOGUE": {
    "KEY": "vogue",
    "PATH": "icons/special/vogue.svg"
  },
  "WEDDING_CAKE_COUPLE": {
    "KEY": "wedding-cake-couple",
    "PATH": "icons/stroke-version/39-Romance/05-Wedding/wedding-cake-couple.svg",
    "STROKE_PATH": "icons/stroke-version/39-Romance/05-Wedding/wedding-cake-couple.svg",
    "OUTLINE_PATH": "icons/outline-version/39-Romance/05-Wedding/wedding-cake-couple.svg"
  },
  "WEDDING_CELEBRATION": {
    "KEY": "wedding-celebration",
    "PATH": "icons/stroke-version/39-Romance/05-Wedding/wedding-celebration.svg",
    "STROKE_PATH": "icons/stroke-version/39-Romance/05-Wedding/wedding-celebration.svg",
    "OUTLINE_PATH": "icons/outline-version/39-Romance/05-Wedding/wedding-celebration.svg"
  },
  "WEDDING_DRESS": {
    "KEY": "wedding-dress",
    "PATH": "icons/stroke-version/39-Romance/05-Wedding/wedding-dress.svg",
    "STROKE_PATH": "icons/stroke-version/39-Romance/05-Wedding/wedding-dress.svg",
    "OUTLINE_PATH": "icons/outline-version/39-Romance/05-Wedding/wedding-dress.svg"
  },
  "WUNDERWEIB": {
    "KEY": "wunderweib",
    "PATH": "icons/special/wunderweib.svg"
  }
};
