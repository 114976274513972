export const WIZARD_ANSWERS = {
	BERLIN: 'berlin',
	CAK_BUDGET_TIER_FOUR: 'cak_budget_tier_four',
	CAK_BUDGET_TIER_ONE: 'cak_budget_tier_one',
	CAK_BUDGET_TIER_THREE: 'cak_budget_tier_three',
	CAK_BUDGET_TIER_TWO: 'cak_budget_tier_two',
	CAT_DIET_ANYTHING: 'cat_diet_anything',
	CAT_DIET_HALAL: 'cat_diet_halal',
	CAT_DIET_KOSHER: 'cat_diet_kosher',
	CAT_DIET_VEGAN: 'cat_diet_vegan',
	CAT_TYPE_3_COURSE_MEAL: 'cat_type_3_course_meal',
	CAT_TYPE_5_COURSE_MEAL: 'cat_type_5_course_meal',
	CAT_TYPE_APPETIZERS: 'cat_type_appetizers',
	CAT_TYPE_BUFFET: 'cat_type_buffet',
	COLOGNE: 'cologne',
	DATE: 'date',
	GEN_ADV_BEGINNING: 'gen_adv_beginning',
	GEN_ADV_DETAILS: 'gen_adv_details',
	GEN_ADV_LOCATION: 'gen_adv_location',
	GEN_ADV_VENDORS: 'gen_adv_vendors',
	GEN_AREA_CITY: 'gen_area_city',
	GEN_AREA_DESTINATION: 'gen_area_destination',
	GEN_AREA_LAND: 'gen_area_land',
	GEN_BUDGET_TIER_FOUR: 'gen_budget_tier_four',
	GEN_BUDGET_TIER_ONE: 'gen_budget_tier_one',
	GEN_BUDGET_TIER_THREE: 'gen_budget_tier_three',
	GEN_BUDGET_TIER_TWO: 'gen_budget_tier_two',
	GEN_CITY_BALLROOM: 'gen_city_ballroom',
	GEN_CITY_FACTORY: 'gen_city_factory',
	GEN_CITY_HOTEL: 'gen_city_hotel',
	GEN_CITY_RESTAURANT: 'gen_city_restaurant',
	GEN_COUNTDOWN_TIER_FOUR: 'gen_countdown_tier_four',
	GEN_COUNTDOWN_TIER_ONE: 'gen_countdown_tier_one',
	GEN_COUNTDOWN_TIER_THREE: 'gen_countdown_tier_three',
	GEN_COUNTDOWN_TIER_TWO: 'gen_countdown_tier_two',
	GEN_DEST_FRANCE: 'gen_dest_france',
	GEN_DEST_ITALY: 'gen_dest_italy',
	GEN_DEST_OTHER: 'gen_dest_other',
	GEN_DEST_SPAIN: 'gen_dest_spain',
	GEN_GUESTS_TIER_FOUR: 'gen_guests_tier_four',
	GEN_GUESTS_TIER_ONE: 'gen_guests_tier_one',
	GEN_GUESTS_TIER_THREE: 'gen_guests_tier_three',
	GEN_GUESTS_TIER_TWO: 'gen_guests_tier_two',
	GEN_LAND_BARN: 'gen_land_barn',
	GEN_LAND_CASTLE: 'gen_land_castle',
	GEN_LAND_LAKE: 'gen_land_lake',
	GEN_LAND_MANOR: 'gen_land_manor',
	GEN_SEASON_TIER_FOUR: 'gen_season_tier_four',
	GEN_SEASON_TIER_ONE: 'gen_season_tier_one',
	GEN_SEASON_TIER_THREE: 'gen_season_tier_three',
	GEN_SEASON_TIER_TWO: 'gen_season_tier_two',
	LOC_BUDGET_HIGH_TIER_FOUR: 'loc_budget_high_tier_four',
	LOC_BUDGET_HIGH_TIER_ONE: 'loc_budget_high_tier_one',
	LOC_BUDGET_HIGH_TIER_THREE: 'loc_budget_high_tier_three',
	LOC_BUDGET_HIGH_TIER_TWO: 'loc_budget_high_tier_two',
	LOC_BUDGET_LOW_TIER_FOUR: 'loc_budget_low_tier_four',
	LOC_BUDGET_LOW_TIER_ONE: 'loc_budget_low_tier_one',
	LOC_BUDGET_LOW_TIER_THREE: 'loc_budget_low_tier_three',
	LOC_BUDGET_LOW_TIER_TWO: 'loc_budget_low_tier_two',
	LOC_BUDGET_MED_HIGH_TIER_FOUR: 'loc_budget_med_high_tier_four',
	LOC_BUDGET_MED_HIGH_TIER_ONE: 'loc_budget_med_high_tier_one',
	LOC_BUDGET_MED_HIGH_TIER_THREE: 'loc_budget_med_high_tier_three',
	LOC_BUDGET_MED_HIGH_TIER_TWO: 'loc_budget_med_high_tier_two',
	LOC_BUDGET_MED_LOW_TIER_FOUR: 'loc_budget_med_low_tier_four',
	LOC_BUDGET_MED_LOW_TIER_ONE: 'loc_budget_med_low_tier_one',
	LOC_BUDGET_MED_LOW_TIER_THREE: 'loc_budget_med_low_tier_three',
	LOC_BUDGET_MED_LOW_TIER_TWO: 'loc_budget_med_low_tier_two',
	LOC_BUDGET_TIER_FOUR: 'loc_budget_tier_four',
	LOC_BUDGET_TIER_ONE: 'loc_budget_tier_one',
	LOC_BUDGET_TIER_THREE: 'loc_budget_tier_three',
	LOC_BUDGET_TIER_TWO: 'loc_budget_tier_two',
	LOC_GUESTS_TIER_FOUR: 'loc_guests_tier_four',
	LOC_GUESTS_TIER_ONE: 'loc_guests_tier_one',
	LOC_GUESTS_TIER_THREE: 'loc_guests_tier_three',
	LOC_GUESTS_TIER_TWO: 'loc_guests_tier_two',
	MUNICH: 'munich',
	MUS_BUDGET_TIER_FOUR: 'mus_budget_tier_four',
	MUS_BUDGET_TIER_ONE: 'mus_budget_tier_one',
	MUS_BUDGET_TIER_THREE: 'mus_budget_tier_three',
	MUS_BUDGET_TIER_TWO: 'mus_budget_tier_two',
	MUS_OCCASION_ALL: 'mus_occasion_all',
	MUS_OCCASION_CEREMONY: 'mus_occasion_ceremony',
	MUS_OCCASION_PARTY: 'mus_occasion_party',
	MUS_OCCASION_RECEPTION: 'mus_occasion_reception',
	MUS_PLAYER_ALLE: 'mus_player_alle',
	MUS_PLAYER_BAND: 'mus_player_band',
	MUS_PLAYER_DJ: 'mus_player_dj',
	MUS_PLAYER_SINGER: 'mus_player_singer',
	MUS_STYLE_CLASSIC: 'mus_style_classic',
	MUS_STYLE_HITS: 'mus_style_hits',
	MUS_STYLE_JAZZ: 'mus_style_jazz',
	MUS_STYLE_OLDIES: 'mus_style_oldies',
	OFF_BUDGET_TIER_FOUR: 'off_budget_tier_four',
	OFF_BUDGET_TIER_ONE: 'off_budget_tier_one',
	OFF_BUDGET_TIER_THREE: 'off_budget_tier_three',
	OFF_BUDGET_TIER_TWO: 'off_budget_tier_two',
	OFF_DEN_CATHOLIC: 'off_den_catholic',
	OFF_DEN_EVANGELIC: 'off_den_evangelic',
	OFF_DEN_SECULAR: 'off_den_secular',
	OTHER: 'other',
	PHO_DURATION_TIER_FOUR: 'pho_duration_tier_four',
	PHO_DURATION_TIER_ONE: 'pho_duration_tier_one',
	PHO_DURATION_TIER_THREE: 'pho_duration_tier_three',
	PHO_DURATION_TIER_TWO: 'pho_duration_tier_two',
	PHO_LOWER_BUDGET_TIER_FOUR: 'pho_lower_budget_tier_four',
	PHO_LOWER_BUDGET_TIER_ONE: 'pho_lower_budget_tier_one',
	PHO_LOWER_BUDGET_TIER_THREE: 'pho_lower_budget_tier_three',
	PHO_LOWER_BUDGET_TIER_TWO: 'pho_lower_budget_tier_two',
	PHO_OCCASION_ALL: 'pho_occasion_all',
	PHO_OCCASION_CIVIL: 'pho_occasion_civil',
	PHO_OCCASION_COUPLE: 'pho_occasion_couple',
	PHO_OCCASION_REPORTAGE: 'pho_occasion_reportage',
	PHO_STYLE_CLASSIC: 'pho_style_classic',
	PHO_STYLE_CREATIVE: 'pho_style_creative',
	PHO_STYLE_DOCUMENTARY: 'pho_style_documentary',
	PHO_STYLE_VINTAGE: 'pho_style_vintage',
	PHO_UPPER_BUDGET_TIER_FOUR: 'pho_upper_budget_tier_four',
	PHO_UPPER_BUDGET_TIER_ONE: 'pho_upper_budget_tier_one',
	PHO_UPPER_BUDGET_TIER_THREE: 'pho_upper_budget_tier_three',
	PHO_UPPER_BUDGET_TIER_TWO: 'pho_upper_budget_tier_two',
	VID_BUDGET_TIER_FOUR: 'vid_budget_tier_four',
	VID_BUDGET_TIER_ONE: 'vid_budget_tier_one',
	VID_BUDGET_TIER_THREE: 'vid_budget_tier_three',
	VID_BUDGET_TIER_TWO: 'vid_budget_tier_two',
	VID_DURATION_TIER_FOUR: 'vid_duration_tier_four',
	VID_DURATION_TIER_ONE: 'vid_duration_tier_one',
	VID_DURATION_TIER_THREE: 'vid_duration_tier_three',
	VID_DURATION_TIER_TWO: 'vid_duration_tier_two',
	VID_OCCASION_ALL: 'vid_occasion_all',
	VID_OCCASION_CIVIL: 'vid_occasion_civil',
	VID_OCCASION_COUPLE: 'vid_occasion_couple',
	VID_OCCASION_REPORTAGE: 'vid_occasion_reportage',
};
