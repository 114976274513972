import { AgmCoreModule, LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { APP_BASE_HREF, CommonModule, CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { ErrorHandler, LOCALE_ID, NgModule, NgModuleFactoryLoader, SystemJsNgModuleLoader } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatDatepickerIntl, MatIconRegistry, NativeDateAdapter, RippleGlobalOptions } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, UrlSerializer } from '@angular/router';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StarRatingConfigService } from 'angular-star-rating';
import 'moment/locale/de';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ChartsModule } from 'ng2-charts';
import { EmbedVideo } from 'ngx-embed-video';
import { QuicklinkModule } from 'ngx-quicklink';
import { environment } from '../environments/environment';
import { clientTranslateConfig, getBrowserCulturalLang } from '../modules.config';
import { LoadModuleDirective } from './_shared/_directives';
import { AuthGuard, NoAuthGuard, ShopGuard } from './_shared/_guards';
import { CacheHttpInterceptor, ErrorsHttpInterceptor, HeadersHttpInterceptor, ParamsHttpInterceptor, TransferStateHttpInterceptor } from './_shared/_interceptors';
import { MaterialModule } from './_shared/_material/material.module';
import { CustomRouteReuseStrategy } from './_shared/_others/custom-route-reuse-strategy.component';
import { CustomErrorHandler } from './_shared/_others/custom.error-handler';
import { CustomUrlSerializer } from './_shared/_others/custom.url-serializer';
import { FilterCouplesPipe } from './_shared/_pipes';
import { AccountResolver, InquiryBreadcrumbResolver, InquiryResolver } from './_shared/_resolvers';
import { AccountDataService, CacheService, PendingService, TokenService, TransferStateService } from './_shared/_services';
import { ConsoleLoggerService } from './_shared/_services/console-logger.service';
import { LoggerService } from './_shared/_services/logger.service';
import { SharedComponentsModule } from './_shared/components/shared-components.module';
import { DialogsModule } from './_shared/modules/dialogs/dialogs.module';
import { SetPasswordDialogComponent } from './_shared/modules/dialogs/set-password-dialog/set-password-dialog.component';
import { ErrorModule } from './_shared/modules/error/error.module';
import { FooterModule } from './_shared/modules/footer/footer.module';
import { OfflineAlertModule } from './_shared/modules/offline-alert/offline-alert.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingComponent } from './loading/loading.component';
import { RequestAcceptComponent } from './request-accept/request-accept.component';
import { WelcomeComponent } from './welcome/welcome.component';

registerLocaleData(localeDe, 'de', localeDeExtra);

const globalRippleConfig: RippleGlobalOptions = {
	disabled: true,
	animation: {
		enterDuration: 300,
		exitDuration: 0
	}
};

const lazyMapsAPILoaderConfigLiteral: LazyMapsAPILoaderConfigLiteral = {
	apiKey: 'AIzaSyB1oyZUjALeUEe2Y0HdMXPZ9k4UFO925MA',
	libraries: ['places'],
	region: 'DE'
};

declare global {
	interface Document {
		documentMode?: any;
	}
}

@NgModule({
	declarations: [
		AppComponent,
		WelcomeComponent,
		RequestAcceptComponent,
		LoadModuleDirective,
		LoadingComponent
	],
	imports: [
		AgmCoreModule.forRoot(lazyMapsAPILoaderConfigLiteral),
		AppRoutingModule,
		BrowserAnimationsModule,
		ChartsModule,
		CommonModule,
		DialogsModule,
		EmbedVideo.forRoot(),
		ErrorModule,
		FooterModule,
		GalleryModule.forRoot({shortcuts: ['ctrl+s', 'meta+s'], disableSsrWorkaround: true}),
		HttpClientModule,
		LazyLoadImageModule,
		LoadingBarRouterModule,
		MaterialModule,
		OfflineAlertModule,
		QuicklinkModule,
		ReactiveFormsModule,
		SharedComponentsModule,
		TranslateModule.forRoot(clientTranslateConfig)
	],
	entryComponents: [
		SetPasswordDialogComponent
	],
	providers: [
		AccountResolver,
		AccountDataService,
		AuthGuard,
		CacheService,
		CurrencyPipe,
		DatePipe,
		FilterCouplesPipe,
		InquiryBreadcrumbResolver,
		InquiryResolver,
		MatIconRegistry,
		MediaMatcher,
		PendingService,
		ShopGuard,
		StarRatingConfigService,
		Title,
		TokenService,
		TransferStateService,
		NoAuthGuard,
		{provide: ErrorHandler, useClass: CustomErrorHandler},
		{provide: LOCALE_ID, deps: [TranslateService], useFactory: getBrowserCulturalLang},
		{provide: LoggerService, useClass: ConsoleLoggerService},
		{provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig},
		{provide: MatDatepickerIntl, useClass: NativeDateAdapter},
		{provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader},
		{provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
		{provide: UrlSerializer, useClass: CustomUrlSerializer},
		{provide: APP_BASE_HREF, useValue: environment.domain},
		{provide: HTTP_INTERCEPTORS, useClass: TransferStateHttpInterceptor, multi: true}, // 1 They have to keep this order
		{provide: HTTP_INTERCEPTORS, useClass: CacheHttpInterceptor, multi: true}, // 2 They have to keep this order
		{provide: HTTP_INTERCEPTORS, useClass: ParamsHttpInterceptor, multi: true}, // 3 They have to keep this order
		{provide: HTTP_INTERCEPTORS, useClass: HeadersHttpInterceptor, multi: true}, // 4 They have to keep this order
		{provide: HTTP_INTERCEPTORS, useClass: ErrorsHttpInterceptor, multi: true} // 5 They have to keep this order
	],
	exports: [
		AppComponent
	]
})
export class AppModule {
}
