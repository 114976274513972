import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/internal/operators';
import { CacheService, HelpersService } from '../_services';

const TTL = 60 * 30; // CACHING DURING HALF AN HOUR

@Injectable()
export class CacheHttpInterceptor implements HttpInterceptor {

	constructor(private cache: CacheService,
				private injector: Injector,
				@Inject(PLATFORM_ID) private platformId: Object) {
	}

	private static createKeyFromRequest(request: HttpRequest<any>) {
		return `${request.method}_${request.urlWithParams}`;
	}

	public intercept(request: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
		if (request.method !== 'GET' || request.params.get('skipCache')) {
			return httpHandler.handle(request);
		} else {
			const key = CacheHttpInterceptor.createKeyFromRequest(request);
			const cachedResponse = this.cache.get(key);
			return cachedResponse ? of(cachedResponse) : this.sendRequest(key, request, httpHandler);
		}
	}

	public sendRequest(key: string, request: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
		HelpersService.assert(request, 'request');

		return httpHandler.handle(request).pipe(tap((response => {
			if (response instanceof HttpResponse) {
				this.cache.set(key, response, TTL);
			}
		})));
	}

}
