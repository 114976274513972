import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../_material/material.module';
import { CreditCardFormComponent } from './credit-card-form/credit-card-form.component';
import { SepaFormComponent } from './sepa-form/sepa-form.component';
import { TelephoneFormComponent } from './telephone-form/telephone-form.component';

@NgModule({
	declarations: [
		CreditCardFormComponent,
		SepaFormComponent,
		TelephoneFormComponent
	],
	imports: [
		CommonModule,
		MaterialModule,
		FormsModule,
		TranslateModule.forChild(),
		ReactiveFormsModule,
		RouterModule
	],
	exports: [
		CreditCardFormComponent,
		SepaFormComponent,
		TelephoneFormComponent
	]
})
export class AppFormsModule {
}
