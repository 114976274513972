import { isPlatformBrowser, LocationStrategy } from '@angular/common';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable, Injector, isDevMode, PLATFORM_ID } from '@angular/core';
import * as StackTraceParser from 'error-stack-parser';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { HistoryService } from './history.service';

const APP_NAME = 'WeddyPlace Pro';

@Injectable({
	providedIn: 'root'
})
export class ErrorsService {

	public errors: Error[] = [];

	private platformId;
	private locationStrategy: LocationStrategy;
	private api: ApiService;
	private historyService: HistoryService;

	constructor(private injector: Injector) {
	}

	public add(error: Error) {
		this.errors.push(error);
	}

	public post(error, critical?, originalRequest?: HttpRequest<any>) {
		this.initializeVariables();
		const errorToSend = this.addContextInfo(error, critical, originalRequest);

		const req = this.api.post('error', errorToSend, false);

		req.subscribe(
			(res) => {
				console.log(res);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public addContextInfo(error, urgent?, originalRequest?) {
		this.initializeVariables();
		try {
			const branch = this.getBranch();
			const request = this.getRequest(error);
			const name = error.name || undefined;
			const appId = APP_NAME;
			const version = environment.version;
			const date = new Date().getTime();
			const url = this.getUrl();
			const status = this.getStatus(error);
			const message = this.getMessage(error);
			const stack = this.getStack(error);
			const method = this.getMethod(error, stack, originalRequest);
			const history = this.getRouterHistory();
			return {name, appId, version, branch, date, url, status, message, request, stack, method, urgent, history};
		} catch (e) {
			console.error('Error while contextualising error');
		}
	}

	private getBranch() {
		return environment.production ? 'master' : 'dev';
	}

	private getStatus(error) {
		return error.status || undefined;
	}

	private getStack(error) {
		if ((error instanceof HttpErrorResponse)) {
			if (isDevMode()) {
				console.warn('Is HttpErrorResponse');
			}
			return undefined;
		} else {
			try {
				return StackTraceParser.parse(error);
			} catch (e) {
				console.log(error);
				console.error('StackTraceParser could not parse the error');
			}
		}
	}

	private getMethod(error: Error, stack, request: HttpRequest<any>) {
		if (error instanceof HttpErrorResponse) {
			return request.method;
		} else {
			return undefined;
		}
	}

	private getMessage(error) {
		if (error instanceof HttpErrorResponse) {
			return error.error.toString || 'Could not get error message';
		} else {
			return error.message || error.toString() || 'Could not get error message';
		}
	}

	private getUrl() {
		// return this.locationStrategy instanceof PathLocationStrategy ? this.locationStrategy.path() : (isPlatformBrowser(this.platformId) ? window.location.href.split('/')[1] : undefined);
		if (isPlatformBrowser(this.platformId)) {
			return window.location.href;
		} else {
			return '';
		}
	}

	private getRequest(error: Error) {
		if (error instanceof HttpErrorResponse) {
			return error.url;
		} else {
			return undefined;
			// return isPlatformServer(this.platformId) ? this.injector.get(IS_PRE_RENDER) ? undefined : this.injector.get(REQUEST) : undefined;
		}
	}

	private initializeVariables() {
		if (!this.platformId) {
			this.platformId = this.injector.get(PLATFORM_ID);
		}
		if (!this.locationStrategy) {
			this.locationStrategy = this.injector.get(LocationStrategy);
		}
		if (!this.api) {
			this.api = this.injector.get(ApiService);
		}
	}

	private getRouterHistory() {
		if (!this.historyService) {
			this.historyService = this.injector.get(HistoryService);
		}

		return this.historyService.getHistory();
	}

}
