<div class="header">
    <div>
        <button (click)="deleteAppointment()" *ngIf="!editMode" class="toolbar-button grey" mat-icon-button>
            <mat-icon *ngIf="!deleting">delete_outline</mat-icon>
            <mat-spinner *ngIf="deleting" [diameter]="20"></mat-spinner>
        </button>
        <button [mat-dialog-close]="false" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content class="text-center">

    <div *ngIf="editMode" class="content-list">
        <div class="title-container">
            <mat-form-field *ngIf="appointmentType == 'appointment'" class="title-field">
                <input #titleInput [formControl]="form.controls['title']" [placeholder]="'Titel und Uhrzeit hinzufügen'"
                       matInput>
            </mat-form-field>

            <div *ngIf="editMode" class="appointmentType">
                <button (click)="setAppointmentType('appointment')" [class.active]="appointmentType == 'appointment'"
                        class="type-button"
                        mat-raised-button>Termin
                </button>
<!--                <button (click)="setAppointmentType('block')" [class.active]="appointmentType == 'block'"-->
<!--                        class="type-button"-->
<!--                        mat-raised-button>Belegung-->
<!--                </button>-->
            </div>
        </div>

        <div class="item-container">
            <mat-icon>query_builder</mat-icon>
            <!-- Date1 -->
            <mat-form-field (click)="onDateFromInputClick()" class="datepicker-field">
                <input (dateChange)="onFromChange($event)" [formControl]="form.controls['from']"
                       [matDatepicker]="fromPicker" [min]="rangeFromMinDate"
                       [placeholder]="'Am'"
                       matInput readonly>
                <mat-datepicker-toggle [for]="fromPicker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #fromPicker [startAt]="rangeFromStartAt"
                                [touchUi]="renderingService.isMobile"></mat-datepicker>
            </mat-form-field>
            <div class="item-container">

                <!-- Time1 -->
                <mat-form-field class="time-select">
                    <input #timeInput (change)="pickTimeFrom($event)" (click)="timeSelectClick()"
                           [(ngModel)]="timeStart" [matAutocomplete]="autoFrom"
                           matInput
                           type="time">
                </mat-form-field>

                <mat-autocomplete #autoFrom="matAutocomplete" (optionSelected)="pickTimeFrom($event)">
                    <mat-option *ngFor="let option of timePickerValues" [value]="option">{{option }}</mat-option>
                </mat-autocomplete>

                <span *ngIf="enableTimePicker" class="time-picker-divider"> - </span>

                <!-- Time2 -->
                <mat-form-field *ngIf="enableTimePicker" class="time-select">
                    <input (change)="pickTimeToInp($event)" [(ngModel)]="timeEnd" [matAutocomplete]="autoTo" matInput
                           type="time">
                </mat-form-field>

                <mat-autocomplete #autoTo="matAutocomplete" (optionSelected)="pickTimeToComp($event)"
                                  [displayWith]="displayFn"
                                  panelWidth="auto">
                    <mat-option *ngFor="let option of timePickerDurations"
                                [value]="option">{{option.title }}</mat-option>
                </mat-autocomplete>

            </div>
            <!-- Date2 -->
            <mat-form-field (click)="onDateToInputClick()" *ngIf="enableTimePicker" class="datepicker-field">
                <input (dateChange)="onToDateChange($event)" [formControl]="form.controls['to']" [matDatepicker]="toPicker"
                       [min]="rangeToMinDate" [placeholder]="'PLACEHOLDERS.TO' | translate"
                       matInput>
                <mat-datepicker-toggle [for]="toPicker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #toPicker [startAt]="rangeToStartAt"
                                [touchUi]="renderingService.isMobile"></mat-datepicker>
            </mat-form-field>

            <button (click)="enableTime()" *ngIf="!enableTimePicker" class="type-button" mat-button>
                Ende hinzufügen
            </button>
            <mat-error *ngIf="form.hasError('noDates')"
                       [innerHTML]="'ERRORS.NO_DATES_SELECTED' | translate"></mat-error>
        </div>


        <div *ngIf="appointmentType == 'appointment'" class="more-info-container">
            <!-- ---------------------------------------------------------------------------------------------------------- -->
            <!-- -------------------------------------------------Couple--------------------------------------------------- -->
            <!-- ---------------------------------------------------------------------------------------------------------- -->
            <div class="item-container">
                <mat-icon>person_pin</mat-icon>
                <mat-form-field class="weddy-form-field" floatLabel="always">
                    <input #coupleInput [formControl]="form.controls['couple']" [matAutocomplete]="coupleAutocomplete"
                           aria-label="Brautpaar"
                           matInput placeholder="Brautpaar hinzufügen">
                    <mat-autocomplete #coupleAutocomplete="matAutocomplete" [displayWith]="displayFnCouple">
                        <mat-option *ngFor="let couple of couples | filterCouples:coupleInput.value" [value]="couple">
                            <!--                            TODO Add avatars-->
                            <span>{{couple?.accountData?.firstName }}</span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <span style="margin-left: 10px;color: #828282c8">Das Brautpaar erhält keine Benachrichtigung</span>
            </div>

            <!-- ---------------------------------------------------------------------------------------------------------- -->
            <!-- -------------------------------------------------Location------------------------------------------------- -->
            <!-- ---------------------------------------------------------------------------------------------------------- -->
            <div class="item-container full-width">
                <mat-icon>place</mat-icon>
                <mat-form-field class="weddy-form-field" floatLabel="always">
                    <input #location #mapInput [formControl]="form.controls['location']" matInput
                           placeholder="Ort hinzufügen">
                </mat-form-field>
            </div>

            <!-- ---------------------------------------------------------------------------------------------------------- -->
            <!-- -------------------------------------------------Comments------------------------------------------------- -->
            <!-- ---------------------------------------------------------------------------------------------------------- -->
            <div class="item-container full-width">
                <mat-icon>subject</mat-icon>
                <mat-form-field class="weddy-form-field" floatLabel="always">
                    <input [formControl]="form.controls['comments']" [placeholder]="'Beschreibung hinzufügen'"
                           matInput>
                </mat-form-field>
            </div>

            <!-- ---------------------------------------------------------------------------------------------------------- -->
            <!-- -------------------------------------------------Alarm---------------------------------------------------- -->
            <!-- ---------------------------------------------------------------------------------------------------------- -->
            <div class="item-container">
                <mat-icon>alarm</mat-icon>
                <button [matMenuTriggerFor]="alarmMenu" (menuOpened)="helpersService.fixDisappearIOSBug()" class="alarm-button" color="default" mat-button>
                    Erinnerung: {{parseMinutes(alarmMinutes) }} vorher
                </button>
                <mat-menu #alarmMenu="matMenu">
                    <button (click)="onNewAlarmAppointment(1440)" mat-menu-item>24 Std.</button>
                    <button (click)="onNewAlarmAppointment(120)" mat-menu-item>2 Std.</button>
                    <button (click)="onNewAlarmAppointment(60)" mat-menu-item>1 Std.</button>
                    <button (click)="onNewAlarmAppointment(15)" mat-menu-item>15 Min.</button>
                </mat-menu>
            </div>
        </div>
    </div>


    <!-- ---------------------------------------------------------------------------------------------------------- -->
    <!-- -------------------------------------------------View-Mode------------------------------------------------ -->
    <!-- ---------------------------------------------------------------------------------------------------------- -->
    <div *ngIf="!editMode" class="content-list">
        <hr>
        <div class="title-container">
            <h2 style="text-align: left">{{ this.actEvent.title }}</h2>
        </div>

        <!-- ---------------------------------------------------------------------------------------------------------- -->
        <!-- -------------------------------------------------Title---------------------------------------------------- -->
        <!-- ---------------------------------------------------------------------------------------------------------- -->
        <div class="readonly-container" style="margin-top: 10px">
            <mat-icon>query_builder</mat-icon>
            <span>{{ this.actEvent.start | date:'dd.MM.yyyy' }}</span>
            <span *ngIf="!this.actEvent.allDay" style="margin-left:5px">{{ this.actEvent.start | date:'HH:mm' }}</span>
            <span *ngIf="this.actEvent.end">&nbsp;-&nbsp;</span>
            <span *ngIf="!this.actEvent.allDay" style="margin-right:5px">{{ this.actEvent.end | date:'HH:mm' }}</span>
            <span>{{ this.actEvent.end | date:'dd.MM.yyyy' }}</span>
        </div>

        <mat-checkbox *ngIf="this.actEvent.allDay" [checked]="this.actEvent.allDay" disabled style="margin-left: 50px">
            Ganztägig
        </mat-checkbox>

        <!-- ---------------------------------------------------------------------------------------------------------- -->
        <!-- -------------------------------------------------Couple--------------------------------------------------- -->
        <!-- ---------------------------------------------------------------------------------------------------------- -->
        <div class="readonly-container">
            <mat-icon>person_pin</mat-icon>
            <span *ngIf="this.actEvent.couple">{{ this.actEvent.couple?.accountData?.firstName }}</span>
            <span *ngIf="!this.actEvent.couple" style="color: #c8c8c8">keine angaben</span>
        </div>

        <!-- ---------------------------------------------------------------------------------------------------------- -->
        <!-- -------------------------------------------------Location------------------------------------------------- -->
        <!-- ---------------------------------------------------------------------------------------------------------- -->
        <div class="readonly-container">
            <mat-icon>place</mat-icon>
            <span *ngIf="this.actEvent.location">{{ this.actEvent.location }}</span>
            <span *ngIf="!this.actEvent.location" style="color: #c8c8c8">keine angaben</span>
            <button *ngIf="this.actEvent.location" class="near-me-button" mat-icon-button>
                <mat-icon>near_me</mat-icon>
            </button>
        </div>

        <!-- ---------------------------------------------------------------------------------------------------------- -->
        <!-- -------------------------------------------------Comments------------------------------------------------- -->
        <!-- ---------------------------------------------------------------------------------------------------------- -->
        <div class="readonly-container">
            <mat-icon>subject</mat-icon>
            <span *ngIf="this.actEvent.comments">{{ this.actEvent.comments }}</span>
            <span *ngIf="!this.actEvent.comments" class="keine-angaben">keine angaben</span>
        </div>
        <!-- ---------------------------------------------------------------------------------------------------------- -->
        <!-- ---------------------------------------------------------------------------------------------------------- -->
        <!-- ---------------------------------------------------------------------------------------------------------- -->
        <div class="readonly-container">
            <mat-icon>alarm</mat-icon>
            <button [matMenuTriggerFor]="alarmMenu" (menuOpened)="helpersService.fixDisappearIOSBug()" class="alarm-button" color="default" mat-button>
                Erinnerung: {{parseMinutes(this.actEvent.alarm) }} vorher
            </button>
            <mat-menu #alarmMenu="matMenu">
                <button (click)="onAlarmAppointment(this.actEvent,1440)" mat-menu-item>24 Std.</button>
                <button (click)="onAlarmAppointment(this.actEvent,120)" mat-menu-item>2 Std.</button>
                <button (click)="onAlarmAppointment(this.actEvent,60)" mat-menu-item>1 Std.</button>
                <button (click)="onAlarmAppointment(this.actEvent,15)" mat-menu-item>15 Min.</button>
            </mat-menu>
        </div>
    </div>

</mat-dialog-content>


<mat-dialog-actions *ngIf="editMode" class="dialog-actions">
    <span *ngIf="showError" class="error-message">{{error }}</span>
    <button (click)="onSubmit(form)" color="accent" mat-button mat-raised-button>
        <mat-spinner *ngIf="saving" [diameter]="38" class="save-spinner"></mat-spinner>
        <span *ngIf="!saving">{{ 'Speichern'  }}</span>
    </button>
</mat-dialog-actions>
