import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Package } from '../_interfaces/package.interface';
import { HelpersService } from './helpers.service';

@Injectable({
	providedIn: 'root'
})
export class FormsHelpersService {

	constructor() {
	}

	public static markControlsAsTouched(form: FormGroup) {
		for (const property in form.controls) {
			if (form.controls.hasOwnProperty(property)) {
				form.controls[property].markAsTouched();
			}
		}
	}

	public static matchPassword(control: AbstractControl, passwordControlName?: string, passwordRepeatControlName?: string) {
		const password = control.get(passwordControlName || 'password').value;
		const confirmPassword = control.get(passwordRepeatControlName || 'passwordRepeat').value;

		if (password !== confirmPassword) {
			return {passwordsDoNotMatch: true};
		} else {
			return null;
		}
	}

	public static logAllFormErrors(form: FormGroup): void {
		if (form.errors) {
			console.error(`Form errors: ${HelpersService.safelyStringify(form.errors)}`);
		}
		for (const property in form.controls) {
			if (form.controls.hasOwnProperty(property)) {
				if (form.controls[property].errors) {
					console.error(`${property} errors: ${HelpersService.safelyStringify(form.controls[property].errors)}`);
				}
			}
		}
	}

	public static standardPackageSelected(control: AbstractControl) {
		const packages: Package[] = control.value.packages;
		if (packages) {
			const oneStandardAtLeast = packages.some(pack => !!pack.parentId);
			if (oneStandardAtLeast) {
				return null;
			} else {
				return {noStandardPackageSelected: true};
			}
		} else {
			return {noStandardPackageSelected: true};
		}
	}

	public static extractAndInjectErrorMessage(err: HttpErrorResponse | any, errorMessages: string[], form: FormGroup) {
		try {
			if (HelpersService.safelyGet(() => err.msg)) {
				errorMessages.push(err.msg);
			} else if (HelpersService.safelyGet(() => err.error.msg)) {
				if (typeof err.error.msg === 'string') {
					errorMessages.push(err.error.msg);
				} else {
					errorMessages.push(err.error.msg.errmsg);
				}
			} else if (HelpersService.safelyGet(() => err.json)) {
				errorMessages.push(err.json().msg);
			} else if (HelpersService.safelyGet(() => err[0].msg)) {
				errorMessages.push(err[0].msg);
			}
		} catch (e) {
			console.error(e);
		} finally {
			form.setErrors({'somethingWentWrong': true});
		}
	}



}
