<h1 class="text-center" mat-dialog-title>Badges</h1>

<div class="container">
    <div class="row ">
        <div class="col-md-12 text-center">
            {{ 'DIALOGS.BADGE.BODY' | translate }}
        </div>

        <div class="badges-container">

            <div class="badge-item">

                <img alt="WeddyPlace" src="https://cdn.weddyplace.com/static/misc/badges/badge-gelistet.png">

                <div style="align-self: baseline; border-radius: 5px;border-left: 1px solid #cdcdcd;border-top: 1px solid #cdcdcd;border-right: 1px solid #cdcdcd; background-color: #2a3d53; color: #fff;
                margin-bottom: -5px;
                padding-bottom: 10px;
                padding-left: 5px;
                padding-right: 5px;">Dieses HTML kopieren und auf deiner Webseite einfügen:</div>
                <div class="code-box">
                    <code>
                        <![CDATA[

                        <a href="https://www.weddyplace.com/vendors/{{ shop?._id }}" target="_blank">
                            <img alt="WeddyPlace" src="https://cdn.weddyplace.com/static/misc/badges/badge-gelistet.png">
                        </a>

                        ]]>
                    </code>
                </div>

            </div>
        </div>

    </div>

    <div class="row" style="margin-bottom: 10px;">
        <div class="col-md-12 text-center">
            Du weißt nicht wie du HTML zu deiner Webseite hinzufügst? <a target="_blank" href="https://help.weddyplace.com/wie-binde-ich-das-weddyplace-badge-in-meine-webseite-ein/">Klicke hier</a>
        </div>
    </div>
    <div class="row ">
        <div class="col-md-12 text-center">
            <button (click)="dialogRef.close(true)" color="primary" mat-button mat-raised-button>
                {{ 'BUTTONS.CLOSE' | translate }}
            </button>
        </div>
    </div>
</div>
