import { ChangeDetectionStrategy, Component, Inject, isDevMode } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AccountDataService, IssuesService } from '../../../_services';

@Component({
	selector: 'app-error-dialog',
	templateUrl: './error-dialog.component.html',
	styleUrls: ['./error-dialog.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorDialogComponent {

	public testing: boolean;

	constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>,
				public accountDataService: AccountDataService,
				private issuesService: IssuesService,
				@Inject(MAT_DIALOG_DATA) public error: any) {
		if (isDevMode()) {
			this.testing = true;
		}
	}

	public onClose() {
		window.location.reload();
	}

	public openIssue() {
		this.issuesService.openIssue(this.error);
	}

}
