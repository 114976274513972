import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Template } from '../_interfaces/template.interface';
import { ApiService } from './api.service';
import { ShopsService } from './shops.service';

@Injectable({
	providedIn: 'root'
})
export class TemplatesService {

	get templates(): Template[] {
		return this._templates;
	}

	set templates(newTemplates: Template[]) {
		this._templates = newTemplates;
		this.templatesChanged.next(this.templates);
	}

	public templateSelected = new Subject<Template>();
	public openTemplatesPanelChanged = new Subject<boolean>();

	public templatesChanged = new BehaviorSubject<Template[]>(this.templates);

	private _templates: Template[];

	constructor(public api: ApiService,
	            private shopsService: ShopsService) {
	}

	public getTemplates() {
		this.templates = this.shopsService.currentShop.chatTemplates;
		return this.templates;
	}

	public addTemplate(template: Template) {
		const index = (this.templates.findIndex((anyTemplate: Template) => {
			return anyTemplate._id === template._id;
		}));
		if (index === -1) {
			this.templates.unshift(template);
		}
	}

	public postTemplate(template: Template) {
		const req = this.api.post('pro_templates', template, true);

		req.subscribe(
			(newTemplate: Template) => {
				this.addTemplate(newTemplate);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public editTemplate(template: Template) {
		const index = (this.templates.findIndex((anyTemplate: Template) => {
			return anyTemplate._id === template._id;
		}));
		if (index >= 0) {
			this.templates[index] = template;
		}
	}

	public updateTemplate(template: Template) {
		const req = this.api.put('pro_templates', template, true);

		req.subscribe(
			(res) => {
				this.editTemplate(res);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public removeTemplate(id) {
		const index = (this.templates.findIndex((anyTemplate: Template) => {
			return anyTemplate._id === id;
		}));
		if (index >= 0) {
			this.templates.splice(index, 1);
		}
	}

	public deleteTemplate(template: Template) {
		const req = this.api.delete(`pro_templates`, `${this.shopsService.currentShop._id}/${template._id}`, true);

		req.subscribe(
			() => {
				this.removeTemplate(template._id);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public applyTemplate(template: Template) {
		this.templateSelected.next(template);
	}

	public openTemplatesPanel() {
		this.openTemplatesPanelChanged.next(true);
	}

}
