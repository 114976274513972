import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { AccountData } from '../../../_interfaces';
import { AccountDataService } from '../../../_services';

@Component({
	selector: 'app-premium-dialog',
	templateUrl: './premium-dialog.component.html',
	styleUrls: ['./premium-dialog.component.sass']
})
export class PremiumDialogComponent implements OnInit, OnDestroy {
	public customer: any;
	public product: any;
	public productMonth: any;
	public productYear: any;
	public paymentYearly: any;
	public invoice: any;
	public subscription: any;
	public changePaymentMethod: boolean;
	success: boolean;
	constructor(
		public dialogRef: MatDialogRef<PremiumDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.customer = data.customer;

		this.productMonth = data.productMonth;
		this.productYear = data.productYear;
		this.paymentYearly = data.paymentYearly;
		this.product = data.product;
		this.invoice = data.invoice;
		this.subscription = data.subscription;
		this.changePaymentMethod = data.changePaymentMethod;
	}

	public ngOnInit() {
	}

	public onProcessing($event){
    this.dialogRef.disableClose = $event;
	}

	public onPaymentSuccess() {
		this.success = true;
		// make vendor premium;
		// close dialog
		this.dialogRef.close(true);
	}
	
	public onPaymentFailure($event) {
		// this.dialogRef.close();
		this.success = false;
	}


	public ngOnDestroy() {
	}
}
