import { isPlatformServer } from '@angular/common';
import { Component, Inject, isDevMode, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { getStatusText } from 'http-status-codes';
import { environment } from '../../../../environments/environment';
import { WeddyPlaceError } from '../../_interfaces';
import { AccountDataService, IssuesService, MetaTagsService } from '../../_services';

@Component({
	selector: 'app-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.sass']
})
export class ErrorComponent implements OnInit {

	public testing: boolean;

	public version: string = environment.version;

	public errorCode;
	public errorMessage;

	constructor(public route: ActivatedRoute,
				private issuesService: IssuesService,
				public accountDataService: AccountDataService,
				private metaTagsService: MetaTagsService,
				@Inject(PLATFORM_ID) private platformId,
				@Optional() @Inject(RESPONSE) private response: any) {
	}

	public ngOnInit() {
		try {
			if (isDevMode()) {
				this.testing = true;
			}
			this.metaTagsService.createErrorMetaTags();
			this.errorCode = this.route.snapshot.paramMap.get('errorCode');
			if (this.errorCode) {
				this.errorMessage = getStatusText(this.errorCode);
			} else {
				this.errorMessage = this.route.snapshot.queryParamMap.get('errorMessage');
			}
			if (isPlatformServer(this.platformId) && this.response) {
				this.response.status(this.errorCode);
			}
		} catch (e) {
			console.error(e);
		}
	}

	public openIssue() {
		this.issuesService.openIssue(new WeddyPlaceError(`${this.errorCode} ${this.errorMessage}`));
	}

}
