<!--Card-->
<div [ngClass]="{ 'editing-card': editMode || hovering }" class="form-card">
    <div class="row">
        <div *ngIf="!insideDialog" class="form-card-aux col-md-4 col-xs-12">
            <div class="form-card-title">
                <h3>Ein-Klick Rückmeldung erstellen</h3>
            </div>
            <div class="form-card-description">
                <p>Tragt hier eine Nachricht ein, die automatisch an alle Brautpaar nach Annahme der Anfrage gesendet
                    wird. Eine Anrede ist nicht notwendig, da diese automatisch ausgefüllt wird.
                    Dienstleister mit schneller Rückmeldung werden favorisiert angezeigt und erhalten bis zu 30% mehr
                    Buchungen!</p>
            </div>
            <div class="form-card-actions">
                <!--Edit-->
                <app-edit-button (click)="onEdit()" *ngIf="!editMode" [tabIndex]="1"></app-edit-button>
                <!--Cancel-->
                <app-cancel-button (click)="onCancel()" *ngIf="editMode && !processing" [tabIndex]="7">
                </app-cancel-button>
                <!--Save-->
                <app-save-button (click)="onSave()" *ngIf="editMode" [danger]="form.touched && form.invalid"
                                 [tabIndex]="6"></app-save-button>
                <!--Spinner-->
                <app-spinner-button *ngIf="processing"></app-spinner-button>
                <!--Success-->
                <mat-icon *ngIf="success" class="blue-icon">done</mat-icon>
            </div>
        </div>
        <div [ngClass]="{ 'col-md-8 col-xs-12': !insideDialog, 'col-md-12 col-xs-12': insideDialog, 'disabled': !insideDialog && !editMode }"
             class="form-card-main">

            <mat-dialog-content class="auto-answer-dialog-content">

                <mat-checkbox (click)="activate($event)" [(ngModel)]="autoReplyActive" [disabled]="!editMode"
                              class="example-margin">
                    Aktivieren
                </mat-checkbox>

                <form (ngSubmit)="onSave()" [formGroup]="form">
                    <div class="steps-container">
                        <div class="icon-container">
                            <svg enable-background="new 18 157.5 88 88" height="88px"
                                 id="svg2" version="1.1"
                                 viewBox="18 157.5 88 88" width="88px" x="0px" xml:space="preserve"
                                 xmlns="http://www.w3.org/2000/svg" y="0px">
                                <g>
                                    <path d="M98.345,217.414c-0.22-20.063-22.102-33.62-37.656-33.449c-16.616,0.184-36.331,9.326-36.111,29.392
                                c0.221,20.061,20.371,23.717,37.005,13.092C79.992,214.692,98.522,233.559,98.345,217.414z"
                                          fill="#D5DFE9"/>
                                </g>
                                <path d="M44.584,175.159l34.785-0.042c3.49-0.003,6.328,2.825,6.333,6.317
                            l0.028,24.772c0.006,3.492-2.825,6.326-6.315,6.329l-21.217,0.025l-10,9.631l-0.011-9.619l-3.559,0.005
                            c-3.492,0.003-6.326-2.822-6.332-6.315l-0.028-24.771C38.263,177.998,41.091,175.162,44.584,175.159z"
                                      fill="#FFFFFF" stroke="#96AFC3"
                                      stroke-miterlimit="10"/>
                                <g>
                                    <line fill="#FFFFFF" stroke="#96AFC3" stroke-miterlimit="10" x1="45.354"
                                          x2="60.049" y1="183.425" y2="183.425"/>
                                    <g>
                                        <line fill="#FFFFFF" stroke="#96AFC3" stroke-miterlimit="10" x1="45.354"
                                              x2="78.645" y1="192.747" y2="192.747"/>
                                        <line fill="#FFFFFF" stroke="#96AFC3" stroke-miterlimit="10" x1="45.354"
                                              x2="78.645" y1="197.908" y2="197.908"/>
                                        <line fill="#FFFFFF" stroke="#96AFC3" stroke-miterlimit="10" x1="45.354"
                                              x2="78.645" y1="203.069" y2="203.069"/>
                                    </g>
                                </g>
                            </svg>
                            <p>
                                Erstellt Euch unten eine Vorlage für schnelle Antworten
                            </p>
                        </div>

                        <div class="icon-container">

                            <svg enable-background="new 198 157.5 88 88" height="88px"
                                 id="svg2" version="1.1"
                                 viewBox="198 157.5 88 88" width="88px" x="0px" xml:space="preserve"
                                 xmlns="http://www.w3.org/2000/svg" y="0px">
                                <path d="M285.375,202.035c0,2.209-1.791,4-4,4h-78.75c-2.209,0-4-1.791-4-4v-15.253c0-2.209,1.791-4,4-4h78.75
                                    c2.209,0,4,1.791,4,4V202.035z" fill="#96AFC3"/>
                                <path d="M249.839,201.508c-0.965,0.469,1.682,32.783,1.682,32.783
                                    l7.676-6.488l7.119,14.625l2.783-1.355l2.783-1.356l-7.119-14.625l9.842-2.034C274.604,223.057,250.803,201.038,249.839,201.508z"
                                      fill="#FFFFFF" stroke="#96AFC3"
                                      stroke-miterlimit="10"/>
                                <g>
                                    <g>
                                        <line fill="#FFFFFF" stroke="#D5DFE9" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-miterlimit="10" x1="241.994" x2="242.006"
                                              y1="176.161" y2="184.594"/>
                                    </g>
                                    <g>
                                        <line fill="#FFFFFF" stroke="#D5DFE9" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-miterlimit="10" x1="241.994" x2="242.006"
                                              y1="204.224" y2="212.656"/>
                                    </g>
                                </g>
                                <g>
                                    <line fill="#FFFFFF" stroke="#D5DFE9" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-miterlimit="10" x1="227.805" x2="233.471" y1="180.575" y2="186.826"/>
                                </g>
                                <g>
                                    <line fill="#FFFFFF" stroke="#D5DFE9" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-miterlimit="10" x1="256.195" x2="250.613" y1="180.409" y2="186.736"/>
                                </g>
                                <line fill="#FFFFFF" stroke="#D5DFE9" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-miterlimit="10" x1="234.207" x2="227.803" y1="202.863" y2="208.353"/>
                                <rect fill="none" height="8.381" width="87.016" x="198.625" y="190.219"/>
                                <text fill="#FFFFFF" font-family="'OpenSans'"
                                      font-size="10" transform="matrix(1 0 0 1 213.8955 197.8184)">Annehmen</text>
                            </svg>
                            <p>
                                Diese wird in Zukunft direkt nach Annahme der Anfrage an den Kunden gesendet
                            </p>
                        </div>


                        <div class="icon-container">
                            <svg enable-background="new 412.5 157.5 88 88" height="88px"
                                 id="svg2" version="1.1"
                                 viewBox="412.5 157.5 88 88" width="88px" x="0px" xml:space="preserve"
                                 xmlns="http://www.w3.org/2000/svg" y="0px">
                                <path d="M496.183,203.93c0,17.035-14.181,32.649-43.82,32.649c-25.511,0-37.54-23.062-37.54-40.098
                                    s23.454-36.578,36.022-24.578C468.231,188.504,496.183,185.146,496.183,203.93z"
                                      fill="#D5DFE9"/>
                                <g>
                                    <path d="M487.115,197.752c-0.001-0.076-0.025-0.149-0.061-0.22c-0.012-0.021-0.024-0.038-0.038-0.058
                                        c-0.016-0.021-0.024-0.046-0.043-0.065l-6.892-6.989v-10.492c0-0.276-0.224-0.5-0.5-0.5h-13.789l-9.906-7.854
                                        c-0.088-0.07-0.198-0.108-0.311-0.108h-0.149c-0.112,0-0.223,0.038-0.311,0.108l-9.906,7.854h-13.789c-0.276,0-0.5,0.224-0.5,0.5
                                        v10.661l-6.886,6.677c-0.122,0.118-0.161,0.282-0.137,0.438c-0.002,0.02-0.016,0.036-0.016,0.057v29.676
                                        c0,3.445,1.399,4.099,3.98,4.099h55.277c2.579,0,3.977-0.653,3.977-4.099V197.76C487.117,197.757,487.115,197.755,487.115,197.752z
                                        " fill="#FFFFFF"/>
                                </g>
                                <g>
                                    <g>
                                        <line fill="#FFFFFF" stroke="#96AFC3" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-miterlimit="10" x1="479.267" x2="478.373"
                                              y1="164.611" y2="172.894"/>
                                    </g>
                                    <g>
                                        <line fill="#FFFFFF" stroke="#96AFC3" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-miterlimit="10" x1="495.924" x2="487.703"
                                              y1="186.464" y2="185.112"/>
                                    </g>
                                    <g>
                                        <line fill="#FFFFFF" stroke="#96AFC3" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-miterlimit="10" x1="465.986" x2="470.832"
                                              y1="168.269" y2="175.048"/>
                                    </g>
                                    <g>
                                        <line fill="#FFFFFF" stroke="#96AFC3" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-miterlimit="10" x1="492.331" x2="485.595"
                                              y1="172.305" y2="177.21"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <line fill="none" stroke="#96AFC3" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-miterlimit="10" x1="424.383" x2="431.421"
                                              y1="197.625" y2="190.801"/>
                                        <path clip-rule="evenodd" d="
                                            M455.576,231.034c0,0,25.203,0,27.564,0c2.357,0,3.477-0.499,3.477-3.599c0-3.104,0-29.676,0-29.676l-31.041,20.735h-0.149
                                            l-31.044-20.735c0,0,0,26.571,0,29.676c0,3.101,1.118,3.599,3.48,3.599c2.356,0,27.563,0,27.563,0H455.576z"
                                              fill="none" fill-rule="evenodd"
                                              stroke="#96AFC3" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-miterlimit="10"/>
                                        <polyline clip-rule="evenodd" fill="none" fill-rule="evenodd" points="
                                            479.582,202.39 479.582,179.928 455.576,179.928 455.427,179.928 431.421,179.928 431.421,202.39 		"
                                                  stroke="#96AFC3" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-miterlimit="10"/>
                                        <polyline fill="none" points="
                                            465.619,179.928 455.576,171.966 455.427,171.966 445.384,179.928 		"
                                                  stroke="#96AFC3"
                                                  stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-miterlimit="10"/>

                                        <line fill="none" stroke="#96AFC3" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-miterlimit="10" x1="486.617" x2="479.582"
                                              y1="197.76" y2="190.625"/>
                                    </g>
                                    <line fill="#FFFFFF" stroke="#96AFC3" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-miterlimit="10" x1="437.377" x2="453.377" y1="186.464" y2="186.464"/>
                                    <g>
                                        <line fill="#FFFFFF" stroke="#96AFC3" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-miterlimit="10" x1="437.377" x2="473.626"
                                              y1="194.786" y2="194.786"/>

                                        <line fill="#FFFFFF" stroke="#96AFC3" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-miterlimit="10" x1="437.377" x2="473.626"
                                              y1="198.947" y2="198.947"/>

                                        <line fill="#FFFFFF" stroke="#96AFC3" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-miterlimit="10" x1="437.377" x2="473.626"
                                              y1="203.108" y2="203.108"/>
                                    </g>
                                    <g>
                                        <line fill="#FFFFFF" stroke="#96AFC3" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-miterlimit="10" x1="438.62" x2="472.381"
                                              y1="207.27" y2="207.27"/>

                                        <line fill="#FFFFFF" stroke="#96AFC3" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-miterlimit="10" x1="445.384" x2="465.986"
                                              y1="211.431" y2="211.431"/>
                                    </g>
                                    <g>
                                        <line fill="#FFFFFF" stroke="#96AFC3" stroke-linecap="round"
                                              stroke-linejoin="round" stroke-miterlimit="10" x1="451.125" x2="459.923"
                                              y1="215.592" y2="215.592"/>
                                    </g>
                                </g>
                            </svg>
                            <p>
                                Der Kunde erhält blitzschnell Rückmeldung von Euch und hat eine gute Kundenerfahrung
                            </p>
                        </div>

                    </div>

                    <div *ngIf="autoReplyActive">
                        <mat-card>
                            <mat-card-content>
                                <p class="customer-greeting">Hallo (Kundenname),</p>
                                <!--Description-->
                                <mat-form-field class="weddy-form-field message-body-field">
                                    <textarea [formControl]="form.controls['text']" matInput rows="14"></textarea>
                                    <button (click)="form.controls['text'].reset()" *ngIf="form.controls['text'].value"
                                            aria-label="Clear" mat-button
                                            mat-icon-button matSuffix tabindex="-1"
                                            type="button">
                                        <mat-icon [svgIcon]="'close'"></mat-icon>
                                    </button>
                                    <mat-hint align="end">{{ form.controls['text'].value?.length || 0  }} /
                                        {{ textMaxLength }}</mat-hint>
                                    <mat-error *ngIf="form.controls['text'].hasError('required')">
                                        <span [innerHTML]="'INPUT_ERRORS.DESCRIPTION.REQUIRED' | translate"></span>
                                    </mat-error>
                                    <mat-error *ngIf="form.controls['text'].hasError('minlength')">
                                        <span
                                                [innerHTML]="'INPUT_ERRORS.DESCRIPTION.TOO_SHORT' | translate:{number:textMinLength}"></span>
                                    </mat-error>
                                    <mat-error *ngIf="form.controls['text'].hasError('maxlength')">
                                        <span
                                                [innerHTML]="'INPUT_ERRORS.DESCRIPTION.TOO_LONG' | translate:{number:textMaxLength}"></span>
                                    </mat-error>
                                </mat-form-field>

                                <div class="template-payloads">
                                    <div *ngFor="let payload of payloads" class="template-payload">
                                        <mat-icon [svgIcon]="'attachment'" class="payload-indicator"></mat-icon>
                                        <span>{{ payload.originalName }}</span>
                                        <button (click)="onDeleteAttachment(payload)" [matTooltipPosition]="'below'"
                                                [matTooltipShowDelay]="500"
                                                [matTooltip]="'TOOLTIP.DELETE_ATTACHMENT' | translate"
                                                class="delete-button hover-only"
                                                mat-button
                                                mat-icon-button type="button">
                                            <mat-icon [svgIcon]="'bin-1'"></mat-icon>
                                        </button>
                                    </div>
                                </div>

                                <div class="meta-data-actions">
                                    <mat-spinner *ngFor="let uploadingFile of uploadingFiles" diameter="20">
                                    </mat-spinner>
                                    <input #templatePayloadInput (change)="onFileInput($event)"
                                           accept="application/pdf, image/x-png,image/gif,image/jpeg" id="templatePayloadInput"
                                           multiple name="sendFile"
                                           type="file">
                                </div>

                                <a (click)="templatePayloadInput.click()" [routerLink]="" style="float: right">Anhang
                                    hinzufügen... (z.B: Preisliste,Raumplan...)</a>

                            </mat-card-content>
                        </mat-card>
                    </div>
                </form>
            </mat-dialog-content>
        </div>
    </div>
</div>
