import { Pipe, PipeTransform } from '@angular/core';
import { InputTrackingService } from '../_services/input-tracking.service';

@Pipe({
	name: 'trackInput'
})
export class TrackInputPipe implements PipeTransform {

	constructor(private inputTrackingService: InputTrackingService) {
	}

	public transform(value: any, args?: any): any {
		this.inputTrackingService.addWordUsed(value);
		return value;
	}

}
