export const WIZARD_QUESTIONS = {
	ADVANCE: 'advance',
	AREA: 'area',
	BUDGET: 'budget',
	CAKES_BUDGET: 'cakesBudget',
	CATERING_DIETARY_PREFERENCES: 'cateringDietaryPreferences',
	CATERING_TYPE: 'cateringType',
	CITY: 'city',
	COUNTDOWN: 'countdown',
	DESTINATION: 'destination',
	G_MAPS_LOCATION: 'gMapsLocation',
	GUESTS: 'guests',
	LAND: 'land',
	LOCATION_BUDGET: 'locationBudget',
	LOCATION_BUDGET_HIGH: 'locationBudgetHigh',
	LOCATION_BUDGET_LOW: 'locationBudgetLow',
	LOCATION_BUDGET_MED_HIGH: 'locationBudgetMedHigh',
	LOCATION_BUDGET_MED_LOW: 'locationBudgetMedLow',
	LOCATION_GUESTS: 'locationGuests',
	MUSIC_BUDGET: 'musicBudget',
	MUSIC_OCCASION: 'musicOccasion',
	MUSIC_PLAYER: 'musicPlayer',
	MUSIC_STYLE: 'musicStyle',
	OFFICIANT_BUDGET: 'officiantBudget',
	OFFICIANT_DENOMINATION: 'officiantDenomination',
	PHOTOGRAPHY_DURATION: 'photographyDuration',
	PHOTOGRAPHY_LOWER_BUDGET: 'photographyLowerBudget',
	PHOTOGRAPHY_OCCASION: 'photographyOccasion',
	PHOTOGRAPHY_STYLE: 'photographyStyle',
	PHOTOGRAPHY_UPPER_BUDGET: 'photographyUpperBudget',
	SEASON: 'season',
	SPECIFIC_DATE: 'specificDate',
	VIDEOGRAPHY_BUDGET: 'videographyBudget',
	VIDEOGRAPHY_DURATION: 'videographyDuration',
	VIDEOGRAPHY_OCCASION: 'videographyOccasion',
};
export const WIZARD_DEFAULT_QUESTIONS = {
	CAKES: [WIZARD_QUESTIONS.CAKES_BUDGET, WIZARD_QUESTIONS.GUESTS],
	CATERING: [WIZARD_QUESTIONS.CATERING_TYPE, WIZARD_QUESTIONS.CATERING_DIETARY_PREFERENCES, WIZARD_QUESTIONS.BUDGET, WIZARD_QUESTIONS.SEASON, WIZARD_QUESTIONS.G_MAPS_LOCATION],
	LOCATIONS: [WIZARD_QUESTIONS.AREA, WIZARD_QUESTIONS.GUESTS, WIZARD_QUESTIONS.BUDGET, WIZARD_QUESTIONS.SEASON, WIZARD_QUESTIONS.G_MAPS_LOCATION],
	MUSIC: [WIZARD_QUESTIONS.MUSIC_OCCASION, WIZARD_QUESTIONS.MUSIC_STYLE, WIZARD_QUESTIONS.SEASON, WIZARD_QUESTIONS.MUSIC_BUDGET, WIZARD_QUESTIONS.G_MAPS_LOCATION],
	OFFICIANTS: [WIZARD_QUESTIONS.OFFICIANT_DENOMINATION, WIZARD_QUESTIONS.OFFICIANT_BUDGET, WIZARD_QUESTIONS.SEASON, WIZARD_QUESTIONS.G_MAPS_LOCATION],
	PHOTOGRAPHY: [WIZARD_QUESTIONS.PHOTOGRAPHY_OCCASION, WIZARD_QUESTIONS.SEASON, WIZARD_QUESTIONS.G_MAPS_LOCATION],
	VIDEOGRAPHY: [WIZARD_QUESTIONS.VIDEOGRAPHY_OCCASION, WIZARD_QUESTIONS.VIDEOGRAPHY_DURATION, WIZARD_QUESTIONS.VIDEOGRAPHY_BUDGET, WIZARD_QUESTIONS.SEASON, WIZARD_QUESTIONS.G_MAPS_LOCATION],
	PLANNING: [WIZARD_QUESTIONS.SEASON, WIZARD_QUESTIONS.G_MAPS_LOCATION]
};
