import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService, FormsHelpersService, TokenService } from '../_shared/_services';
import { notEmailValidator } from '../_shared/_validators';

@Component({
	selector: 'app-welcome',
	templateUrl: './welcome.component.html',
	styleUrls: ['./welcome.component.sass']
})
export class WelcomeComponent implements OnInit, OnDestroy {

	public nameMinLength: number = 2;
	public nameMaxLength: number = 100;
	public emailMinLength: number = 2;
	public emailMaxLength: number = 100;
	public passwordMinLength: number = 5;
	public passwordMaxLength: number = 100;

	public processing = false;
	public returnUrl;
	public signUpForm: FormGroup;
	public signInForm: FormGroup;

	public errorMessages: string[] = [];

	public currentShop;

	private currentShopSubscription: Subscription;

	constructor(private router: Router,
				private formBuilder: FormBuilder,
				private route: ActivatedRoute,
				public tokenService: TokenService,
				private authenticationService: AuthenticationService) {
	}

	public ngOnInit() {
		this.buildSignUpForm();
		this.buildSignInForm();
		this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/dashboard/inquiries';
	}

	public buildSignUpForm() {
		this.signUpForm = this.formBuilder.group({
			'name': [{
				value: this.route.snapshot.queryParamMap.get('email') || null,
				disabled: false
			}, [Validators.required, Validators.minLength(this.nameMinLength), Validators.maxLength(this.nameMaxLength), notEmailValidator]],
			'email': [{value: null, disabled: false}, [Validators.required, Validators.minLength(this.emailMinLength), Validators.maxLength(this.emailMaxLength), Validators.email]],
			'password': [{value: null, disabled: false}, [Validators.required, Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]]
		});
	}

	public buildSignInForm() {
		this.signInForm = this.formBuilder.group({
			'email': [{value: null, disabled: false}, [Validators.required, Validators.minLength(this.emailMinLength), Validators.maxLength(this.emailMaxLength), Validators.email]],
			'password': [{value: null, disabled: false}, [Validators.required, Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]]
		});
	}

	public onEmailBlur() {
		if (this.signInForm.get('email').value) {
			this.signInForm.get('email').patchValue(this.signInForm.get('email').value.trim());
		}
	}

	public onSignIn() {
		if (this.signInForm.valid) {
			this.signIn(this.signInForm);
		} else {
			this.signInForm.markAsTouched();
			FormsHelpersService.markControlsAsTouched(this.signInForm);
		}
	}

	public signIn(form: FormGroup) {
		this.processing = true;
		this.authenticationService.login(form.value.email.trim(), form.value.password.trim()).subscribe(
			(res) => {
				if (res && res.account && res.account.vendor) {
					this.router.navigate([this.returnUrl]);
				} else {
					this.processing = false;
					this.signInForm.setErrors({'isNotVendor': true});
				}
			},
			(err) => {
				this.errorMessages = [];
				FormsHelpersService.extractAndInjectErrorMessage(err, this.errorMessages, form);
				this.processing = false;
			}
		);
	}

	public ngOnDestroy() {
		if (this.currentShopSubscription) {
			this.currentShopSubscription.unsubscribe();
		}
	}

}
