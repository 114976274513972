<h4 class="your-offer" mat-dialog-title>{{ 'DIALOGS.OFFER.YOUR_OFFER_FOR_THE_COUPLE' | translate }}</h4>

<mat-dialog-content (submit)="onSubmit(form)" [formGroup]="form">

    <div class="price-and-date">
        <!--Price-->
        <mat-form-field class="weddy-form-field">
            <input (blur)="onPriceBlur()" [formControl]="form.controls['price']" matInput
                   placeholder="{{ 'PLACEHOLDERS.YOUR_OFFER_PRICE' | translate }}">
            <mat-error *ngIf="form.controls['price'].hasError('min')">
                <span [innerHTML]="'INPUT_ERRORS.PRICE.TOO_LOW' | translate:{number:minPrice}"></span>
            </mat-error>
        </mat-form-field>

        <!--Date-->
        <mat-form-field (click)="onDateInputClick($event)" class="weddy-form-field">
            <input [formControl]="form.controls['date']" [matDatepicker]="startPicker" matInput
                   name="start"
                   placeholder="{{ 'PLACEHOLDERS.OFFER.DATE' | translate }}" readonly>
            <mat-datepicker-toggle [for]="startPicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
            <mat-error *ngIf="form.controls['date'].hasError('invalidDate')">
                <span [innerHTML]="'INPUT_ERRORS.INVALID_DATE' | translate:{number:textMaxLength}"></span>
            </mat-error>
        </mat-form-field>
    </div>

    <!--Description-->
    <mat-form-field class="weddy-form-field">
        <textarea [formControl]="form.controls['text']" matInput matTextareaAutosize
                  placeholder="{{ 'PLACEHOLDERS.YOUR_OFFER_DESCRIPTION' | translate }}"></textarea>
        <button (click)="form.controls['text'].reset()" *ngIf="form.controls['text'].value" aria-label="Clear" mat-button mat-icon-button
                matSuffix tabindex="-1" type="button">
            <mat-icon [svgIcon]="'close'"></mat-icon>
        </button>
        <mat-hint align="end">{{ form.controls['text'].value?.length || 0  }} / {{ textMaxLength }}</mat-hint>
        <mat-error *ngIf="form.controls['text'].hasError('required')">
            <span [innerHTML]="'INPUT_ERRORS.DESCRIPTION.REQUIRED' | translate"></span>
        </mat-error>
        <mat-error *ngIf="form.controls['text'].hasError('minlength')">
            <span [innerHTML]="'INPUT_ERRORS.DESCRIPTION.TOO_SHORT' | translate:{number:textMinLength}"></span>
        </mat-error>
        <mat-error *ngIf="form.controls['text'].hasError('maxlength')">
            <span [innerHTML]="'INPUT_ERRORS.DESCRIPTION.TOO_LONG' | translate:{number:textMaxLength}"></span>
        </mat-error>
    </mat-form-field>

    <mat-error *ngIf="form.hasError('somethingWentWrong')" class="general-errors">
        <span [innerHTML]="'INPUT_ERRORS.SOMETHING_WENT_WRONG' | translate"></span>
    </mat-error>

    <h5 class="extra-information">{{ 'DIALOGS.OFFER.EXTRA_INFORMATION' | translate }}</h5>

    <div class="drop-box">
        <app-file-upload (onUploaded)="onUploaded($event)"></app-file-upload>
    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button (click)="onCancel()" mat-raised-button type="button">
        {{ 'BUTTONS.CANCEL' | translate }}
    </button>
    <button (click)="onSubmit(form)" [disabled]="processing" color="primary" mat-raised-button>
        {{ 'DIALOGS.OFFER.SEND_OFFER' | translate }}
    </button>
</mat-dialog-actions>
