<form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout">

    <div class="form-container">

        <!--<div class="row">-->
        <!--<div class="field">-->
        <!--<label for="name" data-tid="elements_examples.form.card_number_label">-->
        <!--Name-->
        <!--</label>-->
        <!--<input id="name" name="name" placeholder="Anna Ananas" required [(ngModel)]="name" class="input">-->
        <!--</div>-->
        <!--</div>-->

        <!--<div class="row">-->
        <!--<div class="field">-->
        <!--<label for="email" data-tid="elements_examples.form.card_number_label">-->
        <!--Email Adresse-->
        <!--</label>-->
        <!--<input id="email" name="email" type="email" placeholder="anna.ananas@example.com" [(ngModel)]="email"-->
        <!--required class="input">-->
        <!--</div>-->
        <!--</div>-->

        <div class="row">
            <div class="field">
                <label for="iban">
                    IBAN
                </label>
                <div #sepaInfo class="input" id="iban"></div>
                <div class="bank-name">{{bankName}}</div>
            </div>
        </div>

        <div *ngIf="error" class="errors">
            <mat-error>
                {{ error }}
            </mat-error>
        </div>

        <div class="sepa-info"  id="mandate-acceptance">
            <p>By providing your IBAN and confirming this payment, you are authorizing WeddyPlace GmbH and
                Stripe,
                our
                payment service provider, to send instructions to your bank to debit your account and your bank
                to
                debit
                your account in accordance with those instructions. You are entitled to a refund from your bank
                under
                the
                terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks
                starting
                from
                the date on which your account was debited.</p>
        </div>

    </div>

    <button class="spinner-button" [class.processing]="processing" color="accent" mat-button mat-raised-button type="submit">
        <span *ngIf="!processing">
            {{product ? 'Jetzt Bezahlen' : 'Speichern'}}
        </span>
        <span *ngIf="processing">
            IN BEARBEITUNG... 
        </span>
        <mat-spinner diameter="24" *ngIf="processing"></mat-spinner>
    </button>


</form>
