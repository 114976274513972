import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Inquiry } from '../_interfaces';
import { InquiriesService } from '../_services';

@Injectable()
export class InquiryBreadcrumbResolver implements Resolve<string> {

	constructor(private inquiriesService: InquiriesService) {
	}

	public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> | Promise<string> | string {
		const id = route.paramMap.get('id');

		return this.inquiriesService.getInquiry(id).toPromise().then(
			(inquiry: Inquiry) => {
				if (inquiry && inquiry.suggestedFor && inquiry.suggestedFor.accountData) {
					return inquiry.suggestedFor.accountData.firstName;
				} else {
					return 'MISSING INQUIRY';
				}
			},
			() => {
				return 'ERROR';
			}
		);
	}

}
