import { Injectable } from '@angular/core';

export abstract class Logger {
	public info: Function;
	public warn: Function;
	public error: Function;
}

@Injectable({
	providedIn: 'root'
})
export class LoggerService implements Logger {

	public info: Function;
	public warn: Function;
	public error: Function;

	public invokeConsoleMethod(type: string, args?: any): void {
	}

}
