import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Package } from '../_interfaces/package.interface';
import { ApiService } from './api.service';
import { ShopsService } from './shops.service';
import { HelpersService } from './helpers.service';

@Injectable({
	providedIn: 'root'
})
export class PackagesService {

	get packages(): Package[] {
		return this._packages;
	}

	set packages(value: Package[]) {
		this._packages = value;
	}

	public standardPackages: Package[] = [];
	public customPackages: Package[] = [];

	public packagesChanged: Subject<Package[]> = new Subject<Package[]>();

	private _packages: Package[] = [];

	constructor(private api: ApiService,
				private shopsService: ShopsService) {
	}

	public async getStandardPackages(categoryKey: string): Promise<Package[]> {
		const res = await this.api.get(`packages/${categoryKey}`).toPromise();
		this.standardPackages = res.packages.map((pack: Package) => {
			pack.parentId = pack._id;
			return pack;
		});

		HelpersService.assertNotEmpty(this.standardPackages, 'this.standardPackages');
		return this.standardPackages;
	}

	public getPackages(): Package[] {
		this.packages = this.shopsService.currentShop.packages;
		return this.packages;
	}

	public removePackage(id) {
		const index = (this.packages.findIndex((anyPackage: Package) => {
			return anyPackage._id === id;
		}));
		if (index >= 0) {
			this.packages.splice(index, 1);
			this.packagesChanged.next(this.packages);
		}
	}
}
