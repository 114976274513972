import { FormControl } from '@angular/forms';

export function TelephoneValidator(control: FormControl): { [key: string]: boolean } | null {

	const value = control.value;
	const pattern = /^[0-9\+\-\ ]*$/gm;

	if (!pattern.test(value)) {
		return {notTelephone: true};
	} else {
		return null;
	}
}
