import { ChangeDetectionStrategy, EventEmitter, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/internal/operators';
import { Inquiry } from '../../../_shared/_interfaces';
import { INQUIRY_STATUS, SNACK_BAR } from '../../../_shared/_others/tokens';
import { DialogsService, HelpersService, InquiriesService, ShopsService, StatusReportService } from '../../../_shared/_services';
import { DeclineDialogComponent } from '../../../_shared/modules/dialogs/decline-dialog/decline-dialog.component';
import { MarkAsBookedDialogComponent } from '../../../_shared/modules/dialogs/mark-as-booked-dialog/mark-as-booked-dialog.component';

@Component({
	selector: 'app-status-report-banner',
	templateUrl: './status-report-banner.component.html',
	styleUrls: ['./status-report-banner.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusReportBannerComponent implements OnInit, OnDestroy {

	@Input() public inquiry: Inquiry;
	@Output() public onFinished: EventEmitter<boolean> = new EventEmitter<boolean>();

	public isWarning: boolean;
	public isSeriousWarning: boolean;

	public inquiriesToReport: Observable<Inquiry[]>;

	public nextInquiry: Inquiry;
	private dialogRef: MatDialogRef<DeclineDialogComponent | MarkAsBookedDialogComponent, any>;

	constructor(private statusReportService: StatusReportService,
				private dialogsService: DialogsService,
				private inquiriesService: InquiriesService,
				private snackBar: MatSnackBar,
				private translateService: TranslateService,
				private cdr: ChangeDetectorRef,
				private shopsService: ShopsService,
				private helpersService: HelpersService,
				private router: Router,
				private dialog: MatDialog) {
	}

	public async ngOnInit() {
		this.determineWarningState();
		this.inquiriesService.getInquiries(this.shopsService.currentShop._id);
		this.inquiriesToReport = this.statusReportService.inquiriesToReportChanged;
		this.statusReportService.inquiriesToReportChanged
			.pipe(
				untilDestroyed(this),
				distinctUntilChanged((a, b) => HelpersService.jsonEqual(a, b)))
			.subscribe(
				(inquiries) => {
					this.onInquiriesToReportChanged(inquiries);
				}
			);
		this.cdr.detectChanges();
	}

	public ngOnDestroy(): void {
		this.cdr.detach();
	}

	public async onDecline(inquiry: Inquiry) {
		this.dialogRef = this.dialog.open(DeclineDialogComponent, this.dialogsService.standardDialogConfig({
			disableClose: true
		}));

		this.dialogRef.afterClosed().toPromise().then(
			async (result: { declining: boolean, reason?: string }) => {
				if (result && result.declining) {
					try {
						await this.statusReportService.report(inquiry._id, INQUIRY_STATUS.DECLINED_VENDOR, result.reason).toPromise().catch(err => console.error(err));
						this.onSuccess();
					} catch (e) {
						this.onError(e);
					}
				}

				this.helpersService.blurOutOfEverything();
			}
		);
	}

	public async onContact(inquiry: Inquiry) {
		try {
			await this.statusReportService.report(inquiry._id, INQUIRY_STATUS.CONTACT_OPENED).toPromise().catch(err => console.error(err));
			this.onSuccess();
			this.helpersService.blurOutOfEverything();
		} catch (e) {
			this.onError(e);
		}
	}

	public async onBooked(inquiry: Inquiry) {
		this.dialogRef = this.dialog.open(MarkAsBookedDialogComponent, this.dialogsService.standardDialogConfig({
			data: inquiry,
			width: 'auto',
			panelClass: ['big-dialog'],
			autoFocus: false
		}));

		this.dialogRef.afterClosed().toPromise().then(
			(res) => {
				if (res) {
					this.onSuccess();
				}
				this.helpersService.blurOutOfEverything();
			}
		);
	}

	private determineWarningState() {
		const now: moment.Moment = moment();
		const warningDeadline: moment.Moment = moment().startOf('month').add(7, 'days');
		const seriousWarningDeadline: moment.Moment = moment().startOf('month').add(14, 'days');

		if (now.isSameOrAfter(warningDeadline) && now.isBefore(seriousWarningDeadline)) {
			this.isWarning = true;
		}

		if (now.isSameOrAfter(seriousWarningDeadline)) {
			this.isSeriousWarning = true;
		}
	}

	private onInquiriesToReportChanged(inquiries) {
		if (inquiries && inquiries.length > 0) {
			if (this.inquiry) {
				this.nextInquiry = inquiries.find((inq) => inq._id === this.inquiry._id);
			} else {
				this.nextInquiry = inquiries[0];
			}
		}
		else if(inquiries && inquiries.length <= 0){
			setTimeout(() => {
				this.onFinished.emit(true);
			}, 2000);
		}

		this.cdr.detectChanges();
	}

	private onSuccess() {
		this.snackBar.open(this.translateService.instant('SNACKBAR.STATUS_REPORT_SUCCESSFUL'), undefined, {
			duration: 2000,
			panelClass: SNACK_BAR.SUCCESS
		});
		if (this.inquiry) {
			this.router.navigate(['/dashboard', 'inquiries']);
		}
	}

	private onError(e: Error) {
		console.error(e);
		this.snackBar.open(this.translateService.instant('SNACKBAR.STATUS_REPORT_ERROR'), undefined, {
			duration: 2000,
			panelClass: SNACK_BAR.ERROR
		});
	}

	public openDetails(){
		this.router.navigateByUrl("/dashboard/inquiries/" + this.nextInquiry._id);
		this.onFinished.emit(true);
		// [routerLink]="['/dashboard', 'inquiries', nextInquiry?._id]"
	}
}
