import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
	selector: 'app-publish-error-dialog',
	templateUrl: './publish-error-dialog.component.html',
	styleUrls: ['./publish-error-dialog.component.sass']
})
export class PublishErrorDialogComponent implements OnInit {

	public errors = [];
	constructor(public dialogRef: MatDialogRef<PublishErrorDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
			this.errors = this.data.errors;
	}

	public ngOnInit() {
	}

	public onClose() {
		this.dialogRef.close();
	}

}
