import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Injector, isDevMode, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { Category } from '../_interfaces/category.interface';
import { Detail } from '../_interfaces/detail.interface';
import { Vendor } from '../_interfaces/vendor.interface';
import { ALL_CATEGORIES, ALL_TITLES, CATEGORIES, CATEGORIES_TOKEN } from '../_others/tokens';
import { CdnService } from './cdn.service';
import { ErrorsService } from './errors.service';
import { HelpersService } from './helpers.service';

@Injectable({
	providedIn: 'root'
})
export class CategoriesService {

	public get categories() {
		return this._categories;
	}

	public set categories(categories) {
		this._categories = categories;
		this.categoriesChanged.next(this.categories);
	}

	public get currentCategory() {
		return this._currentCategory;
	}

	public set currentCategory(category) {
		this._currentCategory = category;
		this.currentCategoryChanged.next(this.currentCategory);
	}

	public get categoriesObject() {
		return this._categoriesObject;
	}

	public set categoriesObject(object) {
		this._categoriesObject = object;
		this.categoriesObjectChanged.next(this.categoriesObject);
	}

	public get servicesObject() {
		return this._servicesObject;
	}

	public set servicesObject(object) {
		this._servicesObject = object;
		this.servicesObjectChanged.next(this.servicesObject);
	}

	get vendorDetailsTitles() {
		return this._vendorDetailsTitles;
	}

	set vendorDetailsTitles(vendorDetailsTitles) {
		this._vendorDetailsTitles = vendorDetailsTitles;
		this.vendorDetailsTitlesChanged.next(this.vendorDetailsTitles);
	}

	public keys = {
		photography: CATEGORIES.PHOTOGRAPHY,
		cakes: CATEGORIES.CAKES,
		catering: CATEGORIES.CATERING,
		florists: CATEGORIES.FLORISTS,
		locations: CATEGORIES.LOCATIONS,
		music: CATEGORIES.MUSIC,
		officiants: CATEGORIES.OFFICIANTS,
		bridalwear: CATEGORIES.BRIDALWEAR,
		papeterie: CATEGORIES.PAPETERIE,
		planning: CATEGORIES.PLANNING,
		videography: CATEGORIES.VIDEOGRAPHY,
		styling: CATEGORIES.STYLING
	};

	public categoriesChanged = new Subject<Category[]>();
	public currentCategoryChanged = new Subject<Category>();
	public categoriesObjectChanged = new Subject<any>();
	public servicesObjectChanged = new Subject<any>();
	public vendorDetailsTitlesChanged: Subject<{ key: string, title: string }[]> = new Subject<{ key: string, title: string }[]>();

	private _categories: Category[] = [];

	private _currentCategory: Category;

	private _categoriesObject: any;
	private _servicesObject: any;

	private _vendorDetailsTitles: { key: string, title: string }[];

	constructor(private cdn: CdnService,
				private transferState: TransferState,
				private errorsService: ErrorsService,
				private injector: Injector,
				@Inject(PLATFORM_ID) private platformId: Object) {
		this.getCategories();
	}

	public static searchValue(key: string, values: any[]) {
		HelpersService.assert(key, 'key', undefined, false);
		HelpersService.assert(values, 'values', undefined, false);

		const index = values.findIndex((anyParam) => anyParam.key === key);
		if (index > -1) {
			return values[index].value;
		}
	}

	public static searchGroup(groups: { filters: any, key: string }[], key: string) {
		HelpersService.assert(key, 'key', undefined, false);
		HelpersService.assert(groups, 'groups', undefined, false);

		let result;
		for (const group of groups) {
			if (group.key === key) {
				result = group;
			}
		}
		return result;
	}

	public static searchFilter(filters: { key: string, value: any }[], key: string) {
		let result;
		for (const filter of filters) {
			if (filter.key === key) {
				result = filter;
			}
		}
		return result;
	}

	public static findTitle(key: string, categories: Category[]) {
		const category = categories.find((anyCategory) => {
			return anyCategory.key === key;
		});
		if (category) {
			return category.title;
		} else {
			return '';
		}
	}

	public static findDetailTitle(item, titles) {
		HelpersService.assert(item, 'item', undefined, false);
		HelpersService.assert(titles, 'titles', undefined, false);

		if (titles && item && item['title_key'] || item && item['key']) {
			const detailTitle = titles.find((anyDetailTitle) => anyDetailTitle.key === (item['title_key'] || item['key']));
			if (detailTitle) {
				item['title'] = detailTitle.title;
			} else {
				item['title'] = (item['title_key'] || item['key']);
			}
		}
		return item;
	}

	public static mapDetails(details, titles): Detail[] {
		if (!details) {
			if (isDevMode()) {
				console.warn('No details provided to mapDetails');
			}
			return [];
		}

		if (!titles) {
			if (isDevMode()) {
				console.warn('No titles provided to mapDetails');
			}
			return [];
		}

		for (let detail of details) {
			detail = CategoriesService.findDetailTitle(detail, titles);
			if (detail && detail['values']) {
				for (let value of detail['values']) {
					value = CategoriesService.findDetailTitle(value, titles);
				}
			}
		}

		if (!details) {
			if (isDevMode()) {
				console.warn('Mapping details failed');
			}
			return [];
		}

		return details || [];
	}

	public static createTags(vendor: Vendor, vendorDetailsTitles, preferredTags?: string[], limit?: number, ending?: string) {
		if (!vendor || !vendor.details || vendor.details.length === 0) {
			if (!vendor && isDevMode()) {
				console.warn('No vendor passed');
			}
			if (!vendor.details && isDevMode()) {
				console.warn('No vendor details passed');
			}
			if (vendor.details.length === 0 && isDevMode()) {
				console.warn('Vendor details is empty');
			}

			return [];
		}

		if (!vendorDetailsTitles) {
			if (isDevMode()) {
				console.warn('No titles provided to createTags');
			}
			return [];
		}

		vendor.details = CategoriesService.mapDetails(vendor.details, vendorDetailsTitles);

		const stylesArray = vendor.details.find((aGroupOfDetails) => {
			return (aGroupOfDetails.title_key === 'genres') ||
				(aGroupOfDetails.title_key === 'styles') ||
				(aGroupOfDetails.title_key === 'format') ||
				(aGroupOfDetails.title_key === 'type') ||
				(aGroupOfDetails.title_key === 'venue') ||
				(aGroupOfDetails.title_key === 'services');
		});

		if (stylesArray && stylesArray.values) {
			let styles = stylesArray.values.filter((anyItem) => {
				return anyItem.value === true;
			});

			if (styles.length > 1) {
				styles = HelpersService.shuffle(styles);
			}

			if (styles.length > 1 && preferredTags) {
				styles = styles.sort((a, b) => {
					if (preferredTags.indexOf(a.key) > -1) {
						return -1;
					} else {
						return 1;
					}
				});
			}

			let tags = styles.map((style) => {
				return style['title'];
			});

			const length = tags.length;

			if (limit && length > limit) {
				tags = tags.slice(0, limit);
			}

			if (ending && limit && length > limit) {
				tags.push(ending);
			}

			return tags;
		} else {
			if (isDevMode()) {
				console.warn('No styles array found');
			}
			return [];
		}
	}

	public async getCategories() {
		if (isPlatformServer(this.platformId)) {
			this.getCategoriesFromServer();
			this.getTitlesFromServer();
		} else if (isPlatformBrowser(this.platformId)) {
			await this.fetchAllTitles().toPromise().catch(err => console.error(err));
			await this.fetchAllCategories().toPromise().catch(err => console.error(err));
			await this.fetchAllServices().toPromise().catch(err => console.error(err));
		}
	}

	public async getCategory(key: string): Promise<Category> {
		if (this.categories.length === 0) {
			await this.getCategories();
			return this.findCategory(key);
		} else {
			return this.findCategory(key);
		}
	}

	public findCategory(key: string, categories?: Category[]): Category {
		HelpersService.assert(key, 'key', undefined, true);

		if (!categories) {
			categories = this.categories;
		}

		HelpersService.assert(categories, 'categories', undefined, true);

		const category = categories.find((anyCategory) => {
			return anyCategory.key === key;
		});

		HelpersService.assert(category, 'category', undefined, true);

		return category;
	}

	private getTitlesFromServer() {
		this.vendorDetailsTitles = this.injector.get(ALL_TITLES);
		this.transferState.set(makeStateKey(ALL_TITLES), this.vendorDetailsTitles);
	}

	private getCategoriesFromServer() {
		this.categoriesObject = this.injector.get(CATEGORIES_TOKEN);
		this.transferState.set(makeStateKey(ALL_CATEGORIES), this.categoriesObject);
		this.buildCategoriesArray(this.categoriesObject);
	}

	private buildCategoriesArray(categoriesObject) {
		const categories = [];
		if (categoriesObject) {
			Object.keys(categoriesObject).forEach((key) => {
				categories.push(categoriesObject[key]);
			});
		}
		this.categories = categories.sort(function (a, b) {
			return a.order < b.order ? -1 : 1;
		});
	}

	private fetchAllServices() {
		const req = this.cdn.get(`static/misc/all_services.json`, undefined, "SERVICES");

		req.subscribe(
			(servicesObject) => {
				if (servicesObject) {
					this.servicesObject = servicesObject;
				} else {
					console.warn('No services found');
				}
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	private fetchAllCategories() {
		const req = this.cdn.get(`static/misc/all_categories.json`, undefined, CATEGORIES_TOKEN);

		req.subscribe(
			(categoriesObject) => {
				if (categoriesObject) {
					this.categoriesObject = categoriesObject;
					this.buildCategoriesArray(this.categoriesObject);
				} else {
					console.warn('No categories found');
				}
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	private fetchAllTitles(): Observable<any> {
		const req = this.cdn.get(`static/misc/all_titles.json`, undefined, ALL_TITLES);

		req.subscribe(
			(allTitles) => {
				if (allTitles) {
					this.vendorDetailsTitles = allTitles;
				} else {
					console.warn('No titles found');
				}
			},
			(err) => {
				console.error(err);
			});

		return req;
	}

}
