<div class="center">

    <div class="payloads" *ngIf="payloads.length > 0">
        <div *ngFor="let payload of payloads" class="payload">
            <mat-icon class="extension" *ngIf="payload.ext === '.pdf'" [svgIcon]="'office-file-pdf'"></mat-icon>
            <mat-icon class="extension" *ngIf="payload.ext === '.doc'" [svgIcon]="'office-file-doc'"></mat-icon>
            <mat-icon class="extension" *ngIf="payload.ext === '.svg'" [svgIcon]="'image-file-svg'"></mat-icon>
            <mat-icon class="extension" *ngIf="payload.ext === '.ppt'" [svgIcon]="'office-file-ppt'"></mat-icon>
            <mat-icon class="extension" *ngIf="payload.ext === '.txt'" [svgIcon]="'office-file-txt'"></mat-icon>
            <mat-icon class="extension" *ngIf="payload.ext === '.rtf'" [svgIcon]="'office-file-rtf'"></mat-icon>
            <mat-icon class="extension" *ngIf="payload.ext === '.xls'" [svgIcon]="'office-file-xls'"></mat-icon>
            <mat-icon class="extension" *ngIf="payload.ext === '.png'" [svgIcon]="'image-file-png'"></mat-icon>
            <mat-icon class="extension" *ngIf="payload.ext === '.jpg'" [svgIcon]="'image-file-jpg'"></mat-icon>
            <mat-icon class="extension" *ngIf="payload.ext === '.jpeg'" [svgIcon]="'image-file-jpg'"></mat-icon>
            <mat-icon class="extension" *ngIf="payload.ext === '.rar'" [svgIcon]="'file-rar-1'"></mat-icon>
            <mat-icon class="extension" *ngIf="payload.ext === '.zip'" [svgIcon]="'file-zip-1'"></mat-icon>
            <span class="file-name">{{ payload.originalName }} </span>
            <button (click)="onRemove(payload)" mat-icon-button mat-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div *ngFor="let file of uploadingFiles" class="payload">
            <mat-spinner diameter="24"></mat-spinner>
        </div>
    </div>

    <file-drop (onFileDrop)="dropped($event)" (onFileLeave)="fileLeave($event)" (onFileOver)="fileOver($event)"
               [class.error]="errors && errors.length > 0">
        <label class="file-input-label" for="offerFileInput">
            <mat-icon [svgIcon]="'file-64px'"></mat-icon>
            <span>{{ 'CLICK_OR_DROP' | translate }}</span>
        </label>

        <input (change)="onFileInput($event)" accept="application/pdf, image/x-png,image/gif,image/jpeg"
               id="offerFileInput" multiple name="sendFile"
               type="file">
    </file-drop>

</div>
