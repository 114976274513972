import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

class FORMATS {
	public static readonly TODAY = 'HH:mm';
	public static readonly THIS_WEEK = 'EEEE';
	public static readonly THIS_YEAR = 'MMM d';
	public static readonly OLD_DATE = 'M/d/yy';
}

@Pipe({
	name: 'LongRecentDate'
})
export class LongRecentDatePipe extends DatePipe implements PipeTransform {
	public transform(value: any, args?: any): any {
		try {
			if (moment(value).isSame(new Date(Date.now()), 'day')) {
				return super.transform(value, FORMATS.TODAY);
			} else if (moment(value).isSame(new Date(Date.now()), 'week')) {
				return super.transform(value, FORMATS.THIS_WEEK);
			} else if (moment(value).isSame(new Date(Date.now()), 'year')) {
				return super.transform(value, FORMATS.THIS_YEAR);
			} else {
				return super.transform(value, FORMATS.OLD_DATE);
			}
		} catch (e) {
			console.error(e);
			return super.transform(value);
		}
	}
}
