import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Note } from '../_interfaces/note.interface';

@Injectable({
	providedIn: 'root'
})
export class NotesService {

	constructor(private api: ApiService) {
	}

	public async postNote(note: Note) {
		return this.api.post('pro_notes', note, true).toPromise();
	}

	public async editNote(note: Note) {
		return this.api.put('pro_notes', note, true).toPromise();
	}

	public async deleteNote(note: Note, inquiryId) {
		return this.api.delete('pro_notes', `${inquiryId}/${note._id}`, true).toPromise();
	}

}
