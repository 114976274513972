import { CurrencyPipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDatepicker, MatDialogRef, MatStepper } from '@angular/material';
import { environment } from '../../../../../environments/environment';
import { Message, Payload } from '../../../_interfaces';
import { FormsHelpersService, HttpService, InquiriesService, OffersService, PaymentsService, ShopsService } from '../../../_services';
import { DateValidator } from '../../../_validators';

@Component({
	selector: 'app-approved-booked-dialog',
	templateUrl: './approved-booked-dialog.component.html',
	styleUrls: ['./approved-booked-dialog.component.sass']
})
export class ApprovedBookedDialogComponent implements OnInit {

	// @ViewChild('headerStepper') public headerStepper: MatStepper;
	// @ViewChild('startPicker') public startPicker: MatDatepicker<null>;
	//
	// public cdnPath: string = `${environment.cdn}/resize`;
	//
	// public minPrice: number = 0;
	// public maxPrice: number = 999999999;
	// public textMinLength: number = 5;
	// public textMaxLength: number = 1000;
	//
	// public processing: boolean = false;
	// public success: boolean = false;
	// public editMode: boolean = false;
	// public hovering: boolean = false;
	//
	// public form: FormGroup;
	//
	// public offer: any;
	// public account: any;
	// public vendor: any;
	//
	// public conditions: any;
	//
	// public finalStep: boolean;
	// public favourite: any;
	// public showReasonInput: boolean;
	// public reason: String;
	// public reasonDetail: String;

	constructor(private formBuilder: FormBuilder,
				private httpService: HttpService,
				private offersService: OffersService,
				private paymentsService: PaymentsService,
				private inquiriesService: InquiriesService,
				private currencyPipe: CurrencyPipe,
				private shopsService: ShopsService,
				public dialogRef: MatDialogRef<ApprovedBookedDialogComponent>,
				@Inject(MAT_DIALOG_DATA) public data: { fav: any; weddingDate: any; budget: any }) {

	}

	public ngOnInit() {
		//
		// this.inquiriesService.getInquiry(this.data.fav).subscribe((res) => {
		// 	if (res) {
		// 		this.favourite = res;
		// 		this.buildForm();
		// 	}
		// });
		//
		// try {
		// 	if (this.shopsService.currentShop) {
		// 		this.vendor = this.shopsService.currentShop.vendor;
		// 		// if (this.vendor.sourceCreated) {
		// 		// 	this.finalStep = true;
		// 		// }
		// 	}
		//
		// 	this.shopsService.currentShopChanged.subscribe((shop) => {
		// 		if (shop) {
		// 			this.vendor = this.shopsService.currentShop.vendor;
		// 			// if (this.vendor.sourceCreated) {
		// 			// 	this.finalStep = true;
		// 			// }
		// 		}
		// 	});
		//
		// 	this.httpService.get(`${environment.api}/pro_getConditions/${this.shopsService.currentShop._id}`).subscribe(
		// 		(res) => {
		// 			this.conditions = res;
		// 		}, (err) => {
		// 			console.error(err);
		// 		}
		// 	);
		//
		// } catch (err) {
		// 	console.error(err);
		// }
	}

	// public buildForm() {
	// 	const budget = this.data.budget;
	// 	const date = this.data.weddingDate;
	// 	// 'text': [null, [Validators.required, Validators.minLength(this.textMinLength), Validators.maxLength(this.textMaxLength)]],
	// 	this.form = this.formBuilder.group({
	// 		'price': [{value: budget, disabled: false}, [Validators.min(this.minPrice), Validators.max(this.maxPrice)]],
	// 		'date': [{value: date, disabled: false}, [DateValidator()]],
	// 		'currency': ['EUR'],
	// 		'payloads': [[]]
	// 	});
	// }
	//
	// public onPriceBlur() {
	// 	if (this.form.get('price').value) {
	// 		const price = `${this.form.get('price').value}`;
	// 		const newValue = this.currencyPipe.transform(parseFloat(price.replace('.', '').replace(',', '.').replace(' ', '')), 'EUR', 'symbol');
	// 		this.form.get('price').setValue(newValue);
	// 	}
	// }
	//
	// public onPayloadUploaded(fileName: string | Payload) {
	// 	const array = this.form.controls['payloads'].value;
	// 	array.push(fileName);
	// 	this.form.controls['payloads'].patchValue(array);
	// }
	//
	// public submit(message: Message) {
	// 	this.processing = true;
	// 	this.form.disable();
	//
	// 	this.offersService.confirmBooking(message).subscribe(
	// 		() => {
	//
	// 			const body = {amount: message.data.order.price, favId: this.data.fav, order: message};
	// 			this.paymentsService.createOrder(body).subscribe(
	// 				() => {
	//
	// 					this.processing = false;
	// 					this.editMode = false;
	// 					this.success = true;
	//
	// 					this.dialogRef.close();
	//
	// 					setTimeout(() => {
	// 						this.success = false;
	// 					}, 1000);
	// 				},
	// 				(err) => {
	// 					this.processing = false;
	// 					this.form.enable();
	// 					this.form.setErrors({'somethingWentWrong': true});
	// 				}
	// 			);
	//
	// 		},
	// 		(err) => {
	// 			this.processing = false;
	// 			this.form.enable();
	// 			this.form.setErrors({'somethingWentWrong': true});
	// 		});
	// }
	//
	// public onNoClick(): void {
	// 	this.dialogRef.close();
	// }
	//
	// public close() {
	// 	this.dialogRef.close();
	// }
	//
	// public onSubmit(form: FormGroup) {
	// 	if (form.valid) {
	//
	// 		const price = '' + form.controls['price'].value;
	// 		let formattedPrice = form.controls['price'].value;
	// 		if (price) {
	// 			formattedPrice = parseFloat(price.replace(' ', '').replace('.', '').replace(',', '.'));
	// 		}
	//
	// 		const message: Message = {
	// 			mType: 'booking',
	// 			subject: 'booking',
	// 			inquiryId: this.data.fav,
	// 			message: '-', // form.controls['text'].value,
	// 			data: {
	// 				text: '-', // form.controls['text'].value,
	// 				payloads: form.controls['payloads'].value,
	// 				order: {
	// 					price: formattedPrice,
	// 					currency: form.controls['currency'].value,
	// 					date: form.controls['date'].value
	// 				}
	// 			}
	// 		};
	//
	// 		this.submit(message);
	// 	} else {
	// 		this.form.markAsTouched();
	// 		FormsHelpersService.markControlsAsTouched(this.form);
	// 	}
	// }
	//
	// public onCancel() {
	// 	this.dialogRef.close();
	// }
	//
	// public onPrevious() {
	// 	if (this.headerStepper.selectedIndex === 2) {
	// 		this.headerStepper.selectedIndex = 0;
	// 	} else {
	// 		this.headerStepper.previous();
	// 	}
	// }
	//
	// public onNext() {
	// 	if (this.headerStepper.selectedIndex === 0) {
	// 		this.headerStepper.selectedIndex = 2;
	// 	} else if (this.headerStepper.selectedIndex === 2) {
	// 		if (this.form.valid) {
	// 			this.headerStepper.next();
	// 		} else {
	// 			this.form.markAsTouched();
	// 			FormsHelpersService.markControlsAsTouched(this.form);
	// 		}
	// 	} else {
	// 		this.headerStepper.next();
	// 	}
	// }
	//
	// public decline() {
	// 	this.headerStepper.next();
	// }
	//
	// public enterReason(reason) {
	// 	this.reason = reason;
	// 	this.showReasonInput = true;
	// }
	//
	// public onMarkFailed() {
	//
	// 	this.processing = true;
	//
	// 	const body = {reason: this.reason, reasonDetail: this.reasonDetail, favId: this.data.fav};
	// 	this.offersService.declineBooking(body).subscribe(
	// 		() => {
	// 			this.processing = false;
	// 			this.editMode = false;
	// 			this.success = true;
	// 			this.dialogRef.close();
	//
	// 		},
	// 		(err) => {
	// 			this.processing = false;
	// 			console.error(err);
	// 		});
	// }
	//
	// public onPaymentSuccess() {
	// 	this.success = true;
	// 	this.onSubmit(this.form);
	// }
	//
	// public onPaymentFailure($event) {
	// 	this.dialogRef.close();
	// 	this.success = false;
	// }
	//
	// public selectionChange($event) {
	// 	this.finalStep = (this.vendor.sourceCreated && $event.selectedIndex === this.headerStepper._steps.length - 1) || this.headerStepper._steps.length === 1;
	// }
	//
	// public onDateInputClick(event) {
	// 	event.preventDefault();
	// 	event.stopPropagation();
	// 	this.startPicker.open();
	// }

}
