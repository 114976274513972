import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { Inquiry } from '../_interfaces/inquiry.interface';
import { Message } from '../_interfaces/message.interface';
import { Payload } from '../_interfaces/payload.interface';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class ChatService {

	public chatGallery = new Subject<Payload>();
	public currentMessage: Message = {
		inquiryId: null,
		subject: 'TEST_PRO_MESSAGE',
		mType: 'personal',
		message: '',
		data: {
			text: '',
			images: [],
			payloads: []
		}
	};

	public uploadingFiles = [];

	constructor(private api: ApiService) {
	}

	public static injectDateLabels(messages: Message[]): Message[] {
		if (!messages) {
			return;
		}

		function injectLabel(array, i, date: Date) {
			array.splice(i, 0, {mType: 'dateLabel', createdAt: date});
		}

		let previousMoment;
		let currentMoment;

		messages.map(
			(message, i, array) => {
				if (i === 0) {
					currentMoment = moment.utc(message.createdAt);
					injectLabel(array, i, message.createdAt);
				} else {
					previousMoment = currentMoment;
					currentMoment = moment.utc(message.createdAt);

					if (!currentMoment.isSame(previousMoment, 'date')) {
						injectLabel(array, i, message.createdAt);
					}
				}
			}
		);

		return messages;
	}

	public static injectWelcomeMessage(messages: Message[], inquiry: Inquiry): Message[] {
		if (!messages) {
			return;
		}

		messages.unshift({
			mType: 'welcome',
			createdAt: inquiry.createdAt,
		});

		return messages;
	}

	public postMessage(message: Message) {
		const req = this.api.post('pro_inquiriesMessage', message, true);

		req.subscribe(
			(res) => {
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public openChatGallery(payload: Payload) {
		this.chatGallery.next(payload);
	}

}
