import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { API } from '../_others/tokens/endpoints.tokens';
import { AccountDataService } from './account-data.service';
import { ApiService } from './api.service';
import { HelpersService } from './helpers.service';
import { LocalStorageService } from './local-storage.service';
import { NotificationsService } from './notifications.service';
import { ShopsService } from './shops.service';
import { TokenService } from './token.service';
import { Inquiry, Message } from '../_interfaces';
import { StatusReportService } from './status-report.service';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Injectable({
	providedIn: 'root'
})
export class InquiriesService {

	get inquiries(): Inquiry[] {
		return this._inquiries;
	}

	set inquiries(newInquiries: Inquiry[]) {
		const sortedInquiries = newInquiries.sort().sort((a, b) => {
			return +b.createdAt - +a.createdAt;
		});
		this._inquiries = sortedInquiries.map((inq) => {
			if (typeof inq.expirationDate === 'string') {
				inq.expirationDate = new Date(inq.expirationDate);
			}
			if (!inq.lastMessagePreview) {
				inq.lastMessagePreview = {
					data: {
						text: ''
					},
					createdAt: inq.updatedAt
				};
			}
			inq.weddingSeason = InquiriesService.findSeasonInInquiry(inq);
			return inq;
		});
		this.inquiriesChanged.next(this.inquiries);
	}

	get inquiry() {
		return this._inquiry;
	}

	set inquiry(inquiry) {
		if (inquiry) {
			inquiry.expirationDate = new Date(inquiry.expirationDate);
		}
		this._inquiry = inquiry;
		this.inquiryChanged.next(this.inquiry);
	}

	get unreadInquiries(): { shopId: string; count: number }[] {
		return this._unreadInquiries;
	}

	set unreadInquiries(value: { shopId: string; count: number }[]) {
		this._unreadInquiries = value;
		this.unreadInquiriesChanged.next(this.unreadInquiries);
	}

	public inquiriesChanged = new BehaviorSubject<Inquiry[]>(this.inquiries);
	public inquiryChanged = new BehaviorSubject<Inquiry>(this.inquiry);
	public unreadInquiriesChanged = new BehaviorSubject(this.unreadInquiries);
	

	private _inquiries: Inquiry[] = [];

	private _inquiry: Inquiry;

	private _unreadInquiries: { shopId: string, count: number }[] = [];


	constructor(private tokenService: TokenService,
				public api: ApiService,
				public snackBar: MatSnackBar,
				private localStorageService: LocalStorageService,
				private notificationsService: NotificationsService,
				private shopsService: ShopsService,
				private translateService: TranslateService,
				private router: Router,
				private accountDataService: AccountDataService) {
					this.shopsService.currentShopChanged.subscribe((shop)=>{
						this.unreadInquiriesChanged.next(this._unreadInquiries);
					})
	}

	public static findSeasonInInquiry(inquiry: Inquiry) {
		let season;

		if (inquiry.answers && inquiry.answers.length > 0) {
			const seasonAnswer = inquiry.answers.find(
				(anyAnswer) => {
					return anyAnswer.key.includes('gen_season_tier');
				}
			);

			if (seasonAnswer) {
				season = seasonAnswer.title;
			}
		}
		return season;
	}

	public static getLastMessagePreview(inquiry: Inquiry): string | null {
		if (inquiry && inquiry.lastMessagePreview && inquiry.lastMessagePreview.data) {
			if (inquiry.lastMessagePreview.data.text) {
				return inquiry.lastMessagePreview.data.text;
			} else if (!inquiry.lastMessagePreview.data.text && inquiry.lastMessagePreview.data.payloads && inquiry.lastMessagePreview.data.payloads.length > 0 && inquiry.lastMessagePreview.account && inquiry.lastMessagePreview.account.accountData) {
				return `${inquiry.lastMessagePreview.account.accountData.firstName} sent an attachment`;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	public getInquiry(inquiryId): Observable<Inquiry> {
		const req = this.api.get(`pro_inquiries/${this.shopsService.currentShop._id}/${inquiryId}`, null, true, true);

		req.subscribe(
			(inquiry: Inquiry) => {
				this.inquiry = inquiry;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public getInquiries(shopId): Observable<Inquiry[]> {
		const req = this.api.get(`pro_inquiries/${shopId}`, null, true, true);

		req.subscribe(
			(inquiries: Inquiry[]) => {
				this.inquiries = inquiries;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public addInquiry(inquiry: Inquiry) {
		const inquiries = this.inquiries || [];
		const index = (inquiries.findIndex((anyInquiry: Inquiry) => {
			return anyInquiry._id === inquiry._id;
		}));
		if (index === -1) {
			const snackBar = this.snackBar.open(this.translateService.instant('SNACK_BAR.YOU_GOT_A_NEW_INQUIRY'), this.translateService.instant('SNACK_BAR.OPEN_INQUIRY'), {
				duration: 2000,
				panelClass: ['info-snack-bar']
			});
			snackBar.onAction().subscribe(() => this.router.navigate(['dashboard', 'inquiries', inquiry._id]));
			inquiries.push(inquiry);
			this.inquiries = inquiries;
		}
	}

	public editInquiry(inquiry: Inquiry) {
		const index = (this.inquiries.findIndex((anyInquiry: Inquiry) => {
			return anyInquiry._id === inquiry._id;
		}));
		if (index >= 0) {
			const inquiries = this.inquiries;
			inquiries[index] = inquiry;
			this.inquiries = inquiries;
		}
		if (this.inquiry && (inquiry._id === this.inquiry._id)) {
			this.inquiry = inquiry;
		}
	}

	public removeInquiry(inquiry: Inquiry) {
		const index = (this.inquiries.findIndex((anyInquiry: Inquiry) => {
			return anyInquiry._id === inquiry._id;
		}));
		if (index >= 0) {
			const inquiries = this.inquiries;
			inquiries.splice(index, 1);
			this.inquiries = inquiries;
		}
	}

	public addMessage(message: Message) {
		if (this.inquiry && this.inquiry._id === message['inquiryId']) {
			this.reactToNewMessageOnInquiryDetail(message);
		} else {
			this.reactToNewMessageOnInquiriesList(message);
		}
	}

	public editMessage(message: Message) {
		this.reactToNewMessageOnInquiryDetail(message);
	}

	public reactToNewMessageOnInquiryDetail(message: Message) {
		if(!this.inquiry){//TODO: check this. shouldnt be possible....
			return;
		}
		const currentInquiryIndex = (this.inquiry.chat.findIndex((anyMessage: Message) => {
			return anyMessage && anyMessage._id === message._id;
		}));

		if (currentInquiryIndex === -1) {
			this.inquiry.chat.push(message);
		} else {
			this.inquiry.chat[currentInquiryIndex] = message;
		}

		if (message.data && message.data.payloads && message.data.payloads.length > 0) {
			this.inquiry.payloads = [...this.inquiry.payloads.slice(), ...message.data.payloads.slice()];
		}

		this.inquiryChanged.next(this.inquiry);
	}

	public reactToNewMessageOnInquiriesList(message: Message) {
		const index = (this.inquiries.findIndex((anyInquiry: Inquiry) => {
			return anyInquiry._id === message['inquiryId'];
		}));

		if (index >= 0) {
			const inquiries = this.inquiries;

			const messageIsSentByTheCurrentUser = message.account.accountData._id === this.accountDataService.accountData._id;
			const inquiryIsUnread = inquiries[index].chatUnreadByVendor;
			const inquiryIsArchived = (inquiries[index].vendorState && inquiries[index].vendorState.isArchived) ? true : false;
			const messageBelongsToCurrentShop = message.shopId === this.shopsService.currentShop._id;

			inquiries[index].lastMessagePreview = message;
			inquiries[index].updatedAt = new Date(Date.now());

			if (!messageIsSentByTheCurrentUser) {
				inquiries[index].chatUnreadByVendor = true;
				if(this.inquiry && inquiries[index]._id == this.inquiry._id){
					console.log("emit inquiry changed");
					this.inquiryChanged.next(inquiries[index]);
				}
			}

			if (messageBelongsToCurrentShop && !inquiryIsUnread) {
				console.log('should add notification!!!!');
				const i = this.unreadInquiries.findIndex(anyResult => anyResult.shopId === this.shopsService.currentShop._id);
				if (i > -1) {
					this.unreadInquiries[i].count += 1;
					this.unreadInquiriesChanged.next(this.unreadInquiries);
				}
			}


			//If new message but the Inquiry was archived, recover it..
			if(inquiryIsArchived){
				inquiries[index].vendorState.isArchived = false;
				this.reportRecovery(inquiries[index]);
			}


			this.inquiries = inquiries;

			if(!messageIsSentByTheCurrentUser){
				const messagePreview = message.data.text ? HelpersService.truncate(message.data.text, [120, true]) : this.translateService.instant('WROTE_A_MESSAGE'); // ATTACHED_A_FILE
				const snackBarMessage = this.translateService.instant(`${message.account.accountData.firstName + (message.account.accountData.lastName || '')}: ${messagePreview}`);
				const snackBar = this.snackBar.open(snackBarMessage, this.translateService.instant('SNACK_BAR.OPEN_CONVERSATION'), {
					duration: 5000,
					panelClass: ['info-snack-bar']
				});
				snackBar.onAction().subscribe(() => this.router.navigate(['dashboard', 'inquiries', message.inquiryId]));
			}
		}
	}

	public reportRecovery(inquiry: any) {
		inquiry.vendorState.wantsToBeRecovered = false;
		this.unarchiveInquiry(inquiry._id);
	}


	public addInquiryToNotificationsStats(inquiry: Inquiry) {
		if (inquiry.vendor._id === this.shopsService.currentShop._id) {
			const index = this.unreadInquiries.findIndex(anyResult => anyResult.shopId === this.shopsService.currentShop._id);
			if (index > -1) {
				this.unreadInquiries[index].count += 1;
				this.unreadInquiriesChanged.next(this.unreadInquiries);
			}
		}
	}

	public acceptInquiry(inquiryId: string, reason?: string) {
		const req = this.api.get(`pro_inquiriesStatus/${this.shopsService.currentShop._id}/${inquiryId}/${true}/${reason}`, null, false, true);

		req.subscribe(
			() => {
				this.snackBar.open(this.translateService.instant('SNACK_BAR.INQUIRY_OPENED'), null, {
					duration: 1000,
					panelClass: ['success-snack-bar']
				});
			},
			(err) => {
				console.error(err);
				this.snackBar.open(this.translateService.instant('SNACK_BAR.ERROR_WHILE_OPENING_INQUIRY'), null, {
					duration: 1000,
					panelClass: ['error-snack-bar']
				});
			}
		);

		return req;
	}

	public declineInquiry(inquiryId: string, reason?: string) {
		const req = this.api.get(`pro_inquiriesStatus/${this.shopsService.currentShop._id}/${inquiryId}/${false}/${reason}`, null, false, true);

		req.subscribe(
			() => {
				this.snackBar.open(this.translateService.instant('SNACK_BAR.INQUIRY_DECLINED'), null, {
					duration: 1000,
					panelClass: ['success-snack-bar']
				});
			},
			(err) => {
				console.error(err);
				this.snackBar.open(this.translateService.instant('SNACK_BAR.ERROR_WHILE_DECLINING_INQUIRY'), null, {
					duration: 1000,
					panelClass: ['error-snack-bar']
				});
			}
		);

		return req;
	}

	public archiveInquiry(inquiryId: string) {
		const req = this.api.put('pro_inquiriesArchive', {requestId: inquiryId, archived: true}, true);

		req.subscribe(
			(inquiry: Inquiry) => {
				// this.removeInquiry(inquiry);
				const inquiryIndex = (this.inquiries.findIndex((anyInquiry: Inquiry) => {
					return anyInquiry._id === inquiryId;
				}));
		
				if (inquiryIndex >= 0) {
					if(!this.inquiries[inquiryIndex].vendorState){
						this.inquiries[inquiryIndex].vendorState = {};
					}
					this.inquiries[inquiryIndex].vendorState.isArchived = true;
					this.inquiriesChanged.next(this.inquiries);
				}
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public unarchiveInquiry(inquiryId: string) {
		const req = this.api.put('pro_inquiriesArchive', {requestId: inquiryId, archived: false}, true);

		req.subscribe(
			(inquiry: Inquiry) => {
				// this.removeInquiry(inquiry);
				const inquiryIndex = (this.inquiries.findIndex((anyInquiry: Inquiry) => {
					return anyInquiry._id === inquiryId;
				}));
		
				if (inquiryIndex >= 0) {
					if(!this.inquiries[inquiryIndex].vendorState){
						this.inquiries[inquiryIndex].vendorState = {};
					}
					this.inquiries[inquiryIndex].vendorState.isArchived = false;
					this.inquiriesChanged.next(this.inquiries);
				}
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public getUnreadInquiries() {
		const req = this.api.get(API.UNREAD_INQUIRIES, null, false, true);

		req.subscribe(
			(unreadInquiries) => {
				this.unreadInquiries = unreadInquiries;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public markInquiryAsFake(inquiry) {
		const req = this.api.put('pro_markAsFake', {inquiryId: inquiry._id}, true);

		req.subscribe(
			(fakeInquiry) => {
				this.inquiry = fakeInquiry;
				this.removeInquiry(fakeInquiry);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public reactToReadInquiry(res: { inquiryId: string; shopId: string }) {
		const inquiryIndex = (this.inquiries.findIndex((anyInquiry: Inquiry) => {
			return anyInquiry._id === res.inquiryId;
		}));

		let updateCounter:boolean;

		if (inquiryIndex >= 0) {
			if(this.inquiries[inquiryIndex].chatUnreadByVendor){
				this.inquiries[inquiryIndex].chatUnreadByVendor = false;
				this.inquiriesChanged.next(this.inquiries);
				updateCounter = true;
			}
		}

		const count = () => {
			if(!updateCounter)
				return;
			const index = this.unreadInquiries.findIndex(anyResult => anyResult.shopId === res.shopId);
			if (index > -1) {
				if(this.unreadInquiries[index].count > 0){
					this.unreadInquiries[index].count -= 1;
					this.unreadInquiriesChanged.next(this.unreadInquiries);
				}
			}
		};

		if (!this.unreadInquiries) {
			this.getUnreadInquiries().toPromise().then(() => count());
		} else {
			count();
		}
	}

	public onNewOffer(message) {
		if (this.inquiry && this.inquiry._id === message['inquiryId']) {
			this.inquiry.offerMade = true;
			this.inquiryChanged.next(this.inquiry);
		}
		this.addMessage(message);
	}

	public onOfferDeclined(message) {
		if (this.inquiry && this.inquiry._id === message['inquiryId']) {
			this.inquiry.offerMade = false;
			this.inquiry.offerRequested = false;
			this.inquiryChanged.next(this.inquiry);
		}

		this.editMessage(message);
	}

	public onNewOrder(message) {
		if (this.inquiry && this.inquiry._id === message['inquiryId']) {
			this.inquiry.booked = true;
			this.inquiry.bookedDate = new Date();
			this.inquiry.bookedData = message.data;
			this.inquiryChanged.next(this.inquiry);

			const index = (this.inquiries.findIndex((anyInquiry: Inquiry) => {
				return anyInquiry._id === message['inquiryId'];
			}));

			if (index >= 0) {
				const inquiries = this.inquiries;
				inquiries[index] = this.inquiry;
				this.inquiries = inquiries;
			}
		}
		this.addMessage(message);
	}

	public markInquiryAsUnread(inquiryId: any) {
		const req = this.api.post('pro_inquiryUnread', {inquiryId}, true);

		req.subscribe(
			(newInquiry: Inquiry) => {
				this.editInquiry(newInquiry);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public getArchivedInquiries(shopId): Observable<Inquiry[]> {
		const req = this.api.get(`pro_inquiriesArchived/${shopId}`, null, true, true);

		req.subscribe(
			(inquiries: Inquiry[]) => {
				this.inquiries = inquiries;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public deleteInquiry(inquiryId: string) {
		const req = this.api.delete(`pro_inquiriesDelete`, inquiryId, true);

		req.subscribe(
			(inquiry: Inquiry) => {
				this.removeInquiry(inquiry);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}
}
