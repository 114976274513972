export const LOCAL_STORAGE = {
	ACCOUNT_DATA: 'accountData',
	CURRENT_SHOP: 'currentShop',
	CURRENT_USER: 'currentUser',
	CURRENT_WEDDING: 'currentWedding',
	LAST_USED_VERSION: 'lastUsedVersion',
	REFRESH_TOKEN: 'refreshToken',
	TOKEN: 'token',
	ON_BOARDING: 'onBoarding'

};
