import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AccountData } from '../_interfaces';
import { LOCAL_STORAGE } from '../_others/tokens';
import { HelpersService, LocalStorageService, TokenService } from '../_services';

@Injectable()
export class HeadersHttpInterceptor implements HttpInterceptor {

	private tokenService: TokenService;
	private localStorageService: LocalStorageService;

	constructor(private injector: Injector,
				@Inject(PLATFORM_ID) private platformId: Object) {
	}

	private static isOneRequestToOurServer(request): boolean {
		HelpersService.assert(request, 'request');
		return (request.url.indexOf(environment.api) > -1) || (request.url.indexOf(environment.cdn) > -1);
	}

	public intercept(request: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
		this.initializeServices();

		request = this.addContentTypeHeader(request);
		request = this.addAuthorizationHeader(request);
		request = this.addLanguageHeader(request);

		return httpHandler.handle(request);
	}

	private addAuthorizationHeader(request: HttpRequest<any>): HttpRequest<any> {
		HelpersService.assert(request, 'request');

		if (!this.tokenService.token) {
			return request;
		}
		if (!HeadersHttpInterceptor.isOneRequestToOurServer(request)) {
			return request;
		} else {
			return request.clone({
				setHeaders: {
					'Authorization': `Bearer ${this.tokenService.token}`
				}
			});
		}
	}

	private addContentTypeHeader(request: HttpRequest<any>) {
		HelpersService.assert(request, 'request');

		if (!request.headers.has('Content-Type')) {
			return request.clone({
				setHeaders: {
					'Content-Type': 'application/json',
					'Accept':  'application/json'
				}
			});
		} else {
			return request;
		}
	}

	private addLanguageHeader(request: HttpRequest<any>) {
		HelpersService.assert(request, 'request');

		if (isPlatformBrowser(this.platformId)) {
			const accountData: AccountData = this.localStorageService.getItem(LOCAL_STORAGE.CURRENT_USER) || this.localStorageService.getItem(LOCAL_STORAGE.ACCOUNT_DATA);
			if (accountData && accountData.lang) {
				return request.clone({
					setHeaders: {
						'Accept-Language': accountData.lang
					}
				});
			} else {
				return request;
			}
		} else {
			return request;
		}

	}

	private initializeServices() {
		if (!this.localStorageService) {
			this.localStorageService = this.injector.get(LocalStorageService);
		}
		if (!this.tokenService) {
			this.tokenService = this.injector.get(TokenService);
		}
	}
}
