import { MapsAPILoader } from '@agm/core';
// import { CalendarEvent } from 'angular-calendar';
// import event = google.maps.event;
import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDatepicker, MatDialogRef } from '@angular/material';
import { AppointmentsService } from '../../../_services/appointments.service';
import { ShopsService } from '../../../_services/shops.service';

// import google = google;
@Component({
	selector: 'app-calendar-free-dialog',
	templateUrl: './calendar-free-dialog.component.html',
	styleUrls: ['./calendar-free-dialog.component.sass']
})
export class CalendarFreeDialogComponent implements OnInit, AfterViewInit {

	@ViewChild('fromPicker') public fromPicker: MatDatepicker<null>;

	public form: FormGroup;
	public rangeFromStartAt;

	public rangeFromMinDate = new Date(Date.now());

	public freeDates = [];

	public mobileQuery: MediaQueryList;
	private _mobileQueryListener: () => void;

	constructor(private formBuilder: FormBuilder,
	            public dialogRef: MatDialogRef<CalendarFreeDialogComponent>,
	            @Inject(MAT_DIALOG_DATA) public data: { couples: any[], viewDate: Date, event?: any },
	            private changeDetectorRef: ChangeDetectorRef,
	            private media: MediaMatcher,
	            private mapsAPILoader: MapsAPILoader,
	            private ngZone: NgZone,
	            private appointmentsService: AppointmentsService,
	            private shopsService: ShopsService) {

		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
	}

	public ngOnInit() {
		this.setRangeFromStartAt();
		this.buildForm();
		this.appointmentsService.getFreeDates(this.shopsService.currentShop._id).subscribe((res) => {
			if (res) {
				this.freeDates.push(...res);
			}
		});
	}

	public ngAfterViewInit() {

	}

	public setRangeFromStartAt() {
		this.rangeFromMinDate = new Date();
		this.rangeFromStartAt = new Date();
	}

	public buildForm() {
		const from = new Date();

		this.form = this.formBuilder.group({
			'date': [{value: from, disabled: false}, [Validators.required]],
		});
	}

	public onDateFromInputClick() {
		if (this.fromPicker) {
			this.fromPicker.open();
		}
	}

	public onBlock() {
		if (this.form.valid) {

			const newDate = this.form.value.date;
			this.pushToFreeDates(newDate);

			this.appointmentsService.updateFreeDates(this.shopsService.currentShop._id, {freeDates: this.freeDates}).subscribe((res) => {
				if (res) {
					this.dialogRef.close();
				}
			});

		} else {
			this.form.setErrors({'noDates': true});
		}
	}

	public onFromChange(event) {
		console.log(event);
		// TODO: check if already a blocked day
	}

	public addSecondDate(event) {
		event.preventDefault();
		event.stopPropagation();

		const newDate = this.form.value.date;
		this.pushToFreeDates(newDate);

		if (this.fromPicker) {
			this.fromPicker.open();
		}
	}

	public pushToFreeDates(newDate) {
		if (!this.freeDates.includes(newDate)) {
			this.freeDates.push(newDate);
		}
	}

	public removeFreeDate(index) {
		this.freeDates.splice(index, 1);
	}
}
