<div style="min-width: 550px; display: flex; justify-content: flex-end;">
    <button [mat-dialog-close]="false" mat-icon-button>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="text-center">
    <h1>Öffnungszeiten</h1>
    <div style="display: flex; flex-direction: column;align-items: flex-start; margin-top: 45px; margin-bottom:45px">
        <div class="period">
            <span class="day-title">Sonntag</span>
            <mat-slide-toggle class="example-margin"> Geschlossen
            </mat-slide-toggle>
            <input matInput type="time">
            <span> - </span>
            <input matInput type="time">
            <button color="default" mat-button>Zeit hinzufügen</button>
        </div>
        <div class="period">
            <span class="day-title">Montag</span>
            <mat-slide-toggle class="example-margin"> Geschlossen
            </mat-slide-toggle>
            <input matInput type="time">
            <span> - </span>
            <input matInput type="time">
            <button color="default" mat-button>Zeit hinzufügen</button>
        </div>
        <div class="period">
            <span class="day-title">Dienstag</span>
            <mat-slide-toggle class="example-margin"> Geschlossen
            </mat-slide-toggle>
            <input matInput type="time">
            <span> - </span>
            <input matInput type="time">
            <button color="default" mat-button>Zeit hinzufügen</button>
        </div>
        <div class="period">
            <span class="day-title">Mittwoch</span>
            <mat-slide-toggle class="example-margin"> Geschlossen
            </mat-slide-toggle>
            <input matInput type="time">
            <span> - </span>
            <input matInput type="time">
            <button color="default" mat-button>Zeit hinzufügen</button>
        </div>
        <div class="period">
            <span class="day-title">Donnerstag</span>
            <mat-slide-toggle class="example-margin"> Geschlossen
            </mat-slide-toggle>
            <input matInput type="time">
            <span> - </span>
            <input matInput type="time">
            <button color="default" mat-button>Zeit hinzufügen</button>
        </div>
        <div class="period">
            <span class="day-title">Freitag</span>
            <mat-slide-toggle class="example-margin"> Geschlossen
            </mat-slide-toggle>
            <input matInput type="time">
            <span> - </span>
            <input matInput type="time">
            <button color="default" mat-button>Zeit hinzufügen</button>
        </div>
        <div class="period">
            <span class="day-title">Samstag</span>
            <mat-slide-toggle class="example-margin"> Geschlossen
            </mat-slide-toggle>
            <input matInput type="time">
            <span> - </span>
            <input matInput type="time">
            <button color="default" mat-button>Zeit hinzufügen</button>
        </div>
    </div>
</mat-dialog-content>


<mat-dialog-actions style="display:flex;justify-content: flex-end;margin-bottom: 0px">
    <button color="accent" mat-button mat-raised-button>{{ 'Speichern' }}</button>
</mat-dialog-actions>
