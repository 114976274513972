import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AccountData, Payload } from '../../../_shared/_interfaces';
import { CdnService, FormsHelpersService, InquiriesService, ShopsService } from '../../../_shared/_services';

@Component({
	selector: 'app-auto-answer-settings',
	templateUrl: './auto-answer-settings.component.html',
	styleUrls: ['./auto-answer-settings.component.sass']
})
export class AutoAnswerSettingsComponent implements OnInit {

	@Output() public onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() public onError: EventEmitter<any> = new EventEmitter<any>();
	@Input() public account: AccountData;
	@Input() public insideDialog: boolean = false;
	@Input() public doSave: Observable<void>;
	public processing: boolean = false;
	public success: boolean = false;
	public editMode: boolean = false;
	public hovering: boolean = false;
	public form: FormGroup;
	public textMinLength: number = 3;
	public textMaxLength: number = 5000;
	public defaultText = `
Herzlichen Glückwunsch zu Eurer Verlobung und vielen Dank für Euer Interesse.

Es wäre uns eine große Freude Euch und Eure Gäste bei der Hochzeit zu begleiten. Die großartige Nachricht - wir sind an dem von Euch angefragt Datum noch frei!

Lasst mich Doch gerne wissen wann Euch ein Kennenlernen oder Treffen passt?

Ich freue mich auf Eure Rückmeldung

Liebe Grüße,`;
	public payloads = [];
	public autoReplyActive: boolean;
	public uploadingFiles = [];
	private saveSubscription: any;

	constructor(private formBuilder: FormBuilder,
	            private shopsService: ShopsService,
	            private cdnService: CdnService,
	            private inquiriesService: InquiriesService) {
	}

	public ngOnInit() {
		if (this.shopsService.currentShop && this.shopsService.currentShop.chatAutoReply) {
			this.defaultText = this.shopsService.currentShop.chatAutoReply.text || this.defaultText;
			this.payloads = this.shopsService.currentShop.chatAutoReply.payloads;
			this.autoReplyActive = this.shopsService.currentShop.chatAutoReply.active;
		}

		this.shopsService.currentShopChanged.subscribe((shop) => {
			if (shop) {
				if (shop.chatAutoReply) {
					this.defaultText = shop.chatAutoReply.text || this.defaultText;
					this.payloads = this.shopsService.currentShop.chatAutoReply.payloads;
					this.autoReplyActive = this.shopsService.currentShop.chatAutoReply.active;
				}
				this.buildForm();
			}
		});

		if (this.doSave) {
			this.saveSubscription = this.doSave.subscribe(() => this.onSave());
		}

		this.buildForm();
	}

	public buildForm() {
		this.form = this.formBuilder.group({
			'text': [{
				value: this.defaultText ? this.defaultText : '',
				disabled: !this.insideDialog
			}, [Validators.required, Validators.minLength(this.textMinLength), Validators.maxLength(this.textMaxLength)]],
			'payloads': [this.payloads || []],
			'active': [{value: this.autoReplyActive}],
		});

		if ((this.editMode && !this.insideDialog) || (this.insideDialog)) {
			this.form.enable();
		}

		if (!this.autoReplyActive) {
			this.form.disable();
		}
	}

	public activate(event) {
		if (!this.autoReplyActive) {
			this.buildForm();
			this.form.enable();
		} else {
			this.form.disable();
		}
	}

	public onEdit() {
		this.editMode = true;
		if (this.autoReplyActive) {
			this.form.enable();
		}
	}

	public onSave() {
		const text = this.form.controls['text'].value;
		if (this.autoReplyActive && (!text || text.length == 0 || !text.trim())) {
			this.form.markAsTouched();
			FormsHelpersService.markControlsAsTouched(this.form);
			this.onError.emit('No Text');
			return;
		}
		this.submit(this.form);
	}

	public onCancel() {
		this.editMode = false;
		this.buildForm();
		this.onClose.emit(true);
	}

	public onDeleteAttachment(payload: Payload) {
		const payloads: Payload[] = this.form.get('payloads').value;
		const index = payloads.indexOf(payload);
		payloads.splice(index, 1);
		this.form.get('payloads').patchValue(payloads);
	}

	// onApply(template) {
	// 	this.templatesService.applyTemplate(template);
	// }

	public onFileInput(event) {
		for (const file of event.srcElement.files) {
			this.uploadingFiles.push(file);
			this.cdnService.uploadFiles(file).toPromise().then(
				(payload: Payload) => {
					const index = this.uploadingFiles.findIndex(anyFile => anyFile === file);
					this.uploadingFiles.splice(index, 1);
					this.form.controls['payloads'].value.push(payload);
					this.form.markAsTouched();
					FormsHelpersService.markControlsAsTouched(this.form);
				}
			);
		}
	}

	public ngOnDestroy() {
		if (this.saveSubscription) {
			this.saveSubscription.unsubscribe();
		}
	}

	private submit(form: FormGroup) {
		this.processing = true;
		const shop = this.shopsService.currentShop;
		shop.chatAutoReply = form.value;
		shop.chatAutoReply.active = this.autoReplyActive;

		this.shopsService.updateShop(shop).subscribe(
			(res) => {
				this.processing = false;
				this.editMode = false;
				this.success = true;
				this.form.disable();

				if (this.inquiriesService.inquiry) {
					this.inquiriesService.inquiry.vendor.chatAutoReply = shop.chatAutoReply;
				}

				this.success = false;
				this.onClose.emit(true);
			},
			(err) => {
				this.processing = false;
				this.form.setErrors({'somethingWentWrong': true});
				this.onError.next(err);
			}
		);

	}
}
