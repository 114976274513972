import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector, isDevMode, Optional, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material';
import { WeddyPlaceError } from '../_interfaces';
import { DialogsService, ErrorsService } from '../_services';
import { ErrorDialogComponent } from '../modules/dialogs/error-dialog/error-dialog.component';
import { RefreshDialogComponent } from '../modules/dialogs/update-dialog/refresh-dialog.component';
import { ERROR_WRAPPER } from './tokens';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

	private counter: number = 0;
	private lastError: any;

	constructor(private errorsService: ErrorsService,
				private dialog: MatDialog,
				private dialogsService: DialogsService,
				@Inject(Injector) private injector: Injector,
				@Inject(PLATFORM_ID) private platformId: Object,
				@Optional() @Inject(ERROR_WRAPPER) private errorWrapper: any) {
		if (isPlatformBrowser(platformId) && !isDevMode()) {
			this.checkLocation('https://pro.weddyplace.com', ['https://www.weddyplace.com', 'https://pro.weddyplace.com/l/']);
		}
	}

	public handleError(error: Error | HttpErrorResponse) {
		this.counter++;

		console.warn('Error counter: ', this.counter);
		console.error(error);

		if (error && error['msg'] === 'Unauthorized') {
			console.log('Authorization error');
		}

		if (error instanceof HttpErrorResponse) {
			this.onHttpError(error);
		} else {
			this.onApplicationError(error);
		}

		if (this.errorWrapper) {
			this.errorWrapper.error = error;
		}
	}

	private async onApplicationError(error: Error | HttpErrorResponse) {
		const errorIsNew = !this.lastError || (this.lastError.name !== error.name);
		const chunkyError = error.message && error.message.indexOf('Loading chunk') > -1;

		if (errorIsNew) {
			this.lastError = error;

			if (chunkyError) {
				this.exitHandler(error);
			} else {
				if (isPlatformBrowser(this.platformId) && window['hj']) {
					window['hj']('tagRecording', ['Error']);
				}
				await this.postError(error);
				this.exitHandler(error);
			}
		}
	}

	private async postError(error: Error | HttpErrorResponse) {
		try {
			const errorsService = this.injector.get(ErrorsService);
			await errorsService.post(error, undefined, undefined).toPromise().catch(err => console.error(err));
			console.warn('Error posted to the server');
		} catch (e) {
			console.error('Chaos in Error Handler! Error while posting error');
			if (e) {
				console.error(`Chaos cause: ${e.message}`);
				console.error(e);
			}
		}
	}

	private checkLocation(desiredHost: string, wrongHosts: string[]) {
		for (const wrongHost of wrongHosts) {
			if (window.location.href.includes(wrongHost) && !window.location.href.includes('attemptedUrl')) {
				const errorsService = this.injector.get(ErrorsService);
				errorsService.post(new WeddyPlaceError(`ERROR: Wrong host. Target host: ${desiredHost}, current host: ${window.location.href}`), true, undefined).subscribe(
					() => {
						console.warn('Error posted to the server');
						window.alert('Ooops! Something went wrong. Please try refreshing your browser');
					},
					(err) => {
						console.error(err);
					}
				);
			}
		}
	}

	private exitHandler(error) {
		if (isPlatformBrowser(this.platformId)) {
			const chunkyError = error.message && error.message.indexOf('Loading chunk') > -1;

			if (chunkyError) {
				this.openUpdateDialog();
			} else {
				this.openErrorDialog(error);
			}
		}
	}

	private openErrorDialog(error) {
		const dialog = this.injector.get(MatDialog);
		const dialogsService = this.injector.get(DialogsService);
		dialog.open(ErrorDialogComponent, dialogsService.standardDialogConfig({data: error}));
	}

	private openUpdateDialog() {
		const dialog = this.injector.get(MatDialog);
		const dialogsService = this.injector.get(DialogsService);
		dialog.open(RefreshDialogComponent, dialogsService.standardDialogConfig());
	}

	private onHttpError(error: Error | HttpErrorResponse) {
		if (!navigator.onLine) {
			alert('No internet connection');
		}

		throw error;
	}

}
