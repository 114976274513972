import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatStepper } from '@angular/material';
import { TimesTable } from '../../../_interfaces';
import { AppointmentsService, CalendarService, ShopsService } from '../../../_services';

@Component({
	selector: 'app-calendar-welcome-dialog',
	templateUrl: './calendar-welcome-dialog.component.html',
	styleUrls: ['./calendar-welcome-dialog.component.sass']
})
export class CalendarWelcomeDialogComponent implements OnInit {

	@ViewChild('stepper') public headerStepper: MatStepper;
	public finalStep: boolean;

	public opening_hours: { open_now: String, periods: { close: { day: Number, time: String }, open: { day: Number, time: String } }[], weekday_text: [String] };
	public table: TimesTable[];

	constructor(private formBuilder: FormBuilder,
				public shopsService: ShopsService,
				private appointmentsService: AppointmentsService,
				public dialogRef: MatDialogRef<CalendarWelcomeDialogComponent>,
				@Inject(MAT_DIALOG_DATA) public data: { couples: any[], selection: any, event?: any },
				private calendarService: CalendarService) {

	}

	public ngOnInit() {
		this.table = CalendarService.parseOpeningHoursToTable(this.shopsService.currentShop.opening_hours);
	}

	public changeAutoAppointments() {
		this.shopsService.currentShop.autoAppointments = !this.shopsService.currentShop.autoAppointments;
	}

	public changeAutoAppointmentsBlockedDays() {
		this.shopsService.currentShop.autoAppointmentsBlockedDays = !this.shopsService.currentShop.autoAppointmentsBlockedDays;
	}

	public changeAutoAppointmentsMultiple() {
		this.shopsService.currentShop.autoAppointmentsMultiple = !this.shopsService.currentShop.autoAppointmentsMultiple;
	}

	public changeOpeningStatus(day) {
		day.open = !day.open;

		if (day.open && day.times.length <= 0) {
			day.times.push({timeFrom: '10:00', timeTo: '18:00'});
		}

		if (!this.shopsService.currentShop.autoAppointments) {
			this.shopsService.currentShop.autoAppointments = !!this.table.find((d) => {
				return d.open;
			});
		}
	}

	public addTime(day) {
		day.times.push({timeFrom: '10:00', timeTo: '18:00'});
	}

	public removeTime(day, index) {
		day.times.splice(index, 1);
	}

	public onPrevious() {
		this.headerStepper.previous();
	}

	public onNext() {
		this.headerStepper.next();
	}

	public selectionChange($event) {
		this.finalStep = $event.selectedIndex === this.headerStepper._steps.length - 1;
	}

	public onClose() {
		this.shopsService.currentShop.opening_hours = CalendarService.parseTableToOpeningHours(this.table);

		this.shopsService.updateShop(this.shopsService.currentShop).subscribe((res) => {
			this.dialogRef.close(false);
		});
	}

}
