import { MapsAPILoader } from '@agm/core';
import { isPlatformBrowser } from '@angular/common';
import { ElementRef, Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { ParsedLocation, WeddyPlaceError } from '../_interfaces';
import { HelpersService } from './helpers.service';
import { LoggerService } from './logger.service';

declare const google: any;

export const GERMAN_SPEAKING_CITIES = { types: ['(cities)'], componentRestrictions: { country: ['de', 'at', 'ch'] } };
export const GERMAN_SPEAKING_ADDRESS = { types: ['address'], componentRestrictions: { country: ['de', 'at', 'ch'] } };
export const GERMAN_SPEAKING_COMPANIES = { types: ['establishment'], componentRestrictions: { country: ['de', 'at', 'ch'] } };

@Injectable({
	providedIn: 'root'
})
export class MapsService {

	private loadedMapsAPI: boolean = false;

	constructor(private ngZone: NgZone,
				private mapsAPILoader: MapsAPILoader,
				private logger: LoggerService,
				@Inject(PLATFORM_ID) private platformId: Object) {
	}


	public static parseSmallVendor(result: google.maps.places.PlaceResult | any){
		HelpersService.assert(result, 'result', 'The Google Maps result input is invalid (no result)');
		

		let name = result.name;
		let phone = result.formatted_phone_number;

		return {name: name, phone: phone};
	}

	public static parseResult(result: google.maps.places.PlaceResult | any): ParsedLocation {
		HelpersService.assert(result, 'result', 'The Google Maps result input is invalid (no result)');
		HelpersService.assert(result.geometry, 'result.geometry', 'The Google Maps result input is invalid (no result.geomtry)');

		const place = result;

		let street;
		let zipCode;
		let city;
		let state;
		let country;
		let lat;
		let lng;

		if (typeof result.geometry.location.lat === 'number') {
			lat = result.geometry.location.lat;
		} else {
			lat = result.geometry.location.lat();
		}

		if (typeof result.geometry.location.lng === 'number') {
			lng = result.geometry.location.lng;
		} else {
			lng = result.geometry.location.lng();
		}

		const loc: string = result.name || result.formatted_address.split(',')[0];

		for (const component of result.address_components) {
			const key = component.types[0];

			if (key === 'route') {
				const placeStreet = component.long_name;
				let placeNumber = '';
				for (const bcomponent of result.address_components) {
					const bkey = bcomponent.types[0];
					if (bkey === 'street_number') {
						placeNumber = bcomponent.long_name;
					}
				}
				street = `${placeStreet} ${placeNumber}`;
			}

			if (key === 'postal_code' || key === 'postal_code_suffix') {
				zipCode = component.long_name;
			}

			if (key === 'locality') {
				city = component.long_name;
			}

			if (key === 'country') {
				country = component.long_name;
			}

			if (key === 'administrative_area_level_1') {
				state = component.long_name;
			}
		}

		if (!city && state) {
			city = state;
		}

		if (!city && country) {
			city = country;
		}

		if (!country && state) {
			country = state;
		}

		if (!state && country) {
			state = country;
		}

		return { street, zipCode, city, state, country, lat, lng, loc, place };
	}

	public static removePacContainers(): void {
		try {
			const containers = document.getElementsByClassName('pac-container');
			if(containers && containers.length > 1){
				for (let index = 0; index < containers.length -1; index++) {
					const element = containers[index];
					element.remove();
				}
			}
			// for (const c of Array.from(containers)) {
			// 	c.remove();
			// }
		} catch (e) {
			console.error(e);
		}
	}

	public static buildAddress(street: string, city: string, zipCode: string, country: string, oneLine?: boolean): string {
		const separator = oneLine ? '' : '\n';
		let label = street || '';
		if (street && city) {
			label += (', ' + city);
		} else if (!street && city) {
			label += (city);
		} else if (street && !city) {
			label += ('.' + separator);
		}
		if (city && zipCode) {
			label += (' (' + zipCode + ') ' + separator);
		} else if (city && !zipCode) {
			label += ('. ' + separator);
		}
		if (country) {
			label += (country.toUpperCase() + '.' + separator);
		}
		return label;
	}

	public async createAutocomplete(searchElement: ElementRef, autocompleteOptions?): Promise<google.maps.places.Autocomplete> {
		HelpersService.assert(searchElement, 'searchElement');
		this.logger.info('Creating the autocomplete...');
		return new google.maps.places.Autocomplete(searchElement.nativeElement, autocompleteOptions);
	}

	public async loadMapsAPI() {
		if (!this.loadedMapsAPI) {
			this.logger.info('Loading maps API...');
			try {
				await this.mapsAPILoader.load();
				this.loadedMapsAPI = true;
			} catch (e) {
				this.loadedMapsAPI = false;
			}
		}
	}

	public setCurrentLocation() {
		if (isPlatformBrowser(this.platformId)) {
			if ('geolocation' in navigator) {
				navigator.geolocation.getCurrentPosition(() => {
				});
			}
		}
	}

	public findMostLikelyPlace(input?) {
		this.logger.info(`Finding most likely place for ${input}...`);

		return new Promise(
			(resolve, reject) => {
				const suggestionSelected = document.getElementsByClassName('pac-item-selected');
				console.log("PAC ITEMS selected: " + JSON.stringify(suggestionSelected));

				if (suggestionSelected.length === 0) {
					if (document.getElementsByClassName('pac-item-query').length > 0 && document.getElementsByClassName('pac-item').length > 0) {

						const firstResultCity = document.getElementsByClassName('pac-item-query')[0].textContent;
						const firstResultComplete = document.getElementsByClassName('pac-item')[0].textContent;
						const firstResult = firstResultComplete.replace(firstResultCity, firstResultCity + ' ');

						const service = new google.maps.places.AutocompleteService();
						// service.getQueryPredictions({ input: (input ? input : firstResult)}, (predictions, serviceStatus) => {
						// 	if (serviceStatus === google.maps.places.PlacesServiceStatus.OK) {
						// 		if (predictions && predictions.length > 0) {
						// 			const geocoder = new google.maps.Geocoder();
						// 			geocoder.geocode({ 'placeId': predictions[0].place_id }, (results, geoStatus) => {
						// 					if (geoStatus === google.maps.GeocoderStatus.OK) {
						// 						if (results && results[0]) {
						// 							this.ngZone.run(() => {
						// 								MapsService.removePacContainers();
						// 								resolve(results[0]);
						// 							});
						// 						}
						// 					}
						// 				}
						// 			);
						// 		}
						// 	}
						// });

						
						let searchQ = Object.assign({input: String},GERMAN_SPEAKING_CITIES);
						searchQ.input = (input ? input : firstResult);

						service.getPlacePredictions(searchQ, (predictions, serviceStatus) => {
							if (serviceStatus === google.maps.places.PlacesServiceStatus.OK) {
								if (predictions && predictions.length > 0) {
									const geocoder = new google.maps.Geocoder();
									console.log("Most likely place is: " + predictions[0].description)
									geocoder.geocode({ 'placeId': predictions[0].place_id }, (results, geoStatus) => {
											if (geoStatus === google.maps.GeocoderStatus.OK) {
												if (results && results[0]) {
													this.ngZone.run(() => {
														MapsService.removePacContainers();
														resolve(results[0]);
													});
												}
											}
										}
									);
								}
							}
						});
					} else {
						console.warn(`No search results for ${input}`);
						reject(input);
					}
				} else {
					console.warn(`No search results for ${input}`);
					reject(input);
				}
			}
		);
	}

	public reverseGeocode(lat: string, lng: string) {
		this.logger.info(`Reverse geocoding for latitude ${lat} and longitude ${lng}...`);

		return new Promise(
			(resolve, reject) => {
				if (isPlatformBrowser(this.platformId)) {
					this.mapsAPILoader.load().then(
						() => {
							const geoCoder = new google.maps.Geocoder;
							const latLng = { lat: parseFloat(lat), lng: parseFloat(lng) };
							geoCoder.geocode({
								location: latLng,
							}, (results, status) => {
								if (status === google.maps.GeocoderStatus.OK) {
									const result = results.find(
										(anyResult) => {
											return anyResult.address_components[0].types.includes('locality');
										}
									);

									if (result) {
										resolve(result);
									} else {
										reject(new WeddyPlaceError('reverseGeocode found no results'));
									}
								} else {
									reject(new WeddyPlaceError(`reverseGeocode failed due to: ${status}`));
								}
							});
						},
						(err) => {
							reject(err);
						}
					);
				} else {
					reject(new WeddyPlaceError('reverseGeocode is not supposed to run on the server side'));
				}
			}
		);
	}

	public retrievePlaceThroughId(id) {
		this.logger.info(`Retrieving place for id ${id}...`);

		return new Promise(
			(resolve, reject) => {
				if (isPlatformBrowser(this.platformId)) {
					this.mapsAPILoader.load().then(
						() => {
							const geoCoder = new google.maps.Geocoder;
							geoCoder.geocode({ 'placeId': id }, (results, status) => {
								if (status === google.maps.GeocoderStatus.OK) {
									if (results[0]) {
										resolve(results[0]);
									} else {
										window.alert('No results found');
										reject();
									}
								} else {
									window.alert(`Geocoder failed due to: ${status}`);
									reject();
								}
							});
						}
					);
				}

			}
		);
	}

	public async loadMaps(searchElement: ElementRef, autocompleteOptions?): Promise<google.maps.places.Autocomplete> {
		if (isPlatformBrowser(this.platformId)) {
			try {
				console.log('Loading maps....');
				HelpersService.assert(searchElement, 'searchElement');
				await this.loadMapsAPI();
				return await this.createAutocomplete(searchElement, autocompleteOptions);
				// return this.addEventListener(autoComplete);
			} catch (e) {
				console.error(`Error while loading map: ${e}`);
			}
		}
	}

	public onPlaceChanged(autoComplete: google.maps.places.Autocomplete): Promise<ParsedLocation> {
		return this.ngZone.run(async () => {
			HelpersService.assert(autoComplete, 'autoComplete');
			const result: google.maps.places.PlaceResult = autoComplete.getPlace();
			if (HelpersService.safelyGet(() => result.id) || HelpersService.safelyGet(() => result.place_id)) {
				return MapsService.parseResult(result);
			} else {
				const res = await this.findMostLikelyPlace();

				if (res) {
					return MapsService.parseResult(res);
				} else {
					console.error('Could not find a location in Google Maps');
				}
			}
		});
	}

}
