export const INQUIRY_STATUS = {
	AUTOMATICALLY_SUGGESTED: 'auto-suggest',
	BOOKED: 'booked',
	CANCELLED: 'cancellation',
	CONTACT_OPENED: 'contact-opened',
	CONTACT_PENDING: 'contact-pending',
	CONTACT_RESPONDED: 'contact-responded',
	DECLINED_CUSTOMER: 'declined-customer',
	DECLINED_VENDOR: 'declined-vendor',
	DISLIKED: 'disliked',
	LIKED: 'liked',
	MANUALLY_SUGGESTED: 'man-suggest',
};
export const SUGGESTION_STATUS_ARRAYS = {
	BOOKED: [INQUIRY_STATUS.BOOKED],
	CONTACTABLE: [INQUIRY_STATUS.CONTACT_OPENED, INQUIRY_STATUS.BOOKED, INQUIRY_STATUS.CONTACT_RESPONDED],
	CONTACTED: [INQUIRY_STATUS.CONTACT_RESPONDED, INQUIRY_STATUS.CONTACT_OPENED, INQUIRY_STATUS.CONTACT_PENDING],
	DECLINED: [INQUIRY_STATUS.DECLINED_CUSTOMER, INQUIRY_STATUS.DECLINED_VENDOR, INQUIRY_STATUS.CANCELLED],
	LIKED: [INQUIRY_STATUS.LIKED],
};
