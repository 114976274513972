import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../_material/material.module';
import { AddFloatingButtonComponent } from './add-floating-button/add-floating-button.component';
import { CancelButtonComponent } from './cancel-button/cancel-button.component';
import { ClearButtonComponent } from './clear-button/clear-button.component';
import { CloseButtonComponent } from './close-button/close-button.component';
import { DeleteButtonComponent } from './delete-button/delete-button.component';
import { EditButtonComponent } from './edit-button/edit-button.component';
import { FloatingAddButtonComponent } from './floating-add-button/floating-add-button.component';
import { SaveButtonComponent } from './save-button/save-button.component';
import { SpinnerButtonComponent } from './spinner-button/spinner-button.component';
import { SwitcherButtonComponent } from './switcher-button/switcher-button.component';

@NgModule({
	declarations: [
		AddFloatingButtonComponent,
		CancelButtonComponent,
		ClearButtonComponent,
		DeleteButtonComponent,
		EditButtonComponent,
		FloatingAddButtonComponent,
		SaveButtonComponent,
		SpinnerButtonComponent,
		CloseButtonComponent,
		SwitcherButtonComponent
	],
	imports: [
		CommonModule,
		MaterialModule,
		TranslateModule.forChild()
	],
	exports: [
		AddFloatingButtonComponent,
		CancelButtonComponent,
		ClearButtonComponent,
		DeleteButtonComponent,
		EditButtonComponent,
		FloatingAddButtonComponent,
		SaveButtonComponent,
		SpinnerButtonComponent,
		CloseButtonComponent,
		SwitcherButtonComponent
	]
})
export class ButtonsModule {
}
