<div class="header">
    <h1 mat-dialog-title>Der WeddyPlace Kalendar</h1>
    <button [mat-dialog-close]="false" mat-icon-button>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="text-center">
    <mat-horizontal-stepper #stepper (selectionChange)="selectionChange($event)" class="hidden-header">

        <mat-step>
            <h2>Verwalte alle Buchungen und Verfügbarkeiten auf einen Blick!</h2>
            <img src="/assets/img/calendar/calendar-monthview.png" style="margin-top: 35px;margin-bottom: 35px"
                 width="450px" alt="Month view example'">

            <div class="info">
                <strong>Tipp: </strong><span>Auf der nächsten Seite erfährst Du wie der WeddyPlace Kalendar <br>auch den Brautpaaren hilft genau Dich zu finden.</span>
            </div>
        </mat-step>

        <mat-step>

            <h2>Das sind die nächsten Schritte:</h2>
            <div class="steps-container">
                <div class="icon-container">
                    <mat-icon [svgIcon]="'cal-calendar'"></mat-icon>
                    <p>
                        Belegung eintragen
                    </p>
                    <span>
                        Um die Qualität der Anfragen zu steigern, hilft es uns zu wissen an welchen Tagen Du bereits belegt bist. Wir werden Dich Brautpaaren, die an diesen Tagen heiraten wollen, nicht anzeigen. So entsteht keine unnötige Arbeit.
                    </span>
                </div>


                <div class="icon-container">

                    <mat-icon [svgIcon]="'cal-document'"></mat-icon>
                    <p>
                        Termine mit ein paar Klicks über WeddyPlace vereinbaren
                    </p>

                    <span>
                            Vereinbare deine Termine mit Brautpaaren jetzt schneller. Wir helfen beim vereinbaren der Termine, damit ihr kein Brautpaar mehr wegen langwierigen Terminabsprachen verliert. Zudem habt Ihr immer alles im Überblick.
                        <!-- Vereinbare deine Termine mit Brautpaaren jetzt schneller. Wir helfen bei der Findung eines passenden Zeitpunkts und beschleunigen so die Terminvergabe. Ab jetzt ist es nicht mehr nötig hin und her zu telefonieren.  -->
                    </span>
                </div>


                <div class="icon-container">
                    <mat-icon [svgIcon]="'cal-availability'"></mat-icon>
                    <p>
                        Verfügbarkeit boosten
                    </p>
                    <span>
                          Du hast freie Tage? Sag uns wann und wir werden versuchen Dich an verschiedenen Stellen hier auf WeddyPlace mit einem besonderen plus an Liebe zu vermitteln.
                        </span>
                </div>
            </div>

        </mat-step>

        <mat-step>
            <h2>Meine Öffnungszeiten</h2>
            <div class="open-dates-wrapper">
                <div class="another-wrapper-why">
                    <div *ngFor="let day of table" class="day-wrapper">
                        <span class="day-title">{{day.title}}</span>
                        <mat-slide-toggle (change)="changeOpeningStatus(day)" [checked]="day.open"
                                          class="open-toggle"> {{day.open ? 'Geöffnet' : 'Geschlossen'}}
                        </mat-slide-toggle>
                        <ng-container *ngIf="day.open">
                            <div class="open-day-why">
                                <div *ngFor="let times of day.times; let i = index"
                                     style="display:flex;justify-content: flex-start;align-items: center;flex-wrap: wrap">
                                    <input [(ngModel)]="times.timeFrom" class="time-input" matInput type="time">
                                    <span> - </span>
                                    <input [(ngModel)]="times.timeTo" class="time-input" matInput type="time">
                                    <button (click)="removeTime(day, i)" *ngIf="i > 0" color="default" mat-icon-button>
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <button (click)="addTime(day)" *ngIf="i == day.times.length - 1" color="default"
                                            mat-button>Zeit hinzufügen
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>


            </div>
            <p style="font-size: 15px!important">Du entscheidest selbst, welche Terminanfragen Du annimmst.<br>Um die
                Nutzererfahrung zu verbessern, können wir Terminevorschläge, die innerhalb deiner Öffnungszeiten liegen,
                automatisch für Dich annehmen.</p>
            <mat-slide-toggle (change)="changeAutoAppointments()"
                              [checked]="shopsService.currentShop.autoAppointments" class="example-margin"
                              style="margin-top: 25px; margin-bottom: 25px;font-size: smaller">
                {{ shopsService.currentShop.autoAppointments ? 'Terminevorschläge die innerhalb deiner Öffnungszeiten liegen, werden automatisch für Dich angenommen.' : 'Terminanfragen von Kunden müssen von Dir angenommen und bestätigt werden'}}
            </mat-slide-toggle>
            <div *ngIf="shopsService.currentShop.autoAppointments">
                <mat-checkbox (change)="changeAutoAppointmentsBlockedDays()"
                              [checked]="shopsService.currentShop.autoAppointmentsBlockedDays">
                    Keine Termine an belegten Tagen
                </mat-checkbox>
                <mat-checkbox (change)="changeAutoAppointmentsMultiple()"
                              [checked]="shopsService.currentShop.autoAppointmentsMultiple"
                              style="margin-left:15px">Mehrere Termine zur selben Uhrzeit möglich?
                </mat-checkbox>
            </div>
        </mat-step>
    </mat-horizontal-stepper>


</mat-dialog-content>

<!-- ---------------------------------------------------------------------------------------------------------- -->
<!-- -------------------------------------------Dialog-Action-Buttons------------------------------------------ -->
<!-- ---------------------------------------------------------------------------------------------------------- -->

<mat-dialog-actions style="display:flex;justify-content: flex-end;margin-bottom: 0px">
    <button (click)="onPrevious()" *ngIf="stepper.selectedIndex != 0" color="primary" mat-raised-button>
        zurück
    </button>
    <button (click)="onNext()" *ngIf="stepper.selectedIndex != stepper._steps?.length -1" color="primary"
            mat-raised-button>
        Weiter
    </button>
    <button (click)="onClose()" *ngIf="stepper.selectedIndex == stepper._steps?.length -1" color="accent"
            mat-button mat-raised-button>{{ 'Schliessen' }}</button>
</mat-dialog-actions>
