import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
	name: 'pricePerPersonPipe'
})
export class PricePerPersonPipe implements PipeTransform {

	constructor(private currencyPipe: CurrencyPipe) {
	}

	public transform(value: string | number, numberOfGuests: number, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string {
		if (typeof value === 'string') {
			const valueAsNumber = parseInt(value, 10);
			return `+${this.currencyPipe.transform(valueAsNumber / numberOfGuests, currencyCode, display, digitsInfo, locale)}`;
		} else {
			return this.currencyPipe.transform(value / numberOfGuests, currencyCode, display, digitsInfo, locale);
		}
	}
}
