import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { LocalStorageService } from '../_services/local-storage.service';
import { ShopsService } from '../_services/shops.service';
import { TokenService } from '../_services/token.service';

@Injectable()
export class NoAuthGuard implements CanActivate {

	constructor(private router: Router,
				private localStorageService: LocalStorageService,
				private tokenService: TokenService,
				private authenticationService: AuthenticationService,
				private shopsService: ShopsService,
				@Inject(PLATFORM_ID) private platformId: Object) {
	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (isPlatformBrowser(this.platformId)) {
			this.authenticationService.authenticateThroughURL(route);
			ShopsService.findShopIdAndRedirect(route);

			if (this.tokenService.token) {
				this.router.navigate(['/dashboard/inquiries'], {queryParams: {tok: null}, queryParamsHandling: 'merge'});
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	}

}
