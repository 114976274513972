export class WizardAnswer {

	public key: string;
	public type: string;
	public title: string;
	public image: string;
	public tag?: string;
	public budgetStart?: number;
	public budgetEnd?: number;
	public numberOfGuests?: number;

	constructor(key, type, title, assets, image, tag?, budgetStart?, budgetEnd?, numberOfGuests?) {
		this.key = key;
		this.type = type;
		this.title = title;
		this.image = assets + image;
		this.tag = tag;
		this.budgetStart = budgetStart;
		this.budgetEnd = budgetEnd;
		this.numberOfGuests = numberOfGuests;
	}

}
