import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'punctuate'
})
export class PunctuatePipe implements PipeTransform {

	public transform(value: any, args?: any): any {
		if (!value || typeof value !== 'string') {
			return null;
		} else {
			return value.replace(/\s+(\W)/g, '$1');
		}
	}

}
