import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AccountData } from '../_interfaces/account-data.interface';
import { Appointment } from '../_interfaces/appointment.interface';
import { Inquiry } from '../_interfaces/inquiry.interface';
import { Notification } from '../_interfaces/notification.interface';
import { Offer } from '../_interfaces/offer.interface';
import { Partner } from '../_interfaces/partner.interface';
import { Project } from '../_interfaces/project.interface';
import { Shop } from '../_interfaces/shop.interface';
import { RefreshDialogComponent } from '../modules/dialogs/update-dialog/refresh-dialog.component';
import { AccountDataService } from './account-data.service';
import { AppointmentsService } from './appointments.service';
import { BlockedDatesService } from './blocked-dates.service';
import { DialogsService } from './dialogs.service';
import { InquiriesService } from './inquiries.service';
import { NotificationsService } from './notifications.service';
import { OffersService } from './offers.service';
import { PartnersService } from './partners.service';
import { ProjectsService } from './projects.service';
import { ShopsService } from './shops.service';

@Injectable({
	providedIn: 'root'
})
export class SocketEventsService {

	constructor(private accountDataService: AccountDataService,
	            private appointmentsService: AppointmentsService,
	            private busyDatesService: BlockedDatesService,
	            private shopsService: ShopsService,
	            private dialog: MatDialog,
	            private offersService: OffersService,
	            private inquiriesService: InquiriesService,
	            private partnersService: PartnersService,
	            private projectsService: ProjectsService,
	            private dialogsService: DialogsService,
	            private notificationsService: NotificationsService) {
	}

	public listenToEvents(socket: SocketIOClient.Socket) {
		socket.on('account-changed', (accountData: AccountData) => {
			this.accountDataService.accountData = accountData;
		});
		socket.on('appointment-added', (appointment: Appointment) => {
			this.appointmentsService.addAppointment(appointment);
		});
		socket.on('appointment-changed', (appointment: Appointment) => {
			this.appointmentsService.editAppointment(appointment);
		});
		socket.on('appointment-deleted', (id: string) => {
			this.appointmentsService.removeAppointment(id);
		});
		socket.on('busyDate-added', (busyDate: Appointment) => {
			this.busyDatesService.addBlockedDate(busyDate);
		});
		socket.on('busyDate-changed', (busyDate: Appointment) => {
			this.busyDatesService.editBlockedDate(busyDate);
		});
		socket.on('busyDate-deleted', (id: string) => {
			this.busyDatesService.removeBlockedDate(id);
		});
		socket.on('shop-added', (shop: Shop) => {
			this.shopsService.addShop(shop);
		});
		socket.on('shop-changed', (shop: Shop) => {
			this.shopsService.editShop(shop);
		});
		socket.on('new-like', (shop: Shop) => {
			console.log('new like');
		});
		socket.on('shop-deleted', (id: string) => {
			this.shopsService.removeShop(id);
		});
		socket.on('new-inquiry', (inquiry: Inquiry) => {
			this.inquiriesService.addInquiry(inquiry);
			this.inquiriesService.addInquiryToNotificationsStats(inquiry);
		});
		socket.on('inquiry-changed', (inquiry: Inquiry) => {
			this.inquiriesService.editInquiry(inquiry);
		});
		socket.on('new-inquiry-message', (message) => {
			this.inquiriesService.addMessage(message);
		});
		socket.on('weddyFavMessageNEW', (message) => {
			this.inquiriesService.addMessage(message);
		});
		socket.on('new-offer', (message) => {
			this.inquiriesService.onNewOffer(message);
		});
		socket.on('offer-accepted', (message) => {
			this.inquiriesService.editMessage(message);
		});
		socket.on('offer-declined', (message) => {
			this.inquiriesService.onOfferDeclined(message);
		});
		socket.on('new-order', (message) => {
			this.inquiriesService.onNewOrder(message);
		});
		socket.on('vendorRead', (res: { inquiryId: string, shopId: string }) => {
			this.inquiriesService.reactToReadInquiry(res);
		});
		socket.on('notification-stats', (res) => {
			this.notificationsService.notifications = res;
		});
		socket.on('notification-added', (notification: Notification) => {
			this.notificationsService.addNotification(notification);
		});
		socket.on('notification-changed', (notification: Notification) => {
			this.notificationsService.editNotification(notification);
		});
		socket.on('notification-deleted', (id: string) => {
			this.notificationsService.removeNotification(id);
		});
		socket.on('offer-added', (offer: Offer) => {
			this.offersService.addOffer(offer);
		});
		socket.on('new-offer', (offer: Offer) => {
			this.offersService.addOffer(offer);
		});
		socket.on('offer-accepted', (offer: Offer) => {
			this.offersService.editOffer(offer);
		});
		socket.on('offer-declined', (offer: Offer) => {
			this.offersService.editOffer(offer);
		});
		socket.on('offer-changed', (offer: Offer) => {
			this.offersService.editOffer(offer);
		});
		socket.on('offer-deleted', (id: string) => {
			this.offersService.removeOffer(id);
		});
		socket.on('weddyOfferRequestedNew', (inquiry: Inquiry) => {
			this.offersService.openDemandDialog(inquiry);
		});
		socket.on('partner-added', (partner: Partner) => {
			this.partnersService.addPartner(partner);
		});
		socket.on('partner-changed', (partner: Partner) => {
			this.partnersService.editPartner(partner);
		});
		socket.on('partner-deleted', (id: string) => {
			this.partnersService.removePartner(id);
		});
		socket.on('project-added', (project: Project) => {
			this.projectsService.addProject(project);
		});
		socket.on('project-changed', (project: Project) => {
			this.projectsService.editProject(project);
		});
		socket.on('project-deleted', (id: string) => {
			this.projectsService.removeProject(id);
		});
		socket.on('refreshProApp', () => {
			this.dialog.open(RefreshDialogComponent, this.dialogsService.standardDialogConfig());
		});
	}

}
