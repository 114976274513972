<div class="wrapper">
    <div class="container">
        <h1>{{ errorCode || 'ERROR' }}</h1>
        <h2>{{ errorMessage || ('ERRORS.SOMETHING_WENT_WRONG.TITLE' | translate) }}</h2>
        <p>{{ 'THAT_IS_ALL_WE_KNOW' | translate }}</p>

        <p>Version: {{ version }}</p>

        <a class="back-container" href="https://pro.weddyplace.com">
            <mat-icon>keyboard_arrow_left</mat-icon>
            <span>{{ 'ERRORS.NOT_FOUND.BUTTON' | translate }}</span>
        </a>

        <button *ngIf="testing || accountDataService?.accountData?.isTest" (click)="openIssue()" color="warn" mat-button
                mat-raised-button>
            Open issue
        </button>
    </div>
</div>
