<div class="wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12">

                <mat-card>

                    <div class="brand">
                        <mat-icon [svgIcon]="'logo-greenish'" class="logo"></mat-icon>
                        <span class="name">WeddyPlace</span>
                        <mat-icon [svgIcon]="'pro-tag'" class="pro-tag"></mat-icon>
                    </div>

                    <form (submit)="onSignIn()" [formGroup]="signInForm">

                        <!--Email-->
                        <mat-form-field class="weddy-form-field" floatLabel="always">
                            <input (blur)="onEmailBlur()" [formControl]="signInForm.controls['email']"
                                   matInput autocomplete="email"
                                   placeholder="{{ 'PLACEHOLDERS.EMAIL' | translate }}" tabindex="1">
                            <mat-icon *ngIf="!signInForm.controls['email'].value" [svgIcon]="'envelope'"
                                      class="suffix-icon" matSuffix>
                            </mat-icon>
                            <button (click)="signInForm.controls['email'].reset()"
                                    *ngIf="signInForm.controls['email'].value" aria-label="Clear"
                                    mat-button mat-icon-button
                                    matSuffix tabindex="-1"
                                    type="button">
                                <mat-icon [svgIcon]="'close'"></mat-icon>
                            </button>
                            <mat-error *ngIf="signInForm.controls['email'].hasError('required')">
                                <span [innerHTML]="'INPUT_ERRORS.EMAIL.REQUIRED' | translate"></span>
                            </mat-error>
                            <mat-error *ngIf="signInForm.controls['email'].hasError('minlength')">
                                <span [innerHTML]="'INPUT_ERRORS.EMAIL.TOO_SHORT' | translate:{number:emailMinLength}"></span>
                            </mat-error>
                            <mat-error *ngIf="signInForm.controls['email'].hasError('maxlength')">
                                <span [innerHTML]="'INPUT_ERRORS.EMAIL.TOO_LONG' | translate:{number:emailMaxLength}"></span>
                            </mat-error>
                            <mat-error *ngIf="signInForm.controls['email'].hasError('email')">
                                <span [innerHTML]="'INPUT_ERRORS.EMAIL.NOT_AN_EMAIL' | translate"></span>
                            </mat-error>

                        </mat-form-field>

                        <!--Password-->
                        <mat-form-field class="weddy-form-field" floatLabel="always">
                            <input #inPasswordInput [formControl]="signInForm.controls['password']" matInput
                                   placeholder="{{ 'PLACEHOLDERS.PASSWORD' | translate }}" autocomplete="current-password"
                                   tabindex="2" type="password">
                            <mat-icon *ngIf="!signInForm.controls['password'].value" [svgIcon]="'lock-1'"
                                      class="suffix-icon" matSuffix>
                            </mat-icon>

                            <button (click)="inPasswordInput.type === 'text' ? inPasswordInput.type = 'password' : inPasswordInput.type = 'text'"
                                    *ngIf="signInForm.controls['password'].value" aria-label="View" mat-button
                                    mat-icon-button
                                    matSuffix
                                    tabindex="-1"
                                    type="button">
                                <mat-icon [svgIcon]="inPasswordInput.type === 'password' ? 'view' : 'view-off'"
                                          matSuffix>
                                </mat-icon>
                            </button>

                            <mat-error *ngIf="signInForm.controls['password'].hasError('required')">
                                <span [innerHTML]="'INPUT_ERRORS.PASSWORD.REQUIRED' | translate"></span>
                            </mat-error>
                            <mat-error *ngIf="signInForm.controls['password'].hasError('minlength')">
                                <span [innerHTML]="'INPUT_ERRORS.PASSWORD.TOO_SHORT' | translate:{number:passwordMinLength}"></span>
                            </mat-error>
                            <mat-error *ngIf="signInForm.controls['password'].hasError('maxlength')">
                                <span [innerHTML]="'INPUT_ERRORS.PASSWORD.TOO_LONG' | translate:{number:passwordMaxLength}"></span>
                            </mat-error>
                        </mat-form-field>

                        <mat-error *ngIf="signInForm.hasError('somethingWentWrong')">
                            <span *ngFor="let errorMessage of errorMessages" [innerHTML]="errorMessage"></span>
                            <span *ngIf="errorMessages.length === 0"
                                  [innerHTML]="'INPUT_ERRORS.SOMETHING_WENT_WRONG' | translate"></span>
                        </mat-error>

                        <p><a [routerLink]="['/password', 'recover']"
                              [queryParams]="{ email: signInForm.controls['email'].value }" tabindex="4">Passwort
                            vergessen?</a></p>

                        <p>Noch nicht registriert? Registrieren Sie sich <a [routerLink]="['/on-boarding']" class="bold"
                                                                            tabindex="5">hier</a>
                        </p>

                        <button [disabled]="processing" class="submit-button" color="accent" mat-button
                                mat-raised-button tabindex="3">
                            {{ processing ? ('BUTTONS.PROCESSING' | translate) : ('BUTTONS.SIGN_IN' | translate) }}
                        </button>

                    </form>

                </mat-card>
            </div>
        </div>
    </div>
</div>
