import { NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { APPLICATION_NAME } from './_shared/_others/tokens';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';

@NgModule({
	bootstrap: [AppComponent],
	imports: [
		BrowserModule.withServerTransition({
			appId: APPLICATION_NAME
		}),
		BrowserTransferStateModule,
		AppModule
	]
})
export class BrowserAppModule {
}
