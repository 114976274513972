import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LinksService {

	private renderer: Renderer2;

	constructor(private rendererFactory: RendererFactory2,
				@Inject(DOCUMENT) private document) {
		this.renderer = this.rendererFactory.createRenderer(this.document, {
			id: '-1',
			encapsulation: ViewEncapsulation.None,
			styles: [],
			data: {}
		});
	}

	private static _parseSelector(linkDefinition: LinkDefinition): string {
		const attr: string = linkDefinition.rel ? 'rel' : 'hreflang';
		return `link[${attr}="${linkDefinition[attr]}"]`;
	}

	public updateLink(linkDefinition: LinkDefinition) {
		this.removeLink(linkDefinition);
		this.addLink(linkDefinition);
	}

	public removeLink(linkDefinition: LinkDefinition) {
		try {
			const selector = LinksService._parseSelector(linkDefinition);
			const head = this.document.header;

			const linkTags = this.document.querySelectorAll(selector);
			for (const link of linkTags) {
				this.renderer.removeChild(head, link);
			}
		} catch (e) {
			console.error(`Error within linkService : `, e);
		}
	}

	public addLink(linkDefinition: LinkDefinition) {
		try {
			const link = this.renderer.createElement('link');
			const head = this.document.head;

			Object.keys(linkDefinition).forEach((prop: string) => {
				return this.renderer.setAttribute(link, prop, linkDefinition[prop]);
			});

			this.renderer.appendChild(head, link);
		} catch (e) {
			console.error(`Error within linkService : `, e);
		}
	}
}

export declare type LinkDefinition = {
	charset?: string;
	crossorigin?: string;
	href?: string;
	hreflang?: string;
	media?: string;
	rel?: string;
	rev?: string;
	sizes?: string;
	target?: string;
	type?: string;
} & {
	[prop: string]: string;
};
