import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

export class CustomUrlSerializer implements UrlSerializer {

	public parse(url: any): UrlTree {
		const dus = new DefaultUrlSerializer();
		return dus.parse(url);
	}

	public serialize(tree: UrlTree): any {
		const dus = new DefaultUrlSerializer();
		const path = dus.serialize(tree);
		return path.replace(/ *\([^)]*\) */g, '');
	}
}
