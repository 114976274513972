import { Task } from './task.interface';
import { WeddingPartner } from './wedding-partner.interface';

export interface Wedding {
	_id?: string;
	partner1?: WeddingPartner;
	partner2?: WeddingPartner;
	weddingDate?: Date;
	budget?: any;
	location?: { city?: string; lat?: string; lng?: string; searchRadius?: number; place?: {} };
	favourites?: string[];
	checkList?: Task[];
	budgetList?: any;
	requestData?: { category: string, data: any }[];
}

export class WeddingClass implements Wedding {

	public _id: string;
	public budget: any;
	public budgetList: any;
	public checkList: Task[];
	public favourites: string[];
	public location: { city?: string; lat?: string; lng?: string; searchRadius?: number; place?: {} };
	public partner1: WeddingPartner;
	public partner2: WeddingPartner;
	public requestData: { category: string; data: any }[];
	public weddingDate: Date;

	constructor() {
	}
}
