import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ShopsService } from '../_services/shops.service';
import { LocalStorageService } from '../_services/local-storage.service';
import { TokenService } from '../_services/token.service';

@Injectable()
export class ShopGuard implements CanActivate {

	constructor(private router: Router,
	            private shopsService: ShopsService,
	            private tokenService: TokenService,
	            private localStorageService: LocalStorageService,
	            @Inject(PLATFORM_ID) private platformId: Object) {
	}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		await this.shopsService.getCurrentShop();

		if (this.shopsService.currentShop && this.shopsService.currentShop._id !== 'NEW_SHOP') {
			return true;
		} else {
			this.router.navigate(['/dashboard', 'settings']);
			return false;
		}
	}

}
