import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { StarRatingConfigService, StarRatingModule } from 'angular-star-rating';
import { MaterialModule } from '../../_material/material.module';
import { CustomPipesModule } from '../../_pipes';
import { SharedComponentsModule } from '../../components/shared-components.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { AppFormsModule } from '../forms/app-forms.module';
import { AcceptTermsDialogComponent } from './accept-terms-dialog/accept-terms-dialog.component';
import { AddVideoProjectDialogComponent } from './add-video-project-dialog/add-video-project-dialog.component';
import { AppointmentDialogComponent } from './appointment-dialog/appointment-dialog.component';
import { ApprovedBookedDialogComponent } from './approved-booked-dialog/approved-booked-dialog.component';
import { AutoAnswerDialogComponent } from './auto-answer-dialog/auto-answer-dialog.component';
import { AutoAppointmentDialogComponent } from './auto-appointment-dialog/auto-appointment-dialog.component';
import { BadgeDialogComponent } from './badge-dialog/badge-dialog.component';
import { CalendarAppointmentDialogComponent } from './calendar-appointment-dialog/calendar-appointment-dialog.component';
import { CalendarBlockingDialogComponent } from './calendar-blocking-dialog/calendar-blocking-dialog.component';
import { CalendarFreeDialogComponent } from './calendar-free-dialog/calendar-free-dialog.component';
import { CalendarViewAppointmentDialogComponent } from './calendar-view-appointment-dialog/calendar-view-appointment-dialog.component';
import { CalendarWelcomeDialogComponent } from './calendar-welcome-dialog/calendar-welcome-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { DeclineDialogComponent } from './decline-dialog/decline-dialog.component';
import { DeleteAccountDialogComponent } from './delete-account-dialog/delete-account-dialog.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { DemandDialogComponent } from './demand-dialog/demand-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { GalleryDialogComponent } from './gallery-dialog/gallery-dialog.component';
import { InquiryDialogComponent } from './inquiry-dialog/inquiry-dialog.component';
import { InvoiceDialogComponent } from './invoice-dialog/invoice-dialog.component';
import { MarkAsBookedDialogComponent } from './mark-as-booked-dialog/mark-as-booked-dialog.component';
import { MarkAsFakeDialogComponent } from './mark-as-fake-dialog/mark-as-fake-dialog.component';
import { OfferDialogComponent } from './offer-dialog/offer-dialog.component';
import { OpeningHoursDialogComponent } from './opening-hours-dialog/opening-hours-dialog.component';
import { PackageDialogComponent } from './package-dialog/package-dialog.component';
import { PartnerDialogComponent } from './partner-dialog/partner-dialog.component';
import { PublishErrorDialogComponent } from './publish-error-dialog/publish-error-dialog.component';
import { ReviewDialogComponent } from './review-dialog/review-dialog.component';
import { ServicesDialogComponent } from './services-dialog/services-dialog.component';
import { SetPasswordDialogComponent } from './set-password-dialog/set-password-dialog.component';
import { StandardPackagesDialogComponent } from './standard-packages-dialog/standard-packages-dialog.component';
import { SuggestPartnerDialogComponent } from './suggest-partner-dialog/suggest-partner-dialog.component';
import { RefreshDialogComponent } from './update-dialog/refresh-dialog.component';
import { ViewOfferDialogComponent } from './view-offer-dialog/view-offer-dialog.component';
import { BookingReminderDialogComponent } from './booking-reminder-dialog/booking-reminder-dialog.component';
import { StatusReportBannerComponent } from '../../../dashboard/inquiries/status-report-banner/status-report-banner.component';
import { MomentModule } from 'ngx-moment';
import { PremiumDialogComponent } from './premium-dialog/premium-dialog.component';

@NgModule({
	declarations: [
		AcceptTermsDialogComponent,
		AddVideoProjectDialogComponent,
		AppointmentDialogComponent,
		ApprovedBookedDialogComponent,
		AutoAppointmentDialogComponent,
		AutoAnswerDialogComponent,
		BadgeDialogComponent,
		CalendarAppointmentDialogComponent,
		CalendarBlockingDialogComponent,
		CalendarFreeDialogComponent,
		CalendarViewAppointmentDialogComponent,
		CalendarWelcomeDialogComponent,
		ConfirmationDialogComponent,
		DeclineDialogComponent,
		DeleteDialogComponent,
		DemandDialogComponent,
		ErrorDialogComponent,
		GalleryDialogComponent,
		InquiryDialogComponent,
		InvoiceDialogComponent,
		MarkAsBookedDialogComponent,
		MarkAsFakeDialogComponent,
		OfferDialogComponent,
		OpeningHoursDialogComponent,
		PackageDialogComponent,
		PartnerDialogComponent,
		ReviewDialogComponent,
		ServicesDialogComponent,
		SetPasswordDialogComponent,
		SuggestPartnerDialogComponent,
		RefreshDialogComponent,
		ViewOfferDialogComponent,
		PublishErrorDialogComponent,
		DeleteAccountDialogComponent,
		StandardPackagesDialogComponent,
		BookingReminderDialogComponent,
		StatusReportBannerComponent,
		PremiumDialogComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MaterialModule,
		RouterModule,
		FormsModule,
		AppFormsModule,
		SharedComponentsModule,
		ButtonsModule,
		StarRatingModule,
		CustomPipesModule,

		MomentModule
	],
	providers: [
		StarRatingConfigService
	],
	entryComponents: [
		ReviewDialogComponent,
		DeleteDialogComponent,
		CalendarBlockingDialogComponent,
		CalendarFreeDialogComponent,
		CalendarWelcomeDialogComponent,
		BadgeDialogComponent,
		RefreshDialogComponent,
		AutoAnswerDialogComponent,
		ErrorDialogComponent,
		AcceptTermsDialogComponent,
		ApprovedBookedDialogComponent,
		OpeningHoursDialogComponent,
		AutoAppointmentDialogComponent,
		ApprovedBookedDialogComponent,
		DemandDialogComponent,
		OfferDialogComponent,
		PublishErrorDialogComponent,
		BookingReminderDialogComponent,
		InvoiceDialogComponent,
		PremiumDialogComponent
	],
	exports: [
		AcceptTermsDialogComponent,
		AddVideoProjectDialogComponent,
		AppointmentDialogComponent,
		ApprovedBookedDialogComponent,
		AutoAppointmentDialogComponent,
		AutoAnswerDialogComponent,
		BadgeDialogComponent,
		CalendarAppointmentDialogComponent,
		CalendarBlockingDialogComponent,
		CalendarFreeDialogComponent,
		CalendarViewAppointmentDialogComponent,
		CalendarWelcomeDialogComponent,
		ConfirmationDialogComponent,
		DeclineDialogComponent,
		DeleteDialogComponent,
		DemandDialogComponent,
		ErrorDialogComponent,
		GalleryDialogComponent,
		InquiryDialogComponent,
		InvoiceDialogComponent,
		MarkAsBookedDialogComponent,
		MarkAsFakeDialogComponent,
		OfferDialogComponent,
		OpeningHoursDialogComponent,
		PackageDialogComponent,
		PartnerDialogComponent,
		ReviewDialogComponent,
		ServicesDialogComponent,
		SetPasswordDialogComponent,
		SuggestPartnerDialogComponent,
		RefreshDialogComponent,
		ViewOfferDialogComponent,
		PublishErrorDialogComponent,
		DeleteAccountDialogComponent,
		StandardPackagesDialogComponent,
		BookingReminderDialogComponent,
		StatusReportBannerComponent,
		PremiumDialogComponent
	]
})
export class DialogsModule {
}
