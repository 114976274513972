import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, isDevMode, PLATFORM_ID } from '@angular/core';
import { HelpersService } from './helpers.service';

@Injectable({
	providedIn: 'root'
})
export class SessionStorageService {

	constructor(@Inject(PLATFORM_ID) private platformId: Object) {
		if (isPlatformBrowser(this.platformId)) {
			console.log();
		}
	}

	public setItem(key: string, data: string | any) {
		if (isPlatformBrowser(this.platformId)) {
			try {
				return sessionStorage.setItem(key, HelpersService.safelyStringify(data));
			} catch (e) {
				if (isDevMode()) {
					console.warn('Error while stringifying a JSON in the Local Storage Service');
				}
				console.error(e);
			}
		}
	}

	public getItem(key, expected?: boolean): any {
		if (isPlatformBrowser(this.platformId)) {
			let str;
			try {
				str = sessionStorage.getItem(key);
				if (str) {
					return JSON.parse(str);
				} else {
					if (isDevMode() && expected) {
						console.warn(`No item found in local storage for key: ${key}`);
					}
					return str;
				}
			} catch (e) {
				if (isDevMode()) {
					console.warn(`Parsing of a JSON might have failed for key: ${key}`);
				}
				return str;
			}
		}
	}

	public clear() {
		if (isPlatformBrowser(this.platformId)) {
			try {
				sessionStorage.clear();
			} catch (e) {
				console.error(e);
			}
		}
	}

	public removeItem(key: string) {
		if (isPlatformBrowser(this.platformId)) {
			try {
				sessionStorage.removeItem(key);
			} catch (e) {
				console.error(e);
			}
		}
	}

	public length() {
		if (isPlatformBrowser(this.platformId)) {
			try {
				return sessionStorage.length;
			} catch (e) {
				console.error(e);
			}
		}
	}

	public key(index: number) {
		if (isPlatformBrowser(this.platformId)) {
			try {
				return sessionStorage.key(index);
			} catch (e) {
				console.error(e);
			}
		}
	}

}
