import { Injectable } from '@angular/core';
import { EventInput } from '@fullcalendar/core/structs/event';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { CalEvent } from '../_interfaces/cal-event.interface';
import { Couple } from '../_interfaces/couple.interface';
import { OpeningHours, TimesTable } from '../_interfaces/opening-hours.interface';
import { AppointmentsService } from './appointments.service';
import { HelpersService } from './helpers.service';
import { ShopsService } from './shops.service';

@Injectable({
	providedIn: 'root'
})
export class CalendarService {

	get events(): EventInput[] {
		return this._events;
	}

	set events(value: EventInput[]) {
		this._events = value;
		this.eventsChanged.next(this.events);
	}

	public eventsChanged: BehaviorSubject<EventInput[]> = new BehaviorSubject<EventInput[]>([]);

	private couples: Couple[];
	private _events: EventInput[] = [];

	constructor(private appointmentsService: AppointmentsService,
				private shopsService: ShopsService) {
	}

	public static parseTableToOpeningHours(table: TimesTable[]): OpeningHours {
		const tmp_opening_hours: OpeningHours = {
			open_now: '',
			periods: [],
			weekday_text: []
		};

		for (let index = 0; index < table.length; index++) {
			const day = table[index];
			if (day.open) {
				for (let timeIndex = 0; timeIndex < day.times.length; timeIndex++) {
					const time = day.times[timeIndex];
					tmp_opening_hours.periods.push({
						open: {day: day.day, time: time.timeFrom},
						close: {day: day.day, time: time.timeTo}
					});
				}
			}
		}

		return tmp_opening_hours;
	}

	public static parseOpeningHoursToTable(openingHours: OpeningHours): TimesTable[] {
		const table = [
			{day: 0, title: 'Sonntag', open: false, times: []},
			{day: 1, title: 'Montag', open: false, times: []},
			{day: 2, title: 'Dienstag', open: false, times: []},
			{day: 3, title: 'Mittwoch', open: false, times: []},
			{day: 4, title: 'Donnerstag', open: false, times: []},
			{day: 5, title: 'Freitag', open: false, times: []},
			{day: 6, title: 'Samstag', open: false, times: []}
		];

		for (const period of openingHours.periods) {
			if (HelpersService.safelyGet(() => period.open.time) && HelpersService.safelyGet(() => period.close.time)) {
				table[period.open.day].open = true;
				table[period.open.day].times.push({timeFrom: period.open.time, timeTo: period.close.time});
			}
		}
		return table;
	}

	public static extractBlockedDates(events) {
		const blockedDayEvents = events.filter((event: CalEvent) => {
			return event.title === 'busy day';
		});

		return blockedDayEvents.map((event: CalEvent) => {
			return event.start.toDateString();
		});
	}

	public addEvents(newEvents) {
		const events: EventInput[] = this.events;
		events.push(...newEvents);
		this.events = events;
	}

	public removeEvent() {

	}

	// private async getCouples() {
	// 	try {
	// 		this.couples = await this.appointmentsService.getCouples(this.shopsService.currentShop._id).toPromise();
	//
	// 		if (this.couples) {
	// 			const coupleWeddingDates = this.mapWeddingDates();
	// 			this.addEvents(coupleWeddingDates);
	// 			// this.events.push(...coupleWeddingDates);
	// 			this.parseAppointments(this.events);
	// 		}
	// 	} catch (e) {
	// 		console.error(e);
	// 	}
	// }

	// private mapWeddingDates() {
	// 	return this.couples.map((couple) => {
	// 		return {
	// 			start: new Date(couple.weddingData.weddingDate),
	// 			end: moment(couple.weddingData.weddingDate).add(1, 'day').startOf('day').toDate(),
	// 			title: 'Hochzeitstag',
	// 			couple: couple,
	// 			color: '#c87575',
	// 			textColor: '#fff',
	// 			allDay: true,
	// 			favId: couple.favId,
	// 			_id: 'xxx'
	// 		};
	// 	});
	// }

	public splitDates(startDate, endDate) {
		const dates = [];
		let currentDate = startDate;

		if (moment.isMoment(currentDate)) {
			currentDate = currentDate.toDate();
		}

		const addDays = function (days) {
			const date = new Date(this.valueOf());
			date.setDate(date.getDate() + days);
			return date;
		};

		while (currentDate <= endDate) {
			dates.push(currentDate);
			currentDate = addDays.call(currentDate, 1);
		}

		return dates;
	}

	public extractMomentEvents(events) {
		const onlyEvents: CalEvent[] = events.filter((event: CalEvent) => {
			return event.rendering !== 'background';
		});

		const splittedEvents = [];

		for (const calEvent of onlyEvents) {
			const splittedDates = this.splitDates(calEvent.start, calEvent.end);
			for (const date of splittedDates) {
				splittedEvents.push(date);
			}
		}

		return splittedEvents.map((date) => {
			return moment(date).utc(true);
		});
	}

	public extractBlockedMomentDates(events: CalEvent[]): moment.Moment[] {
		const blockedDayEvents = events.filter((event: CalEvent) => {
			return event.rendering === 'background';
		});

		return blockedDayEvents.map((event: CalEvent) => {
			return moment(event.start).utc(true);
		});
	}

	public setupDateClassFunction(fullDayEvents, showFullDayEvents, standardEvents?, showStandardEvents?) {
		return (momentDate: moment.Moment) => {
			let classes = '';

			let isBlockedDate = false;
			let isEventDate = false;

			if (fullDayEvents && showFullDayEvents) {
				isBlockedDate = fullDayEvents.some((blockedMoment: moment.Moment) => {
					return momentDate.isSame(blockedMoment, 'day');
				});
			}

			if (standardEvents && showStandardEvents) {
				isEventDate = standardEvents.some((eventDate: moment.Moment) => {
					return momentDate.isSame(eventDate, 'day');
				});
			}

			if (isEventDate) {
				classes = `${classes} standard-event-class`;
			}

			if (isBlockedDate) {
				classes = `${classes} full-day-event-class`;
			}

			if (classes.length > 0) {
				return classes;
			} else {
				return undefined;
			}
		};
	}

	public setupDateFilterFunction(fullDayEvents, showFullDayEvents, standardEvents?, showStandardEvents?) {
		return (momentDate: moment.Moment) => {
			const booleans = [];

			if (fullDayEvents && showFullDayEvents) {
				booleans.push(fullDayEvents.some((blockedMoment: moment.Moment) => {
					return momentDate.isSame(blockedMoment, 'day');
				}));
			}

			if (standardEvents && showStandardEvents) {
				booleans.push(standardEvents.some((eventDate: moment.Moment) => {
					return momentDate.isSame(eventDate, 'day');
				}));
			}

			return !booleans.every(boolean => boolean === true);
		};
	}

	public mapVendorAppointments(events) {
		events = events.map((event) => {
			return {
				start: new Date(event.start),
				end: new Date(event.end),
				title: event.title,
				couple: event.couple,
				rendering: event.rendering,
				color: event.waitingForConfirmation ? '#ff9800' : (event.color || '#3a546f'),
				textColor: event.waitingForConfirmation ? '#000' : '#fff',
				allDay: event.allDay,
				alarm: event.alarm,
				favId: event.favId,
				comments: event.comments,
				geo: event.geo,
				location: event.location,
				_id: event._id
			};
		});

		function uniqBy(a, key) {
			const seen = {};
			return a.filter(function (item) {
				const k = item[key];
				return seen.hasOwnProperty(k) ? false : (seen[k] = true);
			});
		}

		return uniqBy(events, '_id');
	}

}
