import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DebounceClickDirective } from '../_directives/debounce-click.directive';
import { CustomCurrencyPipe } from './custom-currency.pipe';
import { CutDescriptionPipe } from './cut-description.pipe';
import { DebouncePipe } from './debounce.pipe';
import { FilterCouplesPipe } from './filter-couples.pipe';
import { GetCategoryIconPipe } from './get-category-icon.pipe';
import { GetPositionPipe } from './get-position.pipe';
import { LongRecentDatePipe } from './inquiry-recent-date.pipe';
import { NaturalTypePipe } from './natural-type.pipe';
import { NumberAsArrayPipe } from './number-as-array.pipe';
import { PricePerPersonPipe } from './price-per-person.pipe';
import { PunctuatePipe } from './punctuate.pipe';
import { RecentDatePipe } from './recent-date.pipe';
import { StatusKeyPipe } from './status-key.pipe';
import { StatusPipe } from './status.pipe';
import { TrackInputPipe } from './track-input.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		CutDescriptionPipe,
		DebouncePipe,
		GetPositionPipe,
		NaturalTypePipe,
		TrackInputPipe,
		TruncatePipe,
		StatusPipe,
		StatusKeyPipe,
		PunctuatePipe,
		StatusKeyPipe,
		RecentDatePipe,
		DebounceClickDirective,
		NumberAsArrayPipe,
		GetCategoryIconPipe,
		LongRecentDatePipe,
		FilterCouplesPipe,
		CustomCurrencyPipe,
		PricePerPersonPipe
	],
	exports: [
		CutDescriptionPipe,
		DebouncePipe,
		GetPositionPipe,
		NaturalTypePipe,
		TrackInputPipe,
		TruncatePipe,
		StatusPipe,
		StatusKeyPipe,
		PunctuatePipe,
		StatusKeyPipe,
		RecentDatePipe,
		DebounceClickDirective,
		NumberAsArrayPipe,
		GetCategoryIconPipe,
		LongRecentDatePipe,
		FilterCouplesPipe,
		CustomCurrencyPipe,
		PricePerPersonPipe
	]
})
export class CustomPipesModule {
}
