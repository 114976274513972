import { ShopWarning } from '../_interfaces/shop-issue.interface';

export class ShopWarningInstance implements ShopWarning {
	public _id: string;
	public fragment?: string;
	public message: string;
	public type: 'warning';

	constructor(_id: string, message: string, fragment?: string) {
		this._id = _id;
		this.fragment = fragment;
		this.message = message;
	}
}
