import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
	selector: 'app-request-accept',
	templateUrl: './request-accept.component.html',
	styleUrls: ['./request-accept.component.sass']
})
export class RequestAcceptComponent implements OnInit, OnDestroy {

	public answer: boolean;
	public alreadyMarked: boolean;
	public notFound: boolean;

	public imageUrls: string[] = [
		'/assets/img/thankyou1.jpg',
		'/assets/img/thankyou2.jpg',
		'/assets/img/thankyou3.jpg',
		'/assets/img/thankyou4.jpg'
	];

	public imageUrl: string;

	constructor(private router: Router,
				private activatedRoute: ActivatedRoute) {
		this.imageUrl = this.imageUrls[Math.floor(Math.random() * this.imageUrls.length)];
	}

	public getRandom() {
		return `url(${this.imageUrl}) no-repeat center center fixed`;
	}

	public ngOnInit() {

		this.activatedRoute.queryParams.subscribe((params: Params) => {
			const ans = params['ans'];
			if (ans === 'true') {
				this.answer = true;
			} else if (ans === 'alreadyMarked') {
				this.alreadyMarked = true;
			} else if (ans === 'notFound') {
				this.notFound = true;
			}
		});
	}

	public ngOnDestroy() {
	}

}
