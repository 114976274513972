<!--Header-->
<h3>
    {{ 'DIALOGS.REVIEW.HEADER' | translate }}
</h3>

<!--Form-->
<form [formGroup]="form">

    <div class="row">
        <div class="col-md-8">
            <!--Author-->
            <mat-form-field class="weddy-form-field" floatLabel="always">
                <input [formControl]="form.controls['author']" matInput
                       [placeholder]="'PLACEHOLDERS.REVIEW_AUTHOR' | translate" tabindex="10">
                <button (click)="form.controls['author'].reset()" *ngIf="form.controls['author'].value"
                        aria-label="Clear" mat-button mat-icon-button
                        matSuffix tabindex="-1" type="button">
                    <mat-icon [svgIcon]="'close'"></mat-icon>
                </button>
                <mat-hint align="end">
                    {{ form.controls['author'].value?.length || 0  }} / {{ authorMaxLength }}
                </mat-hint>
                <mat-error *ngIf="form.controls['author'].hasError('required')">
                    <span [innerHTML]="'INPUT_ERRORS.REVIEW_AUTHOR.REQUIRED' | translate"></span>
                </mat-error>
                <mat-error *ngIf="form.controls['author'].hasError('minlength')">
                    <span [innerHTML]="'INPUT_ERRORS.REVIEW_AUTHOR.TOO_SHORT' | translate:{number:authorMinLength}"></span>
                </mat-error>
                <mat-error *ngIf="form.controls['author'].hasError('maxlength')">
                    <span [innerHTML]="'INPUT_ERRORS.REVIEW_AUTHOR.TOO_LONG' | translate:{number:authorMaxLength}"></span>
                </mat-error>
            </mat-form-field>

        </div>

        <div class="col-md-4">
            <span class="rating">
                {{ 'PLACEHOLDERS.CLIENT_RATING' | translate }}
            </span>
            <star-rating (starClickChange)="onRating($event)" [hoverEnabled]="true"
                         [rating]="form.controls['rating'].value"></star-rating>
        </div>

    </div>

    <div class="row">

        <div class="col-md-12">

            <!--Quote-->
            <mat-form-field class="weddy-form-field" floatLabel="always">
                <textarea cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"
                          [formControl]="form.controls['text']" matInput
                          [placeholder]="'PLACEHOLDERS.REVIEW_QUOTE' | translate" tabindex="11"></textarea>
                <button (click)="form.controls['text'].reset()" *ngIf="form.controls['text'].value" aria-label="Clear"
                        mat-button mat-icon-button
                        matSuffix tabindex="-1" type="button">
                    <mat-icon [svgIcon]="'close'"></mat-icon>
                </button>
                <mat-hint align="end">
                    {{ form.controls['text'].value?.length || 0  }} / {{ quoteMaxLength }}
                </mat-hint>
                <mat-error *ngIf="form.controls['text'].hasError('required')">
                    <span [innerHTML]="'INPUT_ERRORS.REVIEW_QUOTE.REQUIRED' | translate"></span>
                </mat-error>
                <mat-error *ngIf="form.controls['text'].hasError('minlength')">
                    <span [innerHTML]="'INPUT_ERRORS.REVIEW_QUOTE.TOO_SHORT' | translate:{number:quoteMinLength}"></span>
                </mat-error>
                <mat-error *ngIf="form.controls['text'].hasError('maxlength')">
                    <span [innerHTML]="'INPUT_ERRORS.REVIEW_QUOTE.TOO_LONG' | translate:{number:quoteMaxLength}"></span>
                </mat-error>
            </mat-form-field>

        </div>

    </div>

    <div class="row">

        <div class="col-md-12">

            <!--Source link-->
            <mat-form-field class="weddy-form-field" floatLabel="always">
                <input [formControl]="form.controls['url']" matInput
                       [placeholder]="'PLACEHOLDERS.REVIEW_SOURCE' | translate" tabindex="12">
                <button (click)="form.controls['url'].reset()" *ngIf="form.controls['url'].value" aria-label="Clear"
                        mat-button mat-icon-button
                        matSuffix tabindex="-1" type="button">
                    <mat-icon [svgIcon]="'close'"></mat-icon>
                </button>
                <mat-hint align="end">
                    {{ form.controls['url'].value?.length || 0  }} / {{ sourceMaxLength }}
                </mat-hint>
                <mat-error *ngIf="form.controls['url'].hasError('required')">
                    <span [innerHTML]="'INPUT_ERRORS.REVIEW_SOURCE.REQUIRED' | translate"></span>
                </mat-error>
                <mat-error *ngIf="form.controls['url'].hasError('minlength')">
                    <span [innerHTML]="'INPUT_ERRORS.REVIEW_SOURCE.TOO_SHORT' | translate:{number:sourceMinLength}"></span>
                </mat-error>
                <mat-error *ngIf="form.controls['url'].hasError('maxlength')">
                    <span [innerHTML]="'INPUT_ERRORS.REVIEW_SOURCE.TOO_LONG' | translate:{number:sourceMaxLength}"></span>
                </mat-error>
            </mat-form-field>

        </div>

    </div>

    <!--Error-->
    <mat-error *ngIf="form.hasError('somethingWentWrong')" class="general-errors">
        <span [innerHTML]="'INPUT_ERRORS.SOMETHING_WENT_WRONG' | translate"></span>
    </mat-error>

    <mat-error *ngIf="form.controls['rating'].touched && form.controls['rating'].hasError('minlength')"
               class="general-errors">
        <span [innerHTML]="'INPUT_ERRORS.MISSING_RATING' | translate"></span>
    </mat-error>

    <!--Buttons-->
    <div class="buttons">
        <button (click)="onCancel()" mat-button>
            {{ 'BUTTONS.CANCEL' | translate }}
        </button>
        <button (click)="onSave()" color="primary" mat-button mat-raised-button>
            {{ 'BUTTONS.ADD' | translate }}
        </button>
    </div>

</form>

