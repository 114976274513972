import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { TranslateModule } from '@ngx-translate/core';
import { StarRatingModule } from 'angular-star-rating';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BarRatingModule } from 'ngx-bar-rating';
import { FileDropModule } from 'ngx-file-drop';
import { MomentModule } from 'ngx-moment';
import { AutoAnswerSettingsComponent } from '../../dashboard/settings/auto-answer-settings/auto-answer-settings.component';
import { PaymentSettingsComponent } from '../../dashboard/settings/payment-settings/payment-settings.component';
import { MaterialModule } from '../_material/material.module';
import { CustomPipesModule } from '../_pipes/custom-pipes.module';
import { ButtonsModule } from '../modules/buttons/buttons.module';
import { SearchPartnerResultComponent } from '../modules/dialogs/partner-dialog/search-partner-result/search-partner-result.component';
import { SuggestSearchPartnerResultComponent } from '../modules/dialogs/suggest-partner-dialog/suggest-search-partner-result/suggest-search-partner-result.component';
import { AppFormsModule } from '../modules/forms/app-forms.module';
import { AddressMapComponent } from './address-map/address-map.component';
import { AvatarComponent } from './avatar/avatar.component';
import { BannerComponent } from './banner/banner.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FormTipComponent } from './form-tip/form-tip.component';
import { ProjectSavedSnackBarComponent } from './project-saved-snack-bar/project-saved-snack-bar.component';
import { SectionHeaderComponent } from './section-header/section-header.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { StatusLabelComponent } from './status-label/status-label.component';
import { TextFooterComponent } from './text-footer/text-footer.component';

@NgModule({
	imports: [
		CommonModule,
		MaterialModule,
		FormsModule,
		RouterModule,
		LazyLoadImageModule,
		ReactiveFormsModule,
		FileDropModule,
		StarRatingModule,
		TranslateModule.forChild(),
		AgmCoreModule,
		MomentModule,
		GalleryModule.forRoot({ shortcuts: ['ctrl+s', 'meta+s'], disableSsrWorkaround: true }),
		CustomPipesModule,
		BarRatingModule,
		AppFormsModule,
		ButtonsModule
	],
	declarations: [
		SectionHeaderComponent,
		FileUploadComponent,
		AddressMapComponent,
		ProjectSavedSnackBarComponent,
		SearchPartnerResultComponent,
		SuggestSearchPartnerResultComponent,
		TextFooterComponent,
		StarRatingComponent,
		AvatarComponent,
		BreadcrumbsComponent,
		StatusLabelComponent,
		BannerComponent,
		FormTipComponent,
		PaymentSettingsComponent,
		AutoAnswerSettingsComponent
	],
	exports: [
		SectionHeaderComponent,
		FileUploadComponent,
		AddressMapComponent,
		ProjectSavedSnackBarComponent,
		SearchPartnerResultComponent,
		SuggestSearchPartnerResultComponent,
		TextFooterComponent,
		StarRatingComponent,
		AvatarComponent,
		BreadcrumbsComponent,
		StatusLabelComponent,
		BannerComponent,
		FormTipComponent,
		PaymentSettingsComponent,
		AutoAnswerSettingsComponent
	],
})
export class SharedComponentsModule {
}
