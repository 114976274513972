import { Pipe, PipeTransform } from '@angular/core';
import { Vendor } from '../_interfaces/vendor.interface';

@Pipe({
	name: 'status'
})
export class StatusPipe implements PipeTransform {

	public transform(vendors: Vendor[], desiredStatusIdArray: string[]): Vendor[] {

		if (!vendors || !desiredStatusIdArray || desiredStatusIdArray.length === 0) {
			return vendors;
		}

		return vendors.filter(
			anyVendor => {
				let currentStatusId;

				if (anyVendor.fav && anyVendor.fav.status.status.id) {
					currentStatusId = anyVendor.fav.status.status.id;
				} else if (anyVendor.status) {
					currentStatusId = anyVendor.status;
				} else {
					return true;
				}

				return desiredStatusIdArray.indexOf(currentStatusId) >= 0;
			}
		);

	}

}
