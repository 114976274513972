import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Inquiry } from '../../../_interfaces/inquiry.interface';

@Component({
	selector: 'app-demand-dialog',
	templateUrl: './demand-dialog.component.html',
	styleUrls: ['./demand-dialog.component.sass']
})
export class DemandDialogComponent implements OnInit {

	constructor(public dialogRef: MatDialogRef<DemandDialogComponent>,
	            @Inject(MAT_DIALOG_DATA) public inquiry: Inquiry) {
	}

	public ngOnInit() {
	}

}
