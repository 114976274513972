<div style="display: flex; justify-content: flex-end;">
    <button [mat-dialog-close]="false" mat-icon-button>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="text-center">
    <h1>Automatische Terminannahme</h1>
    <h2 style="font-size: 15px!important">Sollen Terminevorschläge die innerhalb deiner Öffnungszeiten liegen, <br/>automatisch
        für dich angenommen werden?</h2>
    <mat-slide-toggle (change)="changeAutoAppointments()" [checked]="autoAppointments"
                      class="example-margin">{{ autoAppointments ? 'Aktiv' : 'Inaktiv'}}</mat-slide-toggle>

    <!-- <mat-accordion> -->
    <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Meine Öffnungszeiten
            </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
            <div class="periods-wrapper">
                <div *ngFor="let day of table" class="day-wrapper">
                    <span class="day-title">{{day.title}}</span>
                    <mat-slide-toggle (change)="changeOpeningStatus(day)" [checked]="day.open"
                                      class="open-toggle"> {{day.open ? 'Geöffnet' : 'Geschlossen'}}
                    </mat-slide-toggle>
                    <ng-container *ngIf="day.open">
                        <div class="day-times-wrapper">
                            <div *ngFor="let times of day.times; let i = index" class="time-wrapper">
                                <input [(ngModel)]="times.timeFrom" class="time-input" matInput type="time">
                                <span> - </span>
                                <input [(ngModel)]="times.timeTo" class="time-input" matInput type="time">
                                <button (click)="removeTime(day, i)" *ngIf="i > 0" color="default" mat-icon-button>
                                    <mat-icon>close</mat-icon>
                                </button>
                                <button (click)="addTime(day)" *ngIf="i == day.times.length - 1" color="default"
                                        mat-button>Zeit hinzufügen
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-template>
    </mat-expansion-panel>
    <!-- </mat-accordion> -->
</mat-dialog-content>


<mat-dialog-actions style="display:flex;justify-content: flex-end;margin-bottom: 0px;padding-top:25px">
    <button (click)="submit()" color="accent" mat-button mat-raised-button>{{ 'Speichern' }}</button>
</mat-dialog-actions>
