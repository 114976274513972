import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class StatisticsService {

	get statsData() {
		return this._statsData;
	}

	set statsData(statsData) {
		this._statsData = statsData;
		this.statsDataChanged.next(this.statsData);
	}

	public static WEEK_DAYS = {
		SUNDAY: 0,
		MONDAY: 1,
		TUESDAY: 2,
		WEDNESDAY: 3,
		THURSDAY: 4,
		FRIDAY: 5,
		SATURDAY: 6
	};

	public NUMBER_OF_DAYS = {
		ONE_YEAR: 365,
		HALF_YEAR: 182,
		THREE_MONTHS: 84,
		ONE_MONTH: 30,
		ONE_WEEK: 7,
		ONE_DAY: 1
	};

	public TIME_RANGES = {
		HOUR: 'hour',
		DAY: 'day',
		WEEK: 'week',
		MONTH: 'month',
		YEAR: 'year'
	};

	public today: Date = new Date(Date.now());
	public statsDataChanged: Subject<any> = new Subject<any>();
	public colors = [
		{
			backgroundColor: '#344d67'
			// [
			//   '#182940',
			//   '#253a52',
			//   '#2c435c',
			//   '#344d67',
			//   '#3a546f',
			//   '#586e85',
			//   '#75879a',
			//   '#9daab7',
			//   '#c4ccd4',
			//   '#e7eaee',
			// ]
		},
		{
			backgroundColor: '#586e85'
		},
		{
			backgroundColor: '#9daab7'
		}
	];

	private _statsData: {
		visits: { x: Date, y: number }[],
		messages: { x: Date, y: number }[],
		offers: { x: Date, y: number }[]
	};

	constructor() {

		const visits = [
			{x: StatisticsService.randomDate(new Date(2017, 4, 1), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2017, 4, 1), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2017, 4, 1), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2017, 4, 1), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2017, 4, 1), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2017, 4, 1), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2017, 4, 1), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2017, 4, 1), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2017, 4, 1), this.today), y: 1}
		];
		const messages = [
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
		];
		const offers = [
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
			{x: StatisticsService.randomDate(new Date(2018, 4, 8), this.today), y: 1},
		];

		this.statsData = {
			visits: StatisticsService.sortData(visits),
			messages: StatisticsService.sortData(messages),
			offers: StatisticsService.sortData(offers)
		};
	}

	public static filterData(data: { x: Date, y: number }[], numberOfDays: number): { x: Date, y: number }[] {
		if (data) {
			const today = new Date(Date.now());
			today.setHours(today.getHours(), 0, 0, 0);
			let result = data;
			result = result.filter(
				(day) => {
					const newDate = new Date(new Date().setDate(today.getDate() - numberOfDays));
					newDate.setHours(today.getHours(), 0, 0, 0);
					return newDate.getTime() < day.x.getTime();
				}
			);
			return result;
		} else {
			console.error('StatisticsService: Cannot filter undefined data');
			return data;
		}
	}

	public static sortData(data: { x: Date, y: number }[]): { x: Date, y: number }[] {
		return data.sort((a, b) => {
			return +a.x - +b.x;
		});
	}

	public static reduceData(data: { x: Date, y: number }[], range: string): { x: Date, y: number }[] | void {
		switch (range) {
			case 'month':
				return StatisticsService.reduceByMonth(data);
			case 'week':
				return StatisticsService.reduceByWeek(data);
			case 'day':
				return StatisticsService.reduceByDay(data);
			case 'hour':
				return StatisticsService.reduceByHour(data);
			default:
				return data;
		}
	}

	public static reduceByMonth(data: { x: Date, y: number }[]): { x: Date, y: number }[] | void {
		if (data) {
			const result = data.reduce((total: any[], current: { x: Date, y: number }, index: number) => {
				const month: { x: Date, y: number } = {x: null, y: 0};
				if (index !== 0 && (total && total[total.length - 1] && total[total.length - 1].x && StatisticsService.monthsMatch(total[total.length - 1].x, current.x))) {
					total[total.length - 1].y = total[total.length - 1].y + current.y;
					return total;
				} else {
					month.x = new Date(current.x.getFullYear(), current.x.getMonth());
					month.y = month.y + current.y;
					total.push(month);
					return total;
				}
			}, []);
			return result;
		} else {
			console.error('StatisticsService: Cannot reduce undefined data by month');
			return data;
		}
	}

	public static reduceByWeek(data: { x: Date, y: number }[]): { x: Date, y: number }[] | void {
		if (data) {
			const result = data.reduce((total: any[], current: { x: Date, y: number }, index: number) => {
				const week: { x: Date, y: number } = {x: null, y: 0};
				if (index !== 0 && (total && total[total.length - 1] && total[total.length - 1].x && StatisticsService.weeksMatch(total[total.length - 1].x, current.x, StatisticsService.WEEK_DAYS.MONDAY))) {
					total[total.length - 1].y = total[total.length - 1].y + current.y;
					return total;
				} else {
					// week.x = new Date(current.x.getFullYear(), current.x.getMonth(), current.x.getDate());
					week.x = StatisticsService.getClosestStartingWeekday(current.x, StatisticsService.WEEK_DAYS.MONDAY);
					week.y = week.y + current.y;
					total.push(week);
					return total;
				}
			}, []);
			return result;
		} else {
			console.error('StatisticsService: Cannot reduce undefined data by week');
			return data;
		}
	}

	public static reduceByDay(data: { x: Date, y: number }[]): { x: Date, y: number }[] | void {
		if (data) {
			const result = data.reduce((total: any[], current: { x: Date, y: number }, index: number) => {
				const day: { x: Date, y: number } = {x: null, y: 0};
				if (index !== 0 && (total && total[total.length - 1] && total[total.length - 1].x && StatisticsService.daysMatch(total[total.length - 1].x, current.x))) {
					total[total.length - 1].y = total[total.length - 1].y + current.y;
					return total;
				} else {
					day.x = new Date(current.x.getFullYear(), current.x.getMonth(), current.x.getDate());
					day.y = day.y + current.y;
					total.push(day);
					return total;
				}
			}, []);
			return result;
		} else {
			console.error('StatisticsService: Cannot reduce undefined data by day');
			return data;
		}
	}

	public static reduceByHour(data: { x: Date, y: number }[]): { x: Date, y: number }[] | void {
		if (data) {
			const result = data.reduce((total: any[], current: { x: Date, y: number }, index: number) => {
				const hour: { x: Date, y: number } = {x: null, y: 0};
				if (index !== 0 && (total && total[total.length - 1] && total[total.length - 1].x && StatisticsService.hoursMatch(total[total.length - 1].x, current.x))) {
					total[total.length - 1].y = total[total.length - 1].y + current.y;
					return total;
				} else {
					hour.x = new Date(current.x.getFullYear(), current.x.getMonth(), current.x.getDate(), current.x.getHours());
					hour.y = hour.y + current.y;
					total.push(hour);
					return total;
				}
			}, []);
			return result;
		} else {
			console.error('StatisticsService: Cannot reduce undefined data by hour');
			return data;
		}
	}

	public static monthsMatch(dateA: Date, dateB: Date): boolean {
		if (dateA.getFullYear() === dateB.getFullYear()) {
			return dateA.getMonth() === dateB.getMonth();
		} else {
			return false;
		}
	}

	public static weeksMatch(dateA: Date, dateB: Date, startingWeekDay: number): boolean {
		const weekA = StatisticsService.getClosestStartingWeekday(dateA, startingWeekDay);
		const weekB = StatisticsService.getClosestStartingWeekday(dateB, startingWeekDay);

		return StatisticsService.daysMatch(weekA, weekB);
	}

	public static getClosestStartingWeekday(date: Date, startingWeekDay: number): Date {
		const day = date.getDay();
		const week = new Date(date.getTime());

		if (day !== startingWeekDay) { // it's not the starting day
			week.setDate(date.getDate() - (day - startingWeekDay)); // find starting day in that week
		}

		if (day === 0 && startingWeekDay !== 0) { // it's sunday
			week.setDate(date.getDate() - 6); // find starting day in that week
		}

		week.setHours(0, 0, 0, 0);

		return week;
	}

	public static daysMatch(dateA: Date, dateB: Date): boolean {
		if (dateA.getFullYear() === dateB.getFullYear()) {
			if (dateA.getMonth() === dateB.getMonth()) {
				return dateA.getDate() === dateB.getDate();
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	public static hoursMatch(dateA: Date, dateB: Date): boolean {
		if (dateA.getFullYear() === dateB.getFullYear()) {
			if (dateA.getMonth() === dateB.getMonth()) {
				if (dateA.getDate() === dateB.getDate()) {
					return (dateA.getHours() === dateB.getHours());
				} else {
					return false;
				}
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	public static randomDate(start, end) {
		return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
	}

}
