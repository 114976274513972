import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MaterialModule } from '../../_material/material.module';
import { FooterComponent } from './footer.component';

@NgModule({
	declarations: [
		FooterComponent
	],
	imports: [
		CommonModule,
		MaterialModule,
		LazyLoadImageModule
	],
	exports: [
		FooterComponent
	]
})
export class FooterModule {
}
