import { Inject, Injectable, Optional } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { filter } from 'rxjs/internal/operators';

interface HistoryEntry {
	route: string;
	timestamp: Date;
}

@Injectable({
	providedIn: 'root'
})
export class HistoryService {

	private history: HistoryEntry[] = [];

	constructor(private router: Router,
				@Optional() @Inject(REQUEST) private request) {
	}

	public recordRouting(): void {
		if (this.request) {
			this.history.push({ route: this.request.referrer, timestamp: new Date(Date.now()) });
		}

		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(({ urlAfterRedirects }: NavigationEnd) => {
				this.history = [...this.history, { route: urlAfterRedirects, timestamp: new Date(Date.now()) }];
			});
	}

	public getHistory(): HistoryEntry[] {
		return this.history;
	}

	public getPreviousUrl(): string {
		return this.history[this.history.length - 2].route || '/index';
	}

}
