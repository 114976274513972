import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccountData } from '../_interfaces';
import { LOCAL_STORAGE } from '../_others/tokens';
import { ApiService } from './api.service';
import { LocalStorageService } from './local-storage.service';
import { ShopsService } from './shops.service';
import { TokenService } from './token.service';

@Injectable({
	providedIn: 'root'
})
export class AccountDataService {

	get accountData(): AccountData {
		return this._accountData;
	}

	set accountData(accountData: AccountData) {
		if (accountData) {
			accountData.isTest = AccountDataService.isTestEmail(accountData.email);
			accountData.tzOffset = this.setTimeZoneOffset(accountData.tzOffset);
			this._accountData = accountData;
			this.localStorageService.setItem(LOCAL_STORAGE.ACCOUNT_DATA, this.accountData);
			this.accountDataChanged.next(this.accountData);
		} else {
			this.localStorageService.removeItem(LOCAL_STORAGE.CURRENT_USER);
			this.localStorageService.removeItem(LOCAL_STORAGE.ACCOUNT_DATA);
		}
	}

	public accountDataChanged = new BehaviorSubject<AccountData>(this.accountData);
	private _accountData: AccountData;

	constructor(public api: ApiService,
				private tokenService: TokenService,
				private shopsService: ShopsService,
				private localStorageService: LocalStorageService) {
	}

	private static isTestEmail(email) {
		return email.indexOf('@weddyplace.') > -1 || email.indexOf('@vanolia.') > -1;
	}

	public getAccountData(): Observable<any> {
		const req = this.api.get(`account`, null, false, true);

		req.subscribe(
			(accountData: AccountData) => {
				this.accountData = accountData;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public updateAccountData(account: AccountData): Observable<any> {
		const req = this.api.put('account', account, true);

		req.subscribe(
			(acc) => {
				this.accountData = acc;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public recoverPassword(email): Observable<any> {
		return this.api.post('forgot', email);
	}

	public resetPassword(token, password, passwordConfirm): Observable<any> {
		return this.api.post(`reset/${token}`, {
			password: password,
			confirm: passwordConfirm
		});
	}

	public setNewPassword(password: string, passwordRepeat: string): Observable<any> {
		const req = this.api.post(`setNewPassword`, {password: password, confirm: passwordRepeat});

		req.subscribe(
			() => {
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public deleteAccount() {
		const req = this.api.post('deleteShop', {vendorId: this.shopsService.currentShop._id}, true);

		req.subscribe(
			() => {
				this.kickThemOut();
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public deactivateAccount() {
		const req = this.api.post('deleteShop', {vendorId: this.shopsService.currentShop._id}, true);

		req.subscribe(
			() => {
				this.kickThemOut();
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	private kickThemOut() {
		this.localStorageService.clear();
		this.tokenService.removeToken();
		window.location.replace(`${window.location.origin}/welcome`);
	}

	private setTimeZoneOffset(offset: number) {
		const currentOffset = new Date().getTimezoneOffset();

		if (offset !== currentOffset) {
			offset = currentOffset;
			this.api.put('setTZOffset', {offset}, true);
		}

		return offset;
	}

}
