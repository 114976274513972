import { Pipe, PipeTransform } from '@angular/core';
import { Couple } from '../_interfaces/couple.interface';

@Pipe({
	name: 'filterCouples'
})
export class FilterCouplesPipe implements PipeTransform {

	public transform(value: any, args: string): any {
		if (!args) {
			return value;
		} else {
			return value.filter((couple: Couple) => {
				return couple.accountData.firstName.toLowerCase().indexOf(args.toLowerCase()) > -1;
			});
		}
	}
}
