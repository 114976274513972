import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { share } from 'rxjs/operators';
import { Video } from '../_interfaces/video.interface';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class VimeoService {

	private bearerKey: string = 'cc91b3e6ace2ab8e4bbc0a95068a5fca';

	constructor(private http: HttpClient,
				private apiService: ApiService) {
	}

	public generateBearerHeaders() {
		return new HttpHeaders({
			'Authorization': `bearer ${this.bearerKey}`,
			'Accept': 'application/vnd.vimeo.*+json;version=3.4',
			'Content-Type': 'application/json'
		});
	}

	public checkIfThereIsEnoughSpace(fileSize) {
		const headers = this.generateBearerHeaders();

		return new Promise((resolve, reject) => {
			this.http.get('https://api.vimeo.com/me?fields=upload_quota', {headers: headers}).toPromise().then(
				(res) => {
					if (res && res['upload_quota'] && res['upload_quota']['space'] && res['upload_quota']['space']['free'] && res['upload_quota']['space']['free'] > fileSize) {
						resolve(res);
					} else {
						reject(res);
					}
				}
			).catch(
				(err) => {
					reject(err);
				}
			)
		});
	}

	public createVideo(fileSize, metadata) {
		const headers = this.generateBearerHeaders();

		const body = {
			'upload': {
				'approach': 'tus',
				'size': fileSize
			},
			'name': metadata.title,
			'description': metadata.description,
		};

		return new Promise((resolve, reject) => {
			this.http.post('https://api.vimeo.com/me/videos', body, {headers: headers}).toPromise().then(
				(res) => {
					resolve(res);
				}, (err) => {
					reject(err);
				}
			);
		});
	}

	public upload(uploadLink: string, file, remoteId: string, uploadOffset: string) {
		if (!uploadOffset) {
			uploadOffset = '0';
		}

		const headers: HttpHeaders = new HttpHeaders({
			'Tus-Resumable': '1.0.0',
			'Upload-Offset': `${uploadOffset}`,
			'Content-Type': 'application/offset+octet-stream'
		});

		const start = parseInt(uploadOffset, 10);
		const end = file.size;

		const httpRequest = new HttpRequest('PATCH', uploadLink, file.slice(start, end), {
			headers: headers,
			reportProgress: true
		});

		const req = this.http.request(httpRequest).pipe(share());

		req.subscribe(
			(event) => {
				switch (event.type) {
					case HttpEventType.Sent:
						break;
					case HttpEventType.UploadProgress:
						break;
					case HttpEventType.Response:
						// this.monitorUpload(uploadLink).subscribe(
						//   (monitoringResponse) => {
						//     if (monitoringResponse.status === 200) {
						//       if (monitoringResponse.headers.get('upload-length') === monitoringResponse.headers.get('upload-offset')) {
						//         console.log('Video upload is complete!');
						//         this.removeVideoFromUploadingVideos(remoteId);
						//       }
						//     }
						//   }
						// );
						break;
					default:
						console.error(`File "${file.name}" surprising upload event: ${event.type}.`);
				}
			}
		);

		return req;
	}

	public assignMultipleTagsToVideo(tags: string[], video: Video) {
		if (tags && tags.length > 0) {
			const headers = this.generateBearerHeaders();

			const body = tags.map((tag) => {
				return {name: tag};
			});

			const req = this.http.put(`https://api.vimeo.com/videos/${video.remoteId.substr(video.remoteId.lastIndexOf('/') + 1)}/tags`, body, {headers: headers});

			req.subscribe(
				(res) => {
				},
				(err) => {
					console.error(err);
				}
			);

			return req;
		}
	}

	public deleteRemoteVideo(video) {
		const headers = this.generateBearerHeaders();

		const req = this.http.delete(`https://api.vimeo.com/videos/${video.remoteId.substr(video.remoteId.lastIndexOf('/') + 1)}`, {headers: headers}).pipe(share());

		req.subscribe();

		return req;
	}

	public getRemoteVideo(remoteId) {
		const headers = this.generateBearerHeaders();

		const req = this.http.get(`https://api.vimeo.com/videos/${remoteId}`, {headers: headers}).pipe(share());

		req.subscribe();

		return req;
	}

	public monitorUpload(uploadLink) {
		let headers: HttpHeaders = new HttpHeaders({});
		headers = headers.append('Tus-Resumable', '1.0.0');

		const req = this.http.head(uploadLink, {headers: headers, observe: 'response'}).pipe(share());

		req.subscribe();

		return req;
	}

	public editVideoMetadata(metadata: Video) {
		const headers = this.generateBearerHeaders();

		const body = {
			'name': metadata.title,
			'description': metadata.description,
			'category': 'Photography'
		};

		const req = this.http.patch(`https://api.vimeo.com/videos/${metadata.remoteId.substr(metadata.remoteId.lastIndexOf('/') + 1)}`, body, {headers: headers}).pipe(share());

		req.subscribe();

		return req;
	}

	public getVideoThumbnails(remoteId) {
		const headers = this.generateBearerHeaders();

		const req = this.http.get(`https://api.vimeo.com/videos/${remoteId.substr(remoteId.lastIndexOf('/') + 1)}/pictures`, {headers: headers}).pipe(share());

		req.subscribe();

		return req;
	}

	public postVideoThumbnail(remoteId) {
		const headers = this.generateBearerHeaders();

		const req = this.http.post(`https://api.vimeo.com/videos/${remoteId.substr(remoteId.lastIndexOf('/') + 1)}/pictures`, {headers: headers}).pipe(share());

		req.subscribe();

		return req;
	}

	//// UPLOAD PROCESS

	// initiateVideoUpload(file, metadata) {
	//   return new Promise((resolve, reject) => {
	//     this.checkIfThereIsEnoughSpace(file.size).then(
	//       () => {
	//         this.createVideo(file.size, metadata).then(
	//           (vimeoResponse) => {
	//             const uploadLink = vimeoResponse['upload']['upload_link'];
	//             const uri: string = vimeoResponse['uri'];
	//             const remoteId: string = uri.substr(uri.lastIndexOf('/') + 1);
	//             resolve(vimeoResponse);
	//             this.uploadingVideos.push(remoteId);
	//             this.uploadingVideosChanged.next(this.uploadingVideos);
	//             this.startUpload(uploadLink, file, remoteId);
	//           }, (err) => {
	//             reject(err);
	//           }
	//         );
	//       }, (err) => {
	//         reject(err);
	//       }
	//     );
	//   });
	// }



	addVideoToThumbnailQueue(video){
		const req = this.apiService.post("pro_addVideoToThumbnailQueue", video);
		req.subscribe();
		return req;
	}

	removeVideoFromThumbnailQueue(video){
		const req = this.apiService.post("pro_removeVideoFromThumbnailQueue", video);
		req.subscribe();
		return req;
	}
}
