<div class="wrapper">

    <mat-icon [svgIcon]="'locker-88px'" class="big-icon"></mat-icon>

    <h2>Bitte setze Dir jetzt ein Passwort</h2>

    <p>
        Du hast Dir noch kein Passwort gesetzt! Setz Dir bitte ein Passwort um Deinen Account weiterhin sicher zu
        verwenden.
    </p>

    <!--Email-->
    <mat-form-field class="weddy-form-field" floatLabel="always">
        <input [formControl]="form.controls['email']" matInput
               placeholder="{{ 'PLACEHOLDERS.EMAIL' | translate }}">
        <mat-icon [svgIcon]="'envelope'" matSuffix></mat-icon>
        <mat-hint align="end">{{ 'INPUT_HINTS.EMAIL.NOT_EDITABLE' | translate }}</mat-hint>
    </mat-form-field>

    <!--Password-->
    <mat-form-field class="weddy-form-field" floatLabel="always">
        <input #password [formControl]="form.controls['password']" matInput placeholder="{{ 'PLACEHOLDERS.PASSWORD' | translate }}"
               tabindex="1" type="password">
        <mat-icon *ngIf="!form.controls['password'].value" [svgIcon]="'lock-1'"
                  class="suffix-icon" matSuffix>
        </mat-icon>

        <button (click)="password.type === 'text' ? password.type = 'password' : password.type = 'text'" *ngIf="form.controls['password'].value" aria-label="View" mat-button mat-icon-button
                matSuffix tabindex="-1"
                type="button">
            <mat-icon [svgIcon]="password.type === 'password' ? 'view' : 'view-off'" matSuffix>
            </mat-icon>
        </button>

        <mat-error *ngIf="form.controls['password'].hasError('required')">
            <span [innerHTML]="'INPUT_ERRORS.PASSWORD.REQUIRED' | translate"></span>
        </mat-error>
        <mat-error *ngIf="form.controls['password'].hasError('minlength')">
            <span [innerHTML]="'INPUT_ERRORS.PASSWORD.TOO_SHORT' | translate:{number:passwordMinLength}"></span>
        </mat-error>
        <mat-error *ngIf="form.controls['password'].hasError('maxlength')">
            <span [innerHTML]="'INPUT_ERRORS.PASSWORD.TOO_LONG' | translate:{number:passwordMaxLength}"></span>
        </mat-error>
    </mat-form-field>

    <!--Password-->
    <mat-form-field class="weddy-form-field" floatLabel="always">
        <input #passwordRepeat [errorStateMatcher]="matcher" [formControl]="form.controls['passwordRepeat']" matInput
               placeholder="{{ 'PLACEHOLDERS.PASSWORD_REPEAT' | translate }}"
               tabindex="2" type="password">

        <mat-icon *ngIf="!form.controls['passwordRepeat'].value" [svgIcon]="'lock-1'"
                  class="suffix-icon" matSuffix>
        </mat-icon>

        <button (click)="passwordRepeat.type === 'text' ? passwordRepeat.type = 'password' : passwordRepeat.type = 'text'" *ngIf="form.controls['passwordRepeat'].value" aria-label="View" mat-button mat-icon-button
                matSuffix
                tabindex="-1" type="button">
            <mat-icon [svgIcon]="passwordRepeat.type === 'password' ? 'view-1' : 'view-off'" matSuffix>
            </mat-icon>
        </button>

        <mat-error
                *ngIf="form.controls['passwordRepeat'].dirty && form.controls['passwordRepeat'].hasError('required')">
            <span [innerHTML]="'INPUT_ERRORS.PASSWORD.REQUIRED' | translate"></span>
        </mat-error>
        <mat-error
                *ngIf="form.controls['passwordRepeat'].dirty && form.controls['passwordRepeat'].hasError('minlength')">
            <span [innerHTML]="'INPUT_ERRORS.PASSWORD.TOO_SHORT' | translate:{number:passwordMinLength}"></span>
        </mat-error>
        <mat-error
                *ngIf="form.controls['passwordRepeat'].dirty && form.controls['passwordRepeat'].hasError('maxlength')">
            <span [innerHTML]="'INPUT_ERRORS.PASSWORD.TOO_LONG' | translate:{number:passwordMaxLength}"></span>
        </mat-error>
        <mat-error *ngIf="form.controls['passwordRepeat'].dirty && form.hasError('passwordsDoNotMatch')">
            <span [innerHTML]="'INPUT_ERRORS.PASSWORD.NOT_MATCH' | translate"></span>
        </mat-error>
    </mat-form-field>

</div>

<mat-dialog-actions align="end">
    <button (click)="onRemindMeLater()" mat-raised-button>
        {{ 'BUTTONS.REMIND_ME_LATER' | translate }}
    </button>
    <button (click)="onSubmit(form)" color="primary" mat-raised-button>
        {{ 'BUTTONS.SET_PASSWORD' | translate }}
    </button>
</mat-dialog-actions>



