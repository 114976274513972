import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'statusKey',
	pure: true
})
export class StatusKeyPipe implements PipeTransform {

	public transform(value: string, args?: any): any {
		if (!value) {
			return value;
		}

		return value.toUpperCase().replace(/-/g, '_');
	}

}
