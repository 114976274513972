import { isPlatformBrowser } from '@angular/common';
import { Directive, Inject, Injector, Input, NgModuleFactory, NgModuleFactoryLoader, NgModuleRef, OnDestroy, OnInit, PLATFORM_ID, Type, ViewContainerRef } from '@angular/core';
import { LAZY_MODULES_MAP, LazyModules } from '../../../lazy.modules';

type ModuleWithRoot = Type<any> & { rootComponent: Type<any> };

@Directive({
	selector: '[appLoadModule]'
})
export class LoadModuleDirective implements OnInit, OnDestroy {

	@Input('appLoadModule') public lazyModuleName: keyof LazyModules;

	private moduleRef: NgModuleRef<any>;

	constructor(private vcr: ViewContainerRef,
				private injector: Injector,
				private loader: NgModuleFactoryLoader,
				@Inject(LAZY_MODULES_MAP) private lazyModulesMap,
				@Inject(PLATFORM_ID) private platformId: Object) {
	}

	public ngOnInit() {
		if (isPlatformBrowser(this.platformId)) {
			this.loader
				.load(this.lazyModulesMap[this.lazyModuleName])
				.then((moduleFactory: NgModuleFactory<any>) => {
					this.moduleRef = moduleFactory.create(this.injector);

					const rootComponent = (moduleFactory.moduleType as ModuleWithRoot)
						.rootComponent;

					const factory = this.moduleRef.componentFactoryResolver.resolveComponentFactory(
						rootComponent
					);

					this.vcr.createComponent(factory);
				});
		}
	}

	public ngOnDestroy() {
		if (this.moduleRef) {
			this.moduleRef.destroy();
		}
	}
}
