import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { LOCAL_STORAGE } from '../_others/tokens';
import { LocalStorageService } from './local-storage.service';

@Injectable({
	providedIn: 'root'
})
export class TokenService {

	get token(): string {
		return this._token;
	}

	set token(newToken: string) {
		this._token = newToken;
		if (this.token) {
			this.localStorageService.setItem(LOCAL_STORAGE.TOKEN, this.token);
		}
		this.tokenChanged.next(this.token);
	}

	get refreshToken(): string {
		return this._refreshToken;
	}

	set refreshToken(newToken: string) {
		this._refreshToken = newToken;
		if (this.refreshToken) {
			this.localStorageService.setItem(LOCAL_STORAGE.REFRESH_TOKEN, this.refreshToken);
		}
		this.refreshTokenChanged.next(this.refreshToken);
	}

	public tokenChanged: Subject<string> = new Subject<string>();
	public refreshTokenChanged: Subject<string> = new Subject<string>();

	private _token: string;
	private _refreshToken: string;

	constructor(private localStorageService: LocalStorageService,
				@Inject(PLATFORM_ID) private platformId: Object) {
		this.getToken();
	}

	public getToken() {
		let token;
		let refreshToken;

		token = this.localStorageService.getItem(LOCAL_STORAGE.TOKEN);
		refreshToken = this.localStorageService.getItem(LOCAL_STORAGE.REFRESH_TOKEN);

		if (token) {
			try {
				this.token = token;
				this.refreshToken = refreshToken;
			} catch (error) {
				console.error(error);
			}
		}
	}

	public removeToken() {
		this.token = undefined;
		this.refreshToken = undefined;
		this.localStorageService.removeItem(LOCAL_STORAGE.TOKEN);
		this.localStorageService.removeItem(LOCAL_STORAGE.REFRESH_TOKEN);
	}

}
