<div [ngStyle]="{'background': getRandom()}" class="wrapper">
    <div class="container">

        <div class="row text-center">
            <div class="col-md-12">
                <div class="logo-box">
                    <img alt="" src="assets/img/logo.svg">
                    <span class="weddyplace-text">WEDDYPLACE</span>
                </div>
            </div>
        </div>

        <div class="row text-center">
            <div *ngIf="!alreadyMarked && !notFound" class="col-md-4 col-centered"
                 style="background-color: #fff;padding-bottom: 30px;padding-top: 30px;border-radius:5px">

                <span *ngIf="answer"><h1>Schön,<br>dass die Anfrage gepasst hat!</h1></span>
                <h1 *ngIf="!answer">Schade,<br> dass es nicht gepasst hat!</h1>
                <div style="margin-left:auto;margin-right:auto;width: 100px;height: 100px;background-image: url('https://cdn.weddyplace.com/static/home/assets/img/Pauline-400.jpg');background-size: cover; display: block;border-radius: 100px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;">
                </div>
                <p style="margin-top:5px">Pauline Koehler</p>
                <span *ngIf="answer" style="font-size:12px;color:#505050">Ihr erhaltet in Kürze die Kontaktdaten des Brautpaares per Email. Wir freuen uns über Euer Vertrauen und geben unser Bestes Euch so viele Aufträge wie möglich zu bringen. Falls Ihr Verbesserungswünsche oder Feedback habt lasst es uns gerne unter <a
                        href="mailto:pauline@weddyplace.com">pauline@weddyplace.com</a> wissen.</span>

                <span *ngIf="!answer" style="font-size:12px;color:#505050">Wir werden die Gründe für Deine Absage in zukünftigen Anfragen berücksichtigen. Falls Ihr Verbesserungswünsche oder Feedback habt, lasst es uns unter <a
                        href="mailto:pauline@weddyplace.com">pauline@weddyplace.com</a> wissen.</span>
                <!-- <textarea style="margin-top:5px" name="textarea" rows="2" cols="50" [(ngModel)]='feedback' placeholder="Feedback"></textarea>
                <button mat-raised-button color="accent" (click)="openDashboard()"><span style="font-size: 22px">{{(feedback && feedback.length > 0) ? 'Senden & ' : ''}}Dashboard anzeigen</span></button> -->
            </div>

            <div *ngIf="alreadyMarked || notFound" class="col-md-4 col-centered"
                 style="background-color: #fff;padding-bottom: 30px;padding-top: 30px;border-radius:5px">

                <span *ngIf="alreadyMarked"><h1>Hoppla,<br>diese Anfrage wurde bereits beantwortet.</h1></span>
                <h1 *ngIf="notFound">Schade,<br> irgendetwas stimmt nicht.</h1>
                <div style="margin-left:auto;margin-right:auto;width: 100px;height: 100px;background-image: url('https://cdn.weddyplace.com/static/home/assets/img/Pauline-400.jpg');background-size: cover; display: block;border-radius: 100px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;">
                </div>
                <p style="margin-top:5px">Pauline Koehler</p>
                <span *ngIf="alreadyMarked" style="font-size:12px;color:#505050">Wir mussten feststellen dass diese Anfrage bereits bestätigt/abgelehnt wurde. Sollte dies ein Versehen gewesen sein, Ihr Verbesserungswünsche oder Feedback habt lasst es uns gerne unter <a
                        href="mailto:pauline@weddyplace.com">pauline@weddyplace.com</a> wissen.</span>

                <span *ngIf="notFound" style="font-size:12px;color:#505050">Wir konnten diese Anfrage leider nicht aktualisieren. Bitte wende dich an <a
                        href="mailto:pauline@weddyplace.com">pauline@weddyplace.com</a>.</span>
                <!-- <textarea style="margin-top:5px" name="textarea" rows="2" cols="50" [(ngModel)]='feedback' placeholder="Feedback"></textarea>
                <button mat-raised-button color="accent" (click)="openDashboard()"><span style="font-size: 22px">{{(feedback && feedback.length > 0) ? 'Senden & ' : ''}}Dashboard anzeigen</span></button> -->
            </div>

        </div>
        <div class="row text-center">
            <a mat-button style="margin-left: auto; margin-right: auto;" href="https://pro.weddyplace.com/">Zur Übersicht</a>
        </div>

    </div>

</div>

