import { FormControl } from '@angular/forms';

export function notEmailValidator(control: FormControl): { [key: string]: boolean } | null {
	const value = control.value;

	if (value && value.indexOf('@') > -1) {
		return {looksLikeEmail: true};
	} else {
		return null;
	}
}
