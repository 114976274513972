import { MediaMatcher } from '@angular/cdk/layout';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { HelpersService } from './helpers.service';

@Injectable({
	providedIn: 'root'
})
export class DialogsService {

	public mobileQuery: MediaQueryList;

	constructor(private dialog: MatDialog,
				private media: MediaMatcher) {
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
	}

	public open(componentOrTemplateRef: ComponentType<any> | TemplateRef<any>, matDialogConfig?: MatDialogConfig): MatDialogRef<any> {
		return this.dialog.open(componentOrTemplateRef, this.standardDialogConfig(matDialogConfig));
	}

	public standardDialogConfig(matDialogConfig?: MatDialogConfig): MatDialogConfig {
		if (!matDialogConfig) {
			matDialogConfig = {};
		}

		const obj = {
			viewContainerRef: matDialogConfig.viewContainerRef,
			id: matDialogConfig.id,
			role: matDialogConfig.role || 'dialog',
			panelClass: matDialogConfig.panelClass,
			hasBackdrop: matDialogConfig.hasBackdrop,
			backdropClass: matDialogConfig.backdropClass,
			disableClose: matDialogConfig.disableClose,
			width: matDialogConfig.width || '600px',
			height: matDialogConfig.height || this.mobileQuery.matches ? '100%' : 'auto',
			minWidth: matDialogConfig.minWidth,
			minHeight: matDialogConfig.minHeight,
			maxWidth: matDialogConfig.maxWidth || '80%',
			maxHeight: matDialogConfig.maxHeight,
			position: matDialogConfig.position,
			data: matDialogConfig.data,
			direction: matDialogConfig.direction,
			ariaDescribedBy: matDialogConfig.ariaDescribedBy,
			ariaLabel: matDialogConfig.ariaLabel,
			autoFocus: matDialogConfig.autoFocus,
			scrollStrategy: matDialogConfig.scrollStrategy,
			closeOnNavigation: matDialogConfig.closeOnNavigation,
		};

		HelpersService.removeUndefinedProperties(obj);
		return obj;
	}

}
