import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Review } from '../../../_interfaces/review.interface';
import { Shop } from '../../../_interfaces/shop.interface';
import { FormsHelpersService } from '../../../_services/forms-helpers.service';
import { ShopsService } from '../../../_services/shops.service';

@Component({
	selector: 'app-review-dialog',
	templateUrl: './review-dialog.component.html',
	styleUrls: ['./review-dialog.component.sass']
})
export class ReviewDialogComponent implements OnInit {

	public authorMinLength: number = 2;
	public authorMaxLength: number = 20;
	public quoteMinLength: number = 20;
	public quoteMaxLength: number = 1000;
	public sourceMinLength: number = 5;
	public sourceMaxLength: number = 1000;
	public rateMinLength: number = 1;
	public rateMaxLength: number = 5;

	public processing: boolean = false;
	public success: boolean = false;
	public hovering: boolean = false;

	public form: FormGroup;
	public review: Review;
	public shop: Shop;

	constructor(private formBuilder: FormBuilder,
				private shopsService: ShopsService,
				public dialogRef: MatDialogRef<ReviewDialogComponent>,
				@Inject(MAT_DIALOG_DATA) public data: { shop: Shop, review: Review }) {
		this.shop = this.data.shop;
		this.review = this.data.review;
	}

	public ngOnInit() {
		this.buildForm();
	}

	public onEdit() {
		this.form.enable();
	}

	public onSave() {
		if (this.form.valid) {
			this.submit(this.form);
		} else {
			this.form.markAsTouched();
			FormsHelpersService.markControlsAsTouched(this.form);
		}
	}

	public onCancel() {
		this.dialogRef.close();
	}

	public buildForm() {
		this.form = this.formBuilder.group({
			'_id': [{value: this.review ? this.review._id : undefined, disabled: false}],
			'author': [{value: this.review ? this.review.author : '', disabled: false}, [Validators.required, Validators.minLength(this.authorMinLength), Validators.maxLength(this.authorMaxLength)]],
			'text': [{value: this.review ? this.review.text : '', disabled: false}, [Validators.required, Validators.minLength(this.quoteMinLength), Validators.maxLength(this.quoteMaxLength)]],
			'url': [{value: this.review ? this.review.url : '', disabled: false}, [Validators.required, Validators.minLength(this.sourceMinLength), Validators.maxLength(this.sourceMaxLength)]],
			'rating': [{value: this.review ? this.review.rating : 0, disabled: false}, [Validators.required, Validators.minLength(this.rateMinLength), Validators.maxLength(this.rateMaxLength)]]
			// TODO Display error message when stars not selected
		});
	}

	public submit(form: FormGroup) {
		const currentShop = this.shopsService.currentShop;

		if (this.review && this.review._id) {
			const index = currentShop.reviews.findIndex((anyReview) => {
				return anyReview._id === this.review._id;
			});
			currentShop.reviews[index] = form.value;
		} else {
			let newReview = form.value;
			newReview.reviewDate = new Date();
			currentShop.reviews.push(newReview);
		}

		this.shopsService.updateShop(currentShop).subscribe(
			(res) => {
				this.onSuccess(form);
			},
			(err) => {
				this.onError(err, form);
		});
	}

	public onRating(event) {
		if (event && this.form) {
			this.form.controls['rating'].patchValue(event.rating);
		}
	}

	private onError(err, form: FormGroup) {
		console.error(err);
		this.processing = false;
		form.setErrors({'somethingWentWrong': true});
	}

	private onSuccess(form: FormGroup) {
		this.processing = false;
		this.success = true;
		form.disable();
		this.dialogRef.close();
		setTimeout(() => {
			this.success = false;
			this.dialogRef.close();
		}, 1000);
	}

}
