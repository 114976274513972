import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-save-button',
	templateUrl: './save-button.component.html',
	styleUrls: ['./save-button.component.sass']
})
export class SaveButtonComponent implements OnInit {

	@Input() public danger: boolean;
	@Input() public tabIndex: number;

	constructor() {
	}

	ngOnInit() {
	}

}
