import { isPlatformBrowser } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Input,
  Output,
  PLATFORM_ID,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Product } from "../../../_interfaces";
import {
  AccountDataService,
  PaymentsService,
  ShopsService,
} from "../../../_services";

@Component({
  selector: "app-credit-card-form",
  templateUrl: "./credit-card-form.component.html",
  styleUrls: ["./credit-card-form.component.sass"],
})
export class CreditCardFormComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() public product: any;
  @Input() public couponObj: any;
  @Input() public invoice: any;
  @Input() public subscription: any;
  @Input() public changePaymentMethod: boolean;

  @ViewChild("cardNumber") public cardNumberRef: ElementRef;
  @ViewChild("cardExpiry") public cardExpiryRef: ElementRef;
  @ViewChild("cardCvc") public cardCvcRef: ElementRef;

  @Output() public failure: EventEmitter<any> = new EventEmitter<any>();
  @Output() public success: EventEmitter<any> = new EventEmitter<any>();

  public processing: boolean;
  public error: string;
  public handler = this.onChange.bind(this);
  public cardZip: any;

  private cardNumber: any;
  private cardExpiry: any;
  private cardCvc: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private paymentsService: PaymentsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private accountDataService: AccountDataService,
    private shopsService: ShopsService
  ) {}

  public ngOnInit() {
    if (!elements) {
      console.log("No elements :(");
    }
  }

  public ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const currentElements: string[] = elements._elements;
      if (!currentElements.includes("cardNumber")) {
        this.initCard();
      } else {
        this.failure.emit(true);
      }
    }
  }

  public async getProduct() {
    try {
      const accountData =
        this.accountDataService.accountData ||
        (await this.accountDataService.getAccountData().toPromise());
    } catch (err) {
      this.failure.emit(err);
    }
  }

  public initCard() {
    const elementStyles = {
      base: {
        iconColor: "#5F9EA8",
        color: "#282828",
        fontWeight: 500,
        fontFamily: "Work Sans, Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",

        "::placeholder": {
          color: "#CFD7DF",
        },
        ":-webkit-autofill": {
          color: "#e39f48",
        },
      },
      invalid: {
        iconColor: "#c87575",
        color: "#E25950",

        "::placeholder": {
          color: "#FFCCA5",
        },
      },
    };

    const elementClasses = {
      focus: "focused",
      empty: "empty",
      invalid: "invalid",
    };

    this.cardNumber = elements.create("cardNumber", {
      style: elementStyles,
      classes: elementClasses,
    });
    this.cardExpiry = elements.create("cardExpiry", {
      style: elementStyles,
      classes: elementClasses,
    });
    this.cardCvc = elements.create("cardCvc", {
      style: elementStyles,
      classes: elementClasses,
    });

    if (this.cardNumber) {
      this.cardNumber.mount(this.cardNumberRef.nativeElement);
      this.cardNumber.addEventListener("change", this.handler);
    }
    if (this.cardExpiry) {
      this.cardExpiry.mount(this.cardExpiryRef.nativeElement);
      this.cardExpiry.addEventListener("change", this.handler);
    }
    if (this.cardCvc) {
      this.cardCvc.mount(this.cardCvcRef.nativeElement);
      this.cardCvc.addEventListener("change", this.handler);
    }
  }

  public onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.changeDetectorRef.detectChanges();
  }

  public async onSubmit(form: NgForm) {
    if (this.processing) return; //To prevent double clicking

    if(!this.cardNumber) return; // || !this.cardNumber._complete

    this.processing = true;
    var priceId;

    //TODO: create PaymentMethod can throw errors... catch them, handle them ;)
    const paymentMethodResult = await stripe.createPaymentMethod({
      type: "card",
      card: this.cardNumber,
      billing_details: {
        name:
          this.accountDataService.accountData.firstName +
          (this.accountDataService.accountData.lastName
            ? " " + this.accountDataService.accountData.lastName
            : ""),
      },
    });

    if(!paymentMethodResult || paymentMethodResult.error || !paymentMethodResult.paymentMethod){
      this.processing = false;
      return;
    }

    // instead creating, just update existing subscription with new PaymentMethod;
    let subSubscription;
    if (this.invoice) {
      subSubscription = this.paymentsService.updateSubscription({
        subscriptionId: this.subscription.id,
        shopId: this.shopsService.currentShop._id,
        paymentMethodId: paymentMethodResult.paymentMethod.id,
        invoiceId: this.invoice.id,
      });
    }
    else if (this.changePaymentMethod) {
      subSubscription = this.paymentsService.updateSubscription({
        subscriptionId: this.subscription.id,
        shopId: this.shopsService.currentShop._id,
        paymentMethodId: paymentMethodResult.paymentMethod.id,
      });
    } else if(this.product) {
      priceId = this.product.id;
      let subData;
      subData = {
        shopId: this.shopsService.currentShop._id,
        paymentMethodId: paymentMethodResult.paymentMethod.id,
        priceId: priceId,
      };
      if(this.couponObj)
      subData.coupon = this.couponObj.id;
      subSubscription = this.paymentsService.createSubscription(subData);
    }

    subSubscription
      .toPromise()
      // If the card is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result;
      })
      .then((result) => {
        return {
          paymentMethodId: paymentMethodResult.paymentMethod.id,
          priceId: priceId,
          subscription: result,
        };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      .then(this.handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail, you
      // get a requires_payment_method error.
      .then(this.handleRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
      .then(this.onSubscriptionComplete.bind(this))
      .catch(this.handleCardError.bind(this));
  }

  handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    if (subscription && subscription.status === "active") {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      paymentIntent.status === "requires_source_action" ||
      paymentIntent.status === "requires_confirmation" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result.error;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              // Show a success message to your customer.

              // this.processing = false;
              // this.success.emit(result);
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
                paymentIntent: result.paymentIntent,
              };
            }
          }
        })
        .catch((error) => {
          // displayError(error);
          console.log(error);
          throw error;
        });
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId };
    }
  }

  handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
    paymentIntent,
  }) {
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        subscription.latest_invoice.payment_intent.status
      );
      throw { error: { message: "Your card was declined." } };
    } else {
      return { subscription, priceId, paymentMethodId, paymentIntent };
    }
  }

  handleCardError(error) {
    if(error && error.error)
      error = error.error;
    this.error = error.message;
    console.log(error);
    this.processing = false;
  }

  onSubscriptionComplete(result) {
    // Payment was successful.
    // if (result.subscription.status === 'active' || result.paymentIntent.status === 'succeeded') {
    // Change your UI to show a success message to your customer.
    // Call your backend to grant access to your service based on
    // `result.subscription.items.data[0].price.product` the customer subscribed to.

    if (this.invoice) {
			this.paymentsService.payInvoice({
				invoiceId: this.invoice.id
			}).toPromise()
			.then((result) => {
				if(result && result.invoice && !result.invoice.paid){
					alert("Wir konnten den aktuellen Vorgang nicht ausführen. Bitte versuche es erneut.")
				}
				else{
					this.processing = false;
					this.success.emit(result);
				}
			})
		.catch(this.handleInvoiceError.bind(this))
		}
		else{
			this.processing = false;
			this.success.emit(result);
		}
    // }
  }


	handleInvoiceError(error){
		if(error && error.error)
			error = error.error;
		this.error = error.message;
		console.log(error);				
		this.processing = false;
	}


  public ngOnDestroy(): void {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  }
}
