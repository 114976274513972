import { ChangeDetectorRef, NgZone, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'naturalType',
	pure: false
})
export class NaturalTypePipe implements PipeTransform {

	private typed: string = '';
	private target: string = '';
	private currentIndex: number = -1;
	private timeoutHandle;

	constructor(private changeDetector: ChangeDetectorRef,
				private zone: NgZone) {
		this.timeoutHandle = -1;
	}

	public transform(value: string, minimumTypingSpeed: number = 30): any {
		if (this.target !== value) {
			clearTimeout(this.timeoutHandle);
			this.typed = '';
			this.currentIndex = -1;
			this.target = value;
			this.typeNextCharacter(minimumTypingSpeed);
		}
		return this.typed;
	}

	private typeNextCharacter(minimumTypingSpeed: number) {
		this.currentIndex++;
		this.typed = this.target.substr(0, this.currentIndex);
		this.changeDetector.markForCheck();
		if (this.typed !== this.target) {
			const time = Math.round(Math.random() * 70) + minimumTypingSpeed;
			this.timeoutHandle = setTimeout(() => {
				this.zone.run(() => this.typeNextCharacter(minimumTypingSpeed));
			}, time);
		}
	}

}
