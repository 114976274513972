import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class VendorsService {


	private _currentVendor: any;
	
	public get currentVendor() : any {
		return this._currentVendor;
	}
	
	
	public set currentVendor(v : any) {
		this._currentVendor = v;
		this.currentVendorChanged.next(this._currentVendor);
	}
	
	public currentVendorChanged = new BehaviorSubject<any>(this.currentVendor);

	constructor(private api: ApiService) {
	}

	public getVendor() {
		const req = this.api.get(`pro_vendor`, undefined, true, true);

		req.subscribe(
			(res) => {
				if(res)
					this.currentVendor = res;
			}
		);

		return req;
	}
}
