import { Component, EventEmitter, Output } from '@angular/core';
import { FileSystemFileEntry, UploadEvent } from 'ngx-file-drop';
import { Payload } from '../../_interfaces';
import { CdnService } from '../../_services';

@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.sass']
})
export class FileUploadComponent {

	public uploadingFiles: any[] = [];
	public payloads: Payload[] = [];

	public errors = [];

	@Output() public onUploaded: EventEmitter<string | Payload> = new EventEmitter<string | Payload>();
	@Output() public onRemoved: EventEmitter<string | Payload> = new EventEmitter<string | Payload>();

	constructor(private cdnService: CdnService) {
	}

	public dropped($event: UploadEvent) {
		for (const uploadFile of $event.files) {
			this.uploadingFiles = $event.files;
			(uploadFile.fileEntry as FileSystemFileEntry).file(info => {
				this.cdnService.uploadFiles(info)
					.toPromise()
					.then((payload: Payload) => {
						this.uploadingFiles.pop();
						this.payloads.push(payload);
						this.onUploaded.emit(payload);
					})
					.catch((err) => {
						this.errors.push('There was an error');
					});
			});
		}
	}

	public onFileInput(event) {
		for (const file of event.target.files) {
			this.uploadingFiles.push(1);
			this.cdnService.uploadFiles(file)
				.toPromise()
				.then((payload: Payload) => {
					this.uploadingFiles.pop();
					this.payloads.push(payload);
					this.onUploaded.emit(payload);
				})
				.catch((err) => {
					this.errors.push('There was an error');
				});
		}
	}

	public fileOver(event) {
	}

	public fileLeave(event) {
	}

	public onRemove(payload: Payload) {
		const index = this.payloads.findIndex(aPayload => aPayload._id === payload._id);
		if (index > -1) {
			this.payloads.splice(index, 1);
		}
		this.onRemoved.emit(payload);
	}
}
