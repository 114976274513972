import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material';

@Component({
	selector: 'app-offline-snack-bar',
	templateUrl: './offline-snack-bar.component.html',
	styleUrls: ['./offline-snack-bar.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfflineSnackBarComponent {

	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
	}

}
