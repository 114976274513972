import { Inject, Injectable, isDevMode, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EXPECTATION_FAILED } from 'http-status-codes';
import { BehaviorSubject, Observable } from 'rxjs';
import { ShopErrorInstance, ShopWarningInstance } from '../_classes';
import { Shop, ShopError, ShopWarning } from '../_interfaces';
import { MINIMUM_LENGTH_DESCRIPTION } from '../_others/tokens';
import { ApiService } from './api.service';
import { CategoriesService } from './categories.service';
import { ErrorsService } from './errors.service';
import { HelpersService } from './helpers.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
	providedIn: 'root'
})
export class ShopsService {

	get shops(): Shop[] {
		return this._shops;
	}

	set shops(newShops: Shop[]) {
		this._shops = newShops;
		this.shopsChanged.next(this.shops);
	}

	get currentShop(): Shop {
		return this._currentShop;
	}

	set currentShop(shop: Shop) {
		if (shop && shop._id) {
			ShopsService.checkStatus(shop);
			this._currentShop = shop;
			if (isDevMode()) {
				console.log(`Hi there! You can find this vendor in weddyplace clicking on this link: https://dev.weddyplace.com/vendors/${this.currentShop.slug}`);
			}
			this.localStorageService.setItem('currentShopId', shop._id);
			this.putCurrentShopFirst(shop);
			this.currentShopChanged.next(this.currentShop);
		}
	}

	public shopsChanged = new BehaviorSubject<any[]>(this.shops);
	public currentShopChanged = new BehaviorSubject<any>(this.currentShop);

	private _shops: Shop[] = [];

	private _currentShop: Shop;

	constructor(public api: ApiService,
				private router: Router,
				private categoriesService: CategoriesService,
				private errorsService: ErrorsService,
				private localStorageService: LocalStorageService,
				@Inject(PLATFORM_ID) private platformId: any) {
	}

	public static checkStatus(shop: Shop): any {
		if (shop) {
			shop.completionPercentage = 0;

			const totalSteps = 14;

			shop.warnings = this.setShopWarnings(shop);
			shop.errors = this.setShopErrors(shop);

			shop.hasError = (_id => shop.errors.some(error => error._id === _id));
			shop.hasWarning = (_id => shop.warnings.some(warning => warning._id === _id));

			shop.getError = (_id => shop.errors.find(error => error._id === _id));
			shop.getWarning = (_id => shop.warnings.find(warning => warning._id === _id));

			shop.completionPercentage = 100 / totalSteps * (totalSteps - shop.warnings.length);
		} else {
			console.error('No shop passed to check status');
		}
	}

	public static findShopIdAndRedirect(route: ActivatedRouteSnapshot): void {
		const shopId = route.queryParamMap.get('redirectToPreview');

		if (shopId) {
			window.location.href = `https://${isDevMode() ? 'dev' : 'www'}.weddyplace.com/vendors/${shopId.replace(/(^"|"$)/g, '')}?previewMode=true`;
		}
	}

	private static setShopErrors(shop: Shop): ShopError[] {
		const errors: ShopError[] = [];
		if (!shop.description || shop.description.length < MINIMUM_LENGTH_DESCRIPTION) {
			errors.push(new ShopErrorInstance(
				'shortDescription',
				'Deine Beschreibung ist leider zu kurz.'
			));
		}
		if (!shop.headerImgSrc) {
			errors.push(new ShopErrorInstance(
				'noHeaderImgSrc',
				'Dein Profilbild dient dem ersteindruck und sollte einzigartig und aussagekräftig sein. Liebe auf den ersten Blick!'
			));
		}
		if (!shop.contact || !shop.contact.name || !shop.contact.email || !shop.contact.phone) {
			errors.push(new ShopErrorInstance(
				'noContact',
				'Deine Kontaktdetails sollten vollständig ausgefüllt sein damit das Brautpaar einfach mit dir in Verbindung treten kann.'
			));
		}
		if (!shop.category || !shop.category.key) {
			errors.push(new ShopErrorInstance(
				'noCategory',
				'Du hast keine Kategorie gewählt. Ohne Kategorie wirst Du nicht berücksichtigt.'
			));
		}
		if (!shop.serviceArea) {
			errors.push(new ShopErrorInstance(
				'noServiceArea',
				'Für welche Umgebung sollen wir Dich listen?'
			));
		}
		if (!shop.packages || shop.packages.length === 0) {
			errors.push(new ShopErrorInstance(
				'noPackages',
				'Die Pakete geben dem Kunden eine Erstübersicht deiner Preise. Sie sind nicht endgültig sondern geben lediglich eine Indikation.'
			));
		} else {
			const hasPrice = shop.packages.find((pack) => {
				return pack.price && pack.price > 0;
			});
			if (!hasPrice) {
				errors.push(new ShopErrorInstance(
					'noPrice',
					'Die Pakete müssen einen Preis haben.'
				));
			}
		}
		if (!shop.packages.some(pack => !!pack.parentId)) {
			errors.push(new ShopErrorInstance('noStandardPackages',
				'Du musst mindestens ein Standardpaket auswählen.'
			));
		}
		if (!shop.location || !shop.location.city) {
			errors.push(new ShopErrorInstance(
				'noLocation',
				'Hinterlege Deine Adressdaten damit Brautpaare wissen wo sich deine Geschäftsstelle befindet.'
			));
		}
		if (!shop.projects || shop.projects.length === 0) {
			errors.push(new ShopErrorInstance(
				'noProjects',
				'Erstelle mindestens eine Bildergalerie, damit sich Brautpaare ein Bild von Deiner Location oder Deinen Dienstleistungen machen können.'
			));
		}
		return errors;
	}

	private static setShopWarnings(shop: Shop): ShopWarning[] {
		const warnings: ShopWarning[] = [];
		if (!shop.packages && shop.packages.length < 5) {
			warnings.push(new ShopWarningInstance(
				'notEnoughPackages',
				'Desto mehr Pakete Du hinzufügst desto warscheinlicher ist es, das Kunden Dich anfragen.'
			));
		}
		if (!shop.reviews || shop.reviews.length < 3) {
			warnings.push(new ShopWarningInstance(
				'notEnoughReviews',
				'Kundenrezensionen helfen Brautpaaren eine bessere Vorstellung von Dir zu bekommen. Dienstleister mit vielen guten Rezensionen werden häufiger gebucht.'
			));
		}
		if (shop.projects && shop.projects.length < 3) {
			warnings.push(new ShopWarningInstance(
				'notEnoughGalleries',
				'Je mehr Bilder Du hinzufügst, desto höher ist die Chance Anfragen von Brautpaaren zu erhalten.'
			));
		}
		if (!shop.tagging || shop.tagging.length === 0) {
			warnings.push(new ShopWarningInstance(
				'noTags',
				'Merkmale helfen uns Dienstleistungen einzukategorisieren.'
			));
		}
		if (shop.tagging && shop.tagging.length < 5) {
			warnings.push(new ShopWarningInstance(
				'notEnoughTags',
				'Je mehr Merkmale Du hinzufügst, desto wahrscheinlicher ist es, dass Brautpaare Deine Dienstleistung anfragen.'
			));
		}
		if (!shop.opening_hours) {
			warnings.push(new ShopWarningInstance(
				'noTimes',
				'Hinterlegte Öffnungszeiten helfen uns und Brautpaaren einen passenden Termin mit Euch zu vereinbaren'
			));
		}
		return warnings;
	}

	public putCurrentShopFirst(shop) {
		const index = this.shops.findIndex((anyShop) => {
			return anyShop._id === shop._id;
		});
		this.shops.splice(index, 1);
		this.shops.unshift(shop);
		this.shopsChanged.next(this.shops);
	}

	public changeCurrentShop(shop) {
		this.currentShop = shop;
	}

	public startOnBoarding() {
		this.router.navigate(['/on-boarding', '0']);
	}

	public getShop(shopId) {
		const req = this.api.get(`shops/${shopId}`, null, false, true);

		req.subscribe(
			(shop: Shop) => {
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public async reloadCurrentShop() {
		return this.getCurrentShop(true);
	}

	public async getCurrentShop(forceReload?: boolean) {
		try {
			if (!this.currentShop || forceReload ) {
				await this.getShops().toPromise().catch(err => console.error(err));
			}

			return this.currentShop;
		} catch (e) {
			console.error(e);
		}
	}


	public async getCurrentShopStats() {
		try {
			if (!this.currentShop ) {
				await this.getShops().toPromise().catch(err => console.error(err));
			}


			const req = this.api.get(`pro_shopStats/${this.currentShop._id}`, null, true, true);

			req.subscribe(
				(data: any) => {
				},
				(err) => {
					console.error(err);
				}
			);

			return req;
		} catch (e) {
			console.error(e);
		}
	}

	public getShops() {
		const req = this.api.get(`shops`, null, null, true);

		req.subscribe(
			(shops: Shop[]) => {
				this.setShops(shops);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public addShop(shop: Shop) {
		const index = (this.shops.findIndex((anyShop: Shop) => {
			return anyShop._id === shop._id;
		}));
		if (index === -1) {
			this.shops.push(shop);
		}
		this.currentShop = shop;
	}

	public postShop(shop: any) {
		const req = this.api.post('shop', shop, true);

		req.subscribe(
			(newShop: Shop) => {
				this.addShop(newShop);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	// EDIT

	public editShop(shop: Shop) {
		const index = (this.shops.findIndex((anyShop: Shop) => {
			return anyShop._id === shop._id;
		}));
		if (index >= 0) {
			this.shops[index] = shop;
			this.currentShop = shop;
		}
	}

	public updateShop(shop: any) {
		const req = this.api.put('shop', shop, true);

		req.subscribe(
			(res) => {
				this.editShop(res);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	// REMOVE

	public removeShop(id) {
		const index = (this.shops.findIndex((anyShop: Shop) => {
			return anyShop._id === id;
		}));
		if (index >= 0) {
			this.shops.splice(index, 1);
		}
		if (this.currentShop && this.currentShop._id === id) {
			this.onCurrentShopDeleted();
		}
	}

	public deleteShop(shop: Shop) {
		const req = this.api.post('deleteShop', {vendorId: shop._id}, true);

		req.subscribe(
			() => {
				this.removeShop(shop._id);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public publishShop(shopId: string, status: boolean) {
		const req = this.api.put('publish', {_id: shopId, published: status}, true);

		req.subscribe(
			(newShop: Shop) => {
				this.editShop(newShop);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public crawlShopDescription(website: string): Observable<any> {
		return this.api.post('pro_getWebsiteDescription', {url: website});
	}

	public startedEditing(): Observable<any> {
		return this.api.post('pro_startEdit', {shopId: this.currentShop._id});
	}

	private setShops(shops: Shop[]) {
		try {
			HelpersService.assert(shops, 'shops');
			HelpersService.assertNotEmpty(shops, 'shops');
			this.determineCurrentShop(shops);
			this.shops = shops;
		} catch (e) {
			this.errorsService.post(e);
			this.localStorageService.clear();
			//TODO: replace 417 Expectation failed with, "Aus Sicherheitsgründen haben wir deine Sitzung beendet";
			window.location.replace(`${window.location.origin}/error/${EXPECTATION_FAILED}`);
		}
	}

	private determineCurrentShop(shops: Shop[]) {
		const currentShopId = this.localStorageService.getItem('currentShopId', true);
		this.currentShop = shops.find((anyShop) => {
			return anyShop._id === currentShopId;
		});
		if (!this.currentShop && shops && shops.length > 0) {
			this.currentShop = shops[0];
		}
	}

	private onCurrentShopDeleted() {
		if (this.shops && this.shops.length > 0) {
			this.currentShop = this.shops[0];
		} else {
			this.router.navigate(['/dashboard/settings']);
		}
	}


	public sendReviewInvite(payload){
		const req = this.api.post('pro_sendReviewInvite', payload, true);

		req.subscribe(
			(result: any) => {

			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}
}
