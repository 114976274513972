import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

	constructor(private currencyPipe: CurrencyPipe) {
	}

	public transform(value: string | number, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string {
		if (typeof value === 'string') {
			const valueAsNumber = parseInt(value, 10);
			return `+${this.currencyPipe.transform(valueAsNumber, currencyCode, display, digitsInfo, locale)}`;
		} else {
			return this.currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
		}
	}
}
