export const ERROR_WRAPPER = 'ERROR_WRAPPER';

export const CATEGORIES_TOKEN = 'CATEGORIES';
export const LOCALES = 'LOCALES';
export const ALL_TITLES = 'ALL_TITLES';
export const ALL_CATEGORIES = 'ALL_CATEGORIES';
export const IS_PRE_RENDER = 'IS_PRE_RENDER';

export const NODE_PORT = 5000;
export const VERSION_KEY = 'pro_version';
export const APPLICATION_NAME = 'WeddyPlace-Pro';
export const MINIMUM_LENGTH_DESCRIPTION = 250;
export const MAXIMUM_LENGTH_DESCRIPTION = 1500;
export const HEADER_IMG_MIN_HEIGHT = 300;
export const HEADER_IMG_MIN_WIDTH = 600;
export const ISSUES_URL = 'https://gitlab.vanolia.com/weddyplace/frontend/pro/issues';

export const SNACK_BAR = {
	ERROR: 'error-snack-bar',
	SUCCESS: 'success-snack-bar'
};
