<form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout">
    <div class="form-container">
        <!--<div id="card-info" #cardInfo></div>-->

        <div class="row">
            <div class="field">
                <label data-tid="elements_examples.form.card_number_label" for="card-number">
                    KARTENNUMMER
                </label>
                <div #cardNumber class="input empty" id="card-number"></div>
            </div>
        </div>
        <div class="row">
            <div class="field half-width">
                <label data-tid="elements_examples.form.card_expiry_label" for="card-expiry">
                    GÜLTIG BIS
                </label>
                <div #cardExpiry class="input empty" id="card-expiry"></div>
            </div>
            <div class="field half-width">
                <label data-tid="elements_examples.form.card_cvc_label" for="card-cvc">
                    CVC
                </label>
                <div #cardCvc class="input empty" id="card-cvc"></div>
            </div>
            <div class="field half-width">
                <label data-tid="elements_examples.form.card_cvc_label" for="card-zip">
                    POSTLEITZAHL
                </label>
                <input [(ngModel)]="cardZip" [ngModelOptions]="{ standalone: true }" class="input" id="card-zip" required
                       type="text">
            </div>
        </div>

        <div *ngIf="error" class="errors">
            <mat-error>
                {{ error }}
            </mat-error>
        </div>
    </div>

    <button class="spinner-button" [class.processing]="processing" color="accent" mat-button mat-raised-button type="submit">
        <span *ngIf="!processing">
            {{product ? 'Jetzt Bezahlen' : 'Speichern'}}
        </span>
        <span *ngIf="processing">
            IN BEARBEITUNG... 
        </span>
        <mat-spinner *ngIf="processing" diameter="24"></mat-spinner>
    </button>
</form>
