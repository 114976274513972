import { WizardAnswer } from './wizard-answer.interface';

export interface ContingentWizardQuestion {
	condition: any;
	key: string;
}

export class WizardQuestion {

	public key: string;
	public type: string;
	public title: string;
	public potentialAnswers: WizardAnswer[];
	public contingentQuestions: ContingentWizardQuestion[];

	constructor(key: string, type: string, title: string, potentialAnswers: WizardAnswer[], contingentQuestions?: ContingentWizardQuestion[]) {
		this.key = key;
		this.type = type;
		this.title = title;
		this.potentialAnswers = potentialAnswers;
		this.contingentQuestions = contingentQuestions;
	}

}
