import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LOCAL_STORAGE } from '../_others/tokens';
import { LocalStorageService } from './local-storage.service';

@Injectable({
	providedIn: 'root'
})
export class VersionService {

	constructor(private http: HttpClient,
				@Inject(PLATFORM_ID) private platformId: Object,
				private localStorageService: LocalStorageService) {
	}

	public checkLastUsedVersion() {
		const lastUsedVersion = this.localStorageService.getItem(LOCAL_STORAGE.LAST_USED_VERSION);

		if (!lastUsedVersion) {
			this.localStorageService.setItem(LOCAL_STORAGE.LAST_USED_VERSION, environment.version);
		}

		if (lastUsedVersion !== environment.version) {
			try {
				this.removeVersionDependantData();
			} catch (e) {
				console.error(e);
				console.warn('Could not remove old values from the local storage');
			}
		}
	}

	private async removeVersionDependantData() {

	}

}
