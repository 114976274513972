import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, share } from 'rxjs/internal/operators';
import { CacheService } from './cache.service';
import { PendingService } from './pending.service';
import { TransferStateService } from './transfer-state.service';
import { retryWithBackoff } from '../_others/retry-with-backoff.operator';

@Injectable({
	providedIn: 'root'
})
export class HttpService {

	constructor(private http: HttpClient,
				private pendingService: PendingService) {
	}

	private static setParams(params?: HttpParams, transferStateKey?: string, skipCache?: boolean): HttpParams {
		if (transferStateKey) {
			params = TransferStateService.addTransferStateKeyParams(params, transferStateKey);
		}
		if (skipCache) {
			params = CacheService.addSkipParams(params, skipCache);
		}
		return params;
	}

	public get(url: string, params?: HttpParams, transferStateKey?: string, skipCache?: boolean): Observable<any> {
		// tslint:disable-next-line:max-line-length
		return this.pendingService.intercept('GET', url, params, undefined, this.http.get(url, { params: HttpService.setParams(params, transferStateKey, skipCache) }).pipe(retryWithBackoff(1000, 3), share(), catchError(this.formatErrors)));
	}

	public post(url: string, body: any, params?: HttpParams): Observable<any> {
		return this.pendingService.intercept('POST', url, params, body, this.http.post(url, body, { params }).pipe(share(), catchError(this.formatErrors)));
	}

	public put(url: string, body: any, params?: HttpParams): Observable<any> {
		return this.pendingService.intercept('PUT', url, params, body, this.http.put(url, body, { params }).pipe(share(), catchError(this.formatErrors)));
	}

	public delete(url: string): Observable<any> {
		return this.pendingService.intercept('DELETE', url, null, undefined, this.http.delete(url).pipe(share(), catchError(this.formatErrors)));
	}

	public patch(url: string, body: any): Observable<any> {
		return this.pendingService.intercept('PATCH', url, null, undefined, this.http.patch(url, body).pipe(share(), catchError(this.formatErrors)));
	}

	public head(url: string): Observable<any> {
		return this.pendingService.intercept('HEAD', url, null, undefined, this.http.head(url).pipe(share(), catchError(this.formatErrors)));
	}

	public options(url: string): Observable<any> {
		return this.pendingService.intercept('OPTIONS', url, null, undefined, this.http.options(url).pipe(share(), catchError(this.formatErrors)));
	}

	private formatErrors(error: any) {
		return throwError(error.error);
	}
}
