import { InjectionToken } from '@angular/core';

export interface LazyModules {
	tester: string;
}

const lazyMap: LazyModules = {
	tester: 'src/app/_shared/modules/tester/tester.module#TesterModule'
};

export const LAZY_MODULES_MAP = new InjectionToken('LAZY_MODULES_MAP', {
	factory: () => lazyMap
});
