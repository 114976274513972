import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Inquiry } from '../../_interfaces';

@Component({
	selector: 'app-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.sass']
})
export class AvatarComponent implements OnInit {

	@Input() public diameter: number = 100;
	@Input() public imagePath: string;
	@Input() public initials: string;
	@Input() public name: string;
	@Input() public inquiry: Inquiry;

	public colorsArray: string[] = [
		'#1d5553',
		'#1d4955',
		'#1d4455',
		'#1d4055',
		'#1d3b55',
		'#1d3655',
		'#1d3255',
		'#1d2d55',
		'#1d3b55',
		'#1d324e',
		'#233b5d',
		'#28446b',
		'#2d4e79',
		'#335787',
		'#386096',
		'#3d69a4',
		'#4372b2',
		'#4c7cbc',
		'#5b86c1',
	];

	public cdnPath: string = `${environment.cdn}/resize`;

	constructor() {
	}

	public ngOnInit() {
		// if (this.inquiry) {
		// 	console.log(this.inquiry);
		// }
	}

	public onImageError($event) {
		console.error($event);
		this.imagePath = null;
	}
}
