export const environment = {
  envName: 'dev',
  production: false,
  api: 'https://api-dev.weddyplace.com',
  cdn: 'https://cdn.weddyplace.com',
  resizer: 'https://cdn.weddyplace.com/resize',
  i18n: 'https://cdn.vanolia.com/uploads/translations/creative-director/',
  assets: 'https://cdn.weddyplace.com/static/dev-pro/assets/',
  port: 8080,
  version: '0.0.383',
  host: 'https://api-dev.weddyplace.com',
  domain: 'https://dev-pro.weddyplace.com',
  createdAt: 'Mon Mar 25 2019 18:43:58 GMT+0100 (CET)'
};
