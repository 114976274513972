import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatStepper } from '@angular/material';
import { BadgeDialogComponent } from '../../../_shared/modules/dialogs/badge-dialog/badge-dialog.component';
import { AccountDataService, PaymentsService, ShopsService } from '../../../_shared/_services';
import { AccountData, Product } from '../../../_shared/_interfaces';

@Component({
	selector: 'app-payment-settings',
	templateUrl: './payment-settings.component.html',
	styleUrls: ['./payment-settings.component.sass']
})
export class PaymentSettingsComponent implements OnInit {

	@Input() public customer: any;
	@Input() public product: any;
	@Input() public productMonth: any;
	@Input() public productYear: any;
	@Input() public paymentYearly: any;
	@Input() public subscription: any;
	@Input() public invoice: any;
	@Input() public changePaymentMethod: boolean;
	@ViewChild('headerStepper') public headerStepper: MatStepper;
	@ViewChild('cardStepper') public cardStepper: MatStepper;

	@Output() public processingData: EventEmitter<any> = new EventEmitter<any>();
	@Output() public failure: EventEmitter<any> = new EventEmitter<any>();
	@Output() public finished: EventEmitter<any> = new EventEmitter<any>();

	public success;
	public error;
	public invoiceError;

	public paymentMethod;
	public paymentMethods;
	public processing: boolean = true;
	public selectedPaymentMethod;
 	public defaultNewMethod: string = 'card'

	processingInvoice: boolean;
	processingQuickBuy: boolean;
	processingNewMethod: boolean;
	processingCoupon: boolean;

	public productNet: number;
	public productMwSt: number;
	public productTotal: number;
	public couponCode: string;
	public foundCoupon: boolean = false;
	public foundCouponObj: any;
	public couponError: string;

	constructor(private shopsService: ShopsService,
				private dialog: MatDialog,
				private paymentsService: PaymentsService,
				private accountDataService: AccountDataService) {
	}

	public ngOnInit() {
		if(this.customer){
			this.getPaymentMethods();
		}
		else{
			this.processing = false;
		}

		this.setNewTotal();

	}

	public async getPaymentMethods() {
		if(this.customer && this.customer.invoice_settings && this.customer.invoice_settings.default_payment_method)
			this.paymentMethod = await this.paymentsService.getPaymentMethod(this.customer.invoice_settings.default_payment_method).toPromise();
		let tMethods = await this.paymentsService.getPaymentMethods().toPromise();
		this.paymentMethods = tMethods;
		this.processing = false;
	}

	/**
	 * onRadioGroupChange
	 */
	public onRadioGroupChange($event) {
		console.log($event)
		if($event && $event.value == "monthly"){
			this.product = this.productMonth;
		}
		else {
			this.product = this.productYear;
		}

		this.setNewTotal();
	}

	/**
	 * setNewTotal
	 */
	public setNewTotal() {
		var tmpTotal = this.product.unit_amount / 100;
		this.productNet = Math.round(tmpTotal / 119 * 100 * 100) / 100;
		tmpTotal = this.productNet;

		if(this.foundCoupon && this.foundCouponObj){
			if(this.foundCouponObj.coupon.amount_off){
				tmpTotal = tmpTotal - this.foundCouponObj.coupon.amount_off / 100;
			}
			else{
				tmpTotal = tmpTotal - (tmpTotal / 100 * this.foundCouponObj.coupon.percent_off);
			}
		}
		this.productMwSt = Math.round(tmpTotal / 100 * 19 * 100) / 100;
		this.productTotal = Math.round((tmpTotal + this.productMwSt) * 100) / 100;
	}

	/**
	 * isDefaultMethod
	 */
	public isDefaultMethod(paymentMethod) {
		if(this.paymentMethod && this.paymentMethod.id == paymentMethod.id)
			return true;
		return false;
	}

	/**
	 * setDefaultMethod
	 */
	public setDefaultMethod(paymentMethod) {
		this.selectedPaymentMethod = paymentMethod;
	}

	public selectNewPamentMethodType(type){
		this.defaultNewMethod = type;
	}

	/**
	 * saveNewPaymentMethod
	 */
	public saveNewPaymentMethod() {
		if(this.processingNewMethod)
			return;
   	this.processingData.emit(true);
		this.processingNewMethod = true;
		let subSubscription = this.paymentsService.updateSubscription({
			subscriptionId: this.subscription.id,
			shopId: this.shopsService.currentShop._id,
			paymentMethodId: this.selectedPaymentMethod.id });

			subSubscription.toPromise()
			// If the card is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result;
      })
			.then((result) => {
				this.success = true;
				this.processingData.emit(false);
				this.finished.emit(true);
				this.processingNewMethod = false;
			})
	}


	/**
	 * payInvoice
	 */
	public payInvoice() {
			if(this.processingInvoice)
				return;
			// try {
				debugger

				this.processingData.emit(true);
				this.processingInvoice = true;
				let returned_Invoice;
				returned_Invoice = this.paymentsService.payInvoice({
					invoiceId: this.invoice.id,
					paymentMethodId: this.selectedPaymentMethod ? this.selectedPaymentMethod.id : this.paymentMethod.id
				});
				returned_Invoice = returned_Invoice.toPromise();
				returned_Invoice.catch((result)=>{
					// if(result && result.error) {
					// 	return this.handleInvoiceError(result.error);
					// }
					debugger
					let paymentIntent = this.invoice.payment_intent;
	
					if (paymentIntent.status === 'requires_action' || paymentIntent.status === 'requires_confirmation' || paymentIntent.status === 'requires_payment_method') {
								return stripe.confirmCardPayment(paymentIntent.client_secret, {
										payment_method: this.selectedPaymentMethod ? this.selectedPaymentMethod.id : this.paymentMethod.id,
									})
									.then((result) => {
										if (result.error) {
											// Start code flow to handle updating the payment details.
											// Display error message in your UI.
											// The card was declined (i.e. insufficient funds, card has expired, etc).
											throw result.error;
										} else {
											if (result.paymentIntent.status === 'succeeded') {
												// Show a success message to your customer.

												// this.processing = false;
												// this.success.emit(result);
												return {
													paymentIntent: result.paymentIntent
												};
											}
										}
									})
									.catch((error) => {
										// displayError(error);
										console.log(error);
										this.processingInvoice = false;
										this.processingData.emit(false);
										throw error;
									});
						}
						else{
							return result;
						}
				})
				.then((result) => {
					// if(result && result.invoice && !result.invoice.paid){ //sepa_debit is never immediately paid
					// 	alert("Der Rechnungsbetrag konnte nicht beglichen werden.")
					// }
					// else{
						this.shopsService.reloadCurrentShop();
						this.processingInvoice = false;
						this.success = true;
				   	this.processingData.emit(false);
						this.finished.emit(true);
					// }
				})
				.catch(this.handleInvoiceError.bind(this))
			// } catch (error) {
			// 	this.processingInvoice = false;
			// }
	}


	handleInvoiceError(error){
		if(error && error.error)
			error = error.error;
		this.invoiceError = error.message;
		console.log(error);				
		this.processingInvoice = false;
	}




	/**
	 * quickBuy
	 */
	public quickBuy() {
		if(this.processingQuickBuy)
			return;
		this.processingQuickBuy = true;
		this.processingData.emit(true);

		var selectedMethod = this.selectedPaymentMethod ? this.selectedPaymentMethod : this.paymentMethod;
		if(selectedMethod.type == "sepa_debit"){
			let subSubscription;
			let subData;
			subData = {
				shopId: this.shopsService.currentShop._id,
				paymentMethodId: selectedMethod.id,
				priceId: this.product.id
			};

				if(this.foundCoupon){
					subData.coupon = this.foundCouponObj.id;
				}
				subSubscription = this.paymentsService.createSepaSubscription(subData);

				subSubscription = subSubscription.toPromise();
				// If the card is declined, display an error to the user.
				subSubscription.then((result) => {
					if (result.error) {
						// The card had an error when trying to attach it to a customer.
						throw result;
					}
					return result;
				})
				.then((result) => {
					this.success = true;
			   	this.processingData.emit(false);
					this.finished.emit(true);
		
					this.processingQuickBuy = false;
				})
				.catch(this.handleCardError.bind(this));
		}
		else{
			let subData;
			subData = {
				shopId: this.shopsService.currentShop._id,
				paymentMethodId: selectedMethod.id,
				priceId: this.product.id 
			};

			if(this.foundCoupon){
				subData.coupon = this.foundCouponObj.id;
			}

			let subSubscription = this.paymentsService.createSubscription(subData);
	
				subSubscription.toPromise()
			// If the card is declined, display an error to the user.
			.then((result) => {
				if (result.error) {
					// The card had an error when trying to attach it to a customer.
					throw result;
				}
				return result;
			})
			.then((result) => {
				return {
					paymentMethodId: this.selectedPaymentMethod ? this.selectedPaymentMethod.id : this.paymentMethod.id,
					priceId: this.product.id,
					subscription: result,
				};
			})
			// Some payment methods require a customer to be on session
			// to complete the payment process. Check the status of the
			// payment intent to handle these actions.
			.then(this.handlePaymentThatRequiresCustomerAction)
			// If attaching this card to a Customer object succeeds,
			// but attempts to charge the customer fail, you
			// get a requires_payment_method error.
			.then(this.handleRequiresPaymentMethod)
			// No more actions required. Provision your service for the user.
			.then(this.onSubscriptionComplete.bind(this))
			.catch(this.handleCardError.bind(this))
		}
	}


	handlePaymentThatRequiresCustomerAction({
		subscription,
		invoice,
		priceId,
		paymentMethodId,
		isRetry,
	}) {
		if (subscription && subscription.status === 'active') {
			// Subscription is active, no customer actions required.
			return { subscription, priceId, paymentMethodId };
		}
	
		// If it's a first payment attempt, the payment intent is on the subscription latest invoice.
		// If it's a retry, the payment intent will be on the invoice itself.
		let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;
	
		if (
			paymentIntent.status === 'requires_action' || paymentIntent.status === 'requires_confirmation' ||
			(isRetry === true && paymentIntent.status === 'requires_payment_method')
		) {
			return stripe
				.confirmCardPayment(paymentIntent.client_secret, {
					payment_method: paymentMethodId,
				})
				.then((result) => {
					if (result.error) {
						// Start code flow to handle updating the payment details.
						// Display error message in your UI.
						// The card was declined (i.e. insufficient funds, card has expired, etc).
						throw result.error;
					} else {
						if (result.paymentIntent.status === 'succeeded') {
							// Show a success message to your customer.

							// this.processing = false;
							// this.success.emit(result);
							return {
								priceId: priceId,
								subscription: subscription,
								invoice: invoice,
								paymentMethodId: paymentMethodId,
								paymentIntent: result.paymentIntent
							};
						}
					}
				})
				.catch((error) => {
					// displayError(error);
					console.log(error);
					throw error;
				});
		} else {
			// No customer action needed.
			return { subscription, priceId, paymentMethodId };
		}
	}

	handleRequiresPaymentMethod({
		subscription,
		paymentMethodId,
		priceId,
		paymentIntent
	}) {
		if (subscription.status === 'active') {
			// subscription is active, no customer actions required.
			return { subscription, priceId, paymentMethodId };
		} else if (
			subscription.latest_invoice.payment_intent.status ===
			'requires_payment_method'
		) {
			// Using localStorage to manage the state of the retry here,
			// feel free to replace with what you prefer.
			// Store the latest invoice ID and status.
			localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
			localStorage.setItem(
				'latestInvoicePaymentIntentStatus',
				subscription.latest_invoice.payment_intent.status
			);
			throw { error: { message: 'Your card was declined.' } };
		} else {
			return { subscription, priceId, paymentMethodId, paymentIntent };
		}
	}

	handleCardError(error){
		this.error = error.message;
		console.log(error);
		this.processingData.emit(false);
		this.processingQuickBuy = false;
	}

	onSubscriptionComplete(result) {
		// Payment was successful.
		// if (result.subscription.status === 'active' || result.paymentIntent.status === 'succeeded') {
			// Change your UI to show a success message to your customer.
			// Call your backend to grant access to your service based on
			// `result.subscription.items.data[0].price.product` the customer subscribed to.
			this.success = true;
			this.processingData.emit(false);
			this.finished.emit(true);

			this.processingQuickBuy = false;

		// }
	}





	/**
	 * applyCoupon
	 */
	public applyCoupon() {
		if(!this.couponCode || this.couponCode.trim().length <= 0){
			this.foundCoupon = false;
			this.couponError = null;
			return;
		}
		this.foundCoupon = false;
		this.processingCoupon = true;
		this.couponError = null;
		try {
			let couponSub = this.paymentsService.checkCoupon(this.couponCode).toPromise()
			.then((result) => {
					if(result && result.found){
						this.foundCoupon = true;
						this.foundCouponObj =  result.code;
					}
					else{
						this.foundCoupon = false;
						this.couponError = "Dieser Gutscheincode ist nicht mehr gültig";
					}
					this.setNewTotal();
					this.processingCoupon = false;
			})
			.catch((error)=> {
				this.processingCoupon = false;
				this.couponError = error;	
			})
		} catch (error) {
			this.processingCoupon = false;
			this.couponError = error;
		}

	}





	public onSuccess() {
		this.success = true;
		if (this.cardStepper) {
			this.cardStepper.next();
		}
		this.processingData.emit(false);
		this.finished.emit(true);
	}

	public onFailure($event) {
		this.success = false;
		this.cardStepper.next();
		this.processingData.emit(false);
		this.failure.emit($event);
	}

	public onTryAgain() {
		this.cardStepper.previous();
	}

}
