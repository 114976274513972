import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher, MatDialogRef } from '@angular/material';
import { AccountDataService } from '../../../_services/account-data.service';

@Component({
	selector: 'app-set-password-dialog',
	templateUrl: './set-password-dialog.component.html',
	styleUrls: ['./set-password-dialog.component.sass']
})
export class SetPasswordDialogComponent implements OnInit {

	public passwordMinLength: number = 6;
	public passwordMaxLength: number = 60;

	public form: FormGroup;

	public matcher = new MyErrorStateMatcher();

	constructor(private formBuilder: FormBuilder,
	            private accountDataService: AccountDataService,
	            public dialogRef: MatDialogRef<SetPasswordDialogComponent>) {
	}

	public ngOnInit() {
		this.form = this.formBuilder.group({
				'email': [{value: this.accountDataService.accountData.email, disabled: true}, []],
				'password': [null, [Validators.required, Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]],
				'passwordRepeat': [null, [Validators.required, Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]],
			}, {
				validator: this.checkPasswords
			}
		);
	}

	public checkPasswords(formGroup: FormGroup, firstControlName: string = 'password', secondControlName: string = 'passwordRepeat'): any {
		const password = formGroup.controls[firstControlName].value;
		const passwordRepeat = formGroup.controls[secondControlName].value;

		return password === passwordRepeat ? null : {passwordsDoNotMatch: true};
	}

	public onSubmit(form: FormGroup) {
		this.accountDataService.setNewPassword(form.controls['password'].value, form.controls['passwordRepeat'].value).subscribe(
			(res) => {
				// show snackbar
				this.dialogRef.close();
			}, (err) => {
				window.alert('Something went wrong. Please try again later.');
			}
		);
	}

	public onRemindMeLater() {
		// this.cookieService.set('dismissPasswordReminder', 'true', moment().add(3, 'hours').toDate());
		this.dialogRef.close();
	}

}

export class MyErrorStateMatcher implements ErrorStateMatcher {
	public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
		const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

		return (invalidCtrl || invalidParent);
	}
}
