import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Shop } from '../_interfaces/shop.interface';
import { Video } from '../_interfaces/video.interface';
import { ApiService } from './api.service';
import { ShopsService } from './shops.service';
import { SocketsService } from './sockets.service';

@Injectable({
	providedIn: 'root'
})
export class VideosService {

	get videos(): Video[] {
		return this._videos;
	}

	set videos(newVideos: Video[]) {
		this._videos = newVideos;
		this.videosChanged.next(this.videos);
	}

	public videosChanged = new Subject<Video[]>();
	public currentShopSubscription: Subscription;
	public uploadingVideos: string[] = [];
	public uploadingVideosChanged = new Subject<string[]>();
	private initialized = 0;
	private currentShop: Shop;

	private _videos: Video[] = [];

	constructor(public api: ApiService,
	            private shopsService: ShopsService,
	            private socketsService: SocketsService) {
		this.currentShop = this.shopsService.currentShop;
		if (this.currentShop) {
			this.videos = this.currentShop.videos || [];
		}

		this.currentShopSubscription = this.shopsService.currentShopChanged.subscribe(
			(currentShop) => {
				this.videos = currentShop.videos || [];
			}
		);
	}

	public initialize() {
		this.initialized++;
		if (this.initialized === 1) {
			// this.getVideos();
			this.loadSockets();
		}
	}

	public restart() {
		this.initialized = 0;
		this.videos = null;
	}

	public loadSockets() {
		if (this.socketsService.socket) {
			this.socketsService.socket.on('video-added', (video: Video) => {
				this.addVideo(video);
			});
			this.socketsService.socket.on('video-changed', (video: Video) => {
				this.editVideo(video);
			});
			this.socketsService.socket.on('video-deleted', (id: string) => {
				this.removeVideo(id);
			});
		}
	}

	// GET

	public getVideo(videoId) {
		// Getting data from server
		const req = this.api.get(`videos/${videoId}`);

		req.subscribe(
			(video: Video) => {
				// Data found in server
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public getVideos() {
		// // Getting data from server
		// const req = this.api.get(`videos`);
		//
		// req.subscribe(
		//   (videos: Video[]) => {
		//     // Data found in server
		//     this.videos = videos;
		//     // Data from server saved in service
		//   },
		//   (err) => {
		//     console.error(err);
		//   }
		// );
		//
		// return req;
	}

	// ADD

	public addVideo(video: Video) {
		const index = (this.videos.findIndex((anyVideo: Video) => {
			return anyVideo._id === video._id;
		}));
		if (index === -1) {
			this.videos.unshift(video);
		}
	}

	public postVideo(video: Video) {
		const req = this.api.post('videos', video);

		req.subscribe(
			(newVideo: Video) => {
				this.addVideo(newVideo);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	// EDIT

	public editVideo(video: Video) {
		const index = (this.videos.findIndex((anyVideo: Video) => {
			return anyVideo._id === video._id;
		}));
		if (index >= 0) {
			this.videos[index] = video;
		}
	}

	public updateVideo(video: Video) {
		const req = this.api.put('videos', video);

		req.subscribe(
			(res) => {
				this.editVideo(res);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	// REMOVE

	public removeVideo(id) {
		const index = (this.videos.findIndex((anyVideo: Video) => {
			return anyVideo._id === id;
		}));
		if (index >= 0) {
			this.videos.splice(index, 1);
		}
	}

	public deleteVideo(video: Video) {
		const req = this.api.delete('videos', this.shopsService.currentShop._id + '/' + video._id);

		req.subscribe(
			() => {
				this.removeVideo(video._id);
				// this.deleteRemoteVideo(video);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public removeVideoFromUploadingVideos(remoteId: string) {
		const index = this.uploadingVideos.indexOf(remoteId);
		if (index > -1) {
			this.uploadingVideos.splice(index, 1);
			this.uploadingVideosChanged.next(this.uploadingVideos);
		}
	}
}
