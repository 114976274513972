import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UNAUTHORIZED } from 'http-status-codes';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpService } from './http.service';
import { LocalStorageService } from './local-storage.service';
import { TokenService } from './token.service';
import { SessionStorageService } from './session-storage.service';
import { isPlatformBrowser } from '@angular/common';
import { WeddyPlaceError } from '../_interfaces';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	private api: string = environment.api;

	constructor(private httpService: HttpService,
				private route: ActivatedRoute,
				private localStorageService: LocalStorageService,
				private sessionStorageService: SessionStorageService,
				private injector: Injector,
				private tokenService: TokenService,
				@Inject(PLATFORM_ID) private platformId: Object) {
	}

	public get(endpoint: string, params?: HttpParams, needsAuth?: boolean, skipCaching?: boolean): Observable<any> {
		let req: Observable<any>;

		if (needsAuth) {
			if (this.tokenService.token) {
				req = this.httpService.get(`${this.api}/${endpoint}`, params, null, skipCaching);
			} else {
				this.onUnauthorized();
			}
		} else {
			req = this.httpService.get(`${this.api}/${endpoint}`, params, null, skipCaching);
		}

		return req;
	}

	public post(endpoint: string, body?: any, needsAuth?: boolean): Observable<any> {
		let req;

		if (needsAuth) {
			if (this.tokenService.token) {
				req = this.httpService.post(`${this.api}/${endpoint}`, body);
			} else {
				this.onUnauthorized();
			}
		} else {
			req = this.httpService.post(`${this.api}/${endpoint}`, body);
		}

		return req;
	}

	public put(endpoint: string, body?: any, needsAuth?: boolean): Observable<any> {
		let req;

		if (needsAuth) {
			if (this.tokenService.token) {
				req = this.httpService.put(`${this.api}/${endpoint}`, body);
			} else {
				this.onUnauthorized();
			}
		} else {
			req = this.httpService.put(`${this.api}/${endpoint}`, body);
		}

		return req;
	}

	public delete(endpoint: string, item?: any, needsAuth?: boolean): Observable<any> {
		let req;

		if (needsAuth) {
			if (this.tokenService.token) {
				req = this.httpService.delete(`${this.api}/${endpoint}/${item}`);
			} else {
				this.onUnauthorized();
			}
		} else {
			req = this.httpService.delete(`${this.api}/${endpoint}/${item}`);
		}

		return req;
	}

	private onUnauthorized() {
		this.localStorageService.clear();
		this.sessionStorageService.clear();
		this.tokenService.removeToken();
		if (isPlatformBrowser(this.platformId)) {
			window.location.replace(`${environment.domain}/welcome?returnUrl=${window.location.pathname}`);
		}
	}
}
