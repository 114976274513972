import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-offline-alert',
	templateUrl: './offline-alert.component.html',
	styleUrls: ['./offline-alert.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfflineAlertComponent {

	private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

	constructor(private snackBar: MatSnackBar,
				private translateService: TranslateService) {
	}

	@HostListener('window:offline', ['$event'])
	public onOffline() {
		this.snackBarRef = this.snackBar.open(this.translateService.instant('YOU_ARE_OFFLINE'), undefined, {
			panelClass: ['error-snack-bar']
		});
		// this.snackBar.openFromComponent(OfflineSnackBarComponent, { data: 'potatoes' });
	}

	@HostListener('window:online', ['$event'])
	public onOnline() {
		if (this.snackBarRef) {
			this.snackBarRef.dismiss();
			this.snackBarRef = undefined;
		}

		this.snackBar.open(this.translateService.instant('YOU_ARE_BACK_ONLINE'), undefined, {
			duration: 1000,
			panelClass: ['success-snack-bar']
		});
	}

}
