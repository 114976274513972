import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { CategoriesService } from './categories.service';

@Injectable({
	providedIn: 'root'
})
export class MetaTagsService {

	constructor(private categoriesService: CategoriesService,
				private meta: Meta) {
	}

	public createDefaultMetaTags(robots: string, canonical: string) {
		this.meta.updateTag({ property: 'og:title', content: 'WeddyPlace Pro' });
		this.meta.updateTag({
			name: 'description',
			content: 'Die Planung Eurer Traumhochzeiten beginnt bei WeddyPlace! Einzigartige Locations und Dienstleister, hilfreiche Planungstools und traumhafte Inspiration.'
		});
		this.meta.updateTag({
			property: 'og:description',
			content: 'Die Planung Eurer Traumhochzeiten beginnt bei WeddyPlace! Einzigartige Locations und Dienstleister, hilfreiche Planungstools und traumhafte Inspiration.'
		});
		this.meta.updateTag({ property: 'og:url', content: 'https://pro.weddyplace.com' });
		this.meta.updateTag({ name: 'robots', content: robots });
	}

	public createErrorMetaTags() {
		this.meta.removeTag('name="description"');
		this.meta.removeTag('property="og:description"');
		this.meta.removeTag('property="og:url"');
		this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow, noodp' });
	}

	private resetMetaTags() {
		this.meta.updateTag({ property: 'og:title', content: '' });
		this.meta.updateTag({ name: 'description', content: '' });
		this.meta.updateTag({ property: 'og:description', content: '' });
		this.meta.updateTag({ property: 'og:url', content: '' });
		this.meta.updateTag({ name: 'robots', content: 'index, follow, noodp' });
	}

}
