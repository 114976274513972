import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AccountData } from '../_interfaces';
import { LOCAL_STORAGE } from '../_others/tokens';
import { HelpersService, LocalStorageService } from '../_services';

@Injectable()
export class ParamsHttpInterceptor implements HttpInterceptor {

	private localStorageService: LocalStorageService;

	constructor(private injector: Injector,
				@Inject(PLATFORM_ID) private platformId: Object) {
	}

	private static isOneRequestToOurServer(request): boolean {
		return (request.url.indexOf(environment.api) > -1) || (request.url.indexOf(environment.cdn) > -1);
	}

	public intercept(request: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
		this.initializeServices();
		if (isPlatformBrowser(this.platformId) && ParamsHttpInterceptor.isOneRequestToOurServer(request)) {
			request = this.addKloguid(request);
		}
		if (ParamsHttpInterceptor.isOneRequestToOurServer(request)) {
			request = this.addAppVersion(request);
		}
		return httpHandler.handle(request);
	}

	private addKloguid(request: HttpRequest<any>): HttpRequest<any> {
		try {
			HelpersService.assert(request, 'request');

			const accountData: AccountData = this.localStorageService.getItem(LOCAL_STORAGE.CURRENT_USER, false) || this.localStorageService.getItem(LOCAL_STORAGE.ACCOUNT_DATA, false);
			if (accountData && accountData._id) {
				const userId = accountData._id;
				return request.clone({
					setParams: {
						'kloguid': userId
					}
				});
			} else {
				return request;
			}
		} catch (e) {
			return request;
		}
	}

	private addAppVersion(request: HttpRequest<any>): HttpRequest<any> {
		try {
			HelpersService.assert(request, 'request');
			const version = environment.version;
			return request.clone({
				setParams: { version }
			});
		} catch (e) {
			console.error(e);
			return request;
		}
	}

	private initializeServices() {
		if (!this.localStorageService) {
			this.localStorageService = this.injector.get(LocalStorageService);
		}
	}

}
