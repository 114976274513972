import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatRadioChange, MatStepper } from '@angular/material';

@Component({
	selector: 'app-decline-dialog',
	templateUrl: './decline-dialog.component.html',
	styleUrls: ['./decline-dialog.component.sass']
})
export class DeclineDialogComponent implements OnInit {

	public reason: string;

	@ViewChild('stepper') public stepper: MatStepper;

	constructor(public dialogRef: MatDialogRef<DeclineDialogComponent>) {
	}

	public ngOnInit() {
	}

	public onDecline(reason?: string | null) {
		this.dialogRef.close({declining: true, reason: reason});
	}

	public onCancel() {
		this.dialogRef.close({declining: false});
	}

	public onRadioGroupChange(radioChange: MatRadioChange) {
		this.reason = radioChange.value;
		this.stepper.next();
	}

}
