import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { unescape } from 'querystring';
import { BehaviorSubject, Observable } from 'rxjs';
import { isArray } from 'util';
import { environment } from '../../../environments/environment';
import { HelpersService } from './helpers.service';
import { HttpService } from './http.service';
import { TokenService } from './token.service';
import { Payload } from '../_interfaces';
import { catchError, share } from 'rxjs/internal/operators';


@Injectable({
	providedIn: 'root'
})
export class CdnService {

	get progress(): number {
		return this._progress;
	}

	set progress(value: number) {
		this._progress = value;
		this.progressChanged.next(this.progress);
	}

	public progressChanged: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	public resize: string = `${environment.cdn}/resize/`;
	public path: string = environment.cdn;

	private _progress: number;

	constructor(private httpService: HttpService,
				private tokenService: TokenService,
				private translateService: TranslateService) {
	}

	public checkImageDimensions(file, minWidth: number, minHeight: number, maxWidth: number, maxHeight: number) {
		return new Promise((resolve, reject) => {
			if (!file.type.match(/image.*/)) {
				reject(new Error(this.translateService.instant('FILE_IS_NOT_AN_IMAGE')));
				return;
			}

			const image = new Image();

			if (window && window.URL && window.URL.createObjectURL) { // TODO Fix "Failed to execute 'createObjectURL' on 'URL': No function was found that matched the signature provided."
				image.src = window.URL.createObjectURL(file);
				image.onload = () => {
					const width = image.naturalWidth;
					const height = image.naturalHeight;
					window.URL.revokeObjectURL(image.src);
					if (width >= minWidth && height >= minHeight && width <= maxWidth && height <= maxHeight) {
						resolve(true);
					} else {
						resolve(false);
					}
				};
			} else {
				reject();
			}
		});
	}

	public checkThatImageIsBiggerThan(file: File, minWidth: number, minHeight: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (!file.type.match(/image.*/)) {
				reject(new Error(this.translateService.instant('FILE_IS_NOT_AN_IMAGE')));
				return;
			}

			const image = new Image();

			if (window && window.URL && window.URL.createObjectURL) { // TODO Fix "Failed to execute 'createObjectURL' on 'URL': No function was found that matched the signature provided."
				image.src = window.URL.createObjectURL(file);
				image.onload = () => {
					const width = image.naturalWidth;
					const height = image.naturalHeight;
					window.URL.revokeObjectURL(image.src);
					if (width >= minWidth && height >= minHeight) {
						resolve(true);
					} else {
						reject();
					}
				};
			} else {
				resolve(true);
			}
		});
	}

	public checkThatImageIsSmallerThan(file: File, maxWidth: number, maxHeight: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (!file.type.match(/image.*/)) {
				reject(new Error(this.translateService.instant('FILE_IS_NOT_AN_IMAGE')));
				return;
			}

			const image = new Image();

			if (window && window.URL && window.URL.createObjectURL) { // TODO Fix "Failed to execute 'createObjectURL' on 'URL': No function was found that matched the signature provided."
				image.src = window.URL.createObjectURL(file);
				image.onload = () => {
					const width = image.naturalWidth;
					const height = image.naturalHeight;
					window.URL.revokeObjectURL(image.src);
					if (width <= maxWidth && height <= maxHeight) {
						resolve(true);
					} else {
						reject();
					}
				};
			} else {
				resolve(true);
			}
		});
	}

	public resizeImage(file: File, maxSize: number, minSize?: number): Promise<File> {
		const reader = new FileReader();
		const image = new Image();
		const canvas = document.createElement('canvas');

		const dataURItoBlob = (dataURI: string) => {
			const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
				atob(dataURI.split(',')[1]) :
				unescape(dataURI.split(',')[1]);
			const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
			const max = bytes.length;
			const ia = new Uint8Array(max);
			for (let i = 0; i < max; i++) {
				ia[i] = bytes.charCodeAt(i);
			}
			return new Blob([ia], {type: mime});
		};

		const blobToFile = (theBlob, fileName) => {
			theBlob.lastModifiedDate = new Date();
			theBlob.name = fileName;
			return theBlob;
		};

		const resize = () => {
			let width = image.width;
			let height = image.height;

			if (!minSize) {
				minSize = 0;
			}

			if (width > height) {
				if (width > maxSize) {
					height *= maxSize / width;
					width = maxSize;
				}

				if (height < minSize) {
					width *= minSize / height;
					height = minSize;
				}
			} else {
				if (height > maxSize) {
					width *= maxSize / height;
					height = maxSize;
				}

				if (width < minSize) {
					height *= minSize / width;
					width = minSize;
				}
			}

			canvas.width = width;
			canvas.height = height;
			canvas.getContext('2d').drawImage(image, 0, 0, width, height);
			const dataUrl = canvas.toDataURL('image/jpeg');
			const blob = dataURItoBlob(dataUrl);
			return blobToFile(blob, file.name);
		};

		return new Promise((resolve, reject) => {
			if (!file.type.match(/image.*/)) {
				reject(new Error(this.translateService.instant('FILE_IS_NOT_AN_IMAGE')));
				return;
			}

			reader.onload = (readerEvent: any) => {
				image.onload = () => resolve(resize());
				image.src = readerEvent.target.result;
			};

			reader.readAsDataURL(file);
		});
	}

	public fileWeightsLessThan(file: File, maxMegaBytes: number = 15) {
		return (file.size / 1024 / 1024) <= maxMegaBytes;
	}

	public get(endpoint: string, params?: any, transferStateKey?: string) {
		return this.httpService.get(`${this.path}/${endpoint}`, params, transferStateKey);
	}

	public post(endpoint: string, body?: any) {
		return this.httpService.post(`${this.path}/${endpoint}`, body, null);
	}

	public put(endpoint: string, body?: any) {
		return this.httpService.put(`${this.path}/${endpoint}`, body, null);
	}

	public delete(endpoint: string, item?: any) {
		return this.httpService.delete(`${this.path}/${endpoint}/${item}`);
	}

	public uploadFiles(files: File | File[]): Observable<Payload> {
		return new Observable(observer => {
			const formData = this.setFormData(files, observer);
			const xhr = this.createXhrRequest(observer);
			xhr.open('POST', `${this.path}/upload/fileUpload`, true);
			if (this.tokenService.token) {
				xhr.setRequestHeader('Authorization', `Bearer ${this.tokenService.token}`);
			}
			xhr.send(formData);
		});
	}

	public upload(files: File | File[]): Observable<any> {
		return new Observable(observer => {
			const formData = this.setFormData(files, observer);
			const xhr = this.createXhrRequest(observer);
			xhr.open('POST', `${this.path}/upload/upload`, true);
			if (this.tokenService.token) {
				xhr.setRequestHeader('Authorization', `Bearer ${this.tokenService.token}`);
			}
			xhr.send(formData);
		});
	}

	public placeholder(width: number, height: number): string {
		return `${this.path}/resize/${width}x${height}/static/home/assets/placeholders/master_placeholder.png`;
	}

	private setFormData(files: File | File[], observer) {
		const formData: FormData = new FormData();
		const maxSize = 30;

		if (isArray(files)) {
			for (const file of files) {
				if (this.fileWeightsLessThan(file, maxSize)) {
					formData.append('uploads[]', file, file.name);
				} else {
					observer.error(new Error(this.translateService.instant('FILE_MUST_BE_SMALLER_THAN_X_MB', {x: maxSize})));
				}
			}
		} else {
			const file = files;
			if (this.fileWeightsLessThan(file, maxSize)) {
				formData.append('uploads[]', file, file.name);
			} else {
				observer.error(new Error(this.translateService.instant('FILE_MUST_BE_SMALLER_THAN_X_MB', {x: maxSize})));
			}
		}

		return formData;
	}

	private createXhrRequest(observer): XMLHttpRequest {
		const xhr: XMLHttpRequest = new XMLHttpRequest();

		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				if (xhr.status === 200) {
					if (observer) {
						if (HelpersService.isJSON(xhr.response)) {
							observer.next(JSON.parse(xhr.response));
						} else {
							observer.next(xhr.response);
						}
						observer.complete();
					}
				} else {
					if (observer) {
						observer.error(xhr.response);
					}
				}
			}
		};

		xhr.upload.onprogress = (event) => {
			this.progress = Math.round(event.loaded / event.total * 100);
		};

		return xhr;
	}





    // TODO Eventually this endpoint will be authorised and it will need headers
    remoteDownload(url) {
        const req = this.httpService.post(`https://cdn.weddyplace.com/upload/downloadRemote`, { image: url }).pipe(share());

        req.subscribe();

        return req;
    }

}
