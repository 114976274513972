import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Notification } from '../_interfaces/notification.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class NotificationsService {

	get notifications(): Notification[] {
		return this._notifications;
	}

	set notifications(newNotifications: Notification[]) {
		try {
			for (const notificationSet of newNotifications) {
				let totalNotifications = 0;

				if (notificationSet) {
					Object.keys(notificationSet).forEach((key) => {
						if (typeof notificationSet[key] === 'number') {
							totalNotifications = totalNotifications + notificationSet[key];
						}
					});
				}

				notificationSet.totalNotifications = totalNotifications;
			}

			this._notifications = newNotifications;
			this.notificationsChanged.next(this.notifications);
		} catch (e) {
			console.error(e);
		}
	}

	public notificationsChanged = new BehaviorSubject<Notification[]>(this.notifications);

	private _notifications: Notification[] = [];

	constructor(public api: ApiService) {
	}

	public getNotifications() {
		// const req = this.api.get(`notifications`, null, false, true);
		//
		// req.subscribe(
		// 	(notifications: Notification[]) => {
		// this.notifications = notifications;
		// },
		// (err) => {
		// 	console.error(err);
		// }
		// );
		//
		// return req;
	}

	// ADD

	public addNotification(notification: Notification) {
		const index = (this.notifications.findIndex((anyNotification: Notification) => {
			return anyNotification._id === notification._id;
		}));
		if (index === -1) {
			this.notifications.push(notification);
		}
	}

	public postNotification(notification: Notification) {
		const req = this.api.post('notifications', notification, true);

		req.subscribe(
			(newNotification: Notification) => {
				this.addNotification(newNotification);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	// EDIT

	public editNotification(notification: Notification) {
		const index = (this.notifications.findIndex((anyNotification: Notification) => {
			return anyNotification._id === notification._id;
		}));
		if (index >= 0) {
			this.notifications[index] = notification;
		}
	}

	public updateNotification(notification: Notification) {
		const req = this.api.put('notifications', notification, true);

		req.subscribe(
			(res) => {
				this.editNotification(res.accommodation);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	// REMOVE

	public removeNotification(id) {
		const index = (this.notifications.findIndex((anyNotification: Notification) => {
			return anyNotification._id === id;
		}));
		if (index >= 0) {
			this.notifications.splice(index, 1);
		}
	}

	public deleteNotification(notification: Notification) {
		const req = this.api.delete('notifications', notification._id, true);

		req.subscribe(
			() => {
				this.removeNotification(notification._id);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

}
