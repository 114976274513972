import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Injector, isDevMode, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { FORBIDDEN, UNAUTHORIZED } from 'http-status-codes';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/internal/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService, DialogsService, ErrorsService, TokenService } from '../_services';
import { ErrorDialogComponent } from '../modules/dialogs/error-dialog/error-dialog.component';

@Injectable()
export class ErrorsHttpInterceptor implements HttpInterceptor {

	private errors = [];

	private tokenService: TokenService;
	private dialog: MatDialog;
	private dialogsService: DialogsService;
	private authenticationService: AuthenticationService;
	private errorsService: ErrorsService;
	private router: Router;

	constructor(private injector: Injector,
				@Inject(PLATFORM_ID) private platformId: Object) {
	}

	private static isOneRequestToOurServer(request): boolean {
		return (request.url.indexOf(environment.api) > -1) || (request.url.indexOf(environment.cdn) > -1);
	}

	public intercept(request: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
		this.initializeServices();

		return httpHandler.handle(request).pipe(
			catchError((error) => {
				if (isDevMode()) {
					console.warn('Intercepting error...');
				}

				this.errors.push(error.name);

				if (error instanceof HttpErrorResponse) {
					if (isPlatformBrowser(this.platformId) && !navigator.onLine && this.errors.indexOf(error.name) === -1) {
						return this.manageNoInternetConnectionError(error);
					}
				}

				if (ErrorsHttpInterceptor.isOneRequestToOurServer(request) && error instanceof HttpErrorResponse) {
					if (error.status === UNAUTHORIZED) {
						return this.manageUnauthorizedError(error, request, httpHandler);
					}
					if (error.status === FORBIDDEN) {
						return this.manageForbiddenError(error, request, httpHandler);
					}
					if (error.status >= 500) {
						return this.manageUnknownError(error, request, httpHandler);
					}
				}

				return throwError(error);
			})
		);
	}

	private manageUnauthorizedError(error: HttpErrorResponse, request: HttpRequest<any>, httpHandler: HttpHandler) {
		if (error.url === `${environment.api}/token`) {
			this.authenticationService.logOut(true);
			return throwError(error);
		} else {
			return this.authenticationService.refreshToken().pipe(
				switchMap(() => {
					request = this.renewAuthorizationHeader(request);
					return httpHandler.handle(request);
				}),
				catchError(() => {
					return throwError(error);
				})
			);
		}
	}

	private renewAuthorizationHeader(request: HttpRequest<any>): HttpRequest<any> {
		if (!this.tokenService.token) {
			return request;
		}
		if (!ErrorsHttpInterceptor.isOneRequestToOurServer(request)) {
			return request;
		} else {
			return request.clone({
				setHeaders: {
					'Authorization': `Bearer ${this.tokenService.token}`
				},
				setParams: {
					'retrying': Math.random().toString()
				}
			});
		}
	}

	private manageNoInternetConnectionError(err: HttpErrorResponse) {
		this.dialog.open(ErrorDialogComponent, this.dialogsService.standardDialogConfig({
			data: {
				error: err
			}
		}));
		return throwError(err);
	}

	private manageForbiddenError(error: HttpErrorResponse, request: HttpRequest<any>, httpHandler: HttpHandler) {
		this.router.navigate(['/error', FORBIDDEN]);
		return throwError(error);
	}

	private manageUnknownError(error: HttpErrorResponse, request: HttpRequest<any>, httpHandler: HttpHandler) {
		this.errorsService.post(error);
		this.router.navigate(['/error', error.status]);
		return throwError(error);
	}

	private initializeServices() {
		if (!this.tokenService) {
			this.tokenService = this.injector.get(TokenService);
		}
		if (!this.dialog) {
			this.dialog = this.injector.get(MatDialog);
		}
		if (!this.dialogsService) {
			this.dialogsService = this.injector.get(DialogsService);
		}
		if (!this.injector) {
			this.injector = this.injector.get(Injector);
		}
		if (!this.authenticationService) {
			this.authenticationService = this.injector.get(AuthenticationService);
		}
		if (!this.errorsService) {
			this.errorsService = this.injector.get(ErrorsService);
		}
		if (!this.router) {
			this.router = this.injector.get(Router);
		}
	}

}
