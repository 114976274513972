import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ApiService, ShopsService } from '../../../_services';

@Component({
	selector: 'app-accept-terms-dialog',
	templateUrl: './accept-terms-dialog.component.html',
	styleUrls: ['./accept-terms-dialog.component.sass']
})
export class AcceptTermsDialogComponent {

	public processing: boolean;

	constructor(
		private api: ApiService,
		public shopsService: ShopsService,
		public dialogRef: MatDialogRef<AcceptTermsDialogComponent>) {
			this.openTermsPreview();
	}

	public close() {
		this.processing = false;
		this.dialogRef.close(false);
	}

	public koopUrl: string;
	public anlageUrl: string;

	public openTermsPreview() {
		this.api.post('pro_previewTermsPDF', {shopName: this.shopsService.currentShop.name || 'dem Partner'}, false).subscribe((res) => {
			if (res && res.results && res.results.length === 2) {
				this.koopUrl = 'https://cdn.weddyplace.com' + res.results[0];
				this.anlageUrl = 'https://cdn.weddyplace.com' + res.results[1]; 
			} else {
				alert('Bitte meldet euch bei unserem Support');
			}
		});
	}

	public onAcceptConditions() {

		this.processing = true;
		const currentShop = this.shopsService.currentShop;

		if (!currentShop.name) {
			alert('Tut uns leid aber zum veröffentlichen melde dich bitte bei unserem Support');
			this.close();
			return;
		}

		try {
			const body = {
				shopId: currentShop._id,
				customText: '',
				emailTo: currentShop.contact.email,
				subject: 'WeddyPlace AGBs und Kooperationsvereinbarung',
				partner: `Guten Tag ${currentShop.contact.name}`,
				isCommissionRate: 7,
				isCommissionDay: 'monatlich jeweils zum 15. Werktag des Folgemonats',
				coopPartner: currentShop.name
			};

			this.api.post('pro_terms', body)
				.toPromise()
				.then((res) => {
					this.processing = false;

					if(window["dataLayer"])
						window["dataLayer"].push({event: "signup-complete"});

					this.dialogRef.close(true);
				})
				.catch((err) => {
					alert(err);
					this.close();
				});

		} catch (error) {
			alert('Tut uns leid aber zum veröffentlichen melde dich bitte bei unserem Support');
			this.close();
		}
	}
}
