import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDatepicker, MatDialogRef, MatHorizontalStepper } from '@angular/material';
import { environment } from '../../../../../environments/environment';
import { Inquiry, Message, Payload } from '../../../_interfaces';
import { AccountDataService, ApiService, FormsHelpersService, HelpersService, InquiriesService, OffersService, PaymentsService, ShopsService, VendorsService } from '../../../_services';
import { DateValidator } from '../../../_validators';

@Component({
	selector: 'app-mark-as-booked-dialog',
	templateUrl: './mark-as-booked-dialog.component.html',
	styleUrls: ['./mark-as-booked-dialog.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkAsBookedDialogComponent implements OnInit {

	@ViewChild('horizontalStepper') public horizontalStepper: MatHorizontalStepper;
	@ViewChild('startPicker') public startPicker: MatDatepicker<null>;

	public cdnPath: string = `${environment.cdn}/resize`;

	public minPrice: number = 0;
	public maxPrice: number = 999999999;
	public textMaxLength: number = 1000;

	public processing: boolean = false;
	public success: boolean = false;
	public hovering: boolean = false;

	public form: FormGroup;

	public offer: any;
	public account: any;
	public vendor: any;

	constructor(private formBuilder: FormBuilder,
				private offersService: OffersService,
				private paymentsService: PaymentsService,
				private inquiriesService: InquiriesService,
				private shopsService: ShopsService,
				private accountDataService: AccountDataService,
				private api: ApiService,
				private vendorsService: VendorsService,
				public dialogRef: MatDialogRef<MarkAsBookedDialogComponent>,
				private cdr: ChangeDetectorRef,
				private currencyPipe: CurrencyPipe,
				@Inject(MAT_DIALOG_DATA) public inquiry: Inquiry) {

	}

	public async ngOnInit() {
		this.offersService.notifyThatVendorStartedBooking(this.inquiry._id);
		await this.buildForm();
		this.cdr.detectChanges();
	}

	public async onSubmit(form: FormGroup) {
		if (form.valid) {
			const price = `${form.controls['price'].value}`;
			let formattedPrice = form.controls['price'].value;

			if (price) {
				formattedPrice = parseFloat(price.replace(' ', '').replace('.', '').replace(',', '.'));
			}

			const message: Message = {
				mType: 'booking',
				subject: 'booking',
				inquiryId: this.inquiry._id,
				message: '-', // form.controls['text'].value,
				data: {
					text: '-', // form.controls['text'].value,
					payloads: form.controls['payloads'].value,
					order: {
						price: formattedPrice,
						currency: form.controls['currency'].value,
						date: form.controls['date'].value
					}
				}
			};

			this.form.disable();
			await this.submit(message);
		} else {
			this.form.markAsTouched();
			FormsHelpersService.markControlsAsTouched(this.form);
			FormsHelpersService.logAllFormErrors(this.form);
		}
	}

	public onPriceBlur() {
		if (this.form.get('price').value) {
			const price = `${this.form.get('price').value}`;
			const newValue = this.currencyPipe.transform(parseFloat(price.replace('.', '').replace(',', '.').replace(' ', '')), 'EUR', 'symbol');
			this.form.get('price').setValue(newValue);
		}
	}

	public onPayloadUploaded(fileName: string | Payload) {
		const payloads = this.form.controls['payloads'].value;
		payloads.push(fileName);
		this.form.controls['payloads'].patchValue(payloads);
	}

	public onPayloadRemoved(fileName: Payload) {
		const payloads = this.form.controls['payloads'].value;
		const index = payloads.findIndex(aPayload => aPayload._id === fileName._id);
		if (index > -1) {
			payloads.splice(index, 1);
			this.form.controls['payloads'].patchValue(payloads);
		}
	}

	public onDateInputClick(event) {
		event.preventDefault();
		event.stopPropagation();
		this.startPicker.open();
	}

	private async buildForm() {
		if (!this.accountDataService.accountData) {
			await this.accountDataService.getAccountData().toPromise().catch(err => console.error(err));
		}

		let budget = HelpersService.safelyGet(() => this.inquiry.requestData.budget);
		const date = HelpersService.safelyGet(() => this.inquiry.suggestedFor.weddingData.weddingDate);
		budget = this.currencyPipe.transform(budget, 'EUR', 'symbol', '1.2-2');

		this.form = this.formBuilder.group({
			'price': [{value: budget, disabled: false}, [Validators.min(this.minPrice), Validators.max(this.maxPrice)]],
			'date': [{value: date, disabled: false}, [DateValidator()]],
			'budget': [{value: budget, disabled: true}, [Validators.required]],
			'name': [{value: HelpersService.buildHumanName(this.inquiry.suggestedFor.accountData.firstName, this.inquiry.suggestedFor.accountData.lastName), disabled: true}, [Validators.required]],
			'currency': ['EUR'],
			'payloads': [[]]
		});
	}

	private async submit(message: Message) {
		try {
			if (!this.processing) {
				this.processing = true;
				this.cdr.detectChanges();
				await this.offersService.postBooking(message).toPromise().catch(err => console.error(err));
				this.success = true;
				this.horizontalStepper.next();
			}
		} catch (e) {
			this.onError(e);
		}
	}

	private onError(e: Error) {
		console.error(e);
		this.processing = false;
		this.form.enable();
		this.form.setErrors({'somethingWentWrong': true});
	}

}
