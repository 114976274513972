import { ChangeDetectionStrategy, Component, Inject, isDevMode } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AccountDataService, IssuesService } from '../../../_services';

@Component({
	selector: 'app-booking-reminder-dialog',
	templateUrl: './booking-reminder-dialog.component.html',
	styleUrls: ['./booking-reminder-dialog.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingReminderDialogComponent {


	constructor(public dialogRef: MatDialogRef<BookingReminderDialogComponent>,
				public accountDataService: AccountDataService,
				private issuesService: IssuesService,
				@Inject(MAT_DIALOG_DATA) public error: any) {
		if (isDevMode()) {
			// this.testing = true;
		}
	}

	public onClose() {
		this.dialogRef.close(true);
	}
}
