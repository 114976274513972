import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { Inquiry } from '../_interfaces';
import { InquiriesService } from '../_services';

@Injectable()
export class InquiryResolver implements Resolve<Inquiry> {

	constructor(private inquiriesService: InquiriesService) {
	}

	public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		const id = route.paramMap.get('id');
		return this.inquiriesService.getInquiry(id).pipe(
			catchError(error => {
				console.error(`Can't resolve inquiry with id ${id} because of the error: ${error}`);
				return of(null);
			})
		);
	}
}
