import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-cancel-button',
	templateUrl: './cancel-button.component.html',
	styleUrls: ['./cancel-button.component.sass']
})
export class CancelButtonComponent {
	@Input() public tabIndex: number;
}
