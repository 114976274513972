<div class="wrapper">
    <mat-icon [svgIcon]="'smiley-sad-1'" class="sad-icon"></mat-icon>

    <h1 mat-dialog-title>Ooops!</h1>

    <p>{{error?.message || 'Da ist etwas schiefgelaufen. Bitte versuche es erneut.'}}</p>

    <p class="support">Sollte das Problem weiterhin bestehen, wende dich bitte direkt an den Weddy-Support unter
        <a target="_self" rel="noopener noreferrer" href="tel:+4903040366899">(0)30 4036 6899</a>.</p>

    <div class="centered" mat-dialog-actions>
        <button *ngIf="testing || accountDataService?.accountData?.isTest" (click)="openIssue()" color="warn" mat-button
                mat-raised-button>
            Open issue
        </button>
        <button (click)="onClose()" color="accent" mat-button mat-raised-button>
            Alles klar!
        </button>
    </div>
</div>

