import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import * as io from 'socket.io-client';
import { environment } from '../../../environments/environment';
import { ErrorsService } from './errors.service';
import { SocketEventsService } from './socket-events.service';
import { TokenService } from './token.service';

@Injectable({
	providedIn: 'root'
})
export class SocketsService implements OnDestroy {

	get socket() {
		return this._socket;
	}

	set socket(newSocket: any) {
		this._socket = newSocket;
		this.socketChanged.next(this.socket);
	}

	public socketChanged: Subject<SocketIOClient.Socket> = new Subject<SocketIOClient.Socket>();

	private url: string = environment.api;
	private pendingEmissions: { event: string; args: any[] }[] = [];
	private _socket: SocketIOClient.Socket;

	constructor(private tokenService: TokenService,
				private socketEventsService: SocketEventsService,
				private errorsService: ErrorsService,
				@Inject(PLATFORM_ID) private platformId: Object) {
		if (isPlatformBrowser(this.platformId)) {
			if (this.tokenService.token) {
				this.connectToSocket(this.tokenService.token);
			}
			this.subscribeToToken();
		}
	}

	public subscribeToToken() {
		this.tokenService.tokenChanged
			.pipe(untilDestroyed(this))
			.subscribe(
				(token) => {
					this.connectToSocket(token);
				}
			);
	}

	public connectToSocket(token?: string) {
		if (!token) {
			token = this.tokenService.token;
		}

		if (this.socket) {
			this.socket.disconnect();
		}

		if (token) {
			this.socket = io(this.url, {'query': `token=${token}`});
			this.socket.on('connect', () => {
				console.log('%c Socket connected!', 'color: green; font-weight: bold;');
				this.socketEventsService.listenToEvents(this.socket);
				if (this.pendingEmissions.length > 0) {
					this.emitPending();
				}
				this.socket.on('connect_error', (e) => {
					console.error('Socket connection error');
					console.error(e);
				});
				this.socket.on('reconnecting', (e) => {
					console.warn('Socket is trying to reconnect');
					console.error(e);
				});
				this.socket.on('disconnect', () => {
					if (this.socket.io.connecting.indexOf(this.socket) === -1) {
						console.warn('Socket disconnected');
						this.socket.removeAllListeners();
						this.connectToSocket(this.tokenService.token);
					}

				});
			});

			this.socket.connect();
		}
	}

	public ngOnDestroy() {
		if (isPlatformBrowser(this.platformId)) {
			this.socket.disconnect();
		}
	}

	public emit(event: string, args: any) {
		if (this.socket) {
			if (this.pendingEmissions.length > 0) {
				this.emitPending();
			}
			this.socket.emit(event, args);
		} else {
			this.pendingEmissions.unshift({event, args});
		}
	}

	public emitPending() {
		let i = this.pendingEmissions.length;

		while (i--) {
			this.socket.emit(this.pendingEmissions[i].event, this.pendingEmissions[i].args);
			this.pendingEmissions.splice(i, 1);
		}
	}

}
