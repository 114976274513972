import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Wedding } from '../_interfaces/wedding.interface';
import { ApiService } from './api.service';
import { SocketsService } from './sockets.service';
import { TokenService } from './token.service';

@Injectable({
	providedIn: 'root'
})
export class WeddingService {

	get wedding(): Wedding {
		return this._wedding;
	}

	set wedding(newWedding: Wedding) {
		this._wedding = newWedding;
		this.weddingChanged.next(newWedding);
	}

	public weddingChanged = new Subject<Wedding>();
	private initialized = 0;

	private _wedding: Wedding;

	constructor(public api: ApiService,
	            private tokenService: TokenService,
	            private socketsService: SocketsService) {
	}

	public initialize() {
		if (this.initialized === 0) {
			this.getWedding();
			this.loadSockets();
		}
	}

	public loadSockets() {
		if (this.socketsService.socket) {
			this.socketsService.socket.on('wedding-changed', (wedding: Wedding) => {
				this.wedding = wedding;
			});
		}
	}

	// GET

	public getWedding() {
		if (this.tokenService.token) {
			const req = this.api.get('weddingData', null, false, true);

			req.subscribe(
				(wedding: Wedding) => {
					this.wedding = wedding;
					this.initialized++;
				},
				(err) => {
				}
			);

			return req;
		}
	}

	// EDIT

	public updateWedding(wedding: Wedding) {
		if (this.tokenService.token) {
			const req = this.api.put('weddingData', wedding, true);

			req.subscribe(
				(res) => {
					this.wedding = res;
				},
				(err) => {
					console.error(err);
				}
			);

			return req;
		}
	}

}
