import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Inquiry } from '../_interfaces/inquiry.interface';
import { Message } from '../_interfaces/message.interface';
import { Offer } from '../_interfaces/offer.interface';
import { DemandDialogComponent } from '../modules/dialogs/demand-dialog/demand-dialog.component';
import { ApiService } from './api.service';
import { DialogsService } from './dialogs.service';
import { LocalStorageService } from './local-storage.service';
import { ShopsService } from './shops.service';

@Injectable({
	providedIn: 'root'
})
export class OffersService {

	get offers(): Offer[] {
		return this._offers;
	}

	set offers(newOffers: Offer[]) {
		this._offers = newOffers;
		this.offersChanged.next(this.offers);
	}

	public offersChanged = new BehaviorSubject<Offer[]>(this.offers);

	private _offers: Offer[] = [];

	constructor(public api: ApiService,
	            private shopsService: ShopsService,
	            private localStorageService: LocalStorageService,
	            private dialogsService: DialogsService,
	            private dialog: MatDialog) {
		this.shopsService.getShops();
	}

	public getOffer(offerId) {
		const req = this.api.get(`offers/${offerId}`, null, false, true);

		req.subscribe(
			(offer: Offer) => {
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public getOffers(currentShopId: string) {
		const req = this.api.get(`offersBV/${currentShopId}`, null, false, true);

		req.subscribe(
			(offers: Offer[]) => {
				this.offers = offers;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public addOffer(offer: Offer) {
		const index = (this.offers.findIndex((anyOffer: Offer) => {
			return anyOffer._id === offer._id;
		}));
		if (index === -1) {
			this.offers.push(offer);
		}
	}

	public postOffer(message: Message) {
		const req = this.api.post('pro_offers', message, true);

		req.subscribe(
			(newOffer: Offer) => {
				// this.addOffer(newOffer);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public postBooking(message: Message) {
		const req = this.api.post('pro_markAsBooked', message, true);

		req.subscribe(
			(newOffer: Offer) => {
				// this.addOffer(newOffer);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public notifyThatVendorStartedBooking(requestId: string) {
		const req = this.api.post('pro_startBooking', { requestId }, true);

		req.subscribe(
			(res) => {
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public confirmBooking(message: Message) {
		const req = this.api.post('pro_confirmAsBooked', message, true);

		req.subscribe(
			(newOffer: Offer) => {
				// this.addOffer(newOffer);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public declineBooking(body: any) {
		const req = this.api.post('pro_declineAsBooked', body, true);

		req.subscribe(
			(newOffer: Offer) => {
				// this.addOffer(newOffer);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public editOffer(offer: Offer) {
		const index = (this.offers.findIndex((anyOffer: Offer) => {
			return anyOffer._id === offer._id;
		}));
		if (index >= 0) {
			this.offers[index] = offer;
		}
	}

	public updateOffer(offer: Offer) {
		const req = this.api.put('offers', offer, true);

		req.subscribe(
			(res) => {
				this.editOffer(res.accommodation);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public removeOffer(id) {
		const index = (this.offers.findIndex((anyOffer: Offer) => {
			return anyOffer._id === id;
		}));
		if (index >= 0) {
			this.offers.splice(index, 1);
		}
	}

	public deleteOffer(offer: Offer) {
		const req = this.api.delete('offers', offer._id, true);

		req.subscribe(
			() => {
				this.removeOffer(offer._id);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public accept(inquiryId: string, messageId: string): Observable<any> {
		// These actions are only available to a customer
		return of(false);
	}

	public decline(inquiryId: string, messageId: string): Observable<any> {
		// These actions are only available to a customer
		return of(false);
	}

	public openDemandDialog(inquiry: Inquiry) {
		const dialogRef = this.dialog.open(DemandDialogComponent, this.dialogsService.standardDialogConfig({
			data: inquiry
		}));
	}

}
