import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-edit-button',
	templateUrl: './edit-button.component.html',
	styleUrls: ['./edit-button.component.sass']
})
export class EditButtonComponent {

	@Input() public tabIndex: number;

	constructor() {
	}

}
