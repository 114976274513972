import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Input, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Product } from '../../../_interfaces/product.interface';
import { ShopsService } from '../../../_services';
import { AccountDataService } from '../../../_services/account-data.service';
import { PaymentsService } from '../../../_services/payments.service';

@Component({
	selector: 'app-sepa-form',
	templateUrl: './sepa-form.component.html',
	styleUrls: ['./sepa-form.component.sass']
})
export class SepaFormComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() public product: any;
  @Input() public couponObj: any;
	@Input() public invoice: any;
	@Input() public subscription: any;
	@Input() public changePaymentMethod: boolean;

	@ViewChild('sepaInfo') public sepaInfo: ElementRef;

	@Output() public failure: EventEmitter<any> = new EventEmitter<any>();
	@Output() public success: EventEmitter<any> = new EventEmitter<any>();

	public processing: boolean;
	public error: string;
	public bankName: string;
	public handler = this.onChange.bind(this);

	private sepa: any;

	constructor(private changeDetectorRef: ChangeDetectorRef,
				private dialog: MatDialog,
				private paymentsService: PaymentsService,
				@Inject(PLATFORM_ID) private platformId: Object,
				private accountDataService: AccountDataService,
				private shopsService: ShopsService) {
	}

	public async ngOnInit() {
		try {
			const accountData = this.accountDataService.accountData || await this.accountDataService.getAccountData().toPromise();
		} catch (err) {
			this.failure.emit(err);
		}
	}

	public ngAfterViewInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			const currentElements: string[] = elements._elements;
			if (!currentElements.includes('iban')) {
				this.initSepa();
			} else {
				this.failure.emit(true);
			}
		}
	}

	public initSepa() {
		const elementStyles = {
			base: {
				iconColor: '#5F9EA8',
				color: '#282828',
				fontWeight: 500,
				fontFamily: 'Work Sans, Roboto, Open Sans, Segoe UI, sans-serif',
				fontSize: '16px',
				fontSmoothing: 'antialiased',

				'::placeholder': {
					color: '#CFD7DF',
				},
				':-webkit-autofill': {
					color: '#e39f48',
				},
			},
			invalid: {
				iconColor: '#c87575',
				color: '#E25950',

				'::placeholder': {
					color: '#FFCCA5',
				},
			},
		};

		this.sepa = elements.create('iban', {supportedCountries: ['SEPA'], style: elementStyles});

		if (this.sepa) {
			this.sepa.mount(this.sepaInfo.nativeElement);
			this.sepa.addEventListener('change', this.handler);
		}
	}

	public onChange({error, bankName}) {
		if (error) {
			this.error = error.message;
		} else {
			this.error = null;
		}

		if (bankName) {
			this.bankName = bankName;
		} else {
			this.bankName = undefined;
		}

		this.changeDetectorRef.detectChanges();
	}

	public async onSubmit(form: NgForm) {
		if(this.processing)
			return;//To prevent double clicking

		if(!this.sepa || !this.sepa._complete) return;

		this.processing = true;

		this.paymentsService.fetchSepaIntent({
			shopId: this.shopsService.currentShop._id})
		.toPromise()
		// If the card is declined, display an error to the user.
		.then((result) => {
			debugger
			if (result.error) {
				// The card had an error when trying to attach it to a customer.
				throw result;
			}
			return result;
		})
		// .then((result) => { //Maybe can be removed
		// 	stripe
		// 	.createSource(this.sepa, {
		// 		type: 'sepa_debit',
		// 		currency: 'eur',
		// 		owner: {
		// 			name: this.accountDataService.accountData.firstName + (this.accountDataService.accountData.lastName ? ' ' + this.accountDataService.accountData.lastName : ''),
		// 		},
		// 	})
		// 	.then(function(resultSource) {
		// 		// Handle result.error or result.source
		// 		debugger;
		// 		console.log(resultSource);
		// 	});

		// 	return result;
		// })
		.then((result) => {

		return stripe.confirmSepaDebitSetup(
			result.clientSecret,
			{
				payment_method: {
					sepa_debit: this.sepa,
					billing_details: {
								name: this.accountDataService.accountData.firstName + (this.accountDataService.accountData.lastName ? ' ' + this.accountDataService.accountData.lastName : ''),
								email: this.accountDataService.accountData.email,
					},
				},
			}
		).then((result) => {
			if (result.error) {
				// Start code flow to handle updating the payment details.
				// Display error message in your UI.
				// The card was declined (i.e. insufficient funds, card has expired, etc).
				throw result.error;
			} else {
				if (result.setupIntent.status === 'succeeded') {
					// Show a success message to your customer.

					// this.processing = false;
					// this.success.emit(result);
					return {
						shopId: this.shopsService.currentShop._id,
						paymentMethodId: result.setupIntent.payment_method,
					};
				}
			}
		})
		.catch((error) => {
			// displayError(error);
			console.log(error);
			throw error;
		});
	})
	.then(this.onSubscriptionComplete.bind(this))
	.catch(this.handleCardError.bind(this))
	}

	handleCardError(error){
		debugger;
		this.error = error.message;
		console.log(error);
		this.processing = false;
	}


	onSubscriptionComplete(result) {
		let subSubscription;

		if (this.invoice) {
      subSubscription = this.paymentsService.updateSubscription({
        subscriptionId: this.subscription.id,
        shopId: this.shopsService.currentShop._id,
        paymentMethodId: result.paymentMethodId,
        invoiceId: this.invoice.id,
      });
    }
		else if(this.changePaymentMethod){//TODO: test if this works
			subSubscription = this.paymentsService.updateSubscription({
				subscriptionId: this.subscription.id,
				shopId: this.shopsService.currentShop._id,
				paymentMethodId: result.paymentMethodId });
		}
		else{
			let subData;
			subData = {
				shopId: this.shopsService.currentShop._id,
				paymentMethodId: result.paymentMethodId,
				priceId: this.product.id};
				if(this.couponObj)
					subData.coupon = this.couponObj.id;
			subSubscription = this.paymentsService.createSepaSubscription(subData);
			}
			
		subSubscription = subSubscription.toPromise();
		// If the card is declined, display an error to the user.
		subSubscription.then((result) => {
			if (result.error) {
				// The card had an error when trying to attach it to a customer.
				throw result;
			}
			return result;
		})
		.then((result) => {

			// Payment was successful.
			// if (result.status === 'active' || result.paymentIntent.status === 'succeeded') {
				// Change your UI to show a success message to your customer.
				// Call your backend to grant access to your service based on
				// `result.subscription.items.data[0].price.product` the customer subscribed to.
				if (this.invoice) {
					this.paymentsService.payInvoice({
						invoiceId: this.invoice.id
					}).toPromise()
					.then((result) => {
						if(result && result.invoice && !result.invoice.paid){
							alert("Wir konnten den aktuellen Vorgang nicht ausführen. Bitte versuche es erneut.")
						}
						else{
							this.processing = false;
							this.success.emit(result);
						}
					})
				.catch(this.handleInvoiceError.bind(this))
				}
				else{
					this.processing = false;
					this.success.emit(result);
				}
			// }
		})

	}


	handleInvoiceError(error){
		if(error && error.error)
			error = error.error;
		this.error = error.message;
		console.log(error);				
		this.processing = false;
	}

	public ngOnDestroy(): void {
		this.sepa.destroy();
	}

}
