<mat-icon [svgIcon]="'app-window-refresh'" class="header-icon"></mat-icon>

<h3 mat-dialog-title>{{ 'DIALOG.UPDATE.TITLE' | translate }}</h3>

<div mat-dialog-content>
    <p>{{ 'DIALOG.UPDATE.EXPLANATION' | translate }}</p>
</div>

<div mat-dialog-actions>
    <button (click)="onDismiss()" mat-raised-button>{{ 'BUTTONS.CANCEL' | translate }}</button>
    <button (click)="onRefresh()" color="primary" mat-raised-button>
        <mat-icon [svgIcon]="'button-refresh-arrows'" align="start"></mat-icon>
        {{ 'BUTTONS.REFRESH' | translate }}
    </button>
</div>
