import { FormControl } from '@angular/forms';
import * as moment from 'moment';

export function DateValidator(format = ['MM/dd/YYYY', 'YYYY-MM-dd', 'dd.MM.YYYY']): any {
	return (control: FormControl): { [key: string]: any } => {
		if (!control.value || control.value === '') {
			return null;
		} else {

			const val = moment(control.value);

			if (!val.isValid()) {
				return {invalidDate: true};
			} else {
				return null;
			}
		}
	};
}
