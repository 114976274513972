import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AccountDataService } from './account-data.service';
import { ApiService } from './api.service';
import { ErrorsService } from './errors.service';
import { LocalStorageService } from './local-storage.service';
import { NotificationsService } from './notifications.service';
import { SessionStorageService } from './session-storage.service';
import { ShopsService } from './shops.service';
import { TokenService } from './token.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	constructor(private tokenService: TokenService,
				private api: ApiService,
				private shopsService: ShopsService,
				private notificationsService: NotificationsService,
				private localStorageService: LocalStorageService,
				private sessionStorageService: SessionStorageService,
				private accountDataService: AccountDataService,
				private errorsService: ErrorsService,
				private injector: Injector,
				@Inject(PLATFORM_ID) private platformId: any) {
	}

	public signUp(name: string, email: string, password: string) {
		const req = this.api.post('signupVendor', {
			name: name,
			email: email,
			password: password,
		});

		req.subscribe(
			(res) => {
				this.onSignUp(res);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public login(email: string, password: string) {
		const req = this.api.post('pro_login', {
			email: email,
			password: password
		});

		req.subscribe(
			(res) => {
				this.onLogIn(res);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public refreshToken() {
		const req = this.api.post('token', { refreshToken: this.tokenService.refreshToken }, false);

		req.subscribe((res) => {
			if (res) {
				this.tokenService.token = res.token;
			} else {
				this.logOut(true);
			}
		}, () => {
			this.logOut(true);
		});

		return req;
	}

	public logOut(refresh?: boolean, url?: string) {
		this.localStorageService.clear();
		this.sessionStorageService.clear();
		this.tokenService.removeToken();

		if (refresh) {
			window.location.reload(true);
		} else if (url) {
			window.location.replace(url);
		} else {
			window.location.replace(`${window.location.origin}/welcome`);
		}
	}

	public authenticateThroughURL(route: ActivatedRouteSnapshot): boolean {
		const token = route.queryParamMap.get('tok');
		const tokenInUrl = !!token;

		if (token) {
			this.tokenService.token = token;
		}

		return tokenInUrl;
	}

	private onSignUp(res) {
		try {
			if (res && res.account) {
				this.tokenService.token = res.token;
				this.tokenService.refreshToken = res.refreshToken;
				this.accountDataService.accountData = res.account;
			}
		} catch (e) {
			console.error(e);
			this.errorsService.post(e);
			if (!this.accountDataService) {
				this.accountDataService = this.injector.get(AccountDataService);
				this.accountDataService.accountData = res.account;
			}
		}
	}

	private onLogIn(res) {
		if (res && res.account && res.account.vendor) {
			this.tokenService.token = res.token;
			this.tokenService.refreshToken = res.refreshToken;
			this.accountDataService.accountData = res.account;
			this.shopsService.getShops();
			this.notificationsService.getNotifications();
		}
	}

}
