import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators';
import { HelpersService } from './helpers.service';

@Injectable()
export class PendingService {

	get pending(): Map<string, Observable<any>> {
		return this._pending;
	}

	set pending(value: Map<string, Observable<any>>) {
		this._pending = value;
	}

	private _pending = new Map<string, Observable<any>>();

	private static createKey(method: string, url: string, params: HttpParams, body: Object): string {
		let key = `${method}_${url}`;

		if (params) {
			key += params.toString();
		}

		if (body) {
			key += HelpersService.safelyStringify(body);
		}

		return key;
	}

	public intercept(method: string, url: string, params: HttpParams, body: Object, requestObservable: Observable<Object>): Observable<any> {
		const key = PendingService.createKey(method, url, params, body);
		const pendingRequestObservable = this._pending.get(key);
		return pendingRequestObservable ? pendingRequestObservable : this.sendRequest(key, requestObservable);
	}

	private sendRequest(key: string, requestObservable: Observable<any>): Observable<any> {
		this._pending.set(key, requestObservable);
		return requestObservable.pipe(tap(() => {
			this._pending.delete(key);
		}));
	}

}
