import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { AccountDataService } from '../_services';

@Injectable()
export class AccountResolver implements Resolve<any> {

	constructor(private accountDataService: AccountDataService) {
	}

	public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		if (this.accountDataService.accountData) {
			return of(this.accountDataService.accountData);
		} else {
			return this.accountDataService.getAccountData().pipe(
				catchError(error => {
					console.error(`Can't resolve account because of the error: ${error}`);
					return of(null);
				})
			);
		}
	}
}
