<div [style.background-color]="imagePath ? 'transparent' : (name && colorsArray[name?.length] ? colorsArray[name?.length] : colorsArray[0])" [style.height.px]="diameter" [style.width.px]="diameter"
     class="image-holder">
    <img *ngIf="imagePath" [alt]="name || initials"
         [height]="diameter" [src]="cdnPath + '/' + diameter + 'x' + diameter + '/' + imagePath"
         (error)="onImageError($event)"
         [width]="diameter" class="customer-avatar">
    <img *ngIf="!imagePath" [alt]="name || initials"
         [src]="cdnPath + '/' + diameter + 'x' + diameter + '/static/misc/images/1.png'" class="default-avatar">
    <span *ngIf="!imagePath" class="initials">{{ initials || name?.substr(0, 1).toUpperCase() }}</span>
</div>
