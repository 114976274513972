import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class ScrollingService {

	constructor(@Inject(PLATFORM_ID) private platformId: Object,
				private route: ActivatedRoute) {
	}

	public scrollIntoView(fragment: string) {
		if (isPlatformBrowser(this.platformId) && fragment) {
			try {
				document.querySelector(`#${fragment}`).scrollIntoView();
			} catch (e) {
				console.error(e);
				console.error(`Could not scroll to #${fragment}`);
			}
		}
	}

	public scrollToTop() {
		if (isPlatformBrowser(this.platformId) && !this.route.snapshot.fragment) {
			window.scroll(0, 0);
		}
	}

	public manageScroll() {
		if (isPlatformBrowser(this.platformId)) {
			const fragment = this.route.snapshot.fragment;

			if (fragment) {
				this.scrollIntoView(fragment);
			} else {
				this.scrollToTop();
			}
		}
	}
}
