<!--Card-->
<div class="form-card">
    <div class="processing" *ngIf="processing">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
    <div>
        <div class="form-card-main ccol-12 ccol-xs-12">
            <mat-dialog-content class="payment-dialog-content">
                    
                    <div class="flex" *ngIf="!processing" >
                        <div  [className]="product ? 'payment-info ccol-md-8 ccol-xs-8' : 'payment-info ccol-md-12 ccol-xs-12'" style="padding-bottom: 10px;">
                            <div *ngIf="product" class="header-step">
                                <h2>Bitte hinterlegt für die Abrechnung eine Zahlungsart:</h2>
                                <ul class="list-unstyled">
                                    <li>
                                        <mat-icon>check</mat-icon>
                                        Die Premiumplatzierung lässt sich steuerlich absetzen
                                    </li>
                                    <li>
                                        <mat-icon>check</mat-icon>
                                        Ihr erhaltet eine Rechnung über den fälligen Betrag
                                    </li>
                                </ul>
                            </div>
                            <div *ngIf="invoice" class="header-step">
                                <h2>Rechnung {{invoice.number}} über {{invoice.total / 100}} €</h2>
                                <h3>Wie möchtet Ihr die Rechnung begleichen:</h3>
                            </div>
                          <mat-accordion class="payments-accordion">
                            <mat-expansion-panel *ngIf="paymentMethod" [expanded]="changePaymentMethod || paymentMethod ? true : false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div style="display: flex;">
                                            <span>Name: {{customer?.name}} <span *ngIf="paymentMethod?.card">Kreditkarte: endet auf {{paymentMethod?.card?.last4}}</span><span *ngIf="paymentMethod?.sepa_debit">Kontonummer: endet auf {{paymentMethod?.sepa_debit?.last4}}</span></span>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
            
                                <ng-template matExpansionPanelContent>
                                    <a class="methodItem"  *ngFor="let paymentMethod of paymentMethods" (click)="setDefaultMethod(paymentMethod)" [ngClass]="{'selected': paymentMethod?.id == selectedPaymentMethod?.id}">
                                        <mat-icon *ngIf="isDefaultMethod(paymentMethod)" [svgIcon]="'big-green-checkmark'"></mat-icon>
                                        <p>Name: {{customer?.name}}</p>
                                        <p *ngIf="paymentMethod?.card">Kreditkarte: endet auf {{paymentMethod?.card?.last4}}</p>
                                        <p *ngIf="paymentMethod?.sepa_debit">Kontonummer: endet auf {{paymentMethod?.sepa_debit?.last4}}</p>
                                    </a>

                                    <div class="actions">
                                        <div *ngIf="invoiceError" class="errors">
                                            <mat-error>
                                                {{ invoiceError }}
                                            </mat-error>
                                        </div>
                                        <div *ngIf="error" class="errors">
                                            <mat-error>
                                                {{ error }}
                                            </mat-error>
                                        </div>
                                        <a class="spinner-button" *ngIf="selectedPaymentMethod && !product && !invoice" (click)="saveNewPaymentMethod()" mat-raised-button color="accent"><span *ngIf="!processingNewMethod">Jetzt ändern</span><span *ngIf="processingNewMethod">wird geändert...</span><mat-spinner *ngIf="processingNewMethod" diameter="24" ></mat-spinner></a>
                                        <a class="spinner-button" *ngIf="product" mat-raised-button color="accent" (click)="quickBuy()"><span *ngIf="!processingQuickBuy">Jetzt Buchen</span><span *ngIf="processingQuickBuy">wird gebucht...</span><mat-spinner *ngIf="processingQuickBuy" diameter="24" ></mat-spinner></a>
                                        <a class="spinner-button" *ngIf="invoice" mat-raised-button color="accent" (click)="payInvoice()"><span *ngIf="!processingInvoice">Jetzt Bezahlen</span><span *ngIf="processingInvoice">wird bezahlt...</span><mat-spinner *ngIf="processingInvoice" diameter="24" ></mat-spinner></a>
                                    </div>
                                </ng-template>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="!paymentMethod">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Neue Zahlungsart hinzufügen
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
            
                                <ng-template matExpansionPanelContent>

                                        <div class="paymentMethodChooser">
                                            <button (click)="selectNewPamentMethodType('card')" class="paymentOption" [ngClass]="{'selected': defaultNewMethod == 'card'}">
                                                <span class="tableHeader">Kreditkarte</span>
                                                <img src="/assets/img/visa-mastercard.png"/>
                                            </button>
                                            <button (click)="selectNewPamentMethodType('sepa')" class="paymentOption" [ngClass]="{'selected': defaultNewMethod == 'sepa'}">
                                                <!-- <img src="/assets/img/lastschriftlogo.png" width="100"/> -->
                                                <span class="tableHeader">Einzugsermächtigung</span>
                                            </button>
                                        </div>
                                        <div>
                                            <div class="ccol-md-12">
                                                <ng-template [ngIf]="defaultNewMethod == 'card'">
                                                    <app-credit-card-form [changePaymentMethod]="changePaymentMethod" [couponObj]="foundCouponObj" [product]="product" [invoice]="invoice" [subscription]="subscription" (failure)="onFailure($event)" (success)="onSuccess()"></app-credit-card-form>
                                                </ng-template>
                                                <ng-template [ngIf]="defaultNewMethod == 'sepa'">
                                                    <app-sepa-form [changePaymentMethod]="changePaymentMethod" [couponObj]="foundCouponObj" [product]="product" [invoice]="invoice" [subscription]="subscription" (failure)="onFailure($event)" (success)="onSuccess()"></app-sepa-form>
                                                </ng-template>
                                            </div>
                                        </div>
                                </ng-template>
                            </mat-expansion-panel>
                          </mat-accordion>

                          <div class="mobile" *ngIf="product">
                            <div class="flex space">
                                <h3 class="bold">MwSt. (19%)</h3>
                                <h3 class="bold">{{productMwSt.toFixed(2)}} €</h3>
                            </div>
                            <div class="flex space">
                                <h3 class="bold">Gesamt</h3>
                                <h3 class="bold">{{productTotal.toFixed(2)}} €</h3>
                            </div>
                            <small>Die Abbuchung erfolgt in der Regel in 1-3 Werktagen. Je nach gewählter Zahlweise kann eine Kündigung zur nächsten Abrechnungsperiode ganz einfach und mit einem Klick auf Ihrer WeddyPlace-Pro Kontoseite durchgeführt werden.</small>
                          </div>
                        </div>
                        <div class="ccol-md-4 ccol-xs-4 product-info" *ngIf="product">
                            <h2>Dein Warenkorb</h2>
                            <mat-radio-group style="display: flex; flex-direction: column; gap: 5px;" (change)="onRadioGroupChange($event)">
                                <mat-radio-button [checked]="paymentYearly" color="accent"
                                                  value="yearly">Jährlich <span style="color: green;">(2 Monate kostenlos!)</span></mat-radio-button>
                                <mat-radio-button [checked]="!paymentYearly" color="accent"
                                                  value="monthly">Monatlich</mat-radio-button>
                            </mat-radio-group>
                            <hr />
                            <div class="flex space">
                                <h3>{{product.nickname}}</h3>
                                <h3 class="bold">{{productNet.toFixed(2)}} €</h3>
                            </div>

                            <div class="flex center" *ngIf="!changePaymentMethod && !invoice">
                                <input [(ngModel)]="couponCode" class="coupon-input" matInput type="text" placeholder="Gutscheincode eingeben">
                                <!-- <div *ngIf="foundCoupon" class="flex center">
                                    <mat-icon>check_circle</mat-icon>
                                    <span *ngIf="foundCouponObj.coupon.amount_off">-{{foundCouponObj.coupon.amount_off / 100}} €</span>
                                    <span *ngIf="foundCouponObj.coupon.percent_off">-{{foundCouponObj.coupon.percent_off}} %</span>
                                </div> -->
                                <a class="spinner-button coupon-apply" mat-raised-button color="accent" (click)="applyCoupon()"><span *ngIf="!processingCoupon">Anwenden</span><mat-spinner *ngIf="processingCoupon" diameter="20"></mat-spinner></a>
                            </div>
                            <span *ngIf="couponError">{{couponError}}</span>
                            <div *ngIf="foundCoupon" style="margin-top: 20px" class="flex space">
                                <h3 style="margin-bottom:0;">Rabatt</h3>
                                <h3  style="margin-bottom:0;" class="bold">
                                    <div  class="flex center">
                                        <span *ngIf="foundCouponObj.coupon.amount_off">- {{foundCouponObj.coupon.amount_off / 100}} €</span>
                                        <span *ngIf="foundCouponObj.coupon.percent_off">- {{foundCouponObj.coupon.percent_off}} %</span>
                                    </div>
                                </h3>
                            </div>
                            <hr>
                            <div class="desktop">
                                <div class="flex space">
                                    <h3 class="bold">MwSt. (19%)</h3>
                                    <h3 class="bold">{{productMwSt.toFixed(2)}} €</h3>
                                </div>
                                <div class="flex space">
                                    <h3 class="bold">Gesamt</h3>
                                    <h3 class="bold">{{productTotal.toFixed(2)}} €</h3>
                                </div>
                                <small>Die Abbuchung erfolgt in der Regel in 1-3 Werktagen. Je nach gewählter Zahlweise kann eine Kündigung zur nächsten Abrechnungsperiode ganz einfach und mit einem Klick auf Ihrer WeddyPlace-Pro Kontoseite durchgeführt werden.</small>
                            </div>
                        </div>
                    </div>
                    
            </mat-dialog-content>

        </div>
    </div>
</div>
