import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'getPosition',
	pure: true
})
export class GetPositionPipe implements PipeTransform {

	public transform(value: HTMLElement, xLerp: number, yLerp: number): { x, y } | null {
		if (value != null) {
			const boundingRect = value.getBoundingClientRect();
			return {
				x: boundingRect.left + xLerp * boundingRect.width,
				y: boundingRect.top + yLerp * boundingRect.height,
			};
		} else {
			return null;
		}
	}

}
