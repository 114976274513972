<div style="min-width: 500px; display: flex; justify-content: flex-end;">
    <button [mat-dialog-close]="false" mat-icon-button>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="text-center">
    <div style="display: flex; flex-direction: column;align-items: center">
        <h1>Du hast noch freie Kapazitäten?</h1>
        <p style="color: #637283">Du hast freie Tage?<br> Sag uns wann und wir werden versuchen dich an verschiedenen
            Stellen hier auf WeddyPlace<br> mit einem besonderen plus an Liebe zu vermitteln.</p>
    </div>

    <div style="display:flex;flex-direction: column;justify-content: center;max-width: 600px">
        <p>{{freeDates.length > 0 ? 'Deine WeddyPlace+ Tage' : 'Du hast noch keine freien Daten angelegt'}}</p>
        <div style="display:flex; flex-direction: row;flex-wrap: wrap">
            <div *ngFor="let freeDate of freeDates;let i=index">
                <button (click)="removeFreeDate(i)" mat-icon-button>
                    <mat-icon>delete_outline</mat-icon>
                </button>
                <span>{{freeDate | date}} {{freeDate | date:'EEEE'}}</span>
            </div>
        </div>
    </div>

    <div style="display: flex;flex-direction: column;margin-top: 15px">

        <div style="display: flex;align-items: center">
            <mat-icon style="margin-right: 25px">query_builder</mat-icon>
            <!-- Date1 -->
            <mat-form-field (click)="onDateFromInputClick()" style="width: 125px">
                <input (dateChange)="onFromChange($event)" [formControl]="form.controls['date']" [matDatepicker]="fromPicker" [min]="rangeFromMinDate"
                       [placeholder]="'Am'"
                       matInput readonly>
                <mat-datepicker-toggle [for]="fromPicker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #fromPicker [startAt]="rangeFromStartAt"
                                [touchUi]="mobileQuery?.matches"></mat-datepicker>
            </mat-form-field>
            <span (click)="onDateFromInputClick()" style="margin-left: 5px; cursor: pointer; color: #828282">Wähle hier ein Datum</span>
        </div>

        <a (click)="addSecondDate($event)" href="#"
           style="text-align: left; margin-bottom: 10px;margin-left: 50px; font-size: 11px">Du möchtest noch ein weiteres Datum belegt haben?</a>

    </div>

</mat-dialog-content>

<mat-dialog-actions style="display:flex;justify-content: flex-end;margin-bottom: 0px">
    <button (click)="onBlock()" color="accent" mat-button mat-raised-button>{{ 'Starten' }}</button>
</mat-dialog-actions>
