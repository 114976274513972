import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../_interfaces/product.interface';
import { AccountDataService } from './account-data.service';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class PaymentsService {

	constructor(public api: ApiService,
	            private accountDataService: AccountDataService) {
	}

	public getProducts() {
		const req = this.api.get(`products`);

		req.subscribe(
			(res) => {

			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public getProduct(productId: string): Observable<Product> {
		const req = this.api.get(`product/${productId}`);

		req.subscribe(
			(res) => {

			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}


	public getPaymentMethod(paymentMethodId: string): Observable<any> {
		const req = this.api.post(`pro_getPaymentMethod`, {paymentMethodId: paymentMethodId});

		req.subscribe(
			(res) => {

			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}


	public getPaymentMethods(): Observable<any> {
		const req = this.api.post(`pro_getPaymentMethods`);

		req.subscribe(
			(res) => {

			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public getInvoices(shopId: string): Observable<any> {
		const req = this.api.post(`pro_getInvoices`, {shopId: shopId});

		req.subscribe(
			(res) => {

			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}


	public getSubscription(subscriptionId: string): Observable<any> {
		const req = this.api.post(`pro_getSubscription`, {subscriptionId: subscriptionId});

		req.subscribe(
			(res) => {

			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}


	public getPrices(category: string): Observable<any> {
		const req = this.api.post(`pro_products`, {cat: category});

		req.subscribe(
			(res) => {

			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}
	
	public getCustomer(): Observable<any> {
		const req = this.api.post(`pro_getSubscriptionCustomer`, {});

		req.subscribe(
			(res) => {

			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}
	


	public cancelSubscription(subscriptionId: string, shopId: string): Observable<any> {
		const req = this.api.post(`pro_cancelSubscription`, {subscriptionId: subscriptionId, shopId: shopId});

		req.subscribe(
			(res) => {

			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}


	public upgradeSubscription(subscriptionId: string, priceId: string, shopId: string): Observable<any> {
		const req = this.api.post(`pro_upgradeSubscription`, {subscriptionId: subscriptionId, priceId: priceId, shopId: shopId});

		req.subscribe(
			(res) => {

			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}



	public payInvoice(data: any): Observable<any> {
		const req = this.api.post(`pro_payInvoice`, data);

		req.subscribe(
			(res) => {

			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}
	
	
	public createSubscription(details) {
		const req = this.api.post('pro_createSubscription', details);

		req.subscribe(
			(res) => {
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}


	public updateSubscription(details) {
		const req = this.api.post('pro_updateSubscription', details);

		req.subscribe(
			(res) => {
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public checkCoupon(code) {
		const req = this.api.post('pro_checkCoupon', {code: code});

		req.subscribe(
			(res) => {
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}
	


	

	/**
	 * fetchSepaIntent
	 */
	public fetchSepaIntent(details) {
		const req = this.api.post('pro_fetchSepaSetupIntent', details);

		req.subscribe(
			(res) => {
				// this.accountDataService.accountData.paymentDone = true;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}


	public createSepaSubscription(details) {
		const req = this.api.post('pro_createSepaSubscription', details);

		req.subscribe(
			(res) => {
				// this.accountDataService.accountData.paymentDone = true;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public createCard(token) {
		const req = this.api.post('pro_createCard', {token: token});

		req.subscribe(
			(res) => {
				// this.accountDataService.accountData.paymentDone = true;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public createSepa(source) {
		const req = this.api.post('pro_createSepa', {source: source});

		req.subscribe(
			(res) => {
				// this.accountDataService.accountData.paymentDone = true;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public createSofortOrder(source) {
		const req = this.api.post('pro_createSofort', {source: source});

		req.subscribe(
			(res) => {
				// this.accountDataService.accountData.paymentDone = true;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public createOrder(body) {
		const req = this.api.post('pro_createorder', body);

		req.subscribe(
			(res) => {
				// this.accountDataService.accountData.paymentDone = true;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}
}
