import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelpersService } from './helpers.service';

@Injectable()
export class CacheService {

	get cache(): Map<string, [Date, HttpResponse<any>]> {
		return this._cache;
	}

	set cache(value: Map<string, [Date, HttpResponse<any>]>) {
		this._cache = value;
	}

	private _cache = new Map<string, [Date, HttpResponse<any>]>();

	public static addSkipParams(params: HttpParams, value: boolean): HttpParams {
		if (!params) {
			params = new HttpParams();
		}
		return params.append('skipCache', HelpersService.safelyStringify(value));
	}

	public get(key: string): HttpResponse<any> {
		const tuple = this._cache.get(key);

		if (!tuple) {
			return null;
		}

		const expires = tuple[0];
		const response = tuple[1];

		const now = new Date();
		if (expires && expires.getTime() < now.getTime()) {
			this._cache.delete(key);
			return null;
		}

		return response;
	}

	public set(key: string, response: HttpResponse<any>, ttl: number = null) {
		if (ttl) {
			const expires: Date = new Date();
			expires.setSeconds(expires.getSeconds() + ttl);
			this._cache.set(key, [expires, response]);
		} else {
			this._cache.set(key, [null, response]);
		}
	}

}
