import { Injectable, OnInit } from '@angular/core';
import { ApiService } from './api.service';
import { Project } from '../_interfaces/project.interface';
import { ShopsService } from './shops.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Shop } from '../_interfaces/shop.interface';

@Injectable({
	providedIn: 'root'
})
export class ProjectsService implements OnInit {

	get projects(): Project[] {
		return this._projects;
	}

	set projects(newProjects: Project[]) {
		this._projects = newProjects;
		this.projectsChanged.next(this.projects);
	}

	public projectsChanged = new BehaviorSubject<Project[]>(this.projects);
	public currentShopSubscription: Subscription;

	private _projects: Project[] = [];

	constructor(public api: ApiService,
				private shopsService: ShopsService) {

	}

	public ngOnInit() {
		this.subscribeToCurrentShop();
	}

	public subscribeToCurrentShop() {
		this.currentShopSubscription = this.shopsService.currentShopChanged.subscribe(
			(currentShop) => {
				this.projects = currentShop.projects;
			}
		);
	}

	public getProject(projectId) {
		const req = this.api.get(`projects/${projectId}`, null, false, true);

		req.subscribe(
			(project: Project) => {
				// Data found in server
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public async getProjects() {
		await this.shopsService.getCurrentShop();
		this.projects = this.shopsService.currentShop.projects;
	}

	public addProject(project: Project) {
		const index = (this.projects.findIndex((anyProject: Project) => {
			return anyProject._id === project._id;
		}));
		if (index === -1) {
			const currentShop: Shop = this.shopsService.currentShop;
			currentShop.projects.push(project);
			this.shopsService.currentShop = currentShop;
			this.projects = this.shopsService.currentShop.projects;
		}
	}

	public postProject(project: Project) {
		const req = this.api.post('projects', project, true);

		req.subscribe(
			(newProject: Project) => {
				this.addProject(newProject);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public editProject(project: Project) {
		const index = (this.projects.findIndex((anyProject: Project) => {
			return anyProject._id === project._id;
		}));
		if (index >= 0) {
			const currentShop: Shop = this.shopsService.currentShop;
			currentShop.projects[index] = project;
			this.shopsService.currentShop = currentShop;
			this.projects = this.shopsService.currentShop.projects;
		}
	}

	public updateProject(project: Project) {
		const req = this.api.put('projects', project, true);

		req.subscribe(
			(res) => {
				this.editProject(res);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public removeProject(id) {
		const index = (this.projects.findIndex((anyProject: Project) => {
			return anyProject._id === id;
		}));
		if (index >= 0) {
			const currentShop: Shop = this.shopsService.currentShop;
			currentShop.projects.splice(index, 1);
			this.shopsService.currentShop = currentShop;
			this.projects = this.shopsService.currentShop.projects;
		}
	}

	public deleteProject(project: Project) {
		const req = this.api.delete('projects', `${this.shopsService.currentShop._id}/${project._id}`, true);

		req.subscribe(
			() => {
				this.removeProject(project._id);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

}
