import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Appointment, CalEvent } from '../_interfaces';
import { ApiService } from './api.service';
import { ShopsService } from './shops.service';

@Injectable({
	providedIn: 'root'
})
export class BlockedDatesService {

	get blockedDates(): Appointment[] {
		return this._blockedDates;
	}

	set blockedDates(newBusyDates: Appointment[]) {
		this._blockedDates = newBusyDates;
		this.blockedDatesChanged.next(this.blockedDates);
	}

	public blockedDatesChanged = new Subject<Appointment[]>();

	private _blockedDates: Appointment[];

	constructor(public api: ApiService,
				private shopsService: ShopsService) {
	}

	public getBlockedDate(busyDateId) {
		return this.api.get(`busyDates/${busyDateId}`, null, false, true);
	}

	public getBlockedDates() {
		return this.api.get(`busyDates`, null, false, true);
	}

	public addBlockedDate(busyDate: Appointment) {
		const index = (this.blockedDates.findIndex((anyAppointment: Appointment) => {
			return anyAppointment._id === busyDate._id;
		}));
		if (index === -1) {
			this.blockedDates.push(busyDate);
		}
	}

	public postBlockedDate(busyDate): Observable<CalEvent> {
		busyDate.vendorId = this.shopsService.currentShop._id;
		return this.api.post('pro_busyDatesBlock', busyDate, true);
	}

	public editBlockedDate(busyDate: Appointment) {
		const index = (this.blockedDates.findIndex((anyAppointment: Appointment) => {
			return anyAppointment._id === busyDate._id;
		}));
		if (index >= 0) {
			this.blockedDates[index] = busyDate;
		}
	}

	public updateBlockedDate(busyDate: Appointment) {
		busyDate.vendorId = this.shopsService.currentShop._id;

		const req = this.api.put('busyDates', busyDate, true);

		req.subscribe(
			(res) => {
				this.editBlockedDate(res.accommodation);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public removeBlockedDate(id) {
		const index = (this.blockedDates.findIndex((anyAppointment: Appointment) => {
			return anyAppointment._id === id;
		}));
		if (index >= 0) {
			this.blockedDates.splice(index, 1);
		}
	}

	public deleteBlockedDate(start) {
		const unblockRange = {
			start: start,
			vendorId: this.shopsService.currentShop._id
		};
		return this.api.post('pro_busyDatesUnblock', unblockRange, true);
	}

}
