import { FormControl } from '@angular/forms';

export function mapsPlaceValidator(control: FormControl): { [key: string]: boolean } | null {
	const value = control.value;

	if (!value || !value['city']) {
		return {noCitySelected: true};
	} else {
		return null;
	}
}
