import { HttpParams } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { environment } from '../../../environments/environment';
import { APPLICATION_NAME, ISSUES_URL } from '../_others/tokens';
import { AccountDataService } from './account-data.service';

@Injectable({
	providedIn: 'root'
})
export class IssuesService {

	constructor(private accountDataService: AccountDataService) {
	}

	public openIssue(error?: Error) {
		const title: string = error ? `${error.name}: ${error.message}` : '';
		const description: string = `## Environment

NAVIGATOR: ${window.navigator}

URL: ${window.location.href}

App: ${APPLICATION_NAME} ${environment.version} ${isDevMode() ? '(DEV)' : ''}

${this.accountDataService.accountData ? `User: ${this.accountDataService.accountData.email} [(open in admin)](https://admin.weddyplace.com/accounts/${this.accountDataService.accountData._id})` : ''}

${this.accountDataService.accountData ? `Kibana: http://kibana.weddyplace.com/app/kibana#/discover?_g=(refreshInterval:(pause:!t,value:0),time:(from:now%2Fd,mode:quick,to:now%2Fd))&_a=(columns:!(referrer,request),filters:!(),interval:auto,query:(language:lucene,query:'*kloguid=${this.accountDataService.accountData._id}*'),sort:!('@timestamp',desc))` : ''}

## Description of the problem

## Expected behaviour

## Attachments

	${error ? error.stack : ''}

`;

		let params: HttpParams = new HttpParams();
		if (title) {
			params = params.append('issue[title]', title);
		}
		if (description) {
			params = params.append('issue[description]', description);
		}
		window.open(`${ISSUES_URL}/new?${params.toString()}`, '_blank');
	}

}


// OS: ${this.deviceDetector.os} (${this.deviceDetector.os_version})

// Device: ${this.deviceDetector.device} (with dimensions ${window.innerWidth}x${window.innerHeight}px)

// Browser: ${this.deviceDetector.browser} ${this.deviceDetector.browser_version}