import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { IS_PRE_RENDER } from '../_others/tokens';

@Injectable({
	providedIn: 'root'
})
export class RenderingService {

	public isPreRendered: boolean;

	public isMobile: boolean;
	public isIEOrEdge: boolean;
	public isHandset: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map(obs => obs.matches));

	constructor(private breakpointObserver: BreakpointObserver,
				private meta: Meta,
				@Inject(PLATFORM_ID) private platformId: any,
				@Optional() @Inject(IS_PRE_RENDER) private isPreRender,
				@Optional() @Inject(REQUEST) private request: any) {
		if (isPlatformServer(this.platformId)) {
			this.isPreRendered = this.isPreRender;

			if (this.isPreRendered) {
				this.addRenderingAgentTag('server-side-pre-rendered');
			} else {
				this.addRenderingAgentTag('server-side-rendered');
				try {
					if (this.request && this.request.headers && this.request.headers['user-agent']) {
						const mobileRegex: RegExp = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/;
						this.isMobile = mobileRegex.test(this.request.headers['User-Agent']);
						this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(this.request.headers['User-Agent']);
					}
				} catch (e) {
					console.error(e);
				}
			}
		} else {
			this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
			this.isHandset.subscribe((isHandset) => {
				this.isMobile = isHandset;
			});
		}
	}

	public wasServerSidePreRendered() {
		return this.getRenderingAgent() === 'server-side-pre-rendered';
	}

	public wasServerSideRendered() {
		return this.getRenderingAgent() === 'server-side-rendered';
	}

	public wasClientSideRendered(): boolean {
		return this.getRenderingAgent() === 'client-side-rendered';
	}

	public getRenderingAgentObject(): { short: string; long: string } {
		if (this.wasServerSideRendered()) {
			return {
				long: 'Rendered by server',
				short: 'SR'
			};
		} else if (this.wasServerSidePreRendered()) {
			return {
				long: 'Pre-rendered by server',
				short: 'PR'

			};
		} else if (this.wasClientSideRendered()) {
			return {
				long: 'Rendered by client',
				short: 'CR'

			};
		}
	}

	private addRenderingAgentTag(agent: 'server-side-pre-rendered' | 'server-side-rendered' | 'client-side-rendered') {
		this.meta.addTag({ property: 'rendering:agent', content: agent });
	}

	private getRenderingAgent(): 'server-side-pre-rendered' | 'server-side-rendered' | 'client-side-rendered' {
		const metaTag: HTMLMetaElement = this.meta.getTag('property="rendering:agent"');

		if (metaTag) {
			return metaTag.content as 'server-side-pre-rendered' | 'server-side-rendered';
		} else {
			this.addRenderingAgentTag('client-side-rendered');
			return 'client-side-rendered';
		}
	}

}
