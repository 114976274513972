import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tag } from '../_interfaces/tag.interface';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class TagsService {

	get tags(): Tag[] {
		return this._tags;
	}

	set tags(value: Tag[]) {
		this._tags = value;
	}

	private _tags: Tag[];

	constructor(private api: ApiService) {

	}

	public getAllTags(category: String): Observable<Tag[]> {
		const req = this.api.get(`pro_tags/${category}`, null, null, true);

		req.subscribe(
			(tags: Tag[]) => {
				this.tags = tags;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}
}
