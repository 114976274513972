import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, isDevMode, PLATFORM_ID } from '@angular/core';
import * as SafeJsonStringify from 'safe-json-stringify';
import { WeddyPlaceError } from '../_interfaces';

@Injectable({
	providedIn: 'root'
})
export class HelpersService {

	constructor(@Inject(PLATFORM_ID) private platformId: Object) {
	}

	public static shuffle(array): any[] {
		if (!array) {
			console.warn('Trying to shuffle an undefined array');
			return array;
		}

		let currentIndex = array.length;
		let temporaryValue;
		let randomIndex;

		while (0 !== currentIndex) {

			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;

			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}

		return array;
	}

	public static orderBy(arr: {}[], props: string[], orders: 'desc' | 'asc') {
		return [...arr].sort((a, b) =>
			props.reduce((acc, prop, i) => {
				if (acc === 0) {
					const [p1, p2] = orders && orders[i] === 'desc' ? [b[prop], a[prop]] : [a[prop], b[prop]];
					acc = p1 > p2 ? 1 : p1 < p2 ? -1 : 0;
				}
				return acc;
			}, 0)
		);
	}

	public static randomNumberInRange(min: number, max: number, integer?: boolean) {
		return integer ? Math.floor((Math.random() * (max - min) + min)) : (Math.random() * (max - min) + min);
	}

	public static isPlainObject(val) {
		return !!val && typeof val === 'object' && val.constructor === Object;
	}

	public static mapValues(obj: {}, fn: Function) {
		return Object.keys(obj).reduce((acc, k) => {
			acc[k] = fn(obj[k], k, obj);
			return acc;
		}, {});
	}

	public static copyToClipboard(str: string) {
		const el = document.createElement('textarea');
		el.value = str;
		el.setAttribute('readonly', '');
		el.style.position = 'absolute';
		el.style.left = '-9999px';
		document.body.appendChild(el);
		const selected =
			document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
		if (selected) {
			document.getSelection().removeAllRanges();
			document.getSelection().addRange(selected);
		}
	}

	public static coalesce(...args) {
		return args.find(_ => ![undefined, null].includes(_));
	}

	public static removeUndefinedProperties(obj): void {
		return Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
	}

	public static safelyGet(fn: Function, defaultValue?: any) {
		try {
			return fn();
		} catch (e) {
			if (isDevMode()) {
				console.warn(e.message);
			}
			return defaultValue;
		}
	}

	public static safelyStringify(obj, replacer = null, spaces = 2, cycleReplacer?) {
		return SafeJsonStringify(obj);
	}

	public static removeDuplicates(arr): any[] {
		const s: Set<any> = new Set(arr);
		const it: IterableIterator<any> = s.values();
		return Array.from(it);
	}

	public static groupBy(array, fn): any[] {
		return array.reduce((grouped, object) => {
			const value = fn(object);
			grouped[value] = grouped[value] || [];
			grouped[value].push(object);
			return grouped;
		}, {});
	}

	public static isJSON(string: string) {
		return /^[\],:{}\s]*$/.test(string.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''));
	}

	public static isLocalHost(): boolean {
		return window.location.href.indexOf('localhost') > -1;
	}

	public static assert(condition: any, identifier: string, message?: string, justWarn?: boolean) {
		if (!HelpersService.safelyGet(() => condition)) {
			if (justWarn) {
				console.warn(message || `${identifier} could not be asserted`);
			} else {
				throw new WeddyPlaceError(message || `${identifier} could not be asserted`);
			}
		}
	}

	public static hash(string: string) {
		return string.split('').reduce((a, b) => {
			a = ((a << 5) - a) + b.charCodeAt(0);
			return a & a;
		}, 0);
	}

	public static getClosestInArray(searchArray: number[], target: number) {
		HelpersService.assert(searchArray, 'searchArray');
		HelpersService.assert(target, 'target');

		let current = searchArray[0];
		let difference = Math.abs(target - current);
		for (let val = 0; val < searchArray.length; val++) {
			const newDifference = Math.abs(target - searchArray[val]);
			if (newDifference < difference) {
				difference = newDifference;
				current = searchArray[val];
			}
		}
		return current;
	}

	public static assertNotEmpty(array: any[], identifier: string, message?: string) {
		HelpersService.assert(array, identifier, undefined, false);

		if (array.length === 0) {
			throw new WeddyPlaceError(message || `The array identified as ${identifier} is empty`);
		}
	}

	public static generateUniqueId() {
		return `_${Math.random().toString(36).substr(2, 9)}`;
	}

	public static jsonEqual(a: any, b: any): boolean {
		return HelpersService.safelyStringify(a) === HelpersService.safelyStringify(b);
	}

	public static clone(o: any): any {
		return JSON.parse(JSON.stringify(o));
	}

	public static truncate(n, useWordBoundary) {
		if (n.length <= n) {
			return n;
		}
		const subString = n.substr(0, n - 1);
		return `${useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString}...`;
	}

	public static flatten(array: any[]): any[] {
		return array.reduce((flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? HelpersService.flatten(toFlatten) : toFlatten), []);
	}

	public static updateQueryStringParameter(uri, key, value) {
		const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
		const separator = uri.indexOf('?') !== -1 ? '&' : '?';
		if (uri.match(re)) {
			return uri.replace(re, '$1' + key + '=' + value + '$2');
		} else {
			return uri + separator + key + '=' + value;
		}
	}

	public fixDisappearIOSBug() {
		if (isPlatformBrowser(this.platformId)) {
			const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
			if (iOS) {
				const styleNode = document.createElement('style');
				styleNode.type = 'text/css';
				styleNode.id = 'panel-fix';
				styleNode.appendChild(document.createTextNode('.mat-menu-panel{overflow: initial !important;}'));
				document.getElementsByTagName('head')[0].appendChild(styleNode);
				setTimeout(() => {
					styleNode.remove();
				}, 500);
			}
		}
	}

	public blurOutOfEverything() {
		if (isPlatformBrowser(this.platformId)) {
			const tmp = document.createElement('input');
			document.body.appendChild(tmp);
			tmp.focus();
			document.body.removeChild(tmp);
		}
	}

	public static buildHumanName(firstName: string, secondName?: string) {
		if (firstName && secondName) {
			return `${firstName} ${secondName}`;
		} else {
			return firstName;
		}
	}

	public checkObject(object: {}, keys: string[] | 'all') {
		if (!object) {
			throw new WeddyPlaceError(`A trivial object is missing`);
		}

		if (typeof object !== 'object') {
			throw new WeddyPlaceError(`'${object}' should be an object but it's a ${typeof object}`);
		}

		if (Object.entries(object).length === 0 && object.constructor === Object) {
			throw new WeddyPlaceError(`The object '${object.constructor.name || object.toString()}' is defined, but empty`);
		}

		Object.keys(object).forEach(
			(key) => {
				if ((keys.indexOf(key) > -1 || keys === 'all') && !object[key]) {
					throw new WeddyPlaceError(`The object '${object.constructor.name || object.toString()}' is missing the property: ${key}`);
				}
			}
		);
	}
}
