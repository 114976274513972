<div class="row" id="conditions">
    <div class="col-12">
        <h1>Einfach, transparent, sicher</h1>
        <hr>
        <ul class="list-unstyled list">
            <li>
                <mat-icon>check</mat-icon>
                Kostenlose Anfragen
            </li>
            <li>
                <mat-icon>check</mat-icon>
                Keine monatliche Gebühr
            </li>
            <li>
                <mat-icon>check</mat-icon>
                Keine Einrichtungsgebühr
            </li>
            <li>
                <mat-icon>check</mat-icon>
                Jederzeit kündbar
            </li>
        </ul>
        <hr>
        <div class="cards">
            <div class="card">
                <span class="header">0€</span>
                <span class="hint">MONATCHLICH</span>
            </div>
            <div class="card">
                <span class="header">0€</span>
                <span class="hint">PRO ANFRAGE</span>
            </div>
            <div class="card">
                <span class="header">7%</span>
                <span class="hint">PROVISION BEI BUCHUNG:</span>
                <ul class="list-unstyled">
                    <li>
                        <mat-icon>done</mat-icon>
                        Bezieht sich auf Nettoumsatz exkl. Fremdleistungen
                    </li>
                </ul>
            </div>
        </div>
        <ul class="list-unstyled bottom-list">
            <li>
                <mat-icon>done</mat-icon>
                Über die Provision besteht Geheimhaltung. Sie darf dem Kunden nicht genannt werden oder
                auf der Rechnung ausgewiesen werden.
            </li>
            <li>
                <mat-icon>done</mat-icon>
                Absichtliches Fehlverhalten wird mit Ausschluss geahndet.
            </li>
            <li>
                <mat-icon>done</mat-icon>
                Mit einem Klick auf 'Bestätigen' stimme ich den <a href="https://www.weddyplace.com/l/impressum"
                                                       target="_blank"> AGBs </a> und der
                <a href="{{koopUrl}}" target="_blank">Kooperationsvereinbarung</a> inkl.
                <a href="{{anlageUrl}}" target="_blank">Anlage A</a>
                für <strong>{{ shopsService?.currentShop?.name }}</strong> zu.
            </li>
        </ul>
        <p>Falls Ihr noch Fragen habt, <br>
            unterstützen wir Euch gerne telefonisch unter <a
                    href="tel:+4903040366899">030 4036 6899</a>.</p>
        <div class="buttons">
            <button (click)="close()" class="previous" mat-button tabindex="-1">
                Zurück
            </button>
            <!--<button mat-button mat-raised-button class="call" (click)="onCallForHelp()">-->
            <!--Rückruf vereinbaren-->
            <!--</button>-->
            <!-- <button (click)="onAcceptConditions()" [disabled]="processing" class="accept" color="primary" mat-button
                    mat-raised-button>
                Bestätigen
            </button> -->
            <button (click)="onAcceptConditions()" [disabled]="processing" class="accept" color="primary" mat-button mat-raised-button>
                <mat-spinner *ngIf="processing" [diameter]="38" style="margin-left:auto;margin-right:auto"></mat-spinner>
                <span *ngIf="!processing">Bestätigen</span>
            </button>
        </div>
    </div>
</div>
