import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NOT_FOUND } from 'http-status-codes';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard, NoAuthGuard } from './_shared/_guards';
import { ErrorComponent } from './_shared/modules/error/error.component';
import { LoadingComponent } from './loading/loading.component';
import { RequestAcceptComponent } from './request-accept/request-accept.component';
import { WelcomeComponent } from './welcome/welcome.component';

const routes: Routes = [
	{path: '', component: WelcomeComponent, canActivate: [NoAuthGuard]},
	{path: 'dashboard', canActivate: [AuthGuard], loadChildren: './dashboard/dashboard.module#DashboardModule'},
	{path: 'welcome', component: WelcomeComponent, canActivate: [NoAuthGuard]},
	{path: 'welcome/:mode', redirectTo: '/welcome'},
	{path: 'login', redirectTo: '/welcome'},
	{path: 'req_acc', component: RequestAcceptComponent},
	{path: 'on-boarding', loadChildren: './on-boarding/on-boarding.module#OnBoardingModule', canActivate: [NoAuthGuard]},
	{path: 'help', loadChildren: './help/help.module#HelpModule'},
];

const legacyRoutes: Routes = [
	{path: 'sign-up', redirectTo: '/on-boarding'},
];

const defaultRoutes: Routes = [
	{path: 'loading', component: LoadingComponent},
	{path: 'password', loadChildren: './password/password.module#PasswordModule'},
	{path: 'forgot', loadChildren: './password/password.module#PasswordModule', canActivate: [NoAuthGuard]},
	{path: 'error', component: ErrorComponent},
	{path: 'error/:errorCode', component: ErrorComponent},
	{path: '**', redirectTo: `error/${NOT_FOUND}`},
];

@NgModule({
	imports: [RouterModule.forRoot([...routes, ...legacyRoutes, ...defaultRoutes], {initialNavigation: 'enabled', preloadingStrategy: QuicklinkStrategy, anchorScrolling: 'enabled'})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
