import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AppointmentsService } from '../../../_services/appointments.service';
import { InquiriesService } from '../../../_services/inquiries.service';

@Component({
	selector: 'app-opening-hours-dialog',
	templateUrl: './opening-hours-dialog.component.html',
	styleUrls: ['./opening-hours-dialog.component.sass']
})
export class OpeningHoursDialogComponent implements OnInit {
	public opening_hours: { open_now: String, periods: { close: { day: Number, time: String }, open: { day: Number, time: String } }[], weekday_text: [String] };

	constructor(
		public dialogRef: MatDialogRef<OpeningHoursDialogComponent>,
		private appointmentService: AppointmentsService,
		private inquiriesService: InquiriesService,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.opening_hours = {
			open_now: 'closed',
			periods: [
				{open: {day: 1, time: '12:00'}, close: {day: 1, time: '18:00'}},
				{open: {day: 2, time: '12:00'}, close: {day: 1, time: '18:00'}},
				{open: {day: 3, time: '12:00'}, close: {day: 1, time: '18:00'}},
				{open: {day: 4, time: '12:00'}, close: {day: 1, time: '18:00'}},
				{open: {day: 5, time: '12:00'}, close: {day: 1, time: '18:00'}}
			],
			weekday_text: ['']
		};

	}

	public ngOnInit() {
	}
}
