export class WeddyPlaceError extends Error {
	public httpStatus?: number = 404;
	public applicationStatus?: number;
	public errorMessageTranslationkey: string;
	public handled: boolean = false;
	public name: string;

	constructor(...args) {
		super(...args);
		this.name = WeddyPlaceError.name;
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, WeddyPlaceError);
		}
		Object.setPrototypeOf(this, WeddyPlaceError.prototype);
	}
}
