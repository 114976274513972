import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class InputTrackingService {

	private wordsUsed: Set<string> = new Set();

	constructor() {
	}

	public addWordUsed(word: string) {
		this.wordsUsed.add(word);
	}

	public getWords(): string[] {
		return Array.from(this.wordsUsed);
	}

}
