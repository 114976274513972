export interface SearchParams {
	cat?: string | null;
	loc?: string | null;
	lat?: number | string | null;
	lng?: number | string | null;
	dateTo?: string | null;
	dateFrom?: string | null;
	budgetStart?: number | null;
	budgetEnd?: number | null;
	services?: string | null;
	tags?: string | null;
}

export class SearchParamsClass implements SearchParams {
	cat?: string | null;
	loc?: string | null;
	lat?: number | string | null;
	lng?: number | string | null;
	dateTo?: string | null;
	dateFrom?: string | null;
	budgetStart?: number | null;
	budgetEnd?: number | null;
	services?: string | null;
	tags?: string | null;

	constructor(cat: string | null, loc: string | null, lat: number | string | null, lng: number | string | null, dateTo: string | null, dateFrom: string | null, budgetStart: number | null, budgetEnd: number | null, services: string | null, tags: string | null) {
		this.cat = cat;
		this.loc = loc;
		this.lat = lat;
		this.lng = lng;
		this.dateTo = dateTo;
		this.dateFrom = dateFrom;
		this.budgetStart = budgetStart;
		this.budgetEnd = budgetEnd;
		this.services = services;
		this.tags = tags;
	}
}
