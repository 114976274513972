<button (click)="close()" class="close" mat-icon-button>
    <mat-icon>close</mat-icon>
</button>
<h4 class="header" mat-dialog-title>Ein-Klick Rückmeldung erstellen</h4>

<mat-dialog-content>
    <app-auto-answer-settings (onClose)="close()" (onError)="error($event)" [doSave]="doSaveSubject.asObservable()"
                              [insideDialog]="true"></app-auto-answer-settings>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button (click)="onCancel()" mat-raised-button type="button">
        {{ 'BUTTONS.CANCEL' | translate }}
    </button>
    <button (click)="save()" [disabled]="answerSettings.processing" color="primary" mat-raised-button>
        Speichern
    </button>

    <app-spinner-button *ngIf="answerSettings.processing"></app-spinner-button>
</mat-dialog-actions>
