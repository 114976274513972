import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
	MatAutocompleteModule,
	MatButtonModule,
	MatButtonToggleModule,
	MatCardModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatDialogModule,
	MatExpansionModule,
	MatFormFieldModule,
	MatIconModule,
	MatInputModule,
	MatListModule,
	MatMenuModule,
	MatNativeDateModule,
	MatPaginatorModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatSelectModule,
	MatSidenavModule,
	MatSliderModule,
	MatSlideToggleModule,
	MatSnackBarModule,
	MatStepperModule,
	MatTableModule,
	MatTabsModule,
	MatToolbarModule,
	MatTooltipModule
} from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';

@NgModule({
	imports: [
		CommonModule,
		MatCardModule,
		MatNativeDateModule,
		MatDatepickerModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatStepperModule,
		MatProgressSpinnerModule,
		MatDialogModule,
		MatTableModule,
		MatMenuModule,
		MatAutocompleteModule,
		MatFormFieldModule,
		MatCheckboxModule,
		MatInputModule,
		MatIconModule,
		MatExpansionModule,
		MatSelectModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatDialogModule,
		MatTabsModule,
		MatTooltipModule,
		MatSnackBarModule,
		MatProgressBarModule,
		MatMomentDateModule,
		MatRadioModule,
		MatToolbarModule,
		MatSidenavModule,
		MatListModule,
		MatPaginatorModule
	],
	exports: [
		CommonModule,
		MatCardModule,
		MatNativeDateModule,
		MatDatepickerModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatStepperModule,
		MatProgressSpinnerModule,
		MatDialogModule,
		MatTableModule,
		MatMenuModule,
		MatAutocompleteModule,
		MatFormFieldModule,
		MatCheckboxModule,
		MatInputModule,
		MatIconModule,
		MatExpansionModule,
		MatSelectModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatDialogModule,
		MatTabsModule,
		MatTooltipModule,
		MatSnackBarModule,
		MatProgressBarModule,
		MatMomentDateModule,
		MatRadioModule,
		MatToolbarModule,
		MatSidenavModule,
		MatListModule,
		MatPaginatorModule
	],
	providers: [
		{provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false}},
	]
})
export class MaterialModule {
}
