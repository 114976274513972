import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injector, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { TranslateLoader } from '@ngx-translate/core';
import { join } from 'path';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LOCALES } from './app/_shared/_others/tokens';
import { HttpService } from './app/_shared/_services';

export function translateLoader(transferState: TransferState, httpService: HttpService, injector: Injector, platform_id: object) {
	return new MultiTranslateHttpLoader(
		transferState,
		httpService,
		injector,
		platform_id
	);
}

export class MultiTranslateHttpLoader implements TranslateLoader {
	constructor(private transferState: TransferState,
				private httpService: HttpService,
				private injector: Injector,
				@Inject(PLATFORM_ID) private platformId: Object) {
	}

	private static mergeFiles(response) {
		return response.reduce((a: any, b: any) => {
			a = a; // parse JSON String to Javascript Object
			b = b; // parse JSON String to Javascript Object
			return { ...a, ...b };
		});
	}

	public getTranslation(lang: string): any {
		const browserResources = [
			{ key: `lang_${lang}`, prefix: join('assets/i18n/'), suffix: '.json' },
			{ key: `langExtra_${lang}`, prefix: join('assets/i18n/extraLocales/'), suffix: '.json' }
		];

		const localesKey = makeStateKey(LOCALES);

		if (isPlatformServer(this.platformId)) {
			const allLocales = this.injector.get(LOCALES);
			this.transferState.set(localesKey, allLocales);
			return of(allLocales);
		} else if (isPlatformBrowser(this.platformId)) {
			if (this.transferState.hasKey(localesKey)) {
				const response = this.transferState.get(localesKey, null);
				this.transferState.remove(localesKey);
				return of(response);
			} else {
				return forkJoin(browserResources.map(config => {
					return this.httpService.get(`${config.prefix}${lang}${config.suffix}`);
				})).pipe(map(response => {
					return MultiTranslateHttpLoader.mergeFiles(response);
				}));
			}
		}
	}
}

export function getBrowserCulturalLang(translate) {
	return 'de'; // translate.getBrowserCultureLang();
}

export const clientTranslateConfig = {
	loader: {
		provide: TranslateLoader,
		useFactory: (translateLoader),
		deps: [TransferState, HttpService, Injector, PLATFORM_ID]
	}
};
