import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Inquiry } from '../_interfaces';
import { ApiService } from './api.service';
import { InquiriesService } from './inquiries.service';
import { ShopsService } from './shops.service';

@Injectable({
	providedIn: 'root'
})
export class StatusReportService {

	get inquiriesToReport(): Inquiry[] {
		return this._inquiriesToReport;
	}

	set inquiriesToReport(inquiries: Inquiry[]) {
		this._inquiriesToReport = inquiries;
		this.inquiriesToReportChanged.next(this.inquiriesToReport);
	}

	public inquiriesToReportChanged: BehaviorSubject<Inquiry[]> = new BehaviorSubject<Inquiry[]>(this.inquiriesToReport);
	private _inquiriesToReport: Inquiry[] = [];




	get inquiriesToRecover(): Inquiry[] {
		return this._inquiriesToRecover;
	}

	set inquiriesToRecover(inquiries: Inquiry[]) {
		this._inquiriesToRecover = inquiries;
		this.inquiriesToRecoverChanged.next(this.inquiriesToRecover);
	}

	public inquiriesToRecoverChanged: BehaviorSubject<Inquiry[]> = new BehaviorSubject<Inquiry[]>(this.inquiriesToRecover);
	private _inquiriesToRecover: Inquiry[] = [];




	constructor(private api: ApiService,
				private shopsService: ShopsService,
				private inquiriesService: InquiriesService) {
		this.inquiriesService.inquiriesChanged.subscribe(
			(inquiries) => {
				if (inquiries) {
					this.inquiriesToReport = inquiries.filter(inquiry => inquiry.waitingForStatusReport);
					this.inquiriesToRecover = inquiries.filter(inquiry => (inquiry.vendorState && inquiry.vendorState.wantsToBeRecovered));
					this.inquiriesToRecover.forEach(inquiry => {
						this.inquiriesService.reportRecovery(inquiry);
					});
				}
			}
			);
		}
		
		
	public report(inquiryId: string, statusKey: string, reason?: string): Observable<any> {
		const req = this.api.get(`pro_inquiriesStatusReport/${this.shopsService.currentShop._id}/${inquiryId}/${statusKey}/${reason || '-'}`, undefined, true, true);

		req.subscribe(
			(res) => {
				// console.log(res);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

}
