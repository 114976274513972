<h2 mat-dialog-title>{{ string || ('DIALOGS.DELETE.ARE_YOU_SURE?' | translate) }}</h2>

<mat-dialog-actions align="end">
    <button (click)="dialogRef.close(true)" color="warn" mat-button mat-raised-button>
        {{ 'DIALOGS.DELETE.DELETE' | translate }}
    </button>
    <button (click)="dialogRef.close(false)" mat-button mat-raised-button>
        {{ 'DIALOGS.DELETE.CANCEL' | translate }}
    </button>
</mat-dialog-actions>
