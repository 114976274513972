import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Shop } from '../../../_interfaces/shop.interface';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
	selector: 'app-badge-dialog',
	templateUrl: './badge-dialog.component.html',
	styleUrls: ['./badge-dialog.component.sass']
})
export class BadgeDialogComponent implements OnInit {
	public shop:any;
	constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
	            @Inject(MAT_DIALOG_DATA) public data: any) {
					this.shop = this.data.shop;
	}

	public ngOnInit() {
	}

	public onNoClick(): void {
		this.dialogRef.close();
	}

}
