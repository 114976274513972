import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, isDevMode, OnInit, PLATFORM_ID, VERSION, } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { APPLICATION_NAME, ISSUES_URL } from './_shared/_others/tokens';
import {
	AccountDataService,
	ErrorsService,
	IconsService,
	LinksService,
	LocalStorageService,
	MetaTagsService,
	NotificationsService,
	RenderingService,
	SCRIPTS,
	ScriptsService,
	TokenService,
	VersionService
} from './_shared/_services';

const BASE_COLOR = '#3a546f';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {

	public testing: boolean;

	constructor(private tokenService: TokenService,
				private iconsService: IconsService,
				private notificationsService: NotificationsService,
				private accountDataService: AccountDataService,
				private localStorageService: LocalStorageService,
				private translateService: TranslateService,
				private route: ActivatedRoute,
				private router: Router,
				private dialog: MatDialog,
				private scriptsService: ScriptsService,
				private errorsService: ErrorsService,
				private linksService: LinksService,
				private renderingService: RenderingService,
				private versionService: VersionService,
				private metaTagsService: MetaTagsService,
				@Inject(DOCUMENT) private document: any,
				@Inject(PLATFORM_ID) private platformId: Object) {
		if (isDevMode()) {
			this.testing = true;
		}
	}

	public ngOnInit() {
		if (isPlatformBrowser(this.platformId)) {
			this.versionService.checkLastUsedVersion();
			this.injectScripts();
			this.injectLinks();
		}

		if (isPlatformBrowser(this.platformId)) {
			isDevMode() ? this.printDevLabel() : this.printLabel();
		}

		this.configureLanguages();
		this.metaTagsService.createDefaultMetaTags('noindex, nofollow, noodp', 'https://pro.weddyplace.com/');
	}

	public onActivate() {
		if (isPlatformBrowser(this.platformId)) {
			window.scroll(0, 0);
		}
	}

	private printLabel() {
		const s = `${new Date(Date.parse(environment.createdAt)).toLocaleString()} || A${VERSION.full} || ${this.renderingService.getRenderingAgentObject().short})}`;

		console.log(`%c
················································
················●●●●········●●●●················
·············●●●●●●●●●●··●●●●●●●●●●·············
············●●●●●●●●●●●●●●●●●●●●●●●●············
············●●●●●····●●●●●●····●●●●●············
············●●●●······●●●●······●●●●············
············●●●●●··············●●●●●············
····●●·······●●●●●●··········●●●●●●·······●●····
···●●●●●······●●●●●●········●●●●●●······●●●●●···
···●●●●●●······●●●●●●······●●●●●●······●●●●●●···
·····●●●●●·······●●●●●····●●●●●·······●●●●●●····
······●●●●●·······●●●●●●●●●●●●·······●●●●●······
·······●●●●●●·······●●●●●●●●●······●●●●●●·······
········●●●●●●·······●●●●●●·······●●●●●●········
·········●●●●●●····●●●●●●●●●·····●●●●●··········
···········●●●●●··●●●●●●·●●●●●··●●●●●···········
············●●●●●●●●●●···●●●●●●●●●●●············
·············●●●●●●●●······●●●●●●●●·············
···············●●●●●········●●●●●···············
················································
        `, 'color: #3a546f');
		console.log(`%c ${APPLICATION_NAME} v.${environment.version}`, `color: ${BASE_COLOR}; font-weight: bold`);
		console.log(`%c ${s}`, `color: ${BASE_COLOR};`);
	}

	private printDevLabel() {
		console.log(`Using Angular ${VERSION.full}`);
		console.log(`%c
················································
················○○○○········○○○○················
·············○○○○○○○○○○··○○○○○○○○○○·············
············○○○○○○○○○○○○○○○○○○○○○○○○············
············○○○○○····○○○○○○····○○○○○············
············○○○○······○○○○······○○○○············
············○○○○○··············○○○○○············
····○○·······○○○○○○··········○○○○○○·······○○····
···○○○○○······○○○○○○········○○○○○○······○○○○○···
···○○○○○○······○○○○○○······○○○○○○······○○○○○○···
·····○○○○○·······○○○○○····○○○○○·······○○○○○○····
······○○○○○·······○○○○○○○○○○○○·······○○○○○······
·······○○○○○○·······○○○○○○○○○······○○○○○○·······
········○○○○○○·······○○○○○○·······○○○○○○········
·········○○○○○○····○○○○○○○○○·····○○○○○··········
···········○○○○○··○○○○○○·○○○○○··○○○○○···········
············○○○○○○○○○○···○○○○○○○○○○○············
·············○○○○○○○○······○○○○○○○○·············
···············○○○○○········○○○○○···············
················································
        `, 'color: #3a546f');
		console.log(`%c ${APPLICATION_NAME} v.${environment.version} DEV`, `color: ${BASE_COLOR}; font-weight: bold`);
		console.log(`%c Built on: ${environment.createdAt}`, `color: ${BASE_COLOR}`);
		console.log(`%c Using Angular ${VERSION.full}`, `color: ${BASE_COLOR}`);
		console.log(`%c ${this.renderingService.getRenderingAgentObject().long}`, `color: ${BASE_COLOR}`);
		console.log(`You can post any issues in ${ISSUES_URL}`);
		if (this.accountDataService.accountData) {
			console.log(`%c Logged in as ${this.accountDataService.accountData.email}`, `color: ${BASE_COLOR}`);
		}
	}

	private injectLinks() {
		this.linksService.addLink({rel: 'stylesheet', type: 'text/css', href: '//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.0.3/cookieconsent.min.css'});
		this.linksService.addLink({rel: 'stylesheet', href: 'https://fonts.googleapis.com/icon?family=Material+Icons'});
	}

	private async injectScripts() {
		try {
			await this.scriptsService.load(SCRIPTS.FONTS).toPromise().catch(err => console.error(err));
			await this.scriptsService.load(SCRIPTS.CONSENT).toPromise().catch(err => console.error(err));
			await this.scriptsService.load(SCRIPTS.COOKIE_CONSENT).toPromise().catch(err => console.error(err));
			await this.scriptsService.load(SCRIPTS.G_TAG).toPromise().catch(err => console.error(err));
			await this.scriptsService.load(SCRIPTS.PEP).toPromise().catch(err => console.error(err));
			await this.scriptsService.load(SCRIPTS.CRISP).toPromise().catch(err => console.error(err));
			// await this.scriptsService.load(SCRIPTS.STRIPE_LOADER).toPromise().catch(err => console.error(err));
		} catch (e) {
			console.error(e);
		} finally {
			if (isDevMode()) {
				const loadedScripts = this.scriptsService.scripts.filter(script => script.loaded).map(script => script.name);
				const failedScripts = this.scriptsService.scripts.filter(script => !script.loaded).map(script => script.name);
				if (loadedScripts.length > 0) {
					console.log(`External scripts loaded: ${loadedScripts}`);
				}
				if (failedScripts.length > 0) {
					console.error(`External scripts with errors: ${failedScripts}`);
				}
			}
		}
	}

	private configureLanguages() {
		this.translateService.setDefaultLang('de');
		this.translateService.use('de');
	}

}
