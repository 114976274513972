import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-spinner-button',
	templateUrl: './spinner-button.component.html',
	styleUrls: ['./spinner-button.component.sass']
})
export class SpinnerButtonComponent implements OnInit {

	constructor() {
	}

	public ngOnInit() {
	}

}
