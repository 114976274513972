import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule, MatSnackBarModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { OfflineAlertComponent } from './offline-alert.component';
import { OfflineSnackBarComponent } from './offline-snack-bar/offline-snack-bar.component';

@NgModule({
	declarations: [
		OfflineAlertComponent,
		OfflineSnackBarComponent
	],
	exports: [
		OfflineAlertComponent
	],
	entryComponents: [
		OfflineSnackBarComponent
	],
	imports: [
		CommonModule,
		MatSnackBarModule,
		TranslateModule.forChild(),
		MatProgressSpinnerModule
	]
})
export class OfflineAlertModule {
}
