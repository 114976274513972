import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Language } from '../_interfaces/language.interface';
import { CdnService } from './cdn.service';

@Injectable({
	providedIn: 'root'
})
export class LanguagesService {

	public get languagesObject() {
		return this._languagesObject;
	}

	public set languagesObject(object) {
		this._languagesObject = object;
		this.languagesObjectChanged.next(this.languagesObject);
	}

	public get languages() {
		return this._languages;
	}

	public set languages(languages) {
		this._languages = languages;
		this.languagesChanged.next(this.languages);
	}

	public languagesChanged = new BehaviorSubject<Language[]>(this.languages);
	public languagesObjectChanged = new BehaviorSubject<{}>(this.languagesObject);

	private _languagesObject: {};

	private _languages: Language[];

	constructor(private cdn: CdnService) {
	}

	public getLanguages() {
		const req = this.cdn.get(`static/misc/languages.json`);

		req.subscribe(
			(languagesObject) => {
				this.languages = languagesObject.languages;
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

}
