import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Partner } from '../_interfaces/partner.interface';
import { ShopsService } from './shops.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PartnersService {

	get partners(): Partner[] {
		return this._partners;
	}

	set partners(newPartners: Partner[]) {
		this._partners = newPartners;
		this.partnersChanged.next(this.partners);
	}

	public partnersChanged = new BehaviorSubject<Partner[]>(this.partners);

	private _partners: Partner[];

	constructor(public api: ApiService,
	            private shopsService: ShopsService) {
	}

	public async getPartners() {
		await this.shopsService.getCurrentShop();
		this.partners = this.shopsService.currentShop.partners;

		// TODO
		// const req = this.api.get(`partners`);
		//
		// req.subscribe(
		//   (partners: Partner[]) => {
		//     // Data found in server
		//     this.partners = partners;
		//     // Data from server saved in service
		//   },
		//   (err) => {
		//     console.error(err);
		//   }
		// );
		//
		// return req;
	}

	// ADD

	public addPartner(partner: Partner) {
		const index = (this.partners.findIndex((anyPartner: Partner) => {
			return anyPartner._id === partner._id;
		}));
		if (index === -1) {
			this.partners.push(partner);
			this.partnersChanged.next(this.partners);
			const currentShop = this.shopsService.currentShop;
			currentShop.partners = this.partners;
			this.shopsService.updateShop(currentShop);
		}
	}

	public postPartner(partner: Partner) {
		const req = this.api.post('partners', partner, true);

		req.subscribe(
			(newPartner: Partner) => {
				this.addPartner(newPartner);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	// EDIT

	public editPartner(partner: Partner) {
		const index = (this.partners.findIndex((anyPartner: Partner) => {
			return anyPartner._id === partner._id;
		}));
		if (index >= 0) {
			this.partners[index] = partner;
		}
	}

	public updatePartner(partner: Partner) {
		const req = this.api.put('partners', partner, true);

		req.subscribe(
			(res) => {
				this.editPartner(res.accommodation);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	// REMOVE

	public removePartner(id) {
		const index = (this.partners.findIndex((anyPartner: Partner) => {
			return anyPartner._id === id;
		}));
		if (index >= 0) {
			this.partners.splice(index, 1);
			this.partnersChanged.next(this.partners);
			const currentShop = this.shopsService.currentShop;
			currentShop.partners = this.partners;
			this.shopsService.updateShop(currentShop);
		}
	}

	public deletePartner(partner: Partner) {
		const req = this.api.delete('partners', partner._id, true);

		req.subscribe(
			() => {
				this.removePartner(partner._id);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public searchPartners(searchTerm) {
		const req = this.api.get(`friends/${searchTerm}`, null, false, true);

		req.subscribe(
			() => {
				// this.removePartner(partner._id);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public addPartnerToInquiry(favId, shop) {
		const req = this.api.post(`pro_partnerSuggestion/${favId}`, shop, true);

		req.subscribe(
			() => {
				// this.removePartner(partner._id);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

	public invitePartner(partner){
		const req = this.api.post(`friends`, partner, false);

		req.subscribe(
			() => {
				console.log("firend posted");
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

}
