import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { ICONS, NODE_PORT } from '../_others/tokens';
import { RenderingService } from './rendering.service';

const LOCAL_HOST_ASSETS: string = `http://localhost:${NODE_PORT}/assets/`;

interface WeddyPlaceIcon {
	KEY: string;
	PATH: string;
	STROKE_PATH: string;
	OUTLINE_PATH: string;
}

@Injectable({
	providedIn: 'root'
})
export class IconsService {

	public pureSvgIcons: string[] = [];

	constructor(private matIconRegistry: MatIconRegistry,
				private domSanitizer: DomSanitizer,
				private http: HttpClient,
				private injector: Injector,
				public renderingService: RenderingService,
				@Inject(PLATFORM_ID) private platformId: Object) {
		if (isPlatformBrowser(this.platformId)) {
			this.addSvgIconsForBrowser();
		} else {
			this.addSvgIconsForServer();
		}

		this.preloadNecessaryIcons();
	}

	public addSvgIconsForBrowser() {
		this.addSvgIcons(environment.assets);
	}

	public addSvgIconsForServer() {
		this.addSvgIcons(LOCAL_HOST_ASSETS);
	}

	public addSvgIcons(assetsPath: string) {
		Object.keys(ICONS).forEach(
			(weddyPlaceIconKey: string) => {
				const weddyPlaceIcon: WeddyPlaceIcon = ICONS[weddyPlaceIconKey];

				if (this.renderingService.isIEOrEdge && weddyPlaceIcon.OUTLINE_PATH) {
					weddyPlaceIcon.PATH = weddyPlaceIcon.OUTLINE_PATH;
				}
				this.matIconRegistry.addSvgIcon(weddyPlaceIcon.KEY, this.domSanitizer.bypassSecurityTrustResourceUrl(assetsPath + weddyPlaceIcon.PATH));
			}
		);
	}

	public preloadNecessaryIcons() {
		this.matIconRegistry.getNamedSvgIcon(ICONS.SMILEY_SAD_1.KEY).toPromise();
	}

	public requestPureSvg(imageUrl) {
		const req = this.http.get(imageUrl, { responseType: 'text' });

		req.subscribe(
			(res) => {
				this.pureSvgIcons.push(res);
			},
			(err) => {
				console.error(err);
			}
		);

		return req;
	}

}
