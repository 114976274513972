import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class TransferStateService {

	constructor() {
	}

	public static addTransferStateKeyParams(params: HttpParams, key: string): HttpParams {
		if (!params) {
			params = new HttpParams();
		}
		return params.append('transferStateKey', key);
	}

}
