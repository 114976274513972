import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/internal/operators';
import { HelpersService } from '../_services';

@Injectable()
export class TransferStateHttpInterceptor implements HttpInterceptor {

	constructor(private transferState: TransferState,
				@Inject(PLATFORM_ID) private platformId: Object) {
	}

	private static generateId(request: HttpRequest<any>): string {
		return request.urlWithParams.substr(request.urlWithParams.lastIndexOf('/') + 1);
	}

	public intercept(request: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
		if (request.method !== 'GET') {
			return httpHandler.handle(request);
		} else {
			const key: StateKey<any> = makeStateKey(request.params.get('transferStateKey') || TransferStateHttpInterceptor.generateId(request));

			if (isPlatformServer(this.platformId)) {
				return this.sendRequestFromServer(request, httpHandler, key);
			} else if (isPlatformBrowser(this.platformId)) {
				if (this.transferState.hasKey(key)) {
					const serverResponse = this.transferState.get(key, null);
					this.transferState.remove(key);
					return of(new HttpResponse({ body: serverResponse, status: 200 }));
				} else {
					return this.sendRequestFromBrowser(request, httpHandler);
				}
			}
		}
	}

	private sendRequestFromBrowser(request: HttpRequest<any>, httpHandler: HttpHandler): Observable<any> | Observable<HttpEvent<any>> {
		// if (isDevMode()) {
		// 	const testKey = makeStateKey(`TEST_${request.params.get('transferStateKey') || TransferStateHttpInterceptor.generateId(request)}`);
		// 	return this.sendRequest(httpHandler, request, testKey);
		// } else {
		// 	return httpHandler.handle(request);
		// }
		return httpHandler.handle(request);
	}

	private sendRequestFromServer(request: HttpRequest<any>, httpHandler: HttpHandler, key: StateKey<any>): Observable<HttpEvent<any>> {
		HelpersService.assert(request, 'request');
		HelpersService.assert(key, 'key');

		const nonSecuredRequest = request.clone({
			url: request.url.replace('https://', 'http://')
		});

		return this.sendRequest(httpHandler, nonSecuredRequest, key);
	}

	private sendRequest(httpHandler: HttpHandler, request, key: StateKey<any>) {
		HelpersService.assert(request, 'request');
		HelpersService.assert(key, 'key');

		return httpHandler.handle(request).pipe(tap((res => {
			if (res instanceof HttpResponse) {
				this.transferState.set(key, res.body);
			}
		})));
	}
}
