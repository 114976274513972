import { Pipe, PipeTransform } from '@angular/core';
import { CategoriesService } from '../_services/categories.service';

@Pipe({
	name: 'getCategoryIcon'
})
export class GetCategoryIconPipe implements PipeTransform {

	constructor(private categoriesService: CategoriesService) {
		this.categoriesService.getCategories();
	}

	public transform(value: string, fancy?: true): string {
		const category = this.categoriesService.findCategory(value);
		if (fancy) {
			return `category-${value}`;
		} else {
			return category.icon || 'question-mark';
		}
	}
}
